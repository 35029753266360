var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { Modal, Select } from '@src/components/elements';
import * as Api from '@src/api';
function failIfUserExists(email) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            yield Api.Account.getAccountExistsByEmail(email);
            throw new Error('exists');
        }
        catch (err) {
            if (err.message === 'exists') {
                throw err;
            }
        }
    });
}
export function InviteUserModal(props) {
    var _a;
    const [isBeingSent, setIsBeingSent] = React.useState(false);
    const intl = useIntl();
    const [form] = Form.useForm();
    const onConfirm = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            setIsBeingSent(true);
            yield form.validateFields();
            const emails = form.getFieldValue('email');
            yield Api.Admin.createInvitation(emails);
            yield props.onSend();
            form.resetFields();
            props.close();
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setIsBeingSent(false);
        }
    }), [form]);
    const accountsEmails = (_a = props.accounts) === null || _a === void 0 ? void 0 : _a.map(({ email }) => email);
    return (React.createElement(Modal, { okText: intl.formatMessage({ id: 'general.buttons.send' }), title: intl.formatMessage({ id: 'admin.users.invite_users' }), width: 500, visible: props.isOpen, onOk: () => onConfirm(), okButtonProps: { loading: isBeingSent }, onCancel: () => {
            form.resetFields();
            props.close();
        }, destroyOnClose: true },
        React.createElement(Form, { form: form, name: "dynamic_form_nest_item", autoComplete: "off", initialValues: { email: [] } },
            React.createElement(Form.Item, { className: "m-b-none", name: "email", fieldKey: "email", normalize: value => value.map(v => v.toLowerCase().trim()).filter(Boolean), rules: [
                    { required: true, message: intl.formatMessage({ id: 'admin.users.missing_email' }) },
                    {
                        type: 'array',
                        defaultField: {
                            type: 'email',
                            message: intl.formatMessage({ id: 'admin.users.not_valid_email' }),
                        },
                        message: intl.formatMessage({ id: 'admin.users.not_valid_email' }),
                    },
                    {
                        validator: (_, value) => value.some(email => accountsEmails.includes(email))
                            ? Promise.reject(new Error(intl.formatMessage({
                                id: 'admin.users.invitation_user_already_exists_in_company',
                            })))
                            : Promise.resolve(),
                    },
                    {
                        validator: (_, value) => __awaiter(this, void 0, void 0, function* () {
                            return new Promise((resolve, reject) => {
                                try {
                                    Promise.all(value.map(email => failIfUserExists(email)))
                                        .then(() => {
                                        resolve();
                                    })
                                        .catch(() => reject(new Error(intl.formatMessage({
                                        id: 'admin.users.invitation_user_already_exists',
                                    }))));
                                }
                                catch (err) {
                                    resolve();
                                }
                            });
                        }),
                    },
                ] },
                React.createElement(Select, { placeholder: "john.doe@email.com", mode: "tags", open: false })))));
}

// @ts-nocheck
import styled, { keyframes } from 'styled-components';
const fadeIn = keyframes `
    from {
        opacity: 0;
        bottom: -40px;
    }
    to {
        opacity: 1;
        bottom: 2rem;
    }
`;
export const ActionsPanel = styled.div `
	bottom: 2rem;
	left: calc(50% - 150px);
	position: fixed;
	width: 300px;
	border-radius: 40px;
	background: white;
	margin: 0 auto;
	border: 1px solid lightgray;
	animation: ${fadeIn} ease 0.5s;
	animation-fill-mode: forwards;
	opacity: 0;
	box-shadow: 0 0 24px #e8e1e1;
`;
export const ActionButton = styled.div `
	cursor: pointer;
	border-left: 1px solid lightgray;
	padding: 0.5rem 1rem 0.5rem 0.5rem;
	border-top-right-radius: 40px;
	border-bottom-right-radius: 40px;
	transition: 0.3s;
	display: flex;
	align-items: center;

	&:hover {
		background: #fff8f8;
	}
`;

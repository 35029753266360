import React from 'react'
import { Col, Row } from 'antd'
import { Holder, Title, Panel } from '@src/components/elements'

const TermsAndConditions = () => {
	return (
		<Holder marginVertical>
			<Row>
				<Col>
					<Title level={1} type="secondary">
						Všeobecné obchodné podmienky
					</Title>
					<Panel>
						<p>
							Obchodné podmienky spoločnosti Praxuj s.r.o., IČO 50 597 523, Nový Svet 1182/40, Banská
							Bystrica 974 01, zapísanej v Obchodnom registri Okresného súdu Banská Bystrica, oddiel Sro,
							vložka č. 30871/S (ďalej len “Prevádzkovateľ”) pre prístup a využitie služieb
							Prevádzkovateľa na internetovej stránke&nbsp;
							<a href="https://praxuj.sk/">https://praxuj.sk</a>.
						</p>
						<h2 className="m-b-s">Úvodné ustanovenia</h2>
						<ol>
							<li>
								Prevádzkovateľ vydáva tieto Všeobecné obchodné podmienky (ďalej len “Podmienky”)
								upravujúce práva a povinnosti Prevádzkovateľa a užívateľov internetovej stránky&nbsp;
								<a href="https://praxuj.sk/">https://praxuj.sk</a>&nbsp;(ďalej len “praxuj.sk”), a to
								pri vstupe na praxuj.sk a jeho ďalšom používaní, a tiež dalšie súvisiace právne vzťahy.
								praxuj.sk slúži na inzerciu stáží a absolvenstkých pozícií.
							</li>
							<li>
								Pred registráciou, alebo iným užívaním praxuj.sk, je užívateľ povinný zoznámiť sa s
								aktuálnym znením Podmienok a povinnosti z nich plynúce dodržiavať. V prípade registrácie
								užívateľa - firmy, je oprávnená tak učiniť len osoba k tomu oprávnená.
							</li>
							<li>
								Podmienky sú záväzné pre všetkých užívateľov služieb a neoddeliteľnou súčasťou zmluvy
								uzavretej s Prevádzkovateľom.
							</li>
							<li>Prevádzkovateľ nesprostredkúva zamestnania za úhradu podľa zákona č. 5/2004 Z. z.</li>
							<li>Prevádzkovateľ je platiteľom DPH.</li>
						</ol>
						<h2 className="m-b-s">Definície pojmov</h2>
						<p>Pojmy použité v týchto Podmienkach majú nasledujúci význam:</p>
						<ul>
							<li>
								<strong>Cenník</strong>&nbsp;- zoznam obsahujúci ceny a podmienky služieb poskytovaných
								Prevádzkovateľom na portáli praxuj.sk;
							</li>
							<li>
								<strong>Firma</strong>&nbsp;- fyzická alebo právnická osoba, ktorá využíva služby
								Prevádzkovateľa na praxuj.sk za účelom nájsť vhodného stážistu alebo zamestnanca;
							</li>
							<li>
								<strong>Klient</strong>&nbsp;- spoločné označenie Firiem a Záujemcov;
							</li>
							<li>
								<strong>Podmienky</strong>&nbsp;- tieto Všeobecné obchodné podmienky;
							</li>
							<li>
								<strong>Ponuka</strong>&nbsp;- ponuka stáže alebo absolventskej pracovnej pozície Firmy
								zverejnená na praxuj.sk;
							</li>
							<li>
								<strong>praxuj.sk</strong>&nbsp;- webový portál umiestnený na adrese&nbsp;
								<a href="https://praxuj.sk/">https://praxuj.sk</a>;
							</li>
							<li>
								<strong>Prevádzkovateľ</strong>&nbsp;- spoločnosť Praxuj s.r.o., IČO 50 597 523, Nový
								Svet 1182/40, Banská Bystrica 974 01, zapísaná v Obchodnom registri Okresného súdu
								Banská Bystrica, oddiel Sro, vložka č. 30871/S;
							</li>
							<li>
								<strong>Služby</strong>&nbsp;- služby, ktoré poskytuje Prevádzkovateľ prostredníctvom
								portálu praxuj.sk;
							</li>
							<li>
								<strong>Užívateľ</strong>&nbsp;- osoba, ktorá používa praxuj.sk bez ohľadu na to, či ide
								o Klienta alebo o neregistrovaného návštevníka;
							</li>
							<li>
								<strong>Záujemca</strong>&nbsp;- fyzická osoba, ktorá využíva služby praxuj.sk za účelom
								nájsť si pracovné uplatnenie.
							</li>
						</ul>
						<h2 className="m-b-s">Služby poskytované Záujemcom</h2>
						<ol>
							<li>
								Záujemca je fyzická osoba, ktorá si prostredníctvom Služieb Prevádzkovateľa hľadá vhodné
								pracovné uplatnenie. Služby poskytované Prevádzkovateľom pre Záujemcov sú bezplatné. Na
								využitie Služieb poskytovaných Záujemcom je vyžadovaná ich registrácia na praxuj.sk.
								Záujemca sa registruje uvedením svojho mena, priezviska, prihlasovacieho emailu,
								prihlasovacieho hesla a zamerania odboru. Záujemca je pri vytvorení účtu povinný uviesť
								pravdivé a aktuálne údaje.
							</li>
							<li>
								Záujemca môže svoju registráciu kedykoľvek zrušiť prostredníctvom emailovej správy
								Prevádzkovateľovi obsahujúce vôľu zrušiť registráciu.
							</li>
							<li>
								Prevádzkovateľ prostredníctvom praxuj.sk poskytuje Záujemcom možnosť vytvoriť si v Účte
								svoj životopis a reagovať na uverejnenú Ponuku na praxuj.sk za týchto podmienok:
							</li>
							<ul>
								<li>táto Služba je dostupná iba registrovaným užívateľom;</li>
								<li>táto Služba je určená iba pre Záujemcov;</li>
								<li>
									pri reakcii na zverejnenú Ponuku môže Záujemca zároveň pripojiť svoj životopis
									a/alebo motivačný list, ak je vyžadovaný.
								</li>
							</ul>
						</ol>
						<h2 className="m-b-s">Služby poskytované Firmám</h2>
						<ol>
							<li>
								Prevádzkovateľ prostredníctvom praxuj.sk poskytuje Firmám možnosť uverejniť Ponuku za
								týchto podmienok:
							</li>
							<ul>
								<li>táto Služba je dostupná iba registrovaným Firmám;</li>
								<li>registrácia Firmy je zdarma;</li>
								<li>zverejnenie Ponuky je spoplatnené;</li>
								<li>Ponuky sú zverejnené maximálne po dobu jedného mesiaca;</li>
								<li>Firma môže zverejniť jednu Ponuku za jeden kredit;</li>
								<li>zakúpený kredit možno uplatniť do 1 roka od jeho zakúpenia;</li>
								<li>pri registrácii Firma obdrží 1 kredit zadarmo;</li>
								<li>
									Základná cena 1 kreditu je 39 EUR + DPH. Cenník obsahuje rozpis cien pri nákupe
									konkrétneho počtu kreditov;
								</li>
								<li>Prevádzkovateľ je platiteľom DPH.</li>
							</ul>
							<li>Ostatné Služby sú poskytované individuálne podľa dohody.</li>
						</ol>
						<h2 className="m-b-s">Vytvorenie Ponuky a uzavretie zmluvy</h2>
						<ol>
							<li>
								Pre využitie Služieb je vyžadovaná registrácia Firmy na praxuj.sk, a to prostredníctvom
								vyplnenia registračného formulára. Po úspešnej registrácii je vytvorený Účet Firmy,
								chránený jedinečným prihlasovacím e-mailom a heslom. Služby si Firma objednáva
								prostredníctvom Účtu.
							</li>
							<li>
								Potvrdením vytvorenia Účtu Firmy zo strany Prevádzkovateľa prostredníctvom emailovej
								správy dochádza k uzatvoreniu rámcovej zmluvy.
							</li>
							<li>
								Potvrdením (prostredníctvom webovej stránky alebo emailom) úhrady kreditov zo strany
								Prevádzkovateľa je uzatvorená jednotlivá zmluva.
							</li>
							<li>Firma uverejní Ponuku sama, určuje jej obsah a tiež čas jej zverejnenia.</li>
							<li>
								Prevádzkovateľ si vyhradzuje právo na úpravu alebo zmazanie zadaných Ponúk bez udania
								dôvodu. Prevádzkovateľ si vyhradzuje právo nezverejniť alebo zrušiť zverejnenú Ponuku,
								ktorá:
							</li>
							<ul>
								<li>
									je v rozpore s právnym poriadkom Slovenskej republiky, všeobecnými etickým
									pravidlami a dobrými mravmi;
								</li>
								<li>
									je neúplná, zavádzajúca, obsahuje nepravdivé údaje, alebo z iného dôvodu znižuje
									úroveň Služieb poskytovaných Prevádzkovateľom;
								</li>
								<li>obsahuje informácie o tretích osobách bez ich vedomia;</li>
								<li>
									obsahuje informácie, ktoré môžu poškodiť dobré meno či povesť Prevádzkovateľa alebo
									iných osôb;
								</li>
								<li>obsahuje informácie zjavne nepravdivé;</li>
								<li>podmieňuje ponuku zaplatením poplatku;</li>
								<li>obsahuje reklamný alebo marketingový obsah;</li>
								<li>prezentuje viac Ponúk v rámci jedného formuláru Ponuky;</li>
								<li>bola zverejnená nad limit povolených ponúk Firmy;</li>
								<li>bola duplicitne zverejnená jedným Klientom na Webe.</li>
							</ul>
						</ol>
						<h2 className="m-b-s">Platobné podmienky</h2>
						<ol>
							<li>
								Všetky ceny sa riadia aktuálnym Cenníkom umiestneným na&nbsp;
								<a href="https://praxuj.sk/firmy">https://praxuj.sk/firmy</a>. Prevádzkovateľ je
								platiteľom DPH.
							</li>
							<li>
								Klient je po výbere počtu kreditov v objednávke vyzvaný na zaplatenie predmetnej čiastky
								online platobnou kartou prostredníctvom platobnej brány. Na základe tejto platby mu bude
								vystavený daňový doklad potvrdzujúci zaplatenie predmetnej čiastky. Firma môže nechať
								bezpečne uložiť údaje platobnej karty pre účely ďalšieho platenia Služieb.
							</li>
							<li>Firma môže byť Prevádzkovateľom upozornená na blížiacu sa exspiráciu kreditov.</li>
							<li>
								Prevádzkovateľ akceptuje platobnú metódu platobnou kartou pomocou online platobnej
								brány. Iný spôsob platby je možný na základe dohody s&nbsp;Prevádzkovateľom
								prostredníctvom emailu info@praxuj.sk.
							</li>
						</ol>
						<h2 className="m-b-s">Použitie portálu praxuj.sk</h2>
						<ol>
							<li>
								Pred používaním praxuj.sk je Užívateľ povinný zoznámiť sa so znením Podmienok.
								Používaním portálu praxuj.sk Užívateľ vyjadruje súhlas so znením Podmienok a zaväzuje sa
								ich dodržiavať. Pokiaľ Užívateľ s Podmienkami nesúhlasí, je povinný bezodkladne portál
								praxuj.sk opustiť a žiadnym iným spôsobom ho nepoužívať.
							</li>
							<li>
								Užívateľ súhlasí s použitím komunikačných prostriedkov na diaľku pri uzatvorení zmluvy s
								Prevádzkovateľom. Prístup a používanie praxuj.sk je bezplatné. Užívateľ nesie náklady mu
								vzniknuté v súvislosti s realizáciou prístupu a používaním praxuj.sk (t.j. náklady na
								internetové pripojenie a iné).
							</li>
							<li>
								Prevádzkovateľ si vyhradzuje právo zrušiť účet v prípade porušenia povinností
								vyplývajúcich z Podmienok Klientom.
							</li>
							<li>
								Firma je oprávnená zadávať Ponuky len po registrácii na praxuj.sk. Pri registrácii a
								zverejňovaní Ponúk je Klient povinný uvádzať pravdivé a aktuálne údaje.
							</li>
							<li>
								Záujemcom je umožnené reagovať na zverejnenú Ponuku po prihlásení sa k Účtu alebo
								zaslaní životopisu zároveň s vytvorením Účtu.
							</li>
							<li>
								Na základe registrácie je Klientovi zriadený účet zabezpečený prístupovým menom a
								heslom. Klient je povinný zachovávať mlčanlivosť ohľadne prístupových údajov a zamedziť
								využívaniu účtu tretím osobám, ktoré nie sú oprávnené na jeho užívanie. Firmám môže byť
								umožnené spravovať profil Firmy prostredníctvom viacerých užívateľských účtov.
							</li>
							<li>
								Účet vytvorený na základe registrácie sa dá kedykoľvek zrušiť prostredníctvom emailovej
								správy Prevádzkovateľovi.
							</li>
						</ol>
						<h2 className="m-b-s">Ochrana osobných údajov a cookies</h2>
						<ol>
							<li>
								Ochrana osobných údajov a používanie cookies sa riadi pravidlami o nakladaní s osobnými
								údajmi a pravidlami používania cookies, ktoré sú uvedené na&nbsp;
								<a href="https://praxuj.sk/ochrana-osobnych-udajov">
									https://praxuj.sk/ochrana-osobnych-udajov
								</a>
								. Tieto dokumenty tvoria nedeliteľnú súčasť týchto Podmienok.
							</li>
							<li>
								Prevádzkovateľom praxuj.sk je spoločnosť Praxuj s.r.o., Nový Svet 1182/40, Banská
								Bystrica 974 01, IČO 50 597 523, email:&nbsp;
								<a href="mailto:gdpr@praxuj.sk">gdpr@praxuj.sk</a>, zapísaná v Obchodnom registri
								Okresného súdu Banská Bystrica, oddiel Sro, vložka č. 30871/S.
							</li>
							<li>
								Spoločnosť Praxuj s.r.o. spracúva osobné údaje dotknutých osôb v súlade so zákonom č.
								18/2018 Z.z., o ochrane osobných údajov a Nariadením Európskeho parlamentu a Rady (EÚ)
								2016/679 z 27.4.2016.
							</li>
							<li>
								Spracúvanie Osobných údajov prebieha v súlade so zásadou zákonnosti, obmedzenia účelu,
								minimalizácie osobných údajov, správnosti, minimalizácie uchovávania, integrity a
								dôvernosti a zodpovednosti v zmysle Zákona a Nariadenia.
							</li>
						</ol>
						<h2 className="m-b-s">Newsletter</h2>
						<ul>
							<li>
								Prevádzkovateľ na praxuj.sk umožňuje zasielanie newsletteru s aktuálnymi reklamnými a
								marketingovými informáciami.
							</li>
							<li>
								Užívateľ zaškrtnutím príslušnej kolónky udeľuje súhlas so spracúvaním svojho emailu pre
								účely zasielania newsletteru s reklamnými a marketingovými informáciami týkajúcimi sa
								Služieb Prevádzkovateľa.
							</li>
							<li>
								Svoj súhlas so spracúvaním údajov môže užívateľ kedykoľvek odvolať v odkaze v prijatom
								newsletteri alebo prostredníctvom emailu Prevádzkovateľovi na&nbsp;
								<a href="mailto:gdpr@praxuj.sk">gdpr@praxuj.sk</a>.
							</li>
							<li>
								Tento systém Prevádzkovateľa je registrovaný v Evidencii informačných systémov osobných
								údajov na Úrade pre ochranu osobných údajov.
							</li>
							<li>
								Účelom spracúvania je poskytnutie reklamných a marketingových informácií týkajúcich sa
								Služieb Prevádzkovateľa Užívateľom. Súhlas je daný na dobu určitú - na obdobie 3 rokov.
							</li>
						</ul>
						<h2 className="m-b-s">Registrácia</h2>
						<ul>
							<li>
								Prevádzkovateľ na praxuj.sk umožňuje vytvorenie Účtu Záujemcu na využitie ďalších
								Služieb Prevádzkovateľa.
							</li>
							<li>
								Záujemca zaškrtnutím príslušnej kolónky prehlasuje, že všetky uvedené údaje sú pravdivé
								a následne poskytuje súhlas so spracúvaním Osobných údajov (meno, priezvisko, email).
							</li>
							<li>
								Účelom spracúvania Osobných údajov je vedenie Účtu a poskytnutie Služieb
								Prevádzkovateľa. Súhlas je daný na dobu určitú - na obdobie 3 rokov.
							</li>
						</ul>
						<h2 className="m-b-s">Životopis</h2>
						<ul>
							<li>
								Prevádzkovateľ na praxuj.sk umožňuje Záujemcom prostredníctvom Účtu vytvorenie svojho
								Životopisu a jeho zmeny. Záujemca má možnosť vo svojom Účte prezerať históriu odoslaných
								Životopisov.
							</li>
							<li>
								Vzhľadom na skutočnosť, že Životopis, uložený v Účte Záujemcu, obsahuje údaje, ktoré sú
								podľa právnych predpisov považované za Osobné údaje, je ich spracúvanie možné iba so
								súhlasom Záujemcu. Záujemca pred vytvorením, resp. uložením Životopisu prehlasuje, že
								všetky uvedené Osobné údaje sú pravdivé a následne udeľuje svoj súhlas so spracúvaním
								Osobných údajov.
							</li>
							<li>
								Účelom spracúvania Osobných údajov je poskytnutie možnosti Záujemcovi vytvoriť si v Účte
								svoj životopis. Súhlas je daný na dobu určitú - obdobie 3 rokov.
							</li>
							<li>
								Prevádzkovateľ nestanovuje žiadne minimálne obsahové požiadavky a uvedenie Osobných
								údajov v Životopise je výlučne na rozhodnutí Záujemcu, a ďalej Prevádzkovateľ v žiadnom
								prípade nepožaduje od Záujemcu uvedenie údajov z osobitnej kategórie osobných údajov
								podle § 16 Zákona alebo čl. 9 Nariadenia.
							</li>
						</ul>
						<h2 className="m-b-s">Mám záujem</h2>
						<ul>
							<li>
								Prevádzkovateľ na praxuj.sk umožňuje Záujemcom prostredníctvom Účtu odpovedať na
								zverejnenú Ponuku, vrátane zaslania motivačného listu alebo životopisu (ak je vyžadovaný
								alebo ho Záujemca chce zaslať).
							</li>
							<li>
								Prevádzkovateľ archivuje odpovede odoslané Záujemcami na Ponuku zverejnenú na praxuj.sk.
								Vzhľadom na skutočnosť, že odpovede obsahujú údaje, ktoré sú podľa právnych nariadení
								považované za Osobné údaje, je spracúvanie možné iba so súhlasom Záujemca.
							</li>
							<li>
								Užívateľ zaškrtnutím príslušnej kolónky prehlasuje, že všetky uvedené údaje sú pravdivé
								a následne udeľuje súhlas so spracúvaním osobných údajov (meno, priezvisko, email,
								životopis, motivačný list, sprievodná správa) spoločnosti Praxuj s.r.o. a Firme, ktorá
								Ponuku uverejnila, ako prevádzkovateľom.
							</li>
							<li>Záujemca má možnosť vo svojom Účtu prezerať svoje odpovede na Ponuky.</li>
							<li>
								Účelom spracúvania Osobných údajov je poskytnutie možnosti prepojiť Firmy hľadajúce
								stážistov a zamestnancov a uchádzačov o stáže a zamestnanie. Spoločnosť Praxuj s.r.o.
								ako Prevádzkovateľ archivuje odpovede odoslané Záujemcami na Ponuky uverejnené na
								praxuj.sk. Súhlas je daný na dobu určitú - po dobu 3 rokov.
							</li>
						</ul>
						<h2 className="m-b-s">Spoločné ustanovenie</h2>
						<ul>
							<li>
								Spoločnosť Praxuj s.r.o. je Prevádzkovateľom - osobou podľa § 5 písm. o) zákona č.
								18/2018 Z. z.
							</li>
							<li>
								Osobné údaje sú uchovávané po dobu 3 rokov od udelenia súhlasu. Užívateľ môže tento
								súhlas opakovane predĺžiť potvrdením predlžovacieho emailu. Po uplynutí tejto doby budú
								údaje v anonymizovanej podobe uchovávané na štatistické účely. Udelený súhlas je
								potrebný na vedenie Účtu a na sprostredkovanie ponúk.
							</li>
							<li>
								Súhlas je možné kedykoľvek odvolať prostredníctvom emailu&nbsp;
								<a href="mailto:gdpr@praxuj.sk">gdpr@praxuj.sk</a>.
							</li>
							<li>Osobné údaje sú spracúvané automaticky.</li>
							<li>Pri spracúvaní Osobných údajov nedôjde k cezhraničnému prenosu do tretích krajín.</li>
							<li>
								Užívateľ má právo na prístup k Osobným údajom, na opravu Osobných údajov, na vymazanie
								Osobných údajov, na obmedzenie spracúvania Osobných údajov, namietať spracúvanie
								Osobných údajov, na prenosnosť Osobných údajov, kedykoľvek svoj súhlas odvolať a podať
								návrh na začatie konania podľa § 100 zákona o ochrane osobných údajov.
							</li>
							<li>Užívateľ bude informovaný o porúšení zabezpečenia osobných údajov.</li>
							<li>
								Užívateľ môže na Prevádzkovateľa podať sťažnosť Úradu pre ochranu osobných údajov
								Slovenskej republiky.
							</li>
							<li>
								Prevádzkovateľ zriadil funkciu zodpovednej osoby, je ňou Jozef Gašparík, email:&nbsp;
								<a href="mailto:gdpr@praxuj.sk">gdpr@praxuj.sk</a>. Zodpovedná osoba je zároveň
								kontaktom pre všetky záležitosti týkajúce sa ochrany osobných údajov.
							</li>
							<li>Dozorným orgánom je Úrad na ochranu osobných údajov SR.</li>
						</ul>
						<h2 className="m-b-s">Cookies</h2>
						<p>
							S cieľom zabezpečiť riadne fungovanie webstránky praxuj.sk môže prevádzkovateľ ukladať v
							zariadení návštevníka malé dátové súbory – cookies, vďaka ktorým si webstránka praxuj.sk
							môže na určitý čas uchovávať údaje o činnosti a nastaveniach (napr. veľkosť písma apod.).
							Webstránka praxuj.sk používa súbory cookies na zapamätanie si užívateľských nastavení
							návštevníka a pre nevyhnutnú funkcionalitu webstránky. Návštevník môže všetky súbory cookies
							uložené v jeho zariadení vymazať a prípadne internetový prehliadač vo svojom zariadení
							nastaviť tak, aby znemožnil ich ukladanie. V takom prípade bude návštevník pri opakovanej
							návšteve webstránky praxuj.sk pravdepodobne musieť manuálne upravovať niektoré nastavenia a
							niektoré služby alebo funkcie webstránky nemusia byť funkčné. Za účelom zlepšenia
							užívateľského prostredia a optimalizácie prevádzkovateľ zároveň spracováva anonymizované
							údaje o správaní sa návštevníka na webovej stránke.
						</p>
						<h2 className="m-b-s">Zodpovednosť za škodu</h2>
						<ol>
							<li>
								Prevádzkovateľ nie je účastníkom zmluvných vzťahov medzi akýmkoľvek Záujemcom a Firmou.
								Prevádzkovateľ umožňuje na portáli praxuj.sk Firmám zverejniť Ponuky, nenesie
								zodpovednosť za prípadnú závadnosť obsahu či vzhľadu obsahu zverejneného Firmou na
								praxuj.sk. Prevádzkovateľ nenesie zodpovednosť za úplnosť, pravdivosť obsahu
								zverejneného na praxuj.sk ani za prípadné nekalosúťažné jednanie prostredníctvom
								praxuj.sk alebo porušenie osobnostných či autorských práv. Prevádzkovateľ nepreberá
								akúkoľvek zodpovednosť za súbory nahrané Klientami.
							</li>
							<li>
								Prevádzkovateľ nezaručuje neprerušený prístup na praxuj.sk, vrátane účtu, ani
								nezávadnosť a bezpečnosť praxuj.sk. Prevádzkovateľ nezodpovedá za škodu spôsobenej
								Užívateľovi pri realizácii prístupu a používaní praxuj.sk, vrátane prípadnej škody
								vzniknutej pri sťahovaní dát zverejnených na praxuj.sk, škody spôsobené prerušením
								prevádzky, poruchou praxuj.sk, počítačovými vírusmi, škody v dôsledku straty dát, disku,
								neoprávneného prístupu k prenosom dát Užívateľa.
							</li>
							<li>
								Kliknutím na niektoré odkazy na portáli praxuj.sk môže dôjsť k opusteniu portálu
								praxuj.sk a k presmerovaniu na webové stránky tretích subjektov.
							</li>
							<li>
								Prevádzkovateľ si vyhradzuje právo kedykoľvek obmedziť či ukončiť prístup
								Klientov/Užívateľov na portál praxuj.sk.
							</li>
							<li>
								Prevádzkovateľ nie je zodpovedný za obsahovú stránku Ponúk zverejnených na praxuj.sk, za
								grafické znázornenie a za ich gramatické spracovanie. Za porušenie práv tretích osôb
								zodpovedá výlučne Klient. Prevádzkovateľ nezodpovedá za škody spôsobené poskytnutím
								Služieb na praxuj.sk.
							</li>
							<li>
								Klient je povinný chrániť údaje získané pri využívaní Služieb Prevádzkovateľa pred
								akýmkoľvek zneužitím, je povinný zabezpečiť ich pred prístupom neoprávnených osôb. V
								prípade porušenia tejto povinnosti za škodu zodpovedá v plnom rozsahu Klient.
								Prevádzkovateľ nezodpovedá za pravdivosť a aktuálnosť údajov Záujemcov.
							</li>
							<li>
								V prípade, že budú voči Prevádzkovateľovi uplatnené nároky z dôvodu porušenia
								akýchkoľvek práv tretích osôb uvedených v tejto časti, Klient sa zaväzuje tieto nároky
								uspokojiť a Prevádzkovateľa odškodniť v plnom rozsahu.
							</li>
							<li>Prevádzkovateľ nezodpovedá za nájdenie vhodného stážistu alebo absolventa.</li>
							<li>
								V prípade omeškania Klienta je Prevádzkovateľ oprávnený dočasne pozastaviť ďalšie
								poskytovanie Služieb Klientovi. Týmto Klientovi nevzniká nárok na náhradu škody a
								náhradu za objednané Služby, které po túto dobu nemohol využívať. Nárok Prevádzkovateľa
								na náhradu škody tým nie je dotknutý.
							</li>
							<li>
								Prevádzkovateľ nenesie žiadnu zodpovednosť za akékoľvek zneužitie prihlasovacích údajov
								neoprávnenou osobou a ani za žiadne prípadné následné škody a prípadné nároky tretích
								osôb spôsobené v dôsledku takého zneužitia.
							</li>
						</ol>
						<h2 className="m-b-s">Reklamačný poriadok</h2>
						<ol>
							<li>
								Klient má právo reklamovať chybu, ktorá sa vyskytla na praxuj.sk v súvislosti s jemu
								poskytovanou Službou.
							</li>
							<li>
								Chybou na praxuj.sk sa rozumie predovšetkým nefunkčnosť Služieb na základe jednotlivej
								zmluvy, a to po dobu dlhšiu než 6 hodín počas 24 hodín.
							</li>
							<li>
								Chybou nie je, pokiaľ Služby Prevádzkovateľa nie sú dostupné v dôsledku nefunkčného
								pripojenia Klienta na internetovú sieť alebo v dôsledku iných skutočností, za ktoré
								Prevádzkovateľ nezodpovedá.
							</li>
							<li>
								Lehota na uplatnenie reklamácie je najneskôr 14 dní odo dňa, kedy Klient chybu zistil
								alebo zistiť mohol.
							</li>
							<li>
								Klient je oprávnený uskutočniť reklamáciu písomne (poštou na adresu Prevádzkovateľa,
								emailom na adresu:&nbsp;<a href="mailto:info@praxuj.sk">info@praxuj.sk</a>). Reklamácia
								musí byť doručená Prevádzkovateľovi. Prevádzkovateľ sa zaväzuje do 14 dní odo dňa
								prevzatia reklamácie oznámiť výsledok jej vybavenia.
							</li>
						</ol>
						<h2 className="m-b-s">Záverečné ustanovenia</h2>
						<ol>
							<li>
								Prevádzkovateľ si vyhradzuje právo na zmenu a doplnenie Podmienok. O tomto je povinný
								informovať Klientov zverejnením zmien na praxuj.sk s uvedením dátumu, od ktorého tieto
								zmeny nadobúdajú platnosť. Nadobudnutím platnosti nových Podmienok strácajú pôvodné
								Podmienky platnosť.
							</li>
							<li>
								Vzťahy neupravené v zmluve a/alebo v Podmienkach, sa riadia platnými právnymi predpismi
								SR.
							</li>
							<li>
								Odchýlne ustanovenia zmluvy majú prednosť pred Podmienkami. Pôsobnosť Podmienok alebo
								ich časti možno vylúčiť iba písomnou dohodou zmluvných strán v Zmluve.
							</li>
							<li>Tieto Podmienky nadobúdajú platnosť dňa 1.3.2021.</li>
						</ol>
					</Panel>
				</Col>
			</Row>
		</Holder>
	)
}

export default TermsAndConditions

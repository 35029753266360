import axios from 'axios'
import config from 'config'

const client = axios.create({
	baseURL: config.api,
	withCredentials: true,
	headers: {
		'Cache-Control': 'no-cache',
		Pragma: 'no-cache',
		Expires: '0',
	},
})

export default client

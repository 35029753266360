import actionNames from 'actions';

const defaultState = {};

export default function offerApplication(state = defaultState, action) {
	switch (action.type) {
		case actionNames.loadOfferApplicationSuccess().type:
			return Object.assign({}, state, {
				application: action.payload.data.application,
			})
		default:
			return state;
	}

}

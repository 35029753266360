import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import URI from 'urijs'
import { history } from '@src/app/index'
import { notification } from 'antd'
import config from 'config'
import actions from '../actions'

export function* companyInfoEditStart(values) {
	const companyImagesData = new FormData()

	if (values.payload.image) {
		companyImagesData.append('image', values.payload.image.originFileObj)
	}
	if (values.payload.title_image_url) {
		companyImagesData.append('title_image_url', values.payload.title_image_url.originFileObj)
	}

	const companyUri = new URI(`${config.api}/0/company`)
	const companyUriString = companyUri.toString()
	const companyImagesUri = new URI(`${config.api}/0/company/images`)
	const companyImagesUriString = companyImagesUri.toString()

	const putData = data =>
		Promise.all([
			axios.put(companyUriString, data, { withCredentials: true }),
			axios.put(companyImagesUriString, companyImagesData, {
				withCredentials: true,
				validateStatus: status => status >= 200 && status <= 304,
			}),
		])
			.then(res => res)
			.catch(err => {
				throw err
			})

	try {
		const companyInfo = { ...values.payload }
		delete companyInfo.title_image_url
		delete companyInfo.image
		const response = yield call(putData, companyInfo)
		yield put(actions.companyInfoEditSuccess(response))
	} catch (err) {
		yield put(actions.companyInfoEditError(err))
	}
}

export function* companyInfoEditError(err) {
	const showError = error => {
		notification.error({
			message: 'Chyba',
			description: error.payload.response.data.error,
		})
	}
	yield call(showError, err)
}

export function* companyInfoEditSuccess() {
	notification.success({
		message: 'Upozornenie',
		description: 'Zmena údajov prebehla úspešne',
	})

	const redirect = () => history.pushLocalized('/admin')
	yield call(redirect)
}

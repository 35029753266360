var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-param-reassign */
import React from 'react';
import produce from 'immer';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { notification } from 'antd';
import * as Api from '@src/api';
import { EOfferApplicationStage } from '@src/types';
import { Columns, Column, ColumnTitle } from './ApplicantsColumns.style';
import { ApplicationCard } from './ApplicationCard';
import { ApplicationCardActionsPanel } from './ApplicationCardActionsPanel';
import { ApplicationColumnsSendMessageModal } from './ApplicationColumnsSendMessageModal';
function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}
const getDefaultApplicationGroups = () => ({
    new: [],
    interview: [],
    hired: [],
    rejected: [],
});
function transformApplicationsToGroups(applications) {
    return applications.reduce((obj, app) => {
        if (app.stage === 'viewed') {
            obj.new.push(app);
            return obj;
        }
        obj[app.stage].push(app);
        return obj;
    }, getDefaultApplicationGroups());
}
export function ApplicantsColumns({ applications, setApplicationDetail, setApplications }) {
    const [applicationGroups, setApplicationGroups] = React.useState(null);
    const [isDragging, setIsDragging] = React.useState(false);
    const [selectedApplications, setSelectedApplications] = React.useState([]);
    const [isSendMessageModalOpen, setIsSendMessageModalOpen] = React.useState(false);
    const intl = useIntl();
    React.useEffect(() => {
        setApplicationGroups(transformApplicationsToGroups(applications));
    }, [applications]);
    const setStage = React.useCallback((id, desiredStage, previousStage, desiredIndex, previousIndex, desiredApplicationGroups) => __awaiter(this, void 0, void 0, function* () {
        try {
            yield Api.OfferApplication.setStage(id, desiredStage);
            setApplications(produce(applications, draft => {
                const application = draft.find(app => String(app.id) === id);
                application.stage = desiredStage;
            }));
        }
        catch (err) {
            const newApplicationGroups = produce(desiredApplicationGroups, draft => {
                const application = draft[desiredStage].find(app => String(app.id) === id);
                application.stage = previousStage;
                if (desiredStage === previousStage) {
                    draft[desiredStage] = reorder(draft[desiredStage], desiredIndex, previousIndex);
                }
                else {
                    draft[previousStage].splice(previousIndex, 0, application);
                    draft[desiredStage].splice(desiredIndex, 1);
                }
            });
            setApplicationGroups(newApplicationGroups);
            notification.error({
                message: intl.formatMessage({
                    id: 'forms.offer_notification_subscription_form.error',
                    defaultMessage: 'Chyba',
                }),
                description: intl.formatMessage({
                    id: 'forms.offer_notification_subscription_form.something_went_wrong',
                    defaultMessage: 'Niekde nastala chyba.',
                }),
            });
        }
    }), [applications]);
    const onDragEnd = React.useCallback(result => {
        const { source, destination, draggableId } = result;
        if (!destination) {
            return null;
        }
        const newApplicationGroups = produce(applicationGroups, (draft) => {
            const application = draft[source.droppableId].find(app => String(app.id) === draggableId);
            application.stage = destination.droppableId;
            if (destination.droppableId === source.droppableId) {
                draft[destination.droppableId] = reorder(draft[destination.droppableId], source.index, destination.index);
            }
            else {
                draft[destination.droppableId].splice(destination.index, 0, application);
                draft[source.droppableId].splice(source.index, 1);
            }
        });
        const isApplicationSelected = selectedApplications.find(app => app.id === Number(draggableId));
        if (isApplicationSelected) {
            const actualizedSelectedApplications = produce(selectedApplications, draft => {
                const applicationToStageChange = draft.find(app => app.id === Number(draggableId));
                applicationToStageChange.stage = destination.droppableId;
            });
            setSelectedApplications(actualizedSelectedApplications);
        }
        setApplicationGroups(newApplicationGroups);
        setStage(draggableId, destination.droppableId, source.droppableId, destination.index, source.index, newApplicationGroups);
        setIsDragging(false);
    }, [applicationGroups, selectedApplications]);
    const onCardSelected = React.useCallback(application => {
        const isApplicationSelected = selectedApplications.find(app => app.id === application.id);
        if (isApplicationSelected) {
            const restOfTheApplications = selectedApplications.filter(app => app.id !== application.id);
            setSelectedApplications(restOfTheApplications);
        }
        else {
            setSelectedApplications([...selectedApplications, application]);
        }
    }, [selectedApplications]);
    if (!applicationGroups) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Columns, null,
            React.createElement("div", { className: "flex-row m-t-m m-b-m" },
                React.createElement(ColumnTitle, null,
                    React.createElement(FormattedMessage, { id: "offer_application.stage.new" })),
                React.createElement(ColumnTitle, null,
                    React.createElement(FormattedMessage, { id: "offer_application.stage.interview" })),
                React.createElement(ColumnTitle, null,
                    React.createElement(FormattedMessage, { id: "offer_application.stage.hired" })),
                React.createElement(ColumnTitle, null,
                    React.createElement(FormattedMessage, { id: "offer_application.stage.rejected" }))),
            React.createElement("div", { className: "flex-row" },
                React.createElement(DragDropContext, { onDragEnd: onDragEnd, onDragStart: () => setIsDragging(true) },
                    React.createElement(Droppable, { droppableId: EOfferApplicationStage.new }, (provided, snapshot) => (React.createElement(Column, Object.assign({ isDragging: isDragging, isDraggingOver: snapshot.isDraggingOver, ref: provided.innerRef }, provided.droppableProps),
                        applicationGroups.new.map((application, index) => (React.createElement(ApplicationCard, { key: application.id, application: application, onOpenDetailClick: app => setApplicationDetail(app), onCardSelected: onCardSelected, isCardSelected: Boolean(selectedApplications.find(app => app.id === application.id)), index: index }))),
                        provided.placeholder))),
                    React.createElement(Droppable, { droppableId: EOfferApplicationStage.interview }, (provided, snapshot) => (React.createElement(Column, Object.assign({ isDragging: isDragging, isDraggingOver: snapshot.isDraggingOver, ref: provided.innerRef }, provided.droppableProps),
                        applicationGroups.interview.map((application, index) => (React.createElement(ApplicationCard, { key: application.id, application: application, onOpenDetailClick: app => setApplicationDetail(app), isCardSelected: Boolean(selectedApplications.find(app => app.id === application.id)), onCardSelected: onCardSelected, index: index }))),
                        provided.placeholder))),
                    React.createElement(Droppable, { droppableId: EOfferApplicationStage.hired }, (provided, snapshot) => (React.createElement(Column, Object.assign({ isDragging: isDragging, isDraggingOver: snapshot.isDraggingOver, ref: provided.innerRef }, provided.droppableProps),
                        applicationGroups.hired.map((application, index) => (React.createElement(ApplicationCard, { key: application.id, application: application, onOpenDetailClick: app => setApplicationDetail(app), isCardSelected: Boolean(selectedApplications.find(app => app.id === application.id)), onCardSelected: onCardSelected, index: index }))),
                        provided.placeholder))),
                    React.createElement(Droppable, { droppableId: EOfferApplicationStage.rejected }, (provided, snapshot) => (React.createElement(Column, Object.assign({ isDragging: isDragging, isDraggingOver: snapshot.isDraggingOver, ref: provided.innerRef }, provided.droppableProps),
                        applicationGroups.rejected.map((application, index) => (React.createElement(ApplicationCard, { key: application.id, application: application, onOpenDetailClick: app => setApplicationDetail(app), isCardSelected: Boolean(selectedApplications.find(app => app.id === application.id)), onCardSelected: onCardSelected, index: index }))),
                        provided.placeholder))))),
            React.createElement(ApplicationCardActionsPanel, { selectedCount: selectedApplications.length, openSendMessageModal: () => setIsSendMessageModalOpen(true) }),
            isSendMessageModalOpen && (React.createElement(ApplicationColumnsSendMessageModal, { setApplications: (applicationSteps) => {
                    const appsWithInterview = Object.values(applicationSteps)
                        .reduce((acc, step) => acc.concat(step.applications), [])
                        .filter(app => app.interviewDate);
                    const newApps = produce(applications, draft => {
                        appsWithInterview.forEach(app => {
                            const appToUpdate = draft.find(a => a.id === app.id);
                            appToUpdate.interview_date = app.interviewDate;
                            appToUpdate.interview_duration_minutes = app.interviewDurationMinutes;
                        });
                    });
                    setApplications(newApps);
                }, applications: transformApplicationsToGroups(selectedApplications), isOpen: isSendMessageModalOpen, onClose: () => setIsSendMessageModalOpen(false) })))));
}

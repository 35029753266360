import React from 'react';
import { Select } from 'antd';
import { useAppContextValue } from '@src/context/app-context';
import ISO6391 from 'iso-639-1';
import { useHistory } from 'react-router-dom';
import { getLocalizedPath } from '@src/shared/localized-path';
import styled from 'styled-components';
const StyledSelect = styled(Select) `
	& {
		width: 7rem;
	}
`;
const LanguageSelector = props => {
    const { Option } = Select;
    const appContext = useAppContextValue();
    const history = useHistory();
    const onSelectHandler = value => {
        history.push(getLocalizedPath(history.location.pathname, value));
        appContext.setLanguage(value);
    };
    return (React.createElement(StyledSelect, Object.assign({ defaultValue: appContext.language, onSelect: onSelectHandler }, props),
        React.createElement(Option, { value: "sk" }, ISO6391.getNativeName('sk')),
        React.createElement(Option, { value: "en" }, ISO6391.getNativeName('en'))));
};
export { LanguageSelector };

import React from 'react'
import classNames from 'classnames'
import { Col, Row } from 'antd'

const geTextareaClasses = show =>
	classNames({
		form__input: true,
		'form__input-textarea': true,
		'form__input--error': show,
	})

const renderTextArea = ({ input, className, label, meta: { touched, error } }) => (
	<div className={`form__row ${className ? className : ''}`}>
		<Row>
			<Col xs={24} id={input.name}>
				<label className="form__label" htmlFor={input.name}>
					{label}
				</label>
				<textarea
					{...input}
					id={input.name}
					data-cy={input.name}
					className={geTextareaClasses(touched && error)}
				/>
				{touched && error && <div className="form__input-error">{error}</div>}
			</Col>
		</Row>
	</div>
)

export default renderTextArea

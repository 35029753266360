import { call, put } from 'redux-saga/effects';
import { history } from '@src/app/index';
import axios from 'axios';
import URI from 'urijs';
import { notification } from 'antd';
import { socket } from '@src/socket';
import { SocketEvents } from '@src/types';
import config from 'config';
import actions from '../actions';
export function* userVerificationStart() {
    // @ts-ignore
    const uri = new URI(`${config.api}/0/verification?token=${history.getQuery().evt}`);
    const apiUri = uri.toString();
    const verifyUser = () => axios
        .get(apiUri, { withCredentials: true })
        .then(res => {
        notification.success({
            message: 'Upozornenie',
            description: 'Overenie prebehlo úspešne',
        });
        socket.emit(SocketEvents.ACCOUNT_VERIFIED, res.data);
        return res;
    })
        .catch(err => {
        throw err;
    });
    try {
        const response = yield call(verifyUser);
        yield put(actions.userVerificationSuccess(response));
    }
    catch (err) {
        yield put(actions.userVerificationError(err));
    }
}
export function* userVerificationError() {
    const showNotification = () => notification.error({
        message: 'Error',
        description: 'Niekde nastala chyba!',
    });
    yield call(showNotification);
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up } from 'styled-breakpoints';
import { Panel } from '@src/components/elements';
import { FiltersTitle, CollapseIcon, CollapsePanelWrapper, CollapseWrapper } from './Filters.style';
import { TypeFilter } from './TypeFilter';
import { LocationFilter } from './LocationFilter';
import { JobAreaFilter } from './JobAreaFilter';
import { useOffersFilterContextValue } from '../../filter-context';
function Filters({ loading }) {
    const [isCollapsed, setIsCollapsed] = React.useState(false);
    const isDesktopBreakpoint = useBreakpoint(up('lg'));
    const offerFilterContextValue = useOffersFilterContextValue();
    const { offersFiltersSSR } = offerFilterContextValue;
    React.useEffect(() => {
        if (isDesktopBreakpoint) {
            setIsCollapsed(false);
        }
    }, [isDesktopBreakpoint]);
    return (React.createElement(Panel, { className: "p-m" }, offersFiltersSSR && (React.createElement(CollapseWrapper, { activeKey: isCollapsed ? [] : '1', onChange: () => setIsCollapsed(!isCollapsed), bordered: false, expandIcon: ({ isActive }) => React.createElement(CollapseIcon, { rotate: isActive ? 180 : 0 }), expandIconPosition: "right", defaultActiveKey: "1", ghost: true },
        React.createElement(CollapsePanelWrapper, { header: React.createElement(FiltersTitle, null,
                React.createElement(FormattedMessage, { id: "offers.filter.filter", defaultMessage: "Filter pon\u00FAk" })), key: "1" },
            React.createElement(React.Fragment, null, offersFiltersSSR.type && (React.createElement(TypeFilter, { typeFilterItems: offersFiltersSSR.type, loading: loading }))),
            React.createElement(React.Fragment, null, offersFiltersSSR.location && (React.createElement(LocationFilter, { locationFilterItems: offersFiltersSSR.location, isLoading: loading }))),
            React.createElement(React.Fragment, null, offersFiltersSSR.jobArea && (React.createElement(JobAreaFilter, { jobAreaFilterItems: offersFiltersSSR.jobArea, loading: loading }))))))));
}
export { Filters };

import React from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import actions from 'actions';
import MenuHeader from '@src/components/modules/navigation/Header';
import { Footer } from '@src/components/modules/navigation/Footer';
import CookieConsent from 'components/modules/global/cookie-consent';
import { AccountAuntenticationModal } from '@src/components/modules/AccountAuthenticationModal';
import { Head } from '@src/components/elements';
import { useAuthContextProvider } from '@src/context/auth-context';
import { BackToTop } from './BackToTop';
export const MainSection = styled.div `
	flex: 1 0 auto;
	background: ${props => props.theme.color.background};
	${props => !props.withHero &&
    css `
			padding-top: ${props.theme.size.headerHeight}px; /* to handle fixed header */
		`}
`;
const LayoutMain = props => {
    var _a;
    // @ts-ignore
    const cookieConsent = useSelector(state => state.app.cookieConsent);
    const dispatch = useDispatch();
    const { value: authContextValue, AuthContextProvider } = useAuthContextProvider();
    return (React.createElement(AuthContextProvider, { value: authContextValue },
        React.createElement(Layout, null,
            !cookieConsent && React.createElement(CookieConsent, { onAgree: () => dispatch(actions.cookieConsentStart()) }),
            React.createElement(Head, null),
            React.createElement(MenuHeader, { navigation: props.navigation }),
            React.createElement(MainSection, { withHero: (_a = props.navigation) === null || _a === void 0 ? void 0 : _a.withHero }, props.children),
            React.createElement(Footer, null),
            React.createElement(BackToTop, null)),
        React.createElement(AccountAuntenticationModal, null)));
};
export { LayoutMain };

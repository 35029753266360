// @ts-nocheck
import styled, { css } from 'styled-components';
const colorSelected = '#1890ff';
export const Application = styled.div `
	border: 2px solid lightgray;
	height: 90px;
	margin: 0.5rem;
	width: auto;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0.5rem;
	background: white;
	transition: 0.3s;

	&:hover {
		border: 2px solid gray;
	}

	${({ isDragging }) => isDragging &&
    css `
			background: #f7eae4;
			box-shadow: -4px 4px 8px lightgray;
			opacity: 0.75;
		`}

	${({ isCardSelected }) => isCardSelected &&
    css `
			border: 2px solid ${colorSelected};

			&:hover {
				border: 2px solid ${colorSelected};
			}
		`}
`;
export const ApplicationContent = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
`;
export const MainBlock = styled.div `
	position: relative;
	min-height: 70px;
	height: 70px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
export const TimestampWrapper = styled.span `
	color: lightgray;
	font-size: 0.75rem;
`;
export const CheckIconWrapper = styled.div `
	position: absolute;
	color: ${colorSelected};
	top: -0.5rem;
	right: -0.2rem;

	& svg {
		height: 1.3rem;
		width: 1.3rem;
	}
`;
export const NameWrapper = styled.div `
	position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

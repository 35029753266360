import { call, put } from 'redux-saga/effects'
import { history } from '@src/app/index'
import axios from 'axios'
import URI from 'urijs'
import { notification } from 'antd'
import config from 'config'
import actions from '../actions'

export function* personalInfoInitStart() {
	const uri = new URI(`${config.api}/0/account`)
	const apiUri = uri.toString()

	const getData = () =>
		axios
			.get(apiUri, { withCredentials: true })
			.then(res => res)
			.catch(err => {
				throw err
			})

	try {
		const response = yield call(getData)
		yield put(actions.personalInfoInitSuccess(response))
	} catch (err) {
		yield put(actions.personalInfoInitError(err))
	}
}

export function* personalInfoInitError(err) {
	const showError = () => {
		notification.error({
			message: 'Chyba',
			description: 'Užívateľ nie je prihlásený',
		})
	}
	yield call(showError(err))
}

export function* personalInfoEditStart(values) {
	const uri = new URI(`${config.api}/0/account`)
	const apiUri = uri.toString()

	const putData = data =>
		axios
			.put(apiUri, data, { withCredentials: true })
			.then(res => res)
			.catch(err => {
				throw err
			})

	try {
		const response = yield call(putData, values.payload)
		yield put(actions.personalInfoEditSuccess(response.data))
		yield put(actions.authCheckStart())
	} catch (err) {
		yield put(actions.personalInfoEditError(err))
	}
}

export function* personalInfoEditError(err) {
	const showError = error => {
		notification.error({
			message: 'Chyba',
			description: error.payload.response.data.error,
		})
	}
	yield call(showError, err)
}

export function* personalInfoEditSuccess(action) {
	notification.success({
		message: 'Upozornenie',
		description: 'Zmena údajov prebehla úspešne',
	})
	const redirect = () => {
		if (action.payload.account_type_id === config.accountType.company) {
			history.pushLocalized('/admin')
		} else {
			history.pushLocalized('/profil')
		}
	}

	yield call(redirect)
}

import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import actions from '@src/actions';
export function CreditsReplenishedDialog() {
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    // @ts-ignore
    const isOpenCreditsReplenishedDialog = useSelector(state => state.admin.isOpenCreditsReplenishedDialog);
    const goToUpsell = () => {
        // @ts-ignore
        history.pushLocalized('/admin/pridat-ponuku');
        dispatch(actions.adminToggleCreditsReplenishedDialog(false));
    };
    return (React.createElement(Modal, { onCancel: () => dispatch(actions.adminToggleCreditsReplenishedDialog(false)), onOk: goToUpsell, okText: intl.formatMessage({ id: 'admin.offers.add_offer' }), visible: isOpenCreditsReplenishedDialog, title: React.createElement(FormattedMessage, { id: "global.notification.payment_success_dialog.start_advertise" }) },
        React.createElement("div", { className: "flex-col justify-center align-center p-l" },
            React.createElement("div", { className: "m-b-l" },
                React.createElement("img", { src: "/media/images/icon-circle-coin-sparkle.svg", alt: "" })),
            React.createElement("div", { className: "font-size-l" },
                React.createElement(FormattedMessage, { id: "global.notification.payment_success_dialog.credits_replenished" })))));
}

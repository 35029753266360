var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Modal } from '@src/components/elements';
import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useAuthContextValue } from '@src/context/auth-context';
import * as Api from '@src/api';
import actions from '@src/actions';
import LoginForm from '../login-form';
export function AccountAuntenticationModal() {
    const { isModalOpen, setIsModalOpen, authModalPromise } = useAuthContextValue();
    const [loginError, setLoginError] = React.useState(null);
    const dispatch = useDispatch();
    const onSubmit = React.useCallback((data) => __awaiter(this, void 0, void 0, function* () {
        try {
            const account = yield Api.Auth.login(data.email, data.password);
            console.log(account);
            dispatch(actions.authCheckSuccess(account));
            authModalPromise.resolve();
            setIsModalOpen(false);
        }
        catch (err) {
            setLoginError(err);
        }
    }), [authModalPromise]);
    const onOAuthSuccess = React.useCallback((account) => {
        authModalPromise.resolve(account);
        setIsModalOpen(false);
    }, [authModalPromise]);
    React.useEffect(() => {
        if (typeof FB !== 'undefined') {
            FB.init({
                appId: '492836724588742',
                cookie: true,
                xfbml: true,
                version: 'v7.0',
            });
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { centered: true, visible: isModalOpen, footer: null, onCancel: () => {
                authModalPromise.reject();
                setIsModalOpen(false);
            } },
            React.createElement("h2", { className: "text-center m-b-l" },
                React.createElement(FormattedMessage, { id: "login.login", defaultMessage: "Prihl\u00E1senie" })),
            React.createElement(LoginForm, { loginError: loginError, onSubmit: onSubmit, showRegistrationButton: true, onOAuthSuccess: onOAuthSuccess }))));
}

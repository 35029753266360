import { offerTypeMap } from '@src/types/offer';
export function getFilterParamsFromPathname(pathname, locale) {
    const offerTypeUrlCategory = 'type';
    let page = 1;
    let offerTypesParam;
    const urlParts = pathname.split('/');
    const getLastUrlPart = () => urlParts[urlParts.length - 1];
    // do not take page number into account
    if (parseInt(getLastUrlPart(), 10)) {
        page = parseInt(urlParts.pop(), 10);
    }
    if (getLastUrlPart().includes(`${offerTypeUrlCategory}:`)) {
        offerTypesParam = urlParts.pop();
    }
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [_, offerTypes] = offerTypesParam ? offerTypesParam.split(':') : [];
    const offerTypeIds = offerTypes ? offerTypes.split(',').map(type => offerTypeMap[locale][type]) : [];
    const pageParam = page === 1 || !page ? '' : String(page);
    return {
        offerTypeIds,
        offerTypesParam,
        page,
        pageParam,
    };
}

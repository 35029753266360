const config = {
	marketing: {
		companies: 500,
		users: 4200,
		offers: 900,
		responses: 4600,
	},
	pricing: {
		VAT: 20,
		levels: [
			{
				limit: 4,
				price: 39,
			},
			{
				limit: 9,
				price: 37.5,
			},
			{
				limit: 19,
				price: 36,
			},
			{
				limit: 29,
				price: 34,
			},
			{
				limit: 39,
				price: 32,
			},
			{
				limit: 50,
				price: 29,
			},
		],
	},
	offerTypes: [
		{ name: 'Internship', id: 1 },
		{ name: 'Permanent job', id: 2 },
		{ name: 'Volunteering', id: 3 },
		{ name: 'Temporary job', id: 4 },
		{ name: 'Work & travel', id: 5 },
		{ name: 'Contractor', id: 6 },
	],
	jobAreas: [
		{ name: 'Administratíva a zákaznícka podpora', id: 1 },
		{ name: 'Automobilový priemysel (výroba a servis)', id: 2 },
		{ name: 'Bankovníctvo a poisťovníctvo', id: 3 },
		{ name: 'Celulózo-papierenský priemysel', id: 4 },
		{ name: 'Chemická a farmaceutická výroba', id: 5 },
		{ name: 'Doprava, špedícia a logistika', id: 6 },
		{ name: 'Drevospracujúci priemysel', id: 7 },
		{ name: 'Ekonomika, účtovníctvo a finančné služby', id: 8 },
		{ name: 'Elektrotechnika', id: 9 },
		{ name: 'Energetika, plyn a elektrina', id: 10 },
		{ name: 'Farmácia', id: 11 },
		{ name: 'Gastronómia', id: 12 },
		{ name: 'Hospodárska správa, prevádzka, požiarna ochrana a BOZP', id: 13 },
		{ name: 'Hotelierstvo a cestovný ruch', id: 14 },
		{ name: 'Hutníctvo, zlievarenstvo a kováčstvo', id: 15 },
		{ name: 'Informačné technológie', id: 16 },
		{ name: 'Kultúra a umenie', id: 17 },
		{ name: 'Lesné hospodárstvo a vodohospodárstvo', id: 18 },
		{ name: 'Manažment, kontrola a štatistika', id: 19 },
		{ name: 'Marketing, reklama a PR', id: 20 },
		{ name: 'Obchodné zastupovanie a reality', id: 21 },
		{ name: 'Ozbrojené sily, ochrana, bezpečnosť a hasiči', id: 23 },
		{ name: 'Personalistika a ľudské zdroje', id: 24 },
		{ name: 'Poľnohospodárstvo, potravinárstvo a veterinárstvo', id: 25 },
		{ name: 'Právo, advokácia a súdnictvo', id: 26 },
		{ name: 'Predaj tovaru (maloobchod, veľkoobchod)', id: 27 },
		{ name: 'Prekladateľstvo a tlmočníctvo', id: 28 },
		{ name: 'Skladovanie a pomocné práce', id: 29 },
		{ name: 'Sklárstvo a keramická výroba', id: 30 },
		{ name: 'Služby a remeslá', id: 31 },
		{ name: 'Sociálne služby', id: 32 },
		{ name: 'Spoločenskovedné služby', id: 33 },
		{ name: 'Štátna správa a samospráva', id: 34 },
		{ name: 'Stavebníctvo a architektúra', id: 35 },
		{ name: 'Strojárstvo', id: 36 },
		{ name: 'Ťažba a úprava surovín, geológia', id: 37 },
		{ name: 'Telekomunikácie', id: 38 },
		{ name: 'Textilný, kožiarsky a odevný priemysel', id: 39 },
		{ name: 'Veda a výskum', id: 40 },
		{ name: 'Vrcholový manažment', id: 41 },
		{ name: 'Vzdelávanie, výchova a šport', id: 42 },
		{ name: 'Zdravotníctvo', id: 43 },
		{ name: 'Životné prostredie a odpadové hospodárstvo', id: 44 },
		{ name: 'Žurnalistika, médiá a polygrafia', id: 45 },
		{ name: 'Ostatné', id: 22 },
	],
	jobAreasEn: [
		{ name: 'Administration and customer support', id: 1 },
		{ name: 'Automotive (manufacturing and repair)', id: 2 },
		{ name: 'Banking and insurance', id: 3 },
		{ name: 'Paper industry', id: 4 },
		{ name: 'Chemical and pharmaceutical production', id: 5 },
		{ name: 'Transport, shipping and logistics', id: 6 },
		{ name: 'Wood processing industry', id: 7 },
		{ name: 'Economics, accounting and financial services', id: 8 },
		{ name: 'Electrical engineering', id: 9 },
		{ name: 'Energy, gas and electricity', id: 10 },
		{ name: 'Pharmacy', id: 11 },
		{ name: 'Gastronomy', id: 12 },
		{ name: 'Governance, operations, fire protection and occupational health and safety', id: 13 },
		{ name: 'Hotel management and tourism', id: 14 },
		{ name: 'Metallurgy, foundry and blacksmithing', id: 15 },
		{ name: 'Information technology', id: 16 },
		{ name: 'Culture and art', id: 17 },
		{ name: 'Forestry and water management', id: 18 },
		{ name: 'Management, controlling and statistics', id: 19 },
		{ name: 'Marketing, advertising and PR', id: 20 },
		{ name: 'Business representation and real estate', id: 21 },
		{ name: 'Armed forces, protection, security and firefighters', id: 23 },
		{ name: 'Human resources', id: 24 },
		{ name: 'Agriculture, food and veterinary medicine', id: 25 },
		{ name: 'Law, advocacy and justice', id: 26 },
		{ name: 'Retail and wholesale', id: 27 },
		{ name: 'Translation and interpretation', id: 28 },
		{ name: 'Storage and assisting jobs', id: 29 },
		{ name: 'Glass and ceramic production', id: 30 },
		{ name: 'Services and crafts', id: 31 },
		{ name: 'Social services', id: 32 },
		{ name: 'Social science services', id: 33 },
		{ name: 'State and local government', id: 34 },
		{ name: 'Construction and architecture', id: 35 },
		{ name: 'Engineering', id: 36 },
		{ name: 'Mining and processing of raw materials, geology', id: 37 },
		{ name: 'Telecommunications', id: 38 },
		{ name: 'Textile, leather and clothing industry', id: 39 },
		{ name: 'Science and research', id: 40 },
		{ name: 'Senior Management', id: 41 },
		{ name: 'Education, training and sport', id: 42 },
		{ name: 'Healthcare', id: 43 },
		{ name: 'Ecology and waste management', id: 44 },
		{ name: 'Journalism, media and printing', id: 45 },
		{ name: 'Other', id: 22 },
	],
	locations: [
		{ name: 'Slovakia', id: 1 },
		{ name: 'Banska Bystrica region', id: 2 },
		{ name: 'Bratislava region', id: 3 },
		{ name: 'Kosice region', id: 4 },
		{ name: 'Nitra region', id: 5 },
		{ name: 'Presov region', id: 6 },
		{ name: 'Trencin region', id: 7 },
		{ name: 'Trnava region', id: 8 },
		{ name: 'Zilina region', id: 9 },
		{ name: 'Bratislava', id: 10 },
		{ name: 'Kosice', id: 11 },
		{ name: 'Lucenec district', id: 12 },
		{ name: 'Rimavska Sobota district', id: 13 },
		{ name: 'Galanta district', id: 14 },
		{ name: 'Bardejov district', id: 15 },
		{ name: 'Kezmarok district', id: 16 },
		{ name: 'Turcianske Teplice district', id: 17 },
		{ name: 'Presov district', id: 18 },
		{ name: 'Trencin district', id: 19 },
		{ name: 'Humenne district', id: 20 },
		{ name: 'Nitra district', id: 21 },
		{ name: 'Nove Zamky district', id: 22 },
		{ name: 'Topolcany district', id: 23 },
		{ name: 'Roznava district', id: 24 },
		{ name: 'Spisska Nova Ves district', id: 25 },
		{ name: 'Vranov nad Toplou district', id: 26 },
		{ name: 'Namestovo district', id: 27 },
		{ name: 'Zvolen district', id: 28 },
		{ name: 'Dunajska Streda district', id: 29 },
		{ name: 'Trebisov district', id: 30 },
		{ name: 'Kosice - surroundings', id: 31 },
		{ name: 'Brezno district', id: 32 },
		{ name: 'Banska Stiavnica district', id: 33 },
		{ name: 'Banska Bystrica district', id: 34 },
		{ name: 'Pezinok district', id: 35 },
		{ name: 'Michalovce district', id: 36 },
		{ name: 'Komarno district', id: 37 },
		{ name: 'Sabinov district', id: 38 },
		{ name: 'Levice district', id: 39 },
		{ name: 'Levoca district', id: 40 },
		{ name: 'Velky Krtis district', id: 41 },
		{ name: 'Stropkov district', id: 42 },
		{ name: 'Piestany district', id: 43 },
		{ name: 'Banovce nad Bebravou district', id: 44 },
		{ name: 'Ziar nad Hronom district', id: 45 },
		{ name: 'Sobrance district', id: 46 },
		{ name: 'Poprad district', id: 47 },
		{ name: 'Nové Mesto nad Váhom district', id: 48 },
		{ name: 'Zilina district', id: 49 },
		{ name: 'Martin district', id: 50 },
		{ name: 'Snina district', id: 51 },
		{ name: 'Zlate Moravce district', id: 52 },
		{ name: 'Svidnik district', id: 53 },
		{ name: 'Puchov district', id: 54 },
		{ name: 'Liptovsky Mikulas district', id: 55 },
		{ name: 'Senec district', id: 56 },
		{ name: 'Ruzomberok district', id: 57 },
		{ name: 'Trnava district', id: 58 },
		{ name: 'Senica district', id: 59 },
		{ name: 'Povazska Bystrica district', id: 60 },
		{ name: 'Ilava district', id: 61 },
		{ name: 'Prievidza district', id: 62 },
		{ name: 'Hlohovec district', id: 63 },
		{ name: 'Malacky district', id: 64 },
		{ name: 'Partizanske district', id: 65 },
		{ name: 'Bratislava V district', id: 66 },
		{ name: 'Bratislava IV district', id: 67 },
		{ name: 'Bratislava III district', id: 68 },
		{ name: 'Bratislava II district', id: 69 },
		{ name: 'Bratislava I district', id: 70 },
		{ name: 'Zarnovica district', id: 71 },
		{ name: 'Medzilaborce district', id: 72 },
		{ name: 'Myjava district', id: 73 },
		{ name: 'Poltar district', id: 74 },
		{ name: 'Tvrdosin district', id: 75 },
		{ name: 'Skalica district', id: 76 },
		{ name: 'Bytca district', id: 77 },
		{ name: 'Dolny Kubin district', id: 78 },
		{ name: 'Krupina district', id: 79 },
		{ name: 'Cadca district', id: 80 },
		{ name: 'Stara Lubovna district', id: 81 },
		{ name: 'Revuca district', id: 82 },
		{ name: 'Detva district', id: 83 },
		{ name: 'Sala district', id: 84 },
		{ name: 'Kysucke Nove Mesto district', id: 85 },
		{ name: 'Gelnica district', id: 86 },
		{ name: 'Košice IV district', id: 87 },
		{ name: 'Košice III district', id: 88 },
		{ name: 'Košice I district', id: 89 },
		{ name: 'Košice II district', id: 90 },
	],
	magazine: {
		postsPerPage: 6,
	},
	offers: {
		itemsPerPage: 10,
	},
	datetimeFormat: 'YYYY-MM-DD HH:mm:ss',
	baseUrl: `${process.env.PROTOCOL}://${process.env.HOST}${
		process.env.ENVIRONMENT === 'local' ? `:${process.env.PORT}` : ''
	}`,
	cdn: {
		companyImageFolder: `${process.env.ENVIRONMENT}/web/company`,
	},
	locale: 'sk', // this will be handled with i18n later
	plan: {
		free: process.env.PLAN_FREE,
		partner: process.env.PLAN_PARTNER,
		startup: {
			1: process.env.PLAN_STARTUP_1M,
			12: process.env.PLAN_STARTUP_12M,
		},
		profi: {
			1: process.env.PLAN_PROFI_1M,
			12: process.env.PLAN_PROFI_12M,
		},
		corporate: {
			1: process.env.PLAN_CORPORATE_1M,
			12: process.env.PLAN_CORPORATE_12M,
		},
	},
}

export default config

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Parser } from 'html-to-react';
import { useHistory, useParams } from 'react-router';
import { useSSE } from 'use-sse';
import striptags from 'striptags';
import { Helmet } from 'react-helmet';
import getSocialImageUrl from '@src/backend/helpers/get-social-image-url';
import { useConfig } from '@src/hooks/useConfig';
import { Button, Holder, Separator } from '@src/components/elements';
import IconAdd from '@streamlinehq/streamlinehq/img/streamline-bold/interface-essential/remove-add/add.svg';
import IconCheck from '@streamlinehq/streamlinehq/img/streamline-bold/interface-essential/form-validation/check.svg';
import IconMapsPin from '@streamlinehq/streamlinehq/img/streamline-bold/maps-navigation/maps/maps-pin.svg';
import IconBriefcase from '@streamlinehq/streamlinehq/img/streamline-bold/business-products/business/briefcase.svg';
import IconFolderOpen from '@streamlinehq/streamlinehq/img/streamline-bold/files-folders/folders/folder-open.svg';
import IconMultipleUsers from '@streamlinehq/streamlinehq/img/streamline-bold/users/geometric-full-body-multiple-users/multiple-users-1.svg';
import IconNetworkBrowser from '@streamlinehq/streamlinehq/img/streamline-bold/internet-networks-servers/worldwide-web/network-browser.svg';
import IconBuildings from '@streamlinehq/streamlinehq/img/streamline-bold/building-construction/buildings/buildings-1.svg';
import getSalaryString from '@src/shared/get-salary-string';
import SlugOffer from '@src/shared/slug-offer';
import cdnLink from '@src/shared/cdn-link';
import * as Api from '@src/api';
import { useCompanyProfileBoxInfo } from '@src/components/modules/global/CompanyProfileBox/use-company-profile-box-info';
import { Main, Header, Body, CompanyBlock, CompanyInfo, MoreInfo, SocialButtons, BodyContent, SubText, Description, IconWrapper, CompanyContent, AddressBox, Position, Heading, TotalCount, StyledPanel, CompanyMainPanel, NumbersTitle, CompanyNumbers, SocialIconFacebook, SocialIconLinkedIn, SocialIconInstagram, SocialIconTwitter, Subheading, ImageBlock, } from './Company.style';
const htmlToReactParser = new Parser();
function CompanyPage() {
    const params = useParams();
    const [localSubscribersCount, setLocalSubscribersCount] = React.useState(null);
    const history = useHistory();
    const [company] = useSSE(
    // @ts-ignore
    () => Api.Company.getCompanyByUrl(params.companyUniqueUrl).catch(() => history.pushLocalized('/error')), [localSubscribersCount]);
    const intl = useIntl();
    const config = useConfig();
    const { isAccountSubscribed, subscribersCount, toggleSubscription, isSubscriptionLoading } = useCompanyProfileBoxInfo(company);
    React.useEffect(() => {
        setLocalSubscribersCount(subscribersCount);
    }, [subscribersCount]);
    if (!company) {
        // TODO skeleton
        return null;
    }
    const activeOffers = company.offers.filter(o => o.is_active === 1);
    const publishedOffers = company.offers.filter(o => Boolean(o.first_published_at));
    const companyActiveOffers = activeOffers.map(({ id, title, city, offer_type_id, salary_rate, salary_amount_from, salary_amount_to, salary_currency, is_paid, }) => {
        var _a;
        const salaryString = salary_amount_from && is_paid
            ? getSalaryString({
                rate: salary_rate,
                amountFrom: salary_amount_from,
                amountTo: salary_amount_to,
                currency: salary_currency,
            })
            : '';
        return {
            key: id,
            position: title,
            salary: salaryString,
            type: (_a = config.offerTypes.find(type => type.id === offer_type_id)) === null || _a === void 0 ? void 0 : _a.name,
            city: city || intl.formatMessage({ id: 'offer_detail.details.remote_location' }),
            link: `/ponuka/${new SlugOffer({ text: title }).result}-${id}`,
        };
    });
    const imageBackgroundUrl = company.title_image_url
        ? cdnLink({
            file: `web/company/${company.title_image_url}`,
            transformation: '',
        })
        : '/media/images/background-gradient.jpg';
    const hasAtleastOneSocial = [
        company.facebook_url,
        company.linkedin_url,
        company.instagram_url,
        company.twitter_url,
    ].some(Boolean);
    const companyImageUrl = getSocialImageUrl({
        companyImage: company.image,
    });
    const companyDescriptionMeta = striptags(company.description).substring(0, 250);
    return (React.createElement(Main, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, `${company.name} | Praxuj.sk`),
            React.createElement("meta", { name: "description", content: companyDescriptionMeta }),
            React.createElement("meta", { property: "og:title", content: `${company.name} | Praxuj.sk` }),
            React.createElement("meta", { property: "og:type", content: "article" }),
            React.createElement("meta", { property: "og:description", content: companyDescriptionMeta }),
            React.createElement("meta", { property: "og:image", content: companyImageUrl })),
        React.createElement(Holder, { marginVertical: true },
            React.createElement(Header, { className: "flex-center", background: imageBackgroundUrl }),
            React.createElement(Body, null,
                React.createElement(ImageBlock, null,
                    company.image && (React.createElement("img", { src: cdnLink({
                            file: `web/company/${company.image}`,
                            transformation: '',
                        }), alt: "logo" })),
                    !company.image && React.createElement(IconBuildings, { viewBox: "0 0 24 24" })),
                React.createElement(CompanyBlock, null,
                    React.createElement(CompanyMainPanel, null,
                        React.createElement(CompanyContent, null,
                            React.createElement("div", { className: "m-b-l" },
                                React.createElement(Heading, { className: "text-center m-none font-size-xxxl" }, company.name)),
                            React.createElement(CompanyNumbers, null,
                                React.createElement("div", { className: "flex-1 flex-col align-center" },
                                    React.createElement(NumbersTitle, null,
                                        React.createElement(FormattedMessage, { id: "company.profile_box.offers" })),
                                    React.createElement("div", { className: "font-size-xxxl font-bold" }, activeOffers.length)),
                                React.createElement("div", { className: "flex-1 flex-col align-center" },
                                    React.createElement(NumbersTitle, null,
                                        React.createElement(FormattedMessage, { id: "company.profile_box.followers" })),
                                    React.createElement("div", { className: "font-size-xxxl font-bold" }, localSubscribersCount))),
                            React.createElement(Button, { size: "middle", shape: "round", loading: isSubscriptionLoading, icon: isAccountSubscribed ? (React.createElement(IconCheck, { viewBox: "0 0 24 24", width: 16 })) : (React.createElement(IconAdd, { viewBox: "0 0 24 24", width: 16 })), onClick: toggleSubscription },
                                React.createElement("span", { className: "m-l-s" },
                                    !isAccountSubscribed && (React.createElement(FormattedMessage, { id: "company.subscription", defaultMessage: "Odber nov\u00FDch pon\u00FAk" })),
                                    isAccountSubscribed && React.createElement(FormattedMessage, { id: "company.subscription.active" }))),
                            React.createElement(TotalCount, null,
                                React.createElement(FormattedMessage, { id: "offers.offers_count", values: {
                                        total: publishedOffers.length,
                                    } })),
                            React.createElement(AddressBox, null,
                                React.createElement("div", { className: "flex-row m-b-m" },
                                    React.createElement(IconWrapper, null,
                                        React.createElement(IconMapsPin, null)),
                                    React.createElement("div", { className: "flex-col m-l-m" },
                                        React.createElement("div", { className: "font-size-m font-bold" },
                                            React.createElement(FormattedMessage, { id: "company.address" })),
                                        React.createElement(SubText, null,
                                            company.formatted_address,
                                            ", ",
                                            company.postal_code,
                                            ", ",
                                            company.city))),
                                Boolean(company.cin) && (React.createElement("div", { className: "flex-row m-b-m" },
                                    React.createElement(IconWrapper, null,
                                        React.createElement(IconBriefcase, null)),
                                    React.createElement("div", { className: "flex-col m-l-m" },
                                        React.createElement("div", { className: "font-size-m font-bold" },
                                            React.createElement(FormattedMessage, { id: "company.ico" })),
                                        React.createElement(SubText, null, company.cin)))),
                                Boolean(company.area) && (React.createElement("div", { className: "flex-row m-b-m" },
                                    React.createElement(IconWrapper, null,
                                        React.createElement(IconFolderOpen, null)),
                                    React.createElement("div", { className: "flex-col m-l-m" },
                                        React.createElement("div", { className: "font-size-m font-bold" },
                                            React.createElement(FormattedMessage, { id: "company.area" })),
                                        React.createElement(SubText, null, company.area)))),
                                Boolean(company.employees) && (React.createElement("div", { className: "flex-row m-b-m" },
                                    React.createElement(IconWrapper, null,
                                        React.createElement(IconMultipleUsers, null)),
                                    React.createElement("div", { className: "flex-col m-l-m" },
                                        React.createElement("div", { className: "font-size-m font-bold" },
                                            React.createElement(FormattedMessage, { id: "company.employees" })),
                                        React.createElement(SubText, null, company.employees)))),
                                Boolean(company.website) && (React.createElement("div", { className: "flex-row m-b-m" },
                                    React.createElement(IconWrapper, null,
                                        React.createElement(IconNetworkBrowser, null)),
                                    React.createElement("div", { className: "flex-col m-l-m" },
                                        React.createElement("div", { className: "font-size-m font-bold" },
                                            React.createElement(FormattedMessage, { id: "company.website" })),
                                        React.createElement("a", { href: company.website }, company.website))))),
                            React.createElement(MoreInfo, { className: "text-left" },
                                React.createElement(Separator, null,
                                    React.createElement(Heading, { className: "font-size-xl m-none" },
                                        React.createElement(FormattedMessage, { id: "company.about" }))),
                                React.createElement("div", { className: "font-size-s" }, htmlToReactParser.parse(company.description))),
                            hasAtleastOneSocial && (React.createElement(CompanyInfo, null,
                                React.createElement(SocialButtons, null,
                                    React.createElement(Separator, null,
                                        React.createElement(Subheading, null,
                                            React.createElement(FormattedMessage, { id: "company.social_media" }))),
                                    React.createElement("div", { className: "flex-center" },
                                        Boolean(company.facebook_url) && (React.createElement("a", { href: `${config.socialBaseUrl.facebook}${company.facebook_url}`, target: "_blank", rel: "noreferrer noopenner" },
                                            React.createElement(SocialIconFacebook, { type: "social-facebook" }))),
                                        Boolean(company.linkedin_url) && (React.createElement("a", { href: `${config.socialBaseUrl.linkedin}${company.linkedin_url}`, target: "_blank", rel: "noreferrer noopenner" },
                                            React.createElement(SocialIconLinkedIn, { type: "social-linkedin" }))),
                                        Boolean(company.instagram_url) && (React.createElement("a", { href: `${config.socialBaseUrl.instagram}${company.instagram_url}`, target: "_blank", rel: "noreferrer noopenner" },
                                            React.createElement(SocialIconInstagram, { type: "social-instagram" }))),
                                        Boolean(company.twitter_url) && (React.createElement("a", { href: `${config.socialBaseUrl.twitter}${company.twitter_url}`, target: "_blank", rel: "noreferrer noopenner" },
                                            React.createElement(SocialIconTwitter, { type: "social-twitter" })))))))))),
                React.createElement(BodyContent, { className: "position-relative" },
                    React.createElement(Description, null,
                        React.createElement(Heading, { className: "font-size-xl" },
                            React.createElement(FormattedMessage, { id: "company.about" })),
                        htmlToReactParser.parse(company.description)),
                    companyActiveOffers.length > 0 && (React.createElement(StyledPanel, { className: "m-t-m m-b-m" },
                        React.createElement(Heading, { className: "font-size-xl" },
                            React.createElement(FormattedMessage, { id: "company.active_offers" }),
                            ' ',
                            React.createElement("em", null,
                                "(",
                                companyActiveOffers.length,
                                ")")),
                        companyActiveOffers.map(item => {
                            return (React.createElement(Position, { to: item.link, key: item.key },
                                React.createElement("div", null,
                                    React.createElement("h2", null, item.position),
                                    React.createElement("div", { className: "type" }, item.type),
                                    React.createElement("div", { className: "city" }, item.city),
                                    React.createElement("div", { className: "salary" }, item.salary)),
                                React.createElement("div", { className: "detail" },
                                    React.createElement(FormattedMessage, { id: "global.detail" }),
                                    " \u279C")));
                        }))))))));
}
export { CompanyPage };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import produce from 'immer';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select, notification } from 'antd';
import { Table, Avatar, Button, Badge } from '@src/components/elements';
import Timestamp from '@src/components/modules/global/timestamp';
import { EOfferApplicationStage } from '@src/types';
import * as Api from '@src/api';
function BadgeWrapper({ application, children }) {
    if (application.viewed_at) {
        return children;
    }
    return React.createElement(Badge, { dot: true }, children);
}
export function ApplicantsTable(props) {
    const intl = useIntl();
    const setStage = React.useCallback((id, desiredStage) => __awaiter(this, void 0, void 0, function* () {
        try {
            yield Api.OfferApplication.setStage(id, desiredStage);
        }
        catch (err) {
            notification.error({
                message: intl.formatMessage({
                    id: 'forms.offer_notification_subscription_form.error',
                    defaultMessage: 'Chyba',
                }),
                description: intl.formatMessage({
                    id: 'forms.offer_notification_subscription_form.something_went_wrong',
                    defaultMessage: 'Niekde nastala chyba.',
                }),
            });
        }
    }), []);
    const stageOptions = [
        {
            label: intl.formatMessage({ id: 'offer_application.stage.new' }),
            value: EOfferApplicationStage.new,
        },
        {
            label: intl.formatMessage({ id: 'offer_application.stage.interview' }),
            value: EOfferApplicationStage.interview,
        },
        {
            label: intl.formatMessage({ id: 'offer_application.stage.hired' }),
            value: EOfferApplicationStage.hired,
        },
        {
            label: intl.formatMessage({ id: 'offer_application.stage.rejected' }),
            value: EOfferApplicationStage.rejected,
        },
    ];
    const columns = [
        {
            title: intl.formatMessage({
                id: 'global.name',
            }),
            key: 'avatar',
            render: (data, application) => (React.createElement(BadgeWrapper, { application: application },
                React.createElement("div", { className: "flex-row align-center" },
                    React.createElement(Avatar, { className: "m-r-m", size: 32, fullName: [application.account.first_name, application.account.last_name] }),
                    application.account.first_name,
                    "\u00A0",
                    application.account.last_name))),
        },
        {
            title: intl.formatMessage({
                id: 'global.sent',
            }),
            dataIndex: 'created_at',
            key: 'created_at',
            render: data => React.createElement(Timestamp, { value: data }),
        },
        {
            title: intl.formatMessage({
                id: 'global.stage',
            }),
            dataIndex: 'stage',
            key: 'stage',
            render: (data, application) => (React.createElement(Select, { style: { width: '120px' }, options: stageOptions, value: data, onChange: val => {
                    const appplication = props.applications.find(a => a.id === application.id);
                    const newApplications = produce(props.applications, draft => {
                        const app = draft.find(a => a.id === application.id);
                        app.stage = val;
                    });
                    const changedApplication = newApplications.find(app => app.id === application.id);
                    props.setSendMessageApplication(changedApplication);
                    props.setApplications(newApplications);
                    setStage(appplication.id, val);
                } })),
        },
        {
            key: 'detail',
            fixed: 'right',
            align: 'right',
            render: (data, application) => (React.createElement(Button, { onClick: () => props.setApplicationDetail(application), type: "default" },
                React.createElement(FormattedMessage, { id: "global.detail" }))),
        },
    ];
    return (React.createElement(Table, { pagination: false, scroll: { x: true }, className: "m-t-m word-break-keep-all", dataSource: props.applications, columns: columns }));
}

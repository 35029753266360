var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Skeleton, Collapse, Popconfirm, notification } from 'antd';
import { PlusOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Panel, Button, Table } from '@src/components/elements';
import * as Api from '@src/api';
import { CompanyAccountRole } from '@src/types';
import { InviteUserModal } from './InviteUserModal';
const InvitationPendingText = styled.span `
	color: ${({ theme }) => theme.color.gray5};
`;
const SuccessButton = styled(Button) `
	color: ${({ theme }) => theme.color.success};
`;
export function AdminUsers() {
    var _a;
    const [accounts, setAccounts] = React.useState(null);
    const [acceptationPendingAccounts, setAcceptationPendingAccounts] = React.useState(null);
    const [isInvitationModalOpen, setIsInvitationModalOpen] = React.useState(false);
    const [invitationIdBeingRemoved, setInvitationIdBeingRemoved] = React.useState(null);
    const [acceptationIdLoading, setAcceptationIdLoading] = React.useState(null);
    // @ts-ignore
    const isVerified = useSelector(state => { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.verified; });
    const intl = useIntl();
    const init = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            const companyAccounts = yield Api.Admin.getCompanyAccounts();
            setAcceptationPendingAccounts(companyAccounts
                .filter(a => { var _a; return ((_a = a.companyAccountRole) === null || _a === void 0 ? void 0 : _a.role) === CompanyAccountRole.acceptation_pending; })
                .map(a => (Object.assign(Object.assign({}, a), { key: `company-account-${a.id}` }))));
            setAccounts(companyAccounts
                .filter(a => { var _a; return ((_a = a.companyAccountRole) === null || _a === void 0 ? void 0 : _a.role) !== CompanyAccountRole.acceptation_pending; })
                .map(a => (Object.assign(Object.assign({}, a), { key: `company-account-${a.id}` }))));
        }
        catch (err) {
            console.log(err);
        }
    }), []);
    const removeInvitation = React.useCallback((invitationId) => __awaiter(this, void 0, void 0, function* () {
        try {
            setInvitationIdBeingRemoved(invitationId);
            yield Api.Admin.removeInvitation(invitationId);
            setAccounts(accounts.filter(a => a.id !== invitationId));
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setInvitationIdBeingRemoved(null);
        }
    }), [accounts]);
    const approveAcceptation = React.useCallback((userId) => __awaiter(this, void 0, void 0, function* () {
        try {
            setAcceptationIdLoading(userId);
            yield Api.Admin.changeUserRole(userId, CompanyAccountRole.admin);
            yield init();
            notification.success({
                message: intl.formatMessage({
                    id: 'admin.users.acceptation.approved_successfully',
                }),
                description: '',
            });
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setAcceptationIdLoading(null);
        }
    }), [acceptationPendingAccounts]);
    const declineAcceptation = React.useCallback((userId) => __awaiter(this, void 0, void 0, function* () {
        try {
            setAcceptationIdLoading(userId);
            yield Api.Admin.deleteUser(userId);
            yield init();
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setAcceptationIdLoading(null);
        }
    }), [acceptationPendingAccounts]);
    React.useEffect(() => {
        init();
    }, []);
    const accountColumns = [
        {
            title: intl.formatMessage({
                id: 'admin.users.name',
            }),
            key: 'full_name',
            render: (_, account) => (React.createElement(React.Fragment, null,
                !account.first_name && (React.createElement(InvitationPendingText, null,
                    "(",
                    React.createElement(FormattedMessage, { id: "admin.users.invitation_pending" }),
                    ")")),
                Boolean(account.first_name) && (React.createElement("span", null,
                    account.first_name,
                    " ",
                    account.last_name)))),
        },
        {
            title: intl.formatMessage({
                id: 'admin.users.email',
            }),
            dataIndex: 'email',
            key: 'email',
        },
        {
            width: '50px',
            key: 'actions',
            render: (_, account) => (React.createElement(React.Fragment, null, !account.first_name && (React.createElement(Popconfirm, { placement: "topLeft", title: intl.formatMessage({ id: 'admin.users.invitation_are_you_sure' }), onConfirm: () => removeInvitation(account.id), okText: intl.formatMessage({ id: 'general.yes' }), okButtonProps: { loading: invitationIdBeingRemoved === account.id }, cancelText: intl.formatMessage({ id: 'general.no' }) },
                React.createElement(Button, { danger: true, type: "text", size: "small" },
                    React.createElement(DeleteOutlined, { className: "m-r-s" }),
                    React.createElement(FormattedMessage, { id: "admin.users.invitation_delete" })))))),
        },
    ];
    const acceptationPendingAccountColumns = [
        {
            title: intl.formatMessage({
                id: 'admin.users.name',
            }),
            key: 'full_name',
            render: (_, account) => (React.createElement(React.Fragment, null,
                React.createElement("span", null,
                    account.first_name,
                    " ",
                    account.last_name))),
        },
        {
            title: intl.formatMessage({
                id: 'admin.users.email',
            }),
            dataIndex: 'email',
            key: 'email',
        },
        {
            width: '50px',
            key: 'actions',
            render: (_, account) => (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "flex-row justify-space-between" },
                    React.createElement(SuccessButton, { onClick: () => approveAcceptation(account.id), type: "text", size: "small" },
                        React.createElement(CheckOutlined, { className: "m-r-s" }),
                        React.createElement(FormattedMessage, { id: "general.accept" })),
                    React.createElement(Popconfirm, { placement: "topLeft", title: intl.formatMessage({ id: 'admin.users.acceptation.are_you_sure' }), onConfirm: () => declineAcceptation(account.id), okText: intl.formatMessage({ id: 'general.yes' }), okButtonProps: { loading: acceptationIdLoading === account.id }, cancelText: intl.formatMessage({ id: 'general.no' }) },
                        React.createElement(Button, { danger: true, type: "text", size: "small" },
                            React.createElement(CloseOutlined, { className: "m-r-s" }),
                            React.createElement(FormattedMessage, { id: "general.decline" })))))),
        },
    ];
    const showAcceptationTable = Boolean(acceptationPendingAccounts) && ((_a = acceptationPendingAccounts) === null || _a === void 0 ? void 0 : _a.length) > 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(Panel, { className: "p-m" },
            React.createElement(Collapse, { expandIconPosition: "right", className: "font-size-m", defaultActiveKey: ['1', '2'] },
                showAcceptationTable && (React.createElement(Collapse.Panel, { header: React.createElement("span", { className: "font-size-l" },
                        React.createElement(FormattedMessage, { id: "admin.users.awaiting_approval" })), key: "1" },
                    React.createElement(Table, { pagination: false, dataSource: acceptationPendingAccounts, columns: acceptationPendingAccountColumns }))),
                React.createElement(Collapse.Panel, { header: React.createElement("span", { className: "font-size-l" },
                        React.createElement(FormattedMessage, { id: "admin.users.company_users" })), key: "2" },
                    !accounts && React.createElement(Skeleton, { active: true }),
                    Boolean(accounts) && (React.createElement(Table, { pagination: false, dataSource: accounts, columns: accountColumns })),
                    Boolean(isVerified) && (React.createElement("div", { className: "flex-center m-t-l" },
                        React.createElement(Button, { className: "flex-row align-center", shape: "round", size: "middle", type: "primary", onClick: () => setIsInvitationModalOpen(true) },
                            React.createElement(PlusOutlined, { className: "m-r-s" }),
                            React.createElement(FormattedMessage, { id: "admin.users.invite_users" }))))))),
        React.createElement(InviteUserModal, { accounts: accounts, onSend: init, isOpen: isInvitationModalOpen, close: () => setIsInvitationModalOpen(false) })));
}

// @ts-nocheck
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { Button } from '@src/components/elements';
import config from '@src/config';
import { up } from 'styled-breakpoints';
export const Box = styled.div `
	width: 100%;
	min-height: 380px;
	position: relative;
	background: ${props => props.theme.color.white};
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0 15px 35px rgb(50 50 77 / 10%), 0 5px 30px rgb(0 0 0 / 7%);
	display: flex;
	flex-direction: column;
`;
export const BoxMain = styled.div `
	flex-grow: 1;
`;
export const BoxBottom = styled.div `
	text-align: center;
	color: ${props => props.theme.color.brand1};
	background: ${props => props.theme.color.gray1};
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export const Quickstart = styled.div `
	width: 100%;
	text-align: center;
	display: flex;
	justify-items: space-between;
	flex-direction: column;

	${up('md')} {
		flex-direction: row;
	}
`;
export const Claim = styled.div `
	text-align: center;
	color: ${props => props.theme.color.gray6};
	font-size: 1.2em;
	padding: 2em 3em 2em;
	a {
		text-decoration: underline;
	}
`;
export const QuickstartTile = styled.div `
	width: 100%;
	padding: 1em 2em 2em;
	display: flex;
	position: relative;
	flex-direction: column;

	${up('sm')} {
		padding: 2em 2em 1.5em;
	}

	${up('md')} {
		padding: 3em 2em 1.5em;
		width: 33%;
	}
`;
export const Picture = styled.div `
	width: 150px;
	height: 150px;
	overflow: hidden;
	align-self: center;
	& img {
		display: block;
		max-width: 100%;
	}
`;
export const Content = styled.dl `
	align-self: center;
	flex: 1;
	text-align: center;

	dt {
		color: ${props => props.theme.color.brand2};
		font-size: 1.2em;
		font-weight: 700;
		margin: 0 0 0.5em;
	}
`;
export const QuickstartCTA = styled.div `
	display: flex;
	justify-content: center;
	padding: 0 0 3em 0;
`;
export const QuickstartCTAButton = styled(Button) ``;
const tiles = [
    {
        name: React.createElement(FormattedMessage, { id: "home.quickstart.item_1_headline" }),
        image: 'nomad-home-2',
        text: React.createElement(FormattedMessage, { id: "home.quickstart.item_1_text" }),
    },
    {
        name: React.createElement(FormattedMessage, { id: "home.quickstart.item_2_headline" }),
        image: 'workspace-imac-coffee',
        text: React.createElement(FormattedMessage, { id: "home.quickstart.item_2_text" }),
    },
    {
        name: React.createElement(FormattedMessage, { id: "home.quickstart.item_3_headline" }),
        image: 'app-launch-laptop',
        text: React.createElement(FormattedMessage, { id: "home.quickstart.item_3_text" }),
    },
];
const BoxQuickstart = () => {
    const theme = useTheme();
    return (React.createElement(Box, null,
        React.createElement(BoxMain, null,
            React.createElement(Quickstart, null, tiles.map(item => {
                return (React.createElement(QuickstartTile, { key: item.image },
                    React.createElement(Picture, null,
                        React.createElement("img", { src: `/media/images/icon-${item.image}.svg`, alt: "" })),
                    React.createElement(Content, null,
                        React.createElement("dt", null, item.name),
                        React.createElement("dd", null, item.text))));
            })),
            React.createElement(QuickstartCTA, null,
                React.createElement(QuickstartCTAButton, { style: {
                        background: theme.color.success,
                        borderColor: theme.color.success,
                        color: theme.color.white,
                    }, link: "/prihlasenie" },
                    React.createElement(FormattedMessage, { id: "home.quickstart.cta" })))),
        React.createElement(BoxBottom, null,
            React.createElement(Claim, null,
                React.createElement(FormattedMessage, { id: "home.quickstart.subheadline", values: {
                        count: React.createElement("strong", null,
                            config.marketing.users,
                            "+"),
                        span: chunks => React.createElement("strong", null, chunks),
                        a: chunks => React.createElement(Link, { to: "/ponuky" }, chunks),
                    } })))));
};
export { BoxQuickstart };

import GA from 'react-ga'

function initialize(id) {
	if (process.env.NODE_ENV === 'production') {
		GA.initialize(id)
	}
}

function event(payload) {
	if (process.env.NODE_ENV === 'production') {
		GA.event(payload)
	}
}

function pageview(payload) {
	if (process.env.NODE_ENV === 'production') {
		GA.pageview(payload)
	}
}

const ReactGA = {
	initialize,
	event,
	pageview,
}

export default ReactGA

import React from 'react'
import classNames from 'classnames'
import { Icon } from 'components/elements'

const Button = function ({ id, align, color, size, text, type, disabled, IconBeforeText, className, dataCy, onClick }) {
	const getButtonClasses = function () {
		return classNames({
			button: true,
			'button--align-center': align === 'center',
			'button--color-brand': color === 'brand',
			'button--color-white': color === 'white',
			'button--color-brand2': color === 'brand2',
			'button--color-social-fb': color === 'socialFb',
			'button--size-huge': size === 'huge',
			'button--color-warning': color === 'warning',
			'button--color-positive': color === 'positive',
			'button--color-danger': color === 'danger',
			'button--color-plain': color === 'plain',
			'button--color-gray': color === 'gray',
			'button--type-fancy': type === 'fancy',
			'button--form': className,
		})
	}

	const extraProps = {}
	if (onClick) {
		extraProps.onClick = () => onClick()
	}

	return (
		<span className={getButtonClasses()}>
			<button id={id} type={type} disabled={disabled} className="button__inner" {...extraProps} data-cy={dataCy}>
				{IconBeforeText && IconBeforeText}
				{text}
			</button>
		</span>
	)
}

export default Button

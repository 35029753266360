var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
import braintree from 'braintree-web';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import { LockOutlined } from '@ant-design/icons';
import { history } from '@src/app/index';
import * as Api from '@src/api';
import { BraintreeHostedField } from '@src/components/modules/forms/renderBraintreeHostedField';
import { Icon, Button, Checkbox, Link } from '@src/components/elements';
import config from '@src/config';
import actions from '@src/actions';
import { Col, Row } from 'antd';
import { getCreditPricing } from '@src/shared/get-credit-pricing';
export const StyledLink = styled(Link) `
	font-weight: 700;
	text-decoration: underline;
	display: block;
	margin: 0.5em auto 0;
`;
const EFieldsAttributes = {
    Id: 'Id',
    Label: 'Label',
    ClassName: 'ClassName',
};
const getFieldsConfig = intl => [
    {
        [EFieldsAttributes.Id]: 'card-number',
        [EFieldsAttributes.Label]: intl.formatMessage({
            id: 'global.checkout.card_number',
            defaultMessage: 'Číslo platobnej karty',
        }),
        [EFieldsAttributes.ClassName]: 'col-12',
    },
    {
        [EFieldsAttributes.Id]: 'expiration-date',
        [EFieldsAttributes.Label]: intl.formatMessage({
            id: 'global.checkout.expiration_date',
            defaultMessage: 'Platnosť karty do',
        }),
        [EFieldsAttributes.ClassName]: 'col-6_xs-12',
    },
    {
        [EFieldsAttributes.Id]: 'cvv',
        [EFieldsAttributes.Label]: intl.formatMessage({
            id: 'global.checkout.cvv',
            defaultMessage: 'Bezpečnostný kód (CVC/CVV)',
        }),
        [EFieldsAttributes.ClassName]: 'col-6_xs-12',
    },
];
const Checkout = props => {
    const [isCheckoutInitialized, setIsCheckoutInitialized] = React.useState(false);
    const [braintree3DSecureInstance, setBraintree3DSecureInstance] = React.useState(null);
    const [braintreeDataCollector, setBraintreeDataCollector] = React.useState(null);
    const [hostedFieldsInstance, setHostedFieldsInstance] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [useSavedCard, setUseSavedCard] = React.useState(false);
    const [shouldSaveCard, setShouldSaveCard] = React.useState(true);
    const [isPaymentInProgress, setIsPaymentInProgress] = React.useState(false);
    const intl = useIntl();
    const dispatch = useDispatch();
    // @ts-ignore
    const { email, telephone, first_name, last_name } = useSelector(state => state.app.user);
    // @ts-ignore
    const { formatted_address, city, postal_code } = useSelector(state => state.admin.company);
    const formattedTelephone = telephone.replace('+', '00');
    const { hasRememberedCard, creditCount } = props;
    const { totalPrice } = getCreditPricing(creditCount);
    React.useEffect(() => {
        if (hasRememberedCard) {
            setUseSavedCard(true);
        }
        setIsCheckoutInitialized(true);
    }, [hasRememberedCard]);
    React.useEffect(() => {
        if (isCheckoutInitialized) {
            initCheckout(!useSavedCard);
            if (useSavedCard) {
                setShouldSaveCard(false);
            }
        }
    }, [isCheckoutInitialized, useSavedCard]);
    function sendPayment() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                setIsPaymentInProgress(true);
                let paymentMethodData = null;
                if (hasRememberedCard && useSavedCard) {
                    paymentMethodData = yield Api.Admin.getPaymentMethodNonce();
                }
                else {
                    paymentMethodData = yield hostedFieldsInstance.tokenize();
                }
                const response = yield braintree3DSecureInstance.verifyCard({
                    // @ts-ignore
                    onLookupComplete(data, next) {
                        next();
                    },
                    // @ts-ignore
                    amount: totalPrice,
                    nonce: paymentMethodData.nonce,
                    bin: paymentMethodData.details.bin,
                    email,
                    mobilePhoneNumber: formattedTelephone,
                    challengeRequested: true,
                    billingAddress: {
                        givenName: first_name,
                        surname: last_name,
                        phoneNumber: formattedTelephone,
                        streetAddress: formatted_address,
                        locality: city,
                        postalCode: postal_code,
                    },
                });
                if (!response.liabilityShifted) {
                    return;
                }
                yield Api.Admin.buyOfferCredits(creditCount, shouldSaveCard, response.nonce, braintreeDataCollector.deviceData);
                // @ts-ignore
                history.pushLocalized('/admin');
                dispatch(actions.adminToggleCreditsReplenishedDialog(true));
            }
            catch (err) {
                console.error(err);
                setError(true);
            }
            finally {
                setIsPaymentInProgress(false);
            }
        });
    }
    const initBraintreeClient = React.useCallback((clientToken) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const createdBraintreeInstance = yield braintree.client.create({
                authorization: clientToken,
            });
            const createdBraintree3DSecureInstance = yield braintree.threeDSecure.create({
                client: createdBraintreeInstance,
                version: 2,
            });
            const createdDataCollector = yield braintree.dataCollector.create({
                client: createdBraintreeInstance,
            });
            setBraintreeDataCollector(createdDataCollector);
            setBraintree3DSecureInstance(createdBraintree3DSecureInstance);
            return createdBraintreeInstance;
        }
        catch (err) {
            console.error(err);
        }
    }), [braintree, config]);
    const initHostedFields = (clientToken) => __awaiter(void 0, void 0, void 0, function* () {
        if (hostedFieldsInstance || (hasRememberedCard && useSavedCard)) {
            return;
        }
        try {
            const createdHostedFieldsInstance = yield braintree.hostedFields.create({
                authorization: clientToken,
                styles: {
                    input: {
                        height: '2rem',
                        border: '1px solid black',
                        'font-size': '14px',
                    },
                    'input.invalid': {
                        color: 'red',
                    },
                    'input.valid': {
                        color: 'green',
                    },
                },
                fields: {
                    number: {
                        selector: '#card-number',
                        placeholder: '1111 1111 1111 1111',
                    },
                    cvv: {
                        selector: '#cvv',
                        placeholder: '123',
                    },
                    expirationDate: {
                        selector: '#expiration-date',
                        placeholder: moment().format('MM / YYYY'),
                    },
                },
            });
            setHostedFieldsInstance(createdHostedFieldsInstance);
        }
        catch (err) {
            console.error(err);
        }
    });
    const initCheckout = (shouldInitHostedFields) => __awaiter(void 0, void 0, void 0, function* () {
        const { clientToken } = yield Api.Admin.getClientToken();
        yield initBraintreeClient(clientToken);
        if (shouldInitHostedFields) {
            yield initHostedFields(clientToken);
        }
    });
    const fieldsConfig = getFieldsConfig(intl);
    return (React.createElement("div", { className: "Checkout m-t-m" },
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24 },
                React.createElement("div", { className: "panel panel--payment" },
                    React.createElement("div", { className: "Checkout__headline" },
                        React.createElement(Icon, { className: "Checkout__icon-shield", type: "shield" }),
                        React.createElement(FormattedMessage, { id: "global.checkout.secure_payment", defaultMessage: "Bezpe\u010Dn\u00E1 platba kartou" })),
                    error && (React.createElement("div", { className: "form__errors" },
                        React.createElement("p", { className: "form__errors-item" },
                            React.createElement(FormattedMessage, { id: "global.checkout.invalid_payment_information", defaultMessage: "Neplatn\u00E9 platobn\u00E9 \u00FAdaje" })))),
                    React.createElement("div", { className: props.className },
                        hasRememberedCard && (React.createElement("div", { className: "u-text-center form__row" },
                            React.createElement("label", { className: "form__label form__label--checkbox" },
                                React.createElement(Checkbox, { checked: useSavedCard, onChange: e => {
                                        setUseSavedCard(e.target.checked);
                                        setHostedFieldsInstance(null);
                                    } },
                                    React.createElement(FormattedMessage, { id: "global.checkout.use_saved_card", defaultMessage: "Pou\u017Ei\u0165 zapam\u00E4tan\u00FA kartu?" }))))),
                        (!useSavedCard || !hasRememberedCard) && (React.createElement(Row, { gutter: [16, 16], justify: "center" }, fieldsConfig.map(field => (React.createElement(BraintreeHostedField, { "data-cy": field[EFieldsAttributes.Id], key: field[EFieldsAttributes.Id], id: field[EFieldsAttributes.Id], label: field[EFieldsAttributes.Label], className: field[EFieldsAttributes.ClassName] }))))),
                        React.createElement(Row, { gutter: [16, 16], justify: "center", className: "m-t-l" },
                            React.createElement(Button, { type: "primary", onClick: sendPayment, loading: isPaymentInProgress, icon: React.createElement(LockOutlined, null) }, intl.formatMessage({
                                id: 'global.checkout.pay_with_card',
                                defaultMessage: 'Zaplatiť kartou',
                            }))),
                        (!useSavedCard || !hasRememberedCard) && (React.createElement("div", { className: "flex-center m-t-l" },
                            React.createElement(Checkbox, { checked: shouldSaveCard, onChange: e => setShouldSaveCard(e.target.checked) }, intl.formatMessage({
                                id: 'global.checkout.save_card',
                            })))))),
                React.createElement("div", { className: "text-center m-t-l" },
                    "Nevyhovuje v\u00E1m platba kartou?",
                    React.createElement(StyledLink, { to: "/kontakt" }, "Kontaktujte n\u00E1s pre platbu prevodom na \u00FA\u010Det"))))));
};
export { Checkout };

import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import URI from 'urijs'
import * as Cookies from 'js-cookie'
import config from 'config'
import actions from '../actions'

export function* mainInitStart() {
	try {
		yield put(actions.authCheckStart())
		// handle cookie consent
		const cookieConsentValue = Cookies.get('cookieConsent')
		if (!cookieConsentValue) {
			yield put(actions.cookieConsentError())
		}
		yield put(actions.mainInitSuccess())
	} catch (err) {
		yield put(actions.mainInitError())
	}
}

export function* cookieConsentStart() {
	const setCookie = () => {
		Cookies.set('cookieConsent', 'true', {
			expires: config.cookieConsentDuration,
		})
	}
	try {
		yield call(setCookie)
		yield put(actions.cookieConsentSuccess())
	} catch (err) {
		console.log(err)
		yield put(actions.cookieConsentError(err))
	}
}

export function* cookieConsentError(err) {
	const throwErr = error => {
		console.log(error)
	}
	yield call(throwErr, err)
}

export function* authCheckStart() {
	const uri = new URI(`${config.api}/0/auth-check`)
	const apiUri = uri.toString()

	const authCheck = () =>
		axios
			.get(apiUri, { withCredentials: true })
			.then(res => {
				return res
			})
			.catch(err => {
				throw err
			})

	try {
		const response = yield call(authCheck)
		yield put(actions.authCheckSuccess(response))
	} catch (err) {
		yield put(actions.authCheckError())
	}
}

// @ts-nocheck
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-scroll';
import styled, { useTheme } from 'styled-components';
import { Button } from '@src/components/elements';
import config from '@src/config';
import { up } from 'styled-breakpoints';
export const Box = styled.div `
	width: 100%;
	min-height: 500px;
	position: relative;
	background: ${props => props.theme.color.white};
	border-radius: 4px 4px 0 0;
	overflow: hidden;
	box-shadow: 0 15px 35px rgb(50 50 77 / 10%), 0 5px 15px rgb(0 0 0 / 7%);
	display: flex;
	flex-direction: column;

	${up('md')} {
		border-radius: 4px 0 0 4px;
		top: 55px;
		width: 50%;
	}
`;
export const BoxHead = styled.div `
	padding: 1.5em 3em 1.2em;
	text-align: center;
	border-bottom: 1px solid ${props => props.theme.color.gray2};

	font-weight: 400;
	font-size: 1.3em;
	color: ${props => props.theme.color.brand1};

	strong {
		font-weight: 700;
		text-transform: uppercase;
		color: ${props => props.theme.color.success};
	}
`;
export const BoxMain = styled.div `
	flex-grow: 1;
`;
export const BoxBottom = styled.div `
	padding: 0 0 1.8em;
	text-align: center;
	border-top: 1px solid ${props => props.theme.color.gray1};
	color: ${props => props.theme.color.brand1};
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export const ButtonText = styled.div ``;
export const Block = styled.div `
	width: 100%;
`;
export const ContactSales = styled.div `
	width: 100%;
	background: ${props => props.theme.color.gray4};
`;
export const FreeCredit = styled.div `
	width: 100%;
	padding: 0 0 2em 0;
	text-align: center;
`;
export const FreeCreditFeatures = styled.div `
	text-align: left;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 2em 3em 0;

	ul {
		width: 400px;
		max-width: 100%;
		margin: 0 auto;
		padding: 0 0 0 2.5em;
		color: ${props => props.theme.color.gray6};
		font-size: 1.2em;
	}
`;
export const Introduction = styled.div `
	text-align: center;
	color: ${props => props.theme.color.gray6};
	font-size: 1.2em;
	padding: 2em 3em 2em;
`;
export const FreeCredit1 = styled.div `
	width: 100%;
	padding: 0 3em;
	font-weight: 700;
`;
export const FreeCredit2 = styled.div `
	font-size: 3em;
	font-weight: 700;
	padding: 0.3em 1em 0.4em;
	color: ${props => props.theme.color.brand3};
`;
export const FreeCredit3 = styled.div `
	width: 100%;
	font-weight: 400;
	font-size: 0.9em;
	padding: 1em 3em 0;
	color: ${props => props.theme.color.gray4};
`;
export const MoreDetials = styled(Link) `
	margin: 1em auto 0;
	display: inline-block;
	text-decoration: underline;
	color: ${props => props.theme.color.gray5};
	font-size: 1em;
`;
const BoxFree = () => {
    const theme = useTheme();
    return (React.createElement(Box, null,
        React.createElement(BoxHead, null,
            React.createElement(FormattedMessage, { id: "companies.free.headline", values: {
                    strong: chunks => React.createElement("strong", null, chunks),
                } })),
        React.createElement(BoxMain, null,
            React.createElement(Introduction, null,
                React.createElement(FormattedMessage, { id: "companies.free.subheadline", values: {
                        count: React.createElement("strong", null,
                            config.marketing.companies,
                            "+"),
                        span: chunks => React.createElement("strong", null, chunks),
                    } })),
            React.createElement(FreeCredit, null,
                React.createElement(FreeCredit1, null,
                    React.createElement(FormattedMessage, { id: "companies.free.you_get_free" }),
                    ":"),
                React.createElement(FreeCredit2, null,
                    React.createElement(FormattedMessage, { values: { count: 1 }, id: "general.credits.pluralized" })),
                React.createElement(Button, { style: {
                        background: theme.color.success,
                        borderColor: theme.color.success,
                        color: theme.color.white,
                    }, link: "/registracia" },
                    React.createElement(ButtonText, null,
                        React.createElement(FormattedMessage, { id: "companies.free.create_account_free" }))),
                React.createElement(FreeCredit3, null,
                    React.createElement(FormattedMessage, { id: "companies.free.no_hidden_fees" })))),
        React.createElement(BoxBottom, null,
            React.createElement(FreeCreditFeatures, null,
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement(FormattedMessage, { id: "companies.free.features_1" })),
                    React.createElement("li", null,
                        React.createElement(FormattedMessage, { id: "companies.free.features_2" })),
                    React.createElement("li", null,
                        React.createElement(FormattedMessage, { id: "companies.free.features_3" })),
                    React.createElement("li", null,
                        React.createElement(FormattedMessage, { id: "companies.free.features_4" })),
                    React.createElement("li", null,
                        React.createElement(FormattedMessage, { id: "companies.free.features_5" })))),
            React.createElement(MoreDetials, { to: "features", smooth: true },
                React.createElement(FormattedMessage, { id: "companies.free.more_details" })))));
};
export { BoxFree };

export function getInitials(fullName) {
    if (!fullName) {
        return null;
    }
    return fullName.reduce((acc, name) => {
        // eslint-disable-next-line no-param-reassign
        acc += name[0];
        return acc;
    }, '');
}

import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import URI from 'urijs'
import { notification } from 'antd'
import config from 'config'
import * as Api from '@src/api'
import actions from '../actions'

export function* loadMyOfferApplicationsStart() {
	try {
		const data = yield call(Api.Profile.getOfferApplications)
		yield put(actions.loadMyOfferApplicationsSuccess(data))
	} catch (err) {
		yield put(actions.loadMyOfferApplicationsError(err))
	}
}

export function* loadMyOfferApplicationsError(err) {
	const showError = () => {
		notification.error({
			message: 'Chyba',
			description: 'Užívateľ nie je prihlásený',
		})
	}
	yield call(showError, err)
}

export function* loadMoreOfferApplicationsStart(action) {
	const uri = new URI(`${config.api}/0/offer-applications`)
	const query = {}

	if (action.payload) {
		if (action.payload) {
			query.page = action.payload
		}
	}

	uri.query(query)
	const apiUri = uri.toString()

	const getData = () =>
		axios
			.get(apiUri, { withCredentials: true })
			.then(res => res)
			.catch(err => {
				throw err
			})

	try {
		const response = yield call(getData)
		yield put(actions.loadMoreOfferApplicationsSuccess(response.data))
	} catch (err) {
		yield put(actions.loadMoreOfferApplicationsError(err))
	}
}

export function* loadMoreOfferApplicationsError(err) {
	const showError = () => {
		notification.error({
			message: 'Chyba',
			description: 'Užívateľ nie je prihlásený',
		})
	}
	yield call(showError, err)
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Modal, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import actions from '@src/actions';
import { Button } from '@src/components/elements';
import { getCreditPricing } from '@src/shared/get-credit-pricing';
import * as Api from '@src/api';
export function NotEnoughCreditsDialog() {
    const [isPaymentInProgress, setIsPaymentInProgress] = React.useState(false);
    const dispatch = useDispatch();
    const intl = useIntl();
    const history = useHistory();
    // @ts-ignore
    const isOpenNotEnoughCreditsDialog = useSelector(state => state.admin.isOpenNotEnoughCreditsDialog);
    // @ts-ignore
    const company = useSelector(state => state.admin.company);
    const goToUpsell = () => {
        // @ts-ignore
        history.pushLocalized('/admin/kredity');
        dispatch(actions.adminToggleNotEnoughCreditsDialog(false));
    };
    const buyCredits = () => __awaiter(this, void 0, void 0, function* () {
        try {
            setIsPaymentInProgress(true);
            yield Api.Admin.buyOfferCredits(1, true);
            // dispatch(actions.adminToggleCreditsReplenishedDialog(true))
            dispatch(actions.adminToggleNotEnoughCreditsDialog(false));
            dispatch(actions.myCompanyInfoInitStart());
            notification.success({
                message: intl.formatMessage({
                    id: 'admin.not_enough_credits_dialog.credits_replenished',
                }),
                description: intl.formatMessage({
                    id: 'admin.not_enough_credits_dialog.credits_replenished.continue',
                }),
                duration: 30,
            });
        }
        catch (err) {
            notification.error({
                message: intl.formatMessage({
                    id: 'forms.offer_notification_subscription_form.error',
                    defaultMessage: 'Chyba',
                }),
                description: intl.formatMessage({
                    id: 'forms.offer_notification_subscription_form.something_went_wrong',
                    defaultMessage: 'Niekde nastala chyba.',
                }),
            });
        }
        finally {
            setIsPaymentInProgress(false);
        }
    });
    const hasRememberedPaymentMethod = Boolean(company.payment_method_token);
    const { creditPrice } = getCreditPricing(1);
    return (React.createElement(Modal, { onCancel: () => dispatch(actions.adminToggleNotEnoughCreditsDialog(false)), onOk: () => dispatch(actions.adminToggleNotEnoughCreditsDialog(false)), visible: isOpenNotEnoughCreditsDialog, title: intl.formatMessage({ id: 'admin.not_enough_credits_dialog.not_enough_credits' }), footer: null },
        hasRememberedPaymentMethod && (React.createElement("div", { className: "flex-col align-center justify-center" },
            React.createElement("div", { className: "m-b-l font-size-l text-center" },
                React.createElement(FormattedMessage, { id: "admin.not_enough_credits_dialog.instant_replenish", values: {
                        creditPrice: intl.formatNumber(creditPrice, { style: 'currency', currency: 'EUR' }),
                        strong: chunks => React.createElement("strong", null, chunks),
                    } })),
            React.createElement("img", { className: "m-b-xl", src: "/media/images/icon-circle-coin-sparkle.svg", alt: "" }),
            React.createElement("div", { className: "flex-row" },
                React.createElement(Button, { type: "default", className: "m-r-m", onClick: goToUpsell },
                    React.createElement(FormattedMessage, { id: "admin.not_enough_credits_dialog.go_to_pricing" })),
                React.createElement(Button, { loading: isPaymentInProgress, onClick: buyCredits },
                    React.createElement(FormattedMessage, { id: "admin.not_enough_credits_dialog.buy_one_credits" }))))),
        !hasRememberedPaymentMethod && (React.createElement("div", { className: "flex-col align-center justify-center" },
            React.createElement("div", { className: "m-b-l font-size-l text-center" },
                React.createElement(FormattedMessage, { id: "admin.not_enough_credits_dialog.insufficient_amount_of_credits" })),
            React.createElement("img", { className: "m-b-xl", src: "/media/images/icon-empty-bag.svg", alt: "" }),
            React.createElement("div", { className: "flex-row" },
                React.createElement(Button, { onClick: goToUpsell },
                    React.createElement(FormattedMessage, { id: "admin.not_enough_credits_dialog.go_to_pricing" })))))));
}

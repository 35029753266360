import React from 'react';
import 'react-quill/dist/quill.snow.css';

class RichTextEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = { text: this.props.input.value }; // You can also pass a Quill Delta here
		this.modules = {
			toolbar: [
				['bold', 'italic', 'underline'],
				[{ list: 'ordered' }, { list: 'bullet' }],
				['link'],
				['clean'],
			],
		};
		if (typeof window !== 'undefined') {
			this.ReactQuill = require('react-quill')
		}
	}

	render() {
		const {
			input,
			className,
		} = this.props;
		const ReactQuill = this.ReactQuill
		if (typeof window === 'undefined') {
			return <textarea data-cy="text-area"/>;
		}
		return (
			<ReactQuill
				className={className}
				{...input}
				readOnly={this.props.readOnly}
				modules={this.modules}
				onChange={(newValue, delta, source) => {
					if (source === 'user') {
						input.onChange(newValue);
					}
				}}
				onBlur={(range, source, quill) => {
					input.onBlur(quill.getHTML());
				}}
			/>
		);
	}
}

export default RichTextEditor;

import actionNames from '../actions'

const defaultState = {
	options: [],
	registrationFormSuccess: null,
	isLoading: false,
	valueKey: 'label',
	companyInfo: {},
}

export default function registration(state = defaultState, action) {
	switch (action.type) {
		case actionNames.registrationLoadCompanyNamesStart().type:
			return { ...state, isLoading: true, companyInfo: {} }

		case actionNames.registrationLoadCompanyNamesSuccess().type:
			return { ...state, options: action.payload.data, isLoading: false }

		case actionNames.registrationLoadCompanyNamesError().type:
			return { ...state, isLoading: false }

		case actionNames.registrationLoadCompanyInfoSuccess().type:
			return { ...state, companyInfo: action.payload.data }

		case actionNames.registrationFormSetSuccess().type:
			return { ...state, registrationFormSuccess: true }

		case '@@router/LOCATION_CHANGE':
			return { ...state, companyInfo: {}, options: [], registrationFormSuccess: null }

		default:
			return state
	}
}

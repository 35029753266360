import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import { history, store } from '@src/app/index'
import URI from 'urijs'
import _get from 'lodash/get'
import config from 'config'
import actions from 'actions'

export function* loadOfferDetailStart(action) {
	// construct URI for API call
	const uri = new URI(`${config.api}/0/offer`)

	// handle parameter
	if (action.payload && action.payload.id) {
		uri.query({
			id: action.payload.id,
			slug: action.payload.slug,
		})
	}
	const apiUri = uri.toString()

	// do API call
	const fetchData = () => axios.get(apiUri, { withCredentials: true }).then(res => res.data)
	// .catch(err => yield put(actions.loadOfferDetailError(err)))

	try {
		const data = yield call(fetchData)
		yield put(actions.loadOfferDetailSuccess({ details: data }))
	} catch (err) {
		yield put(actions.loadOfferDetailError(err))
	}
}

export function* loadOfferDetailError(action) {
	const redirect = () => {
		const responseStatus = _get(action, 'payload.response.status')
		if (responseStatus === 301) {
			history.replace(`/ponuka/${action.payload.response.data.slug}-${action.payload.response.data.id}`)
			store.dispatch(actions.loadOfferDetailStart(action.payload.response.data))
		} else {
			history.replace('/error')
		}
	}
	yield call(redirect)
}

export function* loadOfferSuggestionsCompanyStart(action) {
	const offerId = action.payload.id
	const uri = new URI(`${config.api}/0/offer-detail/offer-suggestions-company`).search({ offerId }).toString()

	// do API call
	const fetchData = () => axios.get(uri, { withCredentials: true }).then(res => res.data)

	try {
		const data = yield call(fetchData)
		yield put(actions.loadOfferSuggestionsCompanySuccess(data))
	} catch (err) {
		yield put(actions.loadOfferSuggestionsCompanyError())
	}
}

export function* loadSimilarOffersStart(action) {
	const offerId = action.payload.id
	const uri = new URI(`${config.api}/0/offer-detail/offer-suggestions-similar`).search({ offerId }).toString()

	const fetchData = async () => {
		const response = await axios.get(uri, { withCredentials: true })
		return response.data
	}

	try {
		const data = yield call(fetchData)
		yield put(actions.loadSimilarOffersSuccess(data))
	} catch (err) {
		yield put(actions.loadSimilarOffersError())
	}
}

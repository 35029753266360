import React from 'react'
import classNames from 'classnames'
import { Col, Row } from 'antd'

const getInputClasses = show =>
	classNames({
		form__input: true,
		'form__input--error': show,
	})

const getLabelClasses = noWhiteSpaceWrap =>
	classNames({
		form__label: true,
		'form__label--noWhiteSpaceWrap': noWhiteSpaceWrap,
	})

const renderField = ({
	input,
	label,
	type,
	placeholder,
	noWhiteSpaceWrap,
	className,
	autocomplete,
	disabled,
	meta: { touched, error },
}) => (
	<div className={`form__row ${className || ''}`}>
		<Row>
			<Col xs={24} id={input.name}>
				<label className={getLabelClasses(noWhiteSpaceWrap)} htmlFor={input.name}>
					{label}
				</label>
				<input
					{...input}
					className={getInputClasses(touched && error)}
					type={type}
					placeholder={placeholder}
					disabled={disabled}
					data-cy={input.name}
					autoComplete={autocomplete}
				/>
				{touched && error && <div className="form__input-error">{error}</div>}
			</Col>
		</Row>
	</div>
)

export default renderField

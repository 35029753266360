/* eslint-disable no-restricted-syntax */
import React from 'react';
import { Tag, Button } from '@src/components/elements';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ClearOutlined } from '@ant-design/icons';
import { ABROAD, REMOTE } from '@src/types';
import { useOffersFilterContextValue } from '../filter-context';
const FilterTag = styled(Tag) `
	display: inline-flex;
	line-height: 2rem;
	font-size: 1rem;
	align-items: center;
	margin-bottom: 0.5rem;

	.ant-tag-close-icon {
		margin-left: 0.75rem;
	}
`;
const ClearFiltersButton = styled(Button) `
	text-decoration: underline;
`;
const OfferCounts = styled.div `
	margin: 0.75rem 0;
	color: ${({ theme }) => theme.color.gray6};
`;
const FilterTags = ({ offerCounts }) => {
    const { offersFiltersSSR, typeFiltersSelected, locationFiltersSelected, jobAreaFiltersSelected, setTypeFilersSelected, setLocationFilersSelected, setJobAreaFiltersSelected, } = useOffersFilterContextValue();
    const [showClearFiltersButton, setShowClearFiltersButton] = React.useState(false);
    const history = useHistory();
    const { offersCount, allOffersCount } = offerCounts;
    const isShownMaxNumberOfOffers = offersCount === allOffersCount;
    const isTypeFilterActive = Boolean(typeFiltersSelected && typeFiltersSelected.length);
    const isJobAreaFilterActive = Boolean(jobAreaFiltersSelected && jobAreaFiltersSelected.length);
    const isLocationFilterActive = Boolean(locationFiltersSelected && locationFiltersSelected.length);
    const removeTypeIdFromFilter = (typeIdToRemove) => {
        const newtypeFiltersSelected = typeFiltersSelected.filter(typeId => typeIdToRemove !== typeId);
        setTypeFilersSelected(newtypeFiltersSelected);
    };
    const removeJobAreaIdFromFilter = (jobAreaIdToRemove) => {
        const newJobAreaIds = jobAreaFiltersSelected.filter(jobAreaId => jobAreaId !== jobAreaIdToRemove);
        setJobAreaFiltersSelected(newJobAreaIds);
    };
    const removeLocationFromFilter = (locationToRemove) => {
        const newlocationFiltersSelected = locationFiltersSelected.filter(loc => loc !== locationToRemove);
        setLocationFilersSelected(newlocationFiltersSelected);
    };
    const getLocationTranslationKeyById = (locationId, currentSublocations) => {
        for (const location of currentSublocations) {
            if (location.id === locationId) {
                return location.translation_key;
            }
            if (location.sublocations) {
                const foundKey = getLocationTranslationKeyById(locationId, location.sublocations);
                if (foundKey) {
                    return foundKey;
                }
            }
        }
    };
    React.useEffect(() => {
        const filtersCount = typeFiltersSelected.length + locationFiltersSelected.length + jobAreaFiltersSelected.length;
        setShowClearFiltersButton(filtersCount > 1);
    }, [typeFiltersSelected, locationFiltersSelected, jobAreaFiltersSelected]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `flex-row align-center ${showClearFiltersButton ? 'justify-space-between' : 'justify-end'}` },
            showClearFiltersButton && (React.createElement(ClearFiltersButton, { onClick: () => {
                    setTypeFilersSelected([]);
                    setJobAreaFiltersSelected([]);
                    setLocationFilersSelected([]);
                    history.pushLocalized(`/ponuky`);
                }, type: "text", icon: React.createElement(ClearOutlined, { className: "m-r-s" }) },
                React.createElement(FormattedMessage, { id: "offers.clear_selected_filters" }))),
            React.createElement(OfferCounts, null,
                isShownMaxNumberOfOffers && (React.createElement(FormattedMessage, { values: {
                        allOffersCount,
                    }, id: "offers.showing_all_offers_count" })),
                !isShownMaxNumberOfOffers && (React.createElement(FormattedMessage, { values: {
                        offersCount,
                        allOffersCount,
                    }, id: "offers.showing_offers_count" })))),
        offersFiltersSSR && (React.createElement(React.Fragment, null,
            isTypeFilterActive &&
                typeFiltersSelected.map(selectedTypeId => {
                    var _a;
                    return (React.createElement(FilterTag, { key: selectedTypeId, closable: true, onClose: () => removeTypeIdFromFilter(selectedTypeId) },
                        React.createElement(FormattedMessage, { id: (_a = offersFiltersSSR.type.find(offerFilter => offerFilter.id === selectedTypeId)) === null || _a === void 0 ? void 0 : _a.translationKey })));
                }),
            isLocationFilterActive &&
                locationFiltersSelected.map(location => (React.createElement(FilterTag, { key: location, closable: true, onClose: () => removeLocationFromFilter(location) },
                    location === ABROAD && React.createElement(FormattedMessage, { id: "location.abroad" }),
                    location === REMOTE && React.createElement(FormattedMessage, { id: "location.remote" }),
                    location !== REMOTE && location !== ABROAD && (React.createElement(FormattedMessage, { id: getLocationTranslationKeyById(location, offersFiltersSSR.location.locations) }))))),
            isJobAreaFilterActive &&
                jobAreaFiltersSelected.map(jobAreaId => {
                    var _a;
                    return (React.createElement(FilterTag, { key: jobAreaId, closable: true, onClose: () => removeJobAreaIdFromFilter(jobAreaId) },
                        React.createElement(FormattedMessage, { id: (_a = offersFiltersSSR.jobArea.find(offerFilter => offerFilter.id === jobAreaId)) === null || _a === void 0 ? void 0 : _a.translationKey })));
                })))));
};
export { FilterTags };

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Cookies from 'js-cookie';
import { ConditionalWrapper } from '@src/components/elements';
import actions from '@src/actions';
import ReactGA from '@src/helpers/react-ga';
import * as Analytics from '@src/helpers/analytics-tracking';
import { LayoutMain } from '@src/components/layouts/Main';
import { Home } from '@src/components/pages/home';
// import { ApplicantsCalculator } from '@src/components/pages/microsite/applicants-calculator'
import About from '@src/components/pages/about';
import Contact from '@src/components/pages/contact';
import Protection from '@src/components/pages/protection';
import TermsAndConditions from '@src/components/pages/terms-and-conditions';
import { Login } from '@src/components/pages/login';
import Registration from '@src/components/pages/registration';
import RegistrationStudent from '@src/components/pages/registration-student';
import ResetPassword from '@src/components/pages/reset-password';
import ResetPasswordEmail from '@src/components/pages/reset-password-email';
import OfferNotificationSubscription from '@src/components/pages/offer-notification-subscription';
import NotFound from '@src/components/pages/not-found';
import { Candidate } from '@src/components/pages/candidate';
import Companies from '@src/components/pages/companies';
import { Offers } from '@src/components/pages/offers';
import Magazine from '@src/components/pages/magazine';
import MagazinePost from '@src/components/pages/magazine/post';
import MagazineHomepage from '@src/components/pages/magazine/homepage';
import { OfferDetail } from '@src/components/pages/offer-detail';
import FeedbackStudent from '@src/components/pages/student-feedback';
import { Profile } from '@src/components/pages/profile';
import ProfileApplicationHistory from '@src/components/pages/profile/modules/application-history';
import ProfileSettings from '@src/components/pages/profile/modules/settings';
import ProfileOfferNotificationSubscription from '@src/components/pages/profile/modules/offer-notification-subscription';
import { ProfileResumes } from '@src/components/pages/profile/modules/Resumes';
import { Admin } from '@src/components/pages/admin';
import AdminOffers from '@src/components/pages/admin/modules/offers';
import { Applicants } from '@src/components/pages/admin/modules/applicants';
import AdminOfferPost from '@src/components/pages/admin/modules/offer-post';
import AdminOfferEdit from '@src/components/pages/admin/modules/offer-edit';
import { AdminCredits } from '@src/components/pages/admin/modules/credits';
import { AdminOrder } from '@src/components/pages/admin/modules/order';
import { AdminPayments } from '@src/components/pages/admin/modules/payments/Payments';
import { AdminUsers } from '@src/components/pages/admin/modules/users/AdminUsers';
import AdminCompanyInfoEdit from '@src/components/pages/admin/modules/company-info-edit';
import AdminLoginInfoEdit from '@src/components/pages/admin/modules/login-info-edit';
import AdminPersonalInfoEdit from '@src/components/pages/admin/modules/personal-info-edit';
import { CompanyPage } from '@src/components/pages/company';
import AdminApi from '@src/components/pages/admin/modules/api';
// import { ChristmasCampaign } from '@src/components/pages/microsite/christmas-campaign/ChristmasCampaign'
import { theme } from '@src/styles/theme';
import 'antd/dist/antd.less';
import { findMatchingRoute, getPathWithoutLocale } from '@src/shared/localized-path';
const routes = [
    {
        id: '/',
        Component: Home,
        props: {
            layout: 'main',
            navigation: {
                withHero: true,
            },
        },
        paths: {
            sk: '/',
            en: '/',
        },
    },
    {
        id: '/verification',
        Component: Home,
        props: {
            layout: 'main',
            navigation: {
                withHero: true,
            },
        },
        shouldRedirect: true,
        paths: {
            sk: '/verification',
            en: '/verification',
        },
    },
    {
        id: '/offer_feedback',
        Component: Home,
        props: {
            layout: 'main',
            navigation: {
                withHero: true,
            },
        },
        shouldRedirect: true,
        paths: {
            sk: '/offer_feedback',
            en: '/offer_feedback',
        },
    },
    {
        id: '/o-nas',
        Component: About,
        props: {
            layout: 'main',
            navigation: {
                withHero: true,
            },
        },
        paths: {
            sk: '/o-nas',
            en: '/about-us',
        },
    },
    {
        id: '/firmy',
        Component: Companies,
        props: {
            layout: 'main',
            navigation: {
                withHero: true,
            },
        },
        paths: {
            sk: '/firmy',
            en: '/companies',
        },
    },
    {
        id: '/firma',
        Component: CompanyPage,
        shouldRedirect: true,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/firma/:companyUniqueUrl',
            en: '/company/:companyUniqueUrl',
        },
    },
    {
        id: '/uchadzac',
        Component: Candidate,
        props: {
            layout: 'main',
            navigation: {
                withHero: true,
            },
        },
        paths: {
            sk: '/uchadzac',
            en: '/candidate',
        },
    },
    {
        id: '/kontakt',
        Component: Contact,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/kontakt',
            en: '/contact',
        },
    },
    {
        id: '/ochrana-osobnych-udajov',
        Component: Protection,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/ochrana-osobnych-udajov',
            en: '/personal-data-protection',
        },
    },
    {
        id: '/obchodne-podmienky',
        Component: TermsAndConditions,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/obchodne-podmienky',
            en: '/terms-and-conditions',
        },
    },
    {
        id: '/ponuky',
        Component: Offers,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/ponuky/:offerType(type:[a-z,-]*)?/:page?',
            en: '/offers/:offerType(type:[a-z,-]*)?/:page?',
        },
    },
    {
        id: '/ponuka',
        Component: OfferDetail,
        props: {
            layout: 'main',
        },
        shouldRedirect: false,
        paths: {
            sk: '/ponuka/:slug',
            en: '/offer/:slug',
        },
    },
    {
        id: '/prihlasenie',
        Component: Login,
        props: {
            layout: 'main',
        },
        shouldRedirect: true,
        paths: {
            sk: '/prihlasenie',
            en: '/login',
        },
    },
    {
        id: '/registracia',
        Component: Registration,
        props: {
            layout: 'main',
        },
        shouldRedirect: true,
        paths: {
            sk: '/registracia',
            en: '/registration',
        },
    },
    {
        id: '/registracia-student',
        Component: RegistrationStudent,
        props: {
            layout: 'main',
        },
        shouldRedirect: true,
        paths: {
            sk: '/registracia-student',
            en: '/registration-student',
        },
    },
    {
        id: '/reset-hesla',
        Component: ResetPassword,
        props: {
            layout: 'main',
        },
        shouldRedirect: true,
        paths: {
            sk: '/reset-hesla',
            en: '/reset-password',
        },
    },
    {
        id: '/reset-hesla-email',
        Component: ResetPasswordEmail,
        props: {
            layout: 'main',
        },
        shouldRedirect: true,
        paths: {
            sk: '/reset-hesla-email',
            en: '/reset-password-email',
        },
    },
    {
        id: '/odber',
        Component: OfferNotificationSubscription,
        props: {
            layout: 'main',
        },
        shouldRedirect: true,
        paths: {
            sk: '/odber',
            en: '/offer-subscription',
        },
    },
    {
        id: '/feedback/:feedbackToken',
        Component: FeedbackStudent,
        props: {
            layout: 'main',
        },
        shouldRedirect: true,
        paths: {
            sk: '/feedback/:feedbackToken',
            en: '/feedback/:feedbackToken',
        },
    },
    {
        id: '/profil',
        Component: Profile,
        props: {
            layout: 'main',
        },
        shouldRedirect: true,
        paths: {
            sk: '/profil',
            en: '/profile',
        },
        routes: [
            {
                id: '/profil',
                Component: ProfileApplicationHistory,
                props: {
                    layout: 'main',
                },
                shouldRedirect: true,
                paths: {
                    sk: '/profil',
                    en: '/profile',
                },
            },
            {
                id: '/profil/nastavenia',
                Component: ProfileSettings,
                props: {
                    layout: 'main',
                },
                shouldRedirect: true,
                paths: {
                    sk: '/profil/nastavenia',
                    en: '/profile/settings',
                },
            },
            {
                id: '/profil/odber',
                Component: ProfileOfferNotificationSubscription,
                props: {
                    layout: 'main',
                },
                shouldRedirect: true,
                paths: {
                    sk: '/profil/odber',
                    en: '/profile/subscription',
                },
            },
            {
                id: '/profil/zivotopisy',
                Component: ProfileResumes,
                props: {
                    layout: 'main',
                },
                shouldRedirect: true,
                paths: {
                    sk: '/profil/zivotopisy',
                    en: '/profile/resumes',
                },
            },
        ],
    },
    {
        id: '/admin',
        Component: Admin,
        props: {
            layout: 'main',
        },
        shouldRedirect: true,
        paths: {
            sk: '/admin',
            en: '/admin',
        },
        routes: [
            {
                id: '/admin',
                Component: AdminOffers,
                props: {
                    layout: 'main',
                },
                shouldRedirect: true,
                paths: {
                    sk: '/admin',
                    en: '/admin',
                },
            },
            {
                id: '/admin/uchadzaci',
                Component: Applicants,
                props: {
                    layout: 'main',
                },
                shouldRedirect: true,
                paths: {
                    sk: '/admin/uchadzaci/:offerId?',
                    en: '/admin/applicants/:offerId?',
                },
            },
            {
                id: '/admin/kredity',
                Component: AdminCredits,
                props: {
                    layout: 'main',
                },
                shouldRedirect: true,
                paths: {
                    sk: '/admin/kredity',
                    en: '/admin/credits',
                },
            },
            {
                id: '/admin/objednavka',
                Component: AdminOrder,
                props: {
                    layout: 'main',
                },
                shouldRedirect: true,
                paths: {
                    sk: '/admin/objednavka/:creditAmount',
                    en: '/admin/order/:creditAmount',
                },
            },
            {
                id: '/admin/firemne-udaje',
                Component: AdminCompanyInfoEdit,
                props: {
                    layout: 'main',
                },
                shouldRedirect: true,
                paths: {
                    sk: '/admin/firemne-udaje',
                    en: '/admin/company-settings',
                },
            },
            {
                id: '/admin/platby',
                Component: AdminPayments,
                props: {
                    layout: 'main',
                },
                shouldRedirect: true,
                paths: {
                    sk: '/admin/platby',
                    en: '/admin/payments',
                },
            },
            {
                id: '/admin/api',
                Component: AdminApi,
                props: {
                    layout: 'main',
                },
                shouldRedirect: true,
                paths: {
                    sk: '/admin/api',
                    en: '/admin/api',
                },
            },
            {
                id: '/admin/pouzivatelia',
                Component: AdminUsers,
                props: {
                    layout: 'main',
                },
                shouldRedirect: true,
                paths: {
                    sk: '/admin/pouzivatelia',
                    en: '/admin/users',
                },
            },
            {
                id: '/admin/osobne-udaje',
                Component: AdminPersonalInfoEdit,
                props: {
                    layout: 'main',
                },
                shouldRedirect: true,
                paths: {
                    sk: '/admin/osobne-udaje',
                    en: '/admin/personal-information',
                },
            },
            {
                id: '/admin/prihlasovacie-udaje',
                Component: AdminLoginInfoEdit,
                props: {
                    layout: 'main',
                },
                shouldRedirect: true,
                paths: {
                    sk: '/admin/prihlasovacie-udaje',
                    en: '/admin/login-information',
                },
            },
            {
                id: '/admin/pridat-ponuku',
                Component: AdminOfferPost,
                props: {
                    layout: 'main',
                },
                shouldRedirect: true,
                paths: {
                    sk: '/admin/pridat-ponuku',
                    en: '/admin/add-offer',
                },
            },
            {
                id: '/admin/uprava-ponuky',
                Component: AdminOfferEdit,
                props: {
                    layout: 'main',
                },
                shouldRedirect: true,
                paths: {
                    sk: '/admin/uprava-ponuky/:slug',
                    en: '/admin/edit-offer/:slug',
                },
            },
        ],
    },
    {
        id: '/magazin',
        Component: Magazine,
        props: {
            layout: 'main',
        },
        paths: {
            sk: '/magazin',
            en: '/magazine',
        },
        routes: [
            {
                id: '/magazin',
                Component: MagazineHomepage,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/magazin',
                    en: '/magazine',
                },
            },
            {
                id: '/magazin/archiv/:page',
                Component: MagazineHomepage,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/magazin/archiv/:page',
                    en: '/magazin/archive/:page',
                },
            },
            {
                id: '/magazin/:slug',
                Component: MagazinePost,
                props: {
                    layout: 'main',
                },
                paths: {
                    sk: '/magazin/:slug',
                    en: '/magazine/:slug',
                },
            },
        ],
    },
];
function getRoutePaths(routeObject, hasParentLayout = false) {
    const paths = Object.entries(routeObject.paths).map(([locale, path]) => locale !== 'sk' ? `/${locale}${path}` : path);
    const Component = routeObject.Component;
    return (React.createElement(Route, { key: paths[0], path: paths, exact: !routeObject.routes },
        React.createElement(ConditionalWrapper, { condition: routeObject.routes || (!routeObject.routes && !hasParentLayout), wrapper: children => React.createElement(LayoutMain, Object.assign({}, routeObject.props), children) }, routeObject.routes ? (React.createElement(Component, Object.assign({}, routeObject.props), routeObject.routes.map(innerRoute => getRoutePaths(innerRoute, true)))) : (React.createElement(Component, Object.assign({}, routeObject.props))))));
}
function Routes() {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const mainLayoutRoutes = routes.filter(({ props }) => props.layout === 'main');
    const micrositeLayoutRoutes = routes.filter(({ props }) => props.layout === 'microsite');
    React.useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            // init google analytics
            ReactGA.initialize(process.env.GOOGLE_ANALYTICS);
        }
    }, []);
    React.useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(actions.mobileMenuClose());
        // Google analytics
        ReactGA.pageview(location.pathname + location.search);
        // inhouse tracking
        Analytics.trackPageView();
        // Email Verification Token === evt
        // @ts-ignore
        if (history.getQuery().evt) {
            dispatch(actions.userVerificationStart());
        }
    }, [location.pathname]);
    React.useEffect(() => {
        const { pathWithoutLocale } = getPathWithoutLocale(window.location.pathname);
        const routeObject = findMatchingRoute(routes, pathWithoutLocale);
        if (routeObject === null || routeObject === void 0 ? void 0 : routeObject.shouldRedirect) {
            let { search } = window.location;
            const sid = Cookies.get('connect.sid');
            if (sid) {
                search = `${search ? `${search}&` : '?'}sid=${sid}`;
            }
            if (process.env.ENVIRONMENT === 'local') {
                return console.log('Redirecting to:', `${process.env.PRACUJ_REDIRECT_TARGET}${window.location.pathname}${search}`);
                // TODO: in pracuj use history.replaceState({}, '', window.location.pathname) to get rid of session token
            }
            return window.location.assign(`${process.env.PRACUJ_REDIRECT_TARGET}${window.location.pathname}${search}`);
        }
    }, [location.pathname]);
    return (React.createElement(Switch, null,
        micrositeLayoutRoutes.map(routeObject => getRoutePaths(routeObject)),
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(Route, null,
                React.createElement(Switch, null,
                    mainLayoutRoutes.map(routeObject => getRoutePaths(routeObject)),
                    React.createElement(Route, { path: "*" },
                        React.createElement(LayoutMain, null,
                            React.createElement(NotFound, null))))))));
}
export { Routes as default, routes };

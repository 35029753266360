import { call, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from '@src/app/index'
import axios from 'axios'
import URI from 'urijs'
import config from 'config'
import actions from '../actions'

export function* accountEditStart(values) {
	const uri = new URI(`${config.api}/0/account`)
	const apiUri = uri.toString()

	const putData = data =>
		axios
			.put(apiUri, data, { withCredentials: true })
			.then(res => res)
			.catch(err => {
				throw err
			})

	try {
		const response = yield call(putData, values.payload)
		yield put(actions.accountEditSuccess(response.data))
		yield put(actions.authCheckStart())
	} catch (err) {
		yield put(actions.accountEditError(err))
	}
}

export function* accountEditError(err) {
	const showError = error => {
		notification.error({
			message: 'Chyba',
			description: error.payload.response.data.error,
		})
	}
	yield call(showError, err)
}

export function* accountEditSuccess(account) {
	notification.success({
		message: 'Upozornenie',
		description: 'Zmena údajov prebehla úspešne',
	})
	const redirect = () => {
		if (account.account_type_id === config.accountType.company) {
			history.pushLocalized('/admin')
		} else {
			history.pushLocalized('/profil')
		}
	}

	yield call(redirect)
}

import React from 'react'

const IconSocialTwitter = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
		<path
			d="M136.03333 37.56667a2.91667 2.91667 0 00-1.16666-5.075l-4.60834-1.16667a2.91667 2.91667 0 01-1.925-4.08333L130.9 22.05a2.91667 2.91667 0 00-3.38333-4.08333L115.85 21.23333a2.91667 2.91667 0 01-2.56667-.46666 29.16667 29.16667 0 00-17.5-5.83334A29.16667 29.16667 0 0066.61667 44.1v2.1a1.45833 1.45833 0 01-1.28334 1.45833c-16.39166 1.925-32.08333-6.41666-49-25.9a2.975 2.975 0 00-2.975-.875 2.91667 2.91667 0 00-1.69166 2.45 44.21667 44.21667 0 002.68333 28.7 1.45833 1.45833 0 01-1.51667 2.1L6.3 52.85a2.91667 2.91667 0 00-3.325 3.44167 30.04167 30.04167 0 0013.825 22.05 1.45833 1.45833 0 010 2.625l-3.09167 1.225a2.91667 2.91667 0 00-1.51666 4.025 25.43333 25.43333 0 0018.66666 14.46666 1.45833 1.45833 0 010 2.74167 63.81667 63.81667 0 01-25.025 4.84167A2.97443 2.97443 0 004.66667 114.1 117.01667 117.01667 0 0052.15 125.35833a73.38333 73.38333 0 0040.83333-11.66666A72.91667 72.91667 0 00125.41667 52.85v-5.075a2.91667 2.91667 0 011.05-2.21667z"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="0"
		/>
	</svg>
)

export default IconSocialTwitter

import React from 'react'
import { connect, useDispatch } from 'react-redux'
import actions from 'actions'
import CompanyInfoEditForm from './modules/company-info-edit-form'

const CompanyInfoEdit = props => {
	const dispatch = useDispatch()

	React.useEffect(() => {
		dispatch(actions.myCompanyInfoInitStart())
	}, [])

	return (
		<div className="panel">
			<CompanyInfoEditForm onSubmit={props.handleSubmit} />
		</div>
	)
}

const mapDispatchToProps = dispatch => ({
	handleSubmit: values => {
		dispatch(actions.companyInfoEditStart(values))
	},
})

export default connect(null, mapDispatchToProps)(CompanyInfoEdit)

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import moment from 'moment';
import { Collapse, Popconfirm, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, FormattedDate, FormattedNumber, useIntl } from 'react-intl';
import { Button, Panel, Table } from '@src/components/elements';
import * as Api from '@src/api';
import styled from 'styled-components';
import actions from '@src/actions';
const ExpireDate = styled.span `
	color: ${({ theme }) => theme.color.error};
`;
const DangerButton = styled(Button) `
	background-color: ${({ theme }) => theme.color.error} !important;
	border-color: ${({ theme }) => theme.color.error} !important;

	&:hover {
		background-color: ${({ theme }) => theme.color.error};
		border-color: ${({ theme }) => theme.color.error};
	}
`;
// const Price
export function AdminPayments() {
    const [isPaymentMethodBeingDeleted, setIsPaymentMethodBeingDeleted] = React.useState(false);
    const [paymentMethods, setPaymentMethods] = React.useState([]);
    // @ts-ignore
    const { invoices, companyOfferCredits } = useSelector(state => state.admin.company);
    const dispatch = useDispatch();
    const intl = useIntl();
    const isInitialized = Boolean(companyOfferCredits) && Boolean(invoices);
    const fetchPaymentMethods = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield Api.Admin.getPaymentMethods();
            setPaymentMethods(response);
        }
        catch (err) {
            console.log(err);
        }
    }), []);
    React.useEffect(() => {
        fetchPaymentMethods();
    }, []);
    const deleteCard = () => __awaiter(this, void 0, void 0, function* () {
        try {
            setIsPaymentMethodBeingDeleted(true);
            yield Api.Admin.deletePaymentMethod();
            dispatch(actions.myCompanyInfoInitStart());
            yield fetchPaymentMethods();
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setIsPaymentMethodBeingDeleted(false);
        }
    });
    if (!isInitialized) {
        return null;
    }
    const columns = [
        {
            title: intl.formatMessage({
                id: 'admin.payments.credits.bought',
            }),
            dataIndex: 'purchased_credit_amount',
            key: 'purchased_credit_amount',
            align: 'center',
        },
        {
            title: intl.formatMessage({
                id: 'admin.payments.credits.used',
            }),
            dataIndex: 'used_credit_amount',
            key: 'used_credit_amount',
            align: 'center',
        },
        {
            title: intl.formatMessage({
                id: 'admin.payments.credits.created_on',
            }),
            dataIndex: 'created_at',
            key: 'created_at',
            align: 'center',
            render: data => React.createElement(FormattedDate, { value: data, day: "numeric", month: "long", year: "numeric" }),
        },
        {
            title: intl.formatMessage({
                id: 'admin.payments.credits.expires_on',
            }),
            dataIndex: 'expiration_date',
            key: 'expiration_date',
            align: 'center',
            render: data => (React.createElement(ExpireDate, null,
                React.createElement(FormattedDate, { value: data, day: "numeric", month: "long", year: "numeric" }))),
        },
        {
            title: intl.formatMessage({
                id: 'admin.payments.credits.total',
            }),
            dataIndex: 'amount_paid_with_vat',
            key: 'amount_paid_with_vat',
            align: 'center',
            render: data => data ? (React.createElement(FormattedNumber, { value: data, style: "currency", currency: "EUR" })) : (React.createElement(Tag, { className: "m-none", color: "green" },
                React.createElement(FormattedMessage, { id: "general.free" }))),
        },
        {
            key: 'download',
            fixed: 'right',
            align: 'right',
            render: (_, invoice) => invoice.url ? (React.createElement(Button, { onClick: () => window.open(invoice.url, 'blank'), size: "middle", type: "default" },
                React.createElement(FormattedMessage, { id: "admin.payments.credits.download_invoice" }))) : null,
        },
    ];
    const creditsWithMappedInvoices = companyOfferCredits
        .map(credit => {
        const invoice = invoices.find(inv => inv.company_offer_credit_id === credit.id);
        return {
            purchased_credit_amount: credit.purchased_credit_amount,
            used_credit_amount: credit.used_credit_amount,
            created_at: credit.created_at,
            expiration_date: credit.expiration_date,
            amount_paid_with_vat: invoice === null || invoice === void 0 ? void 0 : invoice.amount_paid_with_vat,
            url: invoice === null || invoice === void 0 ? void 0 : invoice.url,
        };
    })
        .sort((a, b) => moment(b.created_at).unix() - moment(a.created_at).unix());
    const hasPaymentMethod = paymentMethods.length > 0;
    return (React.createElement(Panel, { className: "p-m" },
        React.createElement(Collapse, { expandIconPosition: "right", className: "font-size-m", defaultActiveKey: ['1', '2'] },
            React.createElement(Collapse.Panel, { header: React.createElement("span", { className: "font-size-l" },
                    React.createElement(FormattedMessage, { id: "admin.payments.credits_and_invoices" })), key: "1" },
                React.createElement(Table, { pagination: false, dataSource: creditsWithMappedInvoices, columns: columns })),
            React.createElement(Collapse.Panel, { header: React.createElement("span", { className: "font-size-l" },
                    React.createElement(FormattedMessage, { id: "admin.payments.payment_methods" })), key: "2" },
                hasPaymentMethod &&
                    paymentMethods.map(paymentMethod => (React.createElement("div", { key: `payment-method-${paymentMethod.maskedNumber}`, className: "p-m flex-row align-center justify-space-between" },
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "admin.payments.payment_methods.payment_card" }),
                            paymentMethod.expired && (React.createElement(Tag, { className: "m-l-m", color: "error" },
                                React.createElement(FormattedMessage, { id: "admin.payments.payment_methods.expired" })))),
                        React.createElement("span", null,
                            React.createElement("img", { className: "m-r-m", src: paymentMethod.imageUrl, alt: "Typ Karty" }),
                            paymentMethod.maskedNumber),
                        React.createElement(Popconfirm, { title: intl.formatMessage({
                                id: 'admin.payments.payment_methods.are_you_sure_remove_card',
                            }), okText: intl.formatMessage({
                                id: 'general.yes',
                            }), cancelText: intl.formatMessage({
                                id: 'general.no',
                            }), onConfirm: deleteCard },
                            React.createElement(DangerButton, { size: "middle", loading: isPaymentMethodBeingDeleted },
                                React.createElement(FormattedMessage, { id: "admin.payments.payment_methods.remove_card" })))))),
                !hasPaymentMethod && (React.createElement("div", { className: "flex-center p-m" },
                    React.createElement("div", { className: "flex-col" },
                        React.createElement(FormattedMessage, { id: "admin.payments.no_payment_methods" }))))))));
}

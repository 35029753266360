import React from 'react';
import { connect, useDispatch } from 'react-redux';
import actions from 'actions';
import PersonalInfoEditForm from './modules/personal-info-edit-form';


const PersonalInfoEdit = props => {
	const dispatch = useDispatch()

	React.useEffect(() => {
		dispatch(actions.personalInfoInitStart())
	}, [])

	return (
		<div className="panel">
			<PersonalInfoEditForm
				onSubmit={props.handleSubmit}
			/>
		</div>
	);
}

const mapDispatchToProps = dispatch => ({
	handleSubmit: (values) => {
		dispatch(actions.personalInfoEditStart(values));
	},
});

export default connect(
	null,
	mapDispatchToProps,
)(PersonalInfoEdit);

import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import URI from 'urijs'
import camelize from 'camelize'
import { notification } from 'antd'
import config from 'config'
import actions from '../actions'

export function* getPlansStart(action) {
	const plans = action.payload
	const requests = plans.map(planId => {
		const uri = new URI(`${config.api}/0/plans/${planId}`)
		const apiUri = uri.toString()
		return axios
			.get(apiUri, { withCredentials: true })
			.then(res => res)
			.catch(err => {
				throw err
			})
	})

	const getData = () =>
		axios
			.all(requests)
			.then(axios.spread((...args) => args.map(item => item.data)))
			.catch(err => {
				throw err
			})

	try {
		const response = yield call(getData)
		const camelizedResponse = camelize(response)
		yield put(actions.getPlansSuccess(camelizedResponse))
	} catch (err) {
		yield put(actions.getPlansError(err))
	}
}

export function* getPlansError(err) {
	const showError = () => {
		notification.error({
			message: 'Chyba',
			description: err.message,
		})
	}
	yield call(showError)
}

// @ts-nocheck
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { useTheme } from 'styled-components';
import { up } from 'styled-breakpoints';
import { Button, Holder } from '@src/components/elements';
import { CompanyLogos } from '@src/components/modules/global/CompanyLogos';
import config from '@src/config';
import { BoxFree } from './BoxFree';
import { BoxCredits } from './BoxCredits';
import { Testimonials } from './Testimonials';
import { Features } from './Features';
export const Wrapper = styled.div `
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;
export const Headline = styled.div `
	position: relative;
	z-index: 1;
	text-align: center;
	padding: 3.5em 2em 0;

	${up('sm')} {
		padding: 2.5em 2em 0;
	}

	${up('md')} {
		padding: 5em 2em 0;
	}

	& > * {
		color: ${props => props.theme.color.white};
	}
	h1 {
		font-size: 2.4em;
		font-weight: bold;
		margin-bottom: 0.5em;
	}
	h2 {
		font-size: 1.4em;
		font-weight: 200;
		line-height: 1.4;
	}
`;
export const Hero = styled.div `
	padding-top: ${props => props.theme.size.headerHeight}px;
	width: 100%;
	height: 800px;
	position: relative;
	background: url('/media/images/hero-company3.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center 0;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		z-index: 0;
		pointer-events: none;
		opacity: 0.95;
		background: url('/media/images/background-gradient.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}
`;
export const Boxes = styled.div `
	width: 100%;
	position: relative;
	z-index: 1;
	display: flex;
	align-items: flex-start;
	margin-top: -461px;
	flex-direction: column;

	${up('md')} {
		flex-direction: row;
	}
`;
export const Content = styled.div `
	width: 100%;
`;
export const CompanyLogosWrapper = styled.div `
	width: 100%;
	padding: 5em 0;

	h2 {
		font-size: 2em;
		text-align: center;
		font-weight: 700;
		margin: 0 0 2em;
		color: ${props => props.theme.color.brand4};
	}

	h3 {
		font-weight: 400;
		font-size: 1.5em;
		text-align: center;
		margin: 0;

		strong {
			color: ${props => props.theme.color.brand2};
		}
	}
`;
export const TestimonialsWrapper = styled.div `
	width: 100%;

	padding: 5em 0;

	h2 {
		color: ${props => props.theme.color.brand4};
		font-size: 2em;
		text-align: center;
		font-weight: 700;
		margin: 0 0 2em;
	}
`;
export const FeaturesWrapper = styled.div `
	width: 100%;
	background: ${props => props.theme.color.white};
	padding: 5em 0 2em;

	h2 {
		color: ${props => props.theme.color.brand4};
		font-size: 2em;
		text-align: center;
		font-weight: 700;
		margin: 0 0 2em;
	}
`;
export const BottomCTA = styled.div `
	width: 100%;
	background: ${props => props.theme.color.white};
	padding: 5em 0;
	text-align: center;

	p strong {
		color: ${props => props.theme.color.brand2};
	}

	h2 {
		color: ${props => props.theme.color.brand4};
		font-size: 2em;
		font-weight: 700;
		margin: 0 0 0.5em;
	}
`;
export const FreeCredit = styled.div `
	width: 100%;
	padding: 0 0 2em 0;
	text-align: center;
`;
export const FreeCredit1 = styled.div `
	width: 100%;
	padding: 1em 3em 0;
	font-weight: 700;
`;
export const FreeCredit2 = styled.div `
	font-size: 2em;
	font-weight: 700;
	padding: 0.3em 1em 1em;
	color: ${props => props.theme.color.brand3};
`;
export const FreeCredit3 = styled.div `
	width: 100%;
	font-weight: 400;
	font-size: 0.9em;
	padding: 1em 3em 0;
	color: ${props => props.theme.color.gray4};
`;
export const ButtonText = styled.div ``;
const Companies = () => {
    const theme = useTheme();
    return (React.createElement(Wrapper, null,
        React.createElement(Hero, null,
            React.createElement(Holder, null,
                React.createElement(Headline, null,
                    React.createElement("h1", null,
                        React.createElement(FormattedMessage, { id: "companies.headline" })),
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "companies.subheadline_1" }),
                        React.createElement("br", null),
                        React.createElement(FormattedMessage, { id: "companies.subheadline_2" }))))),
        React.createElement(Content, null,
            React.createElement(Holder, null,
                React.createElement(Boxes, null,
                    React.createElement(BoxFree, null),
                    React.createElement(BoxCredits, null))),
            React.createElement(CompanyLogosWrapper, null,
                React.createElement(Holder, null,
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "companies.clients.headilne" })),
                    React.createElement(CompanyLogos, null),
                    React.createElement("h3", null,
                        React.createElement(FormattedMessage, { id: "companies.clients.subheadline", values: {
                                count: React.createElement("strong", null,
                                    config.marketing.companies,
                                    "+"),
                            } })))),
            React.createElement(FeaturesWrapper, { id: "features" },
                React.createElement(Holder, null,
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "companies.features.headilne" })),
                    React.createElement(Features, null))),
            React.createElement(TestimonialsWrapper, null,
                React.createElement(Holder, null,
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "companies.testimonials.headilne" })),
                    React.createElement(Testimonials, null))),
            React.createElement(BottomCTA, null,
                React.createElement(Holder, null,
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "companies.results.headilne" })),
                    React.createElement("p", null,
                        React.createElement(FormattedMessage, { id: "companies.results.subheadilne", values: {
                                companies: React.createElement("strong", null,
                                    config.marketing.companies,
                                    "+"),
                                offers: React.createElement("strong", null,
                                    config.marketing.offers,
                                    "+"),
                                responses: React.createElement("strong", null,
                                    config.marketing.responses,
                                    "+"),
                            } })),
                    React.createElement(FreeCredit, null,
                        React.createElement(FreeCredit1, null,
                            React.createElement(FormattedMessage, { id: "companies.results.try" }),
                            ":"),
                        React.createElement(FreeCredit2, null,
                            React.createElement(FormattedMessage, { values: { count: 1 }, id: "general.credits.pluralized" })),
                        React.createElement(Button, { style: {
                                background: theme.color.success,
                                borderColor: theme.color.success,
                                color: theme.color.white,
                            }, link: "/registracia" },
                            React.createElement(ButtonText, null,
                                React.createElement(FormattedMessage, { id: "companies.free.create_account_free" }))),
                        React.createElement(FreeCredit3, null,
                            React.createElement(FormattedMessage, { id: "companies.free.no_hidden_fees" }))))))));
};
export default Companies;

import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import Button from 'components/modules/global/button'
import { FormattedMessage, injectIntl } from 'react-intl'
// field components
import showError from 'components/modules/forms/showError'
import renderField from 'components/modules/forms/renderField'
import renderCheckbox from 'components/modules/forms/renderCheckbox'
import renderMultiselect from 'components/modules/forms/renderMultiselect'
import config from 'config'
import { Col, Row } from 'antd'
import validate from '../validation/sync-validation'
import asyncValidate from '../validation/async-validation'

let SettingsFormComponent = props => {
	const { handleSubmit, intl } = props
	const isUserRegisteredOAuth =
		Boolean(props.initialValues.facebook_user_id) || Boolean(props.initialValues.google_user_id)

	return (
		<div>
			<form className="form" onSubmit={handleSubmit}>
				<Row gutter={[16, 16]}>
					<Col xs={24} md={12}>
						<Field
							component={renderField}
							name="first_name"
							label={intl.formatMessage({
								id: 'profile.settings.name',
								defaultMessage: 'Meno *',
							})}
						/>
					</Col>
					<Col xs={24} md={12}>
						<Field
							component={renderField}
							name="last_name"
							label={intl.formatMessage({
								id: 'profile.settings.surename',
								defaultMessage: 'Priezvisko *',
							})}
						/>
					</Col>
				</Row>
				<div className="form__row">
					<Row gutter={[16, 16]}>
						<Col xs={24}>
							<Field
								component={renderField}
								type="email"
								name="email"
								label={intl.formatMessage({
									id: 'profile.settings.email',
									defaultMessage: 'Email *',
								})}
							/>
						</Col>
						{!isUserRegisteredOAuth && (
							<Col xs={24}>
								<Field
									component={renderField}
									type="password"
									name="currentPassword"
									label={intl.formatMessage({
										id: 'profile.settings.actual_password',
										defaultMessage: 'Aktuálne heslo *',
									})}
									autocomplete="new-password"
								/>
							</Col>
						)}
						<Col xs={24} md={12}>
							<Field
								component={renderField}
								name="password"
								label={intl.formatMessage({
									id: 'profile.settings.new_password',
									defaultMessage: 'Nové heslo',
								})}
								type="password"
								autocomplete="new-password"
							/>
						</Col>
						<Col xs={24} md={12}>
							<Field
								component={renderField}
								name="passwordRepeat"
								label={intl.formatMessage({
									id: 'profile.settings.verificate_password',
									defaultMessage: 'Overenie hesla',
								})}
								type="password"
								autocomplete="new-password"
							/>
						</Col>
					</Row>
				</div>
				<div className="form__row">
					<Row gutter={[16, 16]}>
						<Col xs={24}>
							<Field
								component={renderMultiselect}
								name="job_area"
								onBlur={() => props.onBlur()}
								data={config.jobAreas}
								label={intl.formatMessage({
									id: 'profile.settings.choose_job_area',
									defaultMessage: 'Vyberte zameranie odboru *',
								})}
							/>
						</Col>
						<Col xs={24}>
							<Field
								component={renderCheckbox}
								className="col-12"
								name="newsletter"
								label={intl.formatMessage({
									id: 'profile.settings.agree_with_news_subscription',
									defaultMessage:
										'Súhlasím s odberom noviniek a zasielaním zaujímavých ponúk na email',
								})}
								type="checkbox"
							/>
						</Col>
					</Row>
				</div>
				<div className="form__submit">
					<div className="form__submit-item">
						<Button
							type="submit"
							color="brand"
							text={intl.formatMessage({
								id: 'profile.settings.save_changes',
								defaultMessage: 'Uložiť zmeny',
							})}
							disabled={props.submitting}
						/>
					</div>
				</div>
				<div className="bottom-line">
					<FormattedMessage
						id="profile.settings.required_fields"
						defaultMessage="* Polia označené hviezdičkou sú povinné"
					/>
				</div>
			</form>
		</div>
	)
}

SettingsFormComponent = reduxForm({
	form: 'Settings',
	destroyOnUnmount: true,
	enableReinitialize: true,
	onSubmitFail: showError,
	validate,
	asyncValidate,
})(SettingsFormComponent)

SettingsFormComponent = injectIntl(
	connect(state => ({
		initialValues: state.app.user,
	}))(SettingsFormComponent)
)

// exporting const because of no-mutable-exports
const Settings = SettingsFormComponent

export default Settings

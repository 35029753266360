import React from 'react'

const IconThreeColumns = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 140 140"
	>
		<g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
			<path
				d="M0.750 0.747 L5.250 0.747 L5.250 23.247 L0.750 23.247 Z"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M9.751 0.747 L14.251 0.747 L14.251 23.247 L9.751 23.247 Z"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M18.750 0.747 L23.250 0.747 L23.250 23.247 L18.750 23.247 Z"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
		</g>
	</svg>
)

export default IconThreeColumns

import { SupportedLanguage } from '@src/types';
import moment from 'moment-timezone';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
const RangeHours = ({ timeFrom, timeTo, hasBothTimesDefined }) => (React.createElement(React.Fragment, null,
    timeFrom && (React.createElement(React.Fragment, null,
        React.createElement(FormattedMessage, { id: "offer_detail.details.from" }),
        timeFrom)),
    timeTo && (React.createElement(React.Fragment, null,
        hasBothTimesDefined && React.createElement(React.Fragment, null, ' - '),
        React.createElement(FormattedMessage, { id: "offer_detail.details.to" }),
        timeTo))));
export const WorkingTimeRangeHours = ({ timeFrom, timeTo, timezone, isRemote }) => {
    if (!timezone) {
        return null;
    }
    const intl = useIntl();
    const hasBothTimesDefined = Boolean(timeFrom && timeTo);
    const offerOffset = moment().tz(timezone).utcOffset();
    const clientUtcOffset = moment().utcOffset();
    const timeFormat = intl.locale === SupportedLanguage.sk ? 'HH:mm' : 'hh:mm A';
    // set times from DB as UTC (+00 timezone)
    const utcTimeFrom = timeFrom ? moment.utc(timeFrom, 'HH:mm') : null;
    const utcTimeTo = timeTo ? moment.utc(timeTo, 'HH:mm') : null;
    const isUserInDifferentTimezone = clientUtcOffset !== offerOffset;
    let from = null;
    let to = null;
    if (isUserInDifferentTimezone) {
        from = timeFrom ? moment(utcTimeFrom).tz(timezone).format(timeFormat) : null;
        to = timeTo ? moment(utcTimeTo).tz(timezone).format(timeFormat) : null;
    }
    else {
        from = timeFrom ? moment(utcTimeFrom).tz(timezone).format(timeFormat) : null;
        to = timeTo ? moment(utcTimeTo).tz(timezone).format(timeFormat) : null;
    }
    return (React.createElement("span", { className: "flex-row" },
        React.createElement(RangeHours, { timeFrom: from, timeTo: to, hasBothTimesDefined: hasBothTimesDefined }),
        isRemote && isUserInDifferentTimezone && (React.createElement(React.Fragment, null,
            ' (',
            React.createElement(RangeHours, { timeFrom: timeFrom ? moment(utcTimeFrom, 'HH:mm').utcOffset(clientUtcOffset).format(timeFormat) : null, timeTo: timeTo ? moment(utcTimeTo, 'HH:mm').utcOffset(clientUtcOffset).format(timeFormat) : null, hasBothTimesDefined: hasBothTimesDefined }),
            ' ',
            React.createElement(FormattedMessage, { id: "offer_detail.details.different_timezone" }),
            ")"))));
};

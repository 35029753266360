import { call, put } from 'redux-saga/effects'
import { history } from '@src/app/index'
import axios from 'axios'
import URI from 'urijs'
import { notification } from 'antd'
import config from 'config'
import actions from '../actions'

export function* loginInfoEditStart(values) {
	const uri = new URI(`${config.api}/0/login-info-edit`)
	const apiUri = uri.toString()

	const putData = data =>
		axios
			.put(apiUri, data, { withCredentials: true })
			.then(res => res)
			.catch(err => {
				throw err
			})

	try {
		const response = yield call(putData, values.payload)
		yield put(actions.loginInfoEditSuccess(response.data))
	} catch (err) {
		yield put(actions.loginInfoEditError(err))
	}
}

export function* loginInfoEditError(action) {
	const showError = error => {
		if (error.response.status === 304) {
			notification.warning({
				message: 'Upozornenie',
				description: 'Nezmenili ste žiadne údaje',
			})
		} else {
			notification.error({
				message: 'Chyba',
				description: error.response.data.error,
			})
		}
	}
	yield call(showError, action.payload)
}

export function* loginInfoEditSuccess(res) {
	notification.success({
		message: 'Upozornenie',
		description: `Zmena údajov prebehla úspešne. ${res.payload.message ? res.payload.message : ''}`,
	})
	const redirect = () => history.pushLocalized('/admin')
	yield call(redirect, null)
}

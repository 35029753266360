import React from 'react'
import classNames from 'classnames'
import { Col, Row } from 'antd'

const getInputClasses = show =>
	classNames({
		form__input: true,
		'form__input--error': show,
	})

const renderSalaryAmountFields = ({
	salary_amount_from: {
		input: fromInput,
		meta: { touched: fromTouched, error: fromError },
	},
	salary_amount_to: {
		input: toInput,
		meta: { touched: toTouched, error: toError },
	},
	amountFromLabel,
	amountToLabel,
	disabled,
}) => (
	<div className="form_row">
		<Row gutter={[16, 16]}>
			<Col xs={12}>
				<label className="form__label" htmlFor={fromInput.name}>
					{amountFromLabel}
				</label>

				<input
					{...fromInput}
					className={getInputClasses(fromTouched && fromError)}
					type="number"
					disabled={disabled}
					min="0"
					step="0.1"
					data-cy="amountFrom"
				/>
			</Col>

			<Col xs={12}>
				<label className="form__label" htmlFor={toInput.name}>
					{amountToLabel}
				</label>

				<input
					{...toInput}
					className={getInputClasses(toTouched && toError)}
					type="number"
					disabled={disabled}
					min={fromInput.value}
					step="0.1"
					data-cy="amountTo"
				/>
			</Col>

			<Col xs={24}>
				{fromTouched && fromError && <div className="form__input-rounded-error">{fromError}</div>}
				{toTouched && toError && <div className="form__input-rounded-error">{toError}</div>}
			</Col>
		</Row>
	</div>
)

export default renderSalaryAmountFields

import { injectIntl } from 'react-intl'

const getSalaryString = ({ rate, amountFrom, amountTo, currency, intl }) => {
	const rates = {
		hourly: intl.formatMessage({ id: 'offer_detail.details_model.one_hours', defaultMessage: 'hodina' }),
		monthly: intl.formatMessage({ id: 'offer_detail.details_model.one_month', defaultMessage: 'mesiac' }),
		once: intl.formatMessage({ id: 'offer_detail.details_model.once', defaultMessage: 'jednorázovo' }),
	}

	let result = `${amountFrom} - ${amountTo}${currency}`
	if (!amountTo) {
		result = `od ${amountFrom}${currency}`
	}
	if (amountFrom === amountTo) {
		result = `${amountFrom}${currency}`
	}
	return `${result} / ${rates[rate]}`
}

export default injectIntl(getSalaryString)

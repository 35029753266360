import React from 'react';
import { Holder, Panel, Title, Button } from '@src/components/elements';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'antd';
const NotFound = props => {
    return (React.createElement(Holder, { marginVertical: true },
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, { xs: { span: 24 }, lg: { span: 12 } },
                React.createElement(Title, { level: 2, type: "secondary" },
                    React.createElement(FormattedMessage, { id: "not_found.page_doesnt_exist", defaultMessage: "Str\u00E1nka neexistuje" })))),
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, { xs: { span: 24 }, xl: { span: 12 } },
                React.createElement(Panel, { className: "text-center flex-col align-center" },
                    React.createElement(FormattedMessage, { id: "not_found.we_are_sorry", defaultMessage: "Je n\u00E1m \u013E\u00FAto ale t\u00E1to str\u00E1nka nie je dostupn\u00E1." }),
                    React.createElement(Button, { link: props.link ? props.link : '/', color: "brand", className: "m-t-l" }, props.text ? props.text : React.createElement(FormattedMessage, { id: "not_found.continue_to_home_page" })))))));
};
export default NotFound;

import * as Api from '@src/api';
const asyncValidate = (values, _, props) => {
    const { intl } = props;
    return new Promise((resolve, reject) => Api.Company.getCompanyByUrl(values.company_url)
        .then(() => {
        if (props.initialValues.company_url !== values.company_url) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
                company_url: intl.formatMessage({ id: 'company.company_url.already_used' }),
            });
        }
        else {
            resolve();
        }
    })
        .catch(() => {
        resolve();
    }));
};
export default asyncValidate;

import React from 'react';
import { Link, Button } from '@src/components/elements';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
const StyledCookieConsent = styled.div `
	color: ${props => props.theme.color.gray1};
	z-index: ${props => props.theme.zIndex.cookieConsent};
	bottom: 0;
	left: 0;
	right: 0;
	position: fixed;
	width: 100%;
	margin: 0 auto;
	text-align: center;
	font-size: 1em;

	${up('md')} {
		width: 50%;
	}
`;
const HolderInner = styled.div `
	color: ${props => props.theme.color.gray1};
	background: ${props => props.theme.color.brand4};
	z-index: ${props => props.theme.zIndex.cookieConsent};
	padding: 1.5em 1.5em 1em;
	width: 100%;
	border-radius: 4px 4px 0 0;
	box-shadow: 0 15px 35px rgb(50 50 77 / 20%), 0 5px 30px rgb(0 0 0 / 15%);
`;
const StyledLink = styled(Link) `
	&,
	&:link,
	&:visited,
	&:focus {
		color: ${props => props.theme.color.white};
		text-decoration: underline;
	}
`;
const CookieConsent = ({ onAgree }) => {
    return (React.createElement(StyledCookieConsent, null,
        React.createElement(HolderInner, null,
            React.createElement("p", null,
                React.createElement(FormattedMessage, { id: "global.cookie_consent.cookie_usage" }),
                ' ',
                React.createElement(FormattedMessage, { id: "global.cookie_consent.more_info", values: {
                        Link: chunks => React.createElement(StyledLink, { to: "/ochrana-osobnych-udajov" }, chunks),
                    } })),
            React.createElement(Button, { color: "warning", onClick: onAgree },
                React.createElement(FormattedMessage, { id: "global.cookie_consent.agree" })))));
};
export default CookieConsent;

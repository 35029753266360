var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import * as Api from 'api';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { socket } from '@src/socket';
import { SocketEvents } from '@src/types/socket-events';
import { Button, Panel, Tabs, Separator, Badge, Link } from '@src/components/elements';
import { Skeleton } from 'antd';
import OffersTable from './OffersTable';
import './Offers.scss';
import { transformOffers } from './model';
import ArchiveOfferDialog from './ArchiveOfferDialog';
import { TabTitle } from './Offers.style';
import { useAdminContextValue } from '../../admin-context';
export const EOffersType = {
    active: 'active',
    notActive: 'notActive',
    drafts: 'drafts',
};
// TODO: add types
function Offers() {
    const [myActiveOffers, setMyActiveOffers] = React.useState([]);
    const [myNotActiveOffers, setMyNotActiveOffers] = React.useState([]);
    const [myDraftOffers, setMyDraftOffers] = React.useState([]);
    const [isInitialised, setInitialised] = React.useState(false);
    const [isArchiveDialogOpen, setIsArchiveDialogOpen] = React.useState(false);
    const [currentlyArchivedOffer, setCurrentlyArchivedOffer] = React.useState(null);
    const account = useSelector((state) => state.app.user);
    const { availableCredits } = useSelector((state) => state.admin.company);
    const { setMyOffers } = useAdminContextValue();
    const history = useHistory();
    const intl = useIntl();
    const init = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            const offers = yield Api.Admin.getMyOffers();
            const activeOffers = transformOffers(offers.active);
            const notActiveOffers = transformOffers(offers.notActive);
            const draftOffers = transformOffers(offers.drafts);
            setMyOffers(offers);
            setMyActiveOffers(activeOffers);
            setMyNotActiveOffers(notActiveOffers);
            setMyDraftOffers(draftOffers);
        }
        catch (err) {
            console.error(err);
        }
        setInitialised(true);
    }), [Api, transformOffers]);
    React.useEffect(() => {
        init();
        socket.on(SocketEvents.MY_OFFERS_UPDATED, init);
    }, []);
    const setOfferArchived = React.useCallback((id) => __awaiter(this, void 0, void 0, function* () {
        try {
            yield Api.Offer.archiveOffer(id);
            setIsArchiveDialogOpen(false);
            init();
        }
        catch (err) {
            console.log(err);
        }
    }), [Api, init]);
    const openArchiveDialog = React.useCallback(offerId => {
        const currentOffer = myActiveOffers.find((o) => o.id === offerId);
        setCurrentlyArchivedOffer(currentOffer);
        setIsArchiveDialogOpen(true);
    }, [myActiveOffers]);
    if (!isInitialised) {
        return (React.createElement(Panel, { className: "p-l" },
            React.createElement(Skeleton, { active: true })));
    }
    const isAccountVerified = account.verified === 1;
    return (React.createElement("div", { className: "admin-offers" },
        React.createElement(Panel, { className: "offers-panel" },
            React.createElement(Tabs.Container, null,
                React.createElement(Tabs.TabPane, { tab: intl.formatMessage({
                        id: 'admin.offers.active',
                        defaultMessage: 'Aktívne',
                    }), key: "my-offers-active" },
                    React.createElement(OffersTable, { offers: myActiveOffers, openArchiveDialog: openArchiveDialog, onArchive: init, type: EOffersType.active })),
                myDraftOffers.length > 0 && (React.createElement(Tabs.TabPane, { tab: React.createElement(TabTitle, null,
                        React.createElement(Badge, { count: myDraftOffers.length, offset: [16, 8] }, intl.formatMessage({
                            id: 'admin.offers.drafts',
                            defaultMessage: 'Koncepty',
                        }))), key: "my-offers-drafts" },
                    React.createElement(OffersTable, { offers: myDraftOffers, type: EOffersType.drafts, onArchive: init }))),
                myNotActiveOffers.length > 0 && (React.createElement(Tabs.TabPane, { tab: intl.formatMessage({
                        id: 'admin.offers.archived',
                        defaultMessage: 'Archivované',
                    }), key: "my-offers-not-active" },
                    React.createElement(OffersTable, { offers: myNotActiveOffers, type: EOffersType.notActive, onArchive: init })))),
            React.createElement("div", { className: "p-l p-b-m" },
                React.createElement(Separator, { type: "horizontal" })),
            !availableCredits && (React.createElement("div", { className: "m-b-m text-center" },
                React.createElement("p", { className: "m-b-none" },
                    React.createElement(FormattedMessage, { id: "admin.offers.not_enough_credits" })),
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "admin.offers.look_at_our_pricing", values: {
                            Link: chunks => (React.createElement(Link, { className: "font-bold text-underline", to: "/admin/kredity" }, chunks)),
                        } })))),
            !isAccountVerified && (React.createElement("div", { className: "m-b-m text-center" },
                React.createElement("p", { className: "m-b-none" },
                    React.createElement(FormattedMessage, { id: "admin.offers.verification_notice" })))),
            React.createElement("div", { className: "flex-center" },
                React.createElement(Button, { disabled: !availableCredits || !isAccountVerified, 
                    // @ts-ignore
                    onClick: () => history.pushLocalized('/admin/pridat-ponuku'), "data-cy": "add-offer" },
                    React.createElement(FormattedMessage, { id: "admin.offers.add_offer", defaultMessage: "Prida\u0165 ponuku" }))),
            isArchiveDialogOpen && (React.createElement(ArchiveOfferDialog, { isOpen: isArchiveDialogOpen, offerTitle: currentlyArchivedOffer.title, onConfirm: () => setOfferArchived(currentlyArchivedOffer.id), onCancel: () => setIsArchiveDialogOpen(false) })))));
}
export default Offers;

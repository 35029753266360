import React from 'react'
import actions from 'actions'
import { connect, useDispatch } from 'react-redux'
import _get from 'lodash/get'
import { notification, Col, Row } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import { Panel, Button, Title } from '@src/components/elements'

const Subheadline = styled.strong`
	font-size: 1.2rem;
`

const Api = props => {
	const intl = useIntl()
	const dispatch = useDispatch()

	React.useEffect(() => {
		dispatch(actions.myCompanyInfoInitStart())
	}, [])

	const copyToClipboard = () => {
		const input = document.getElementById('api-key-input')
		if (input) {
			input.select()
			document.execCommand('copy')
			input.blur()
		}

		notification.success({
			message: intl.formatMessage({
				id: 'admin.api.message',
				defaultMessage: 'Správa',
			}),
			description: intl.formatMessage({
				id: 'admin.api.copy_api_key',
				defaultMessage: 'Api kľúč bol skopírovaný',
			}),
		})
	}
	const { createCompanyApiKeyStart, apiKey } = props
	const hasApiKey = apiKey !== ''

	return (
		<Panel>
			<Row>
				<Col xs={24}>
					<Title level="1" type="secondary" align="center">
						<FormattedMessage id="admin.api.title" />
					</Title>
					<Subheadline>
						<FormattedMessage id="admin.api.description" />
					</Subheadline>
					<div className="m-t-l">
						<FormattedMessage
							id="admin.api.example"
							values={{
								strong: chunks => <strong>{chunks}</strong>,
								breakingLine: <br />,
							}}
						/>
					</div>
					<div className="m-t-m m-b-m">
						<a
							href="https://documenter.getpostman.com/view/6622469/RztrKnDf"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FormattedMessage id="admin.api.documentation_here" />
						</a>
					</div>
				</Col>
			</Row>

			<div className="panel panel--secondary">
				<Row>
					<Col xs={24}>
						<input
							id="api-key-input"
							className="form__input api-input"
							value={apiKey}
							type="text"
							onDoubleClick={() => copyToClipboard()}
						/>
					</Col>
				</Row>
				<Row justify="center">
					<Col xs={24} className="text-center">
						<Button
							className="m-t-m"
							onClick={() => {
								if (hasApiKey) {
									const hasConfirmed = confirm(
										intl.formatMessage({
											id: 'admin.api.confirm_generating_new_key',
											defaultMessage:
												'Vygenerovaním nového kľúča stratíte aktuálny. Chcete kľúč premazať?',
										})
									)
									if (hasConfirmed) {
										createCompanyApiKeyStart()
									}
								} else {
									createCompanyApiKeyStart()
								}
							}}
						>
							{intl.formatMessage({
								id: 'admin.api.generate_new_key',
								defaultMessage: 'Vygenerovať nový kľúč',
							})}
						</Button>
					</Col>
				</Row>
			</div>
		</Panel>
	)
}

const mapStateToProps = state => ({
	company: state.admin.company,
	apiKey: _get(state, 'admin.company.apiKey.key', ''),
})

const mapDispatchToProps = dispatch => ({
	createCompanyApiKeyStart: () => dispatch(actions.createCompanyApiKeyStart()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Api)

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/require-default-props */
import React from 'react';
import { Avatar as StyledAvatar } from './Avatar.style';
import { getInitials } from './Avatar.model';
function Avatar(props) {
    const { fullName, className, size = 'large', shape = 'circle' } = props, otherProps = __rest(props, ["fullName", "className", "size", "shape"]);
    return (React.createElement(StyledAvatar, Object.assign({ size: size, shape: shape, className: className }, otherProps), getInitials(fullName)));
}
export { Avatar };

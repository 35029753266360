import { Progress } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { useTheme } from 'styled-components';
const TagsWrapper = styled.ul `
	list-style: none;
	margin: 1rem 0 0 0;
	padding: 0;
`;
const TagWrapper = styled.li `
	display: inline-block;
	margin: 0 0.5rem 0.5rem 0;
	padding: 0.3rem 0.8rem;
	border-radius: 4px;
	background: ${({ theme }) => theme.color.gray2};
`;
const StyledTable = styled.table `
	width: 100%;
	max-width: 300px;
	padding: 0 1rem;
`;
const ProgressText = styled.div `
	font-size: 11px;
	color: ${({ theme }) => theme.color.brand2};
`;
function mapRatesOnTagNames(skillTags, skillTagsRates) {
    const tagsWithRates = skillTags.map(tag => {
        const matchedRate = skillTagsRates.find(tagRate => tagRate.id === tag.id);
        return {
            id: tag.id,
            name: tag.name,
            value: matchedRate.value,
        };
    });
    return tagsWithRates;
}
export const OfferDetailsSkillTag = ({ skillTags, skillTagsRates, areSkillsRated }) => {
    const theme = useTheme();
    const areTagsRated = areSkillsRated && skillTagsRates && skillTagsRates.every(skillTagRate => skillTagRate.value);
    const tags = skillTagsRates && skillTagsRates.length ? mapRatesOnTagNames(skillTags, skillTagsRates) : skillTags;
    return (React.createElement(React.Fragment, null,
        areTagsRated && (React.createElement(StyledTable, null,
            React.createElement("tbody", null, tags.map(tag => (React.createElement("tr", { key: tag.id },
                React.createElement("td", null,
                    tag.name,
                    React.createElement(Progress, { showInfo: false, type: "line", percent: (100 / 5) * tag.value, strokeColor: theme.color.brand1 }),
                    React.createElement("div", { className: "flex-row justify-space-between m-b-s" },
                        React.createElement(ProgressText, null,
                            React.createElement(FormattedMessage, { id: "offer_detail.details.beginner" })),
                        React.createElement(ProgressText, null,
                            React.createElement(FormattedMessage, { id: "offer_detail.details.expert" })))))))))),
        !areTagsRated && (React.createElement("div", null,
            React.createElement(TagsWrapper, null, tags.map(tag => (React.createElement(TagWrapper, { key: tag.id }, tag.name))))))));
};

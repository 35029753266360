var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { FormattedTime, FormattedMessage, FormattedDate, useIntl } from 'react-intl';
import { NotificationType } from '@src/types';
import * as Api from '@src/api';
import { isSameWeek, isSameYear, isToday, isYesterday } from 'date-fns';
import BrowserPageText from '@streamlinehq/streamlinehq/img/streamline-bold/programing-apps-websites/website-development/browser-page-text.svg';
// import RatingStarGive from '@streamlinehq/streamlinehq/img/streamline-bold/rating-star-give-P2WZmj.svg'
import { useConfig } from '@src/hooks/useConfig';
import moment from 'moment';
import { Time, NotificationsWrapper, NoNotificationsMessage, ContentWrapper, IconWrapper, NotificationItem, Content, } from './Notifications.style';
import { getNotificationContent, getNotificationLink } from './Notifications.model';
function Timestamp({ now, date }) {
    if (isToday(date)) {
        return (React.createElement(Time, null,
            React.createElement(FormattedMessage, { id: "general.date.today" }),
            " ",
            React.createElement(FormattedTime, { value: date })));
    }
    if (isYesterday(date)) {
        return (React.createElement(Time, null,
            React.createElement(FormattedMessage, { id: "general.date.yesterday" }),
            " ",
            React.createElement(FormattedTime, { value: date })));
    }
    if (isSameWeek(now, date, { weekStartsOn: 1 })) {
        return (React.createElement(Time, null,
            React.createElement(FormattedDate, { value: date, weekday: "long" }),
            " ",
            React.createElement(FormattedTime, { value: date })));
    }
    return (React.createElement(FormattedDate, { value: date, year: isSameYear(now, date) ? undefined : 'numeric', month: "long", day: "numeric", weekday: undefined }));
}
function Notifications({ notifications = [], refetchNotifications }) {
    const intl = useIntl();
    const config = useConfig();
    const now = new Date();
    const notificationClicked = React.useCallback((uuid, wasClicked) => __awaiter(this, void 0, void 0, function* () {
        try {
            if (!wasClicked) {
                yield Api.Account.patchNotification(uuid, {
                    clicked_at: moment().format(config.datetimeFormat),
                });
                refetchNotifications();
            }
        }
        catch (err) {
            console.error(err);
        }
    }), [refetchNotifications]);
    return (React.createElement(NotificationsWrapper, null,
        notifications.length === 0 && (React.createElement(NoNotificationsMessage, { className: "flex-center p-m" },
            React.createElement(FormattedMessage, { id: "global.notification.no_notifications" }))),
        notifications.map(item => {
            const context = item.context ? JSON.parse(item.context) : {};
            return (React.createElement("span", { key: item.uuid, onClick: () => notificationClicked(item.uuid, Boolean(item.clicked_at)) },
                React.createElement(NotificationItem, { to: getNotificationLink(item.notification_type_id, context), key: item.uuid, isNew: !item.clicked_at },
                    React.createElement(IconWrapper, null, item.notification_type_id === NotificationType.COMPANY_SUBSCRIPTION_NOTIFICATION && (React.createElement(BrowserPageText, { viewBox: "0 0 24 24" }))),
                    React.createElement(ContentWrapper, null,
                        React.createElement(Content, { isNew: !item.clicked_at }, getNotificationContent(intl, item.notification_type_id, context)),
                        React.createElement(Timestamp, { now: now, date: new Date(item.created_at) })))));
        })));
}
export { Notifications };

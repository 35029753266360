import React from 'react';
import Timestamp from 'components/modules/global/timestamp';
import PDFPreview from 'components/modules/global/pdf-preview';

const ApplicationListItem = props => (
	<div className="application-list-item">
		<div className="application-list-item__main">
			<div className="application-list-item__timestamp">
				<Timestamp value={props.application.created_at} />
			</div>
			<div className="application-list-item__title">{props.application.offer_title}</div>
			<div className="application-list-item__company">{props.application.company_name}</div>
			{props.application.message &&
				<div>
					<div className="application-list-item__message-title">Moja správa:</div>
					<div className="application-list-item__message">{props.application.message}</div>
				</div>
			}
		</div>
		<div className="application-list-item__aside">
			{props.application.account_resume &&
				<div className="application-list-item__aside-item">
					<PDFPreview
						documentId={`document-resume-${props.application.id}`}
						documentUrl={props.application.account_resume.resume_url}
						documentName={props.application.account_resume.resume_name}
					/>
				</div>
			}
			{props.application.motivational_letter_url &&
				<div className="application-list-item__aside-item">
					<PDFPreview
						documentId={`document-motivational-${props.application.id}`}
						documentUrl={props.application.motivational_letter_url}
						documentName={props.application.motivational_letter_name}
					/>
				</div>
			}
		</div>
	</div>
);

export default ApplicationListItem;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { FileTextOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import * as Api from '@src/api';
import { Avatar, Button } from '@src/components/elements';
import Timestamp from '@src/components/modules/global/timestamp';
import produce from 'immer';
import { Header, Message, Documents, TimestampWrapper } from './ApplicationDetailModal.style';
export function ApplicationDetailModal({ application, onClose, applications, setApplications }) {
    var _a;
    if (!application) {
        return null;
    }
    const updateViewedAt = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!application.viewed_at) {
            try {
                const currentDate = moment().format();
                yield Api.OfferApplication.updateViewedAt(application.id, currentDate);
                const newApplications = produce(applications, draft => {
                    const app = draft.find(a => a.id === application.id);
                    app.viewed_at = currentDate;
                });
                setApplications(newApplications);
            }
            catch (err) {
                // eslint-disable next-line
                console.error(err);
            }
        }
    }), []);
    React.useEffect(() => {
        updateViewedAt();
    }, [updateViewedAt]);
    const hasResume = Boolean((_a = application.account_resume) === null || _a === void 0 ? void 0 : _a.resume_url);
    const hasMotivationalLetter = Boolean(application.motivational_letter_url);
    const hasDocuments = hasResume || hasMotivationalLetter;
    const drawerWidth = typeof window !== 'undefined' && window.innerWidth >= 1000 ? 480 : 320;
    return (React.createElement(Drawer, { placement: "left", style: { zIndex: 3000000000 }, title: React.createElement(Header, null,
            React.createElement(Avatar, { className: "m-r-l align-self-start", size: 40, fullName: [application.account.first_name, application.account.last_name] }),
            React.createElement("h3", { className: "align-self-center" },
                application.account.first_name,
                "\u00A0",
                application.account.last_name)), footer: React.createElement(TimestampWrapper, null,
            React.createElement(Timestamp, { value: application.created_at })), width: drawerWidth, onClose: onClose, visible: Boolean(application) },
        application.interview_date && (React.createElement(React.Fragment, null,
            React.createElement("h3", { className: "align-self-start m-b-m m-t-m" },
                React.createElement(FormattedMessage, { id: "offer_application.stage.interview" })),
            React.createElement("div", { className: "flex-center" },
                moment(application.interview_date).format('DD. MM. YYYY HH:mm'),
                " -",
                ' ',
                moment(application.interview_date)
                    .add(application.interview_duration_minutes, 'minutes')
                    .format('HH:mm')))),
        hasDocuments && (React.createElement(Documents, null,
            React.createElement("h3", { className: "align-self-start m-b-m" },
                React.createElement(FormattedMessage, { id: "admin.applicants.detail.downloadable_documents" })),
            React.createElement("div", { className: "flex-row" },
                hasResume && (React.createElement("a", { className: "m-r-m", href: application.account_resume.resume_url, target: "_blank", rel: "noreferrer noopenner" },
                    React.createElement(Button, { className: "flex-center", size: "middle", type: "default" },
                        React.createElement(FileTextOutlined, { style: { width: '12px', marginRight: '8px' } }),
                        React.createElement(FormattedMessage, { id: "admin.applicants.detail.downloadable_documents.resume" })))),
                hasMotivationalLetter && (React.createElement("a", { href: application.motivational_letter_url, target: "_blank", rel: "noreferrer noopenner" },
                    React.createElement(Button, { className: "flex-center", size: "middle", type: "default" },
                        React.createElement(FileTextOutlined, { style: { width: '12px', marginRight: '8px' } }),
                        React.createElement(FormattedMessage, { id: "admin.applicants.detail.downloadable_documents.motivational_letter" }))))))),
        React.createElement("h3", { className: "align-self-start m-b-m m-t-m" },
            React.createElement(FormattedMessage, { id: "admin.applicants.send_message.message" })),
        React.createElement(Message, { dangerouslySetInnerHTML: { __html: application.message.replace(/[\n]/g, '</br>') } })));
}

import React from 'react'
import { connect } from 'react-redux'
import OfferPostForm from './modules/offer-post-form'

const OfferPost = props => (
	<div className="panel">
		<OfferPostForm isSubmitting={props.isSubmitting} onSubmit={() => {}} />
	</div>
)

const mapStateToProps = state => ({
	isSubmitting: state.offerPost.isSubmitting,
})

export default connect(mapStateToProps, null)(OfferPost)

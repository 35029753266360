import React from 'react'

const RankingPeople = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 140 140"
	>
		<g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
			<path
				d="M8.25,17.25h-6a1.5,1.5,0,0,0-1.5,1.5v4.5h7.5Z"
				fill="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M21.75,17.25h-6v6h7.5v-4.5A1.5,1.5,0,0,0,21.75,17.25Z"
				fill="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M14.25,11.25H9.75a1.5,1.5,0,0,0-1.5,1.5v10.5h7.5V12.75A1.5,1.5,0,0,0,14.25,11.25Z"
				fill="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M9.375 3.375 A2.625 2.625 0 1 0 14.625 3.375 A2.625 2.625 0 1 0 9.375 3.375 Z"
				fill="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M15.249,8.25a4.269,4.269,0,0,0-6.5,0"
				fill="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M17.376 10.125 A2.625 2.625 0 1 0 22.626 10.125 A2.625 2.625 0 1 0 17.376 10.125 Z"
				fill="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M23.25,15a4.26,4.26,0,0,0-4.5-1.311"
				fill="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M1.374 10.125 A2.625 2.625 0 1 0 6.624 10.125 A2.625 2.625 0 1 0 1.374 10.125 Z"
				fill="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M.75,15A4.286,4.286,0,0,1,4,13.5a4.237,4.237,0,0,1,1.245.188"
				fill="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
			/>
		</g>
	</svg>
)

export default RankingPeople

export const configFrontendEN = {
    api: process.env.API,
    adSenseClientId: 'ca-pub-2352455228582816',
    cookieConsentDuration: 365,
    dateDisplayFormat: 'DD. MM. YYYY',
    environment: process.env.ENVIRONMENT,
    sizeBreakpointXs: 568,
    sizeBreakpointSm: 768,
    sizeBreakpointMd: 1024,
    sizeBreakpointLg: 1280,
    transitionUiSlow: 667,
    socialBaseUrl: {
        facebook: 'https://www.facebook.com/',
        linkedin: 'https://www.linkedin.com/',
        instagram: 'https://www.instagram.com/',
        twitter: 'https://www.twitter.com/',
    },
    accountType: {
        company: 1,
        student: 2,
    },
    offerTypes: [
        { name: 'Internship', id: 1 },
        { name: 'Graduate position', id: 2 },
        { name: 'Volunteering', id: 3 },
        { name: 'Temporary job', id: 4 },
        { name: 'Work & travel', id: 5 },
        { name: 'Contractor', id: 6 },
    ],
    contractTypes: [
        { value: 1, label: 'Full-time' },
        { value: 2, label: 'Part-time' },
    ],
    languages: [
        { code: 'sk', value: 'Slovak' },
        { code: 'en', value: 'English' },
        { code: 'fr', value: 'French' },
        { code: 'cs', value: 'Czech' },
        { code: 'de', value: 'German' },
        { code: 'pl', value: 'Polish' },
        { code: 'es', value: 'Spanish' },
        { code: 'pt', value: 'Portugese' },
        { code: 'zh', value: 'Chinese' },
        { code: 'uk', value: 'Ukrainian' },
        { code: 'ru', value: 'Russian' },
        { code: 'hu', value: 'Hungarian' },
    ],
    locations: [
        { name: 'Slovakia', id: 1 },
        { name: 'Banska Bystrica region', id: 2 },
        { name: 'Bratislava region', id: 3 },
        { name: 'Kosice region', id: 4 },
        { name: 'Nitra region', id: 5 },
        { name: 'Presov region', id: 6 },
        { name: 'Trencin region', id: 7 },
        { name: 'Trnava region', id: 8 },
        { name: 'Zilina region', id: 9 },
        { name: 'Bratislava', id: 10 },
        { name: 'Kosice', id: 11 },
        { name: 'Lucenec district', id: 12 },
        { name: 'Rimavska Sobota district', id: 13 },
        { name: 'Galanta district', id: 14 },
        { name: 'Bardejov district', id: 15 },
        { name: 'Kezmarok district', id: 16 },
        { name: 'Turcianske Teplice district', id: 17 },
        { name: 'Presov district', id: 18 },
        { name: 'Trencin district', id: 19 },
        { name: 'Humenne district', id: 20 },
        { name: 'Nitra district', id: 21 },
        { name: 'Nove Zamky district', id: 22 },
        { name: 'Topolcany district', id: 23 },
        { name: 'Roznava district', id: 24 },
        { name: 'Spisska Nova Ves district', id: 25 },
        { name: 'Vranov nad Toplou district', id: 26 },
        { name: 'Namestovo district', id: 27 },
        { name: 'Zvolen district', id: 28 },
        { name: 'Dunajska Streda district', id: 29 },
        { name: 'Trebisov district', id: 30 },
        { name: 'Kosice - surroundings', id: 31 },
        { name: 'Brezno district', id: 32 },
        { name: 'Banska Stiavnica district', id: 33 },
        { name: 'Banska Bystrica district', id: 34 },
        { name: 'Pezinok district', id: 35 },
        { name: 'Michalovce district', id: 36 },
        { name: 'Komarno district', id: 37 },
        { name: 'Sabinov district', id: 38 },
        { name: 'Levice district', id: 39 },
        { name: 'Levoca district', id: 40 },
        { name: 'Velky Krtis district', id: 41 },
        { name: 'Stropkov district', id: 42 },
        { name: 'Piestany district', id: 43 },
        { name: 'Banovce nad Bebravou district', id: 44 },
        { name: 'Ziar nad Hronom district', id: 45 },
        { name: 'Sobrance district', id: 46 },
        { name: 'Poprad district', id: 47 },
        { name: 'Nové Mesto nad Váhom district', id: 48 },
        { name: 'Zilina district', id: 49 },
        { name: 'Martin district', id: 50 },
        { name: 'Snina district', id: 51 },
        { name: 'Zlate Moravce district', id: 52 },
        { name: 'Svidnik district', id: 53 },
        { name: 'Puchov district', id: 54 },
        { name: 'Liptovsky Mikulas district', id: 55 },
        { name: 'Senec district', id: 56 },
        { name: 'Ruzomberok district', id: 57 },
        { name: 'Trnava district', id: 58 },
        { name: 'Senica district', id: 59 },
        { name: 'Povazska Bystrica district', id: 60 },
        { name: 'Ilava district', id: 61 },
        { name: 'Prievidza district', id: 62 },
        { name: 'Hlohovec district', id: 63 },
        { name: 'Malacky district', id: 64 },
        { name: 'Partizanske district', id: 65 },
        { name: 'Bratislava V district', id: 66 },
        { name: 'Bratislava IV district', id: 67 },
        { name: 'Bratislava III district', id: 68 },
        { name: 'Bratislava II district', id: 69 },
        { name: 'Bratislava I district', id: 70 },
        { name: 'Zarnovica district', id: 71 },
        { name: 'Medzilaborce district', id: 72 },
        { name: 'Myjava district', id: 73 },
        { name: 'Poltar district', id: 74 },
        { name: 'Tvrdosin district', id: 75 },
        { name: 'Skalica district', id: 76 },
        { name: 'Bytca district', id: 77 },
        { name: 'Dolny Kubin district', id: 78 },
        { name: 'Krupina district', id: 79 },
        { name: 'Cadca district', id: 80 },
        { name: 'Stara Lubovna district', id: 81 },
        { name: 'Revuca district', id: 82 },
        { name: 'Detva district', id: 83 },
        { name: 'Sala district', id: 84 },
        { name: 'Kysucke Nove Mesto district', id: 85 },
        { name: 'Gelnica district', id: 86 },
        { name: 'Košice IV district', id: 87 },
        { name: 'Košice III district', id: 88 },
        { name: 'Košice I district', id: 89 },
        { name: 'Košice II district', id: 90 },
    ],
    salaryRates: [
        { value: 'hourly', label: 'Hourly' },
        { value: 'monthly', label: 'Monthly' },
        { value: 'once', label: 'Once' },
    ],
    salaryCurrencies: [{ value: '€', label: '€' }],
    durationTypes: [
        { value: 'day', label: 'Day' },
        { value: 'week', label: 'Week' },
        { value: 'month', label: 'Month' },
        { value: 'year', label: 'Year' },
    ],
    planFeatures: new Map([
        [
            'activeInternships',
            {
                name: 'Advertizing of internships',
                description: 'Maximum number of active offers',
                type: Number,
            },
        ],
        [
            'activeGraduateOffers',
            {
                name: 'Advertising of graduate positions',
                description: 'Maximum number of active offers for university graduates',
                type: Number,
            },
        ],
        [
            'companyLogo',
            {
                name: 'Company logo in profile',
                description: 'Ability to display the company logo in the profile and in your offers',
                type: Boolean,
            },
        ],
        [
            'analytics',
            {
                name: 'Analytical data',
                description: 'We provide basic analytical data (display of offers, etc.)',
                type: Boolean,
            },
        ],
        [
            'socialMarketing',
            {
                name: 'Promotion of offers on social networks',
                description: 'We will increase the reach of your offers in an organic way',
                type: Boolean,
            },
        ],
        [
            'ppcMarketing',
            {
                name: 'Paid promotion of offers on social networks',
                description: 'Paid advertising targeted at potential applicants through PPC',
                type: Boolean,
            },
        ],
        [
            'studentGroupsMarketing',
            {
                name: 'Publication of offers in internet groups and associations',
                description: 'We will deliver your offer among students and graduates who specialize in the field',
                type: Boolean,
            },
        ],
        [
            'highlightOffers',
            {
                name: 'Possibility to highlight offers in the list',
                description: 'You will be able to differentiate your offers from the other offers in the list',
                type: Boolean,
                comingSoon: true,
            },
        ],
        [
            'api',
            {
                name: 'API support',
                description: 'Possibility to integrate Praxuj with your IT system',
                type: Boolean,
            },
        ],
        [
            'emailMarketing',
            {
                name: 'Inclusion of offers in email campaigns',
                description: 'Promotion of your offers by direct mailing to relevant candidates',
                type: Boolean,
            },
        ],
        [
            'istp',
            {
                name: 'Connection to ISTP',
                description: "Possibility of automatic publication of offers on the state portal 'Internetový sprievodca trhom práce'",
                type: Boolean,
            },
        ],
        [
            'importantEmployer',
            {
                name: 'Highlight offers',
                description: 'Label of a significant employer in the list of offers',
                type: Boolean,
            },
        ],
        [
            'homepageLogo',
            {
                name: 'Logo on homepage',
                description: "Promotion of your company's offers directly on the Praxuj service homepage",
                type: Boolean,
            },
        ],
    ]),
};

import React from 'react';
import { Switch as AntSwitch } from 'antd';
import styled from 'styled-components';
import { transparentize } from 'polished';
const StyledSwitch = styled(AntSwitch) `
	& {
		background-color: ${props => transparentize(0.7, props.theme.color.gray5)};
	}
	&.ant-switch-checked {
		background-color: ${props => props.theme.color.success};
	}
`;
function Switch(props) {
    return React.createElement(StyledSwitch, Object.assign({}, props, { defaultChecked: props.checked }));
}
export default Switch;

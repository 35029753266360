import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useClickAway } from 'react-use';
import styled, { css, useTheme } from 'styled-components';
import { transparentize } from 'polished';
import { FormattedMessage, useIntl } from 'react-intl';
import { Spin as Hamburger } from 'hamburger-react';
import isTouchDevice from 'is-touch-device';
import actions from '@src/actions';
import { Link, Holder } from '@src/components/elements';
import { down } from 'styled-breakpoints';
import { getConfig } from '@src/config';
import ProfessionalNetworkLinkedin from '@streamlinehq/streamlinehq/img/streamline-bold/logos/professional-networks/professional-network-linkedin.svg';
import SocialInstagram from '@streamlinehq/streamlinehq/img/streamline-bold/logos/photos-images/social-instagram.svg';
import SocialMediaFacebook1 from '@streamlinehq/streamlinehq/img/streamline-bold/logos/social-medias/social-media-facebook-1.svg';
import EmailActionUnread from '@streamlinehq/streamlinehq/img/streamline-bold/emails/email-actions/email-action-unread.svg';
import EmailActionDownload from '@streamlinehq/streamlinehq/img/streamline-bold/emails/email-actions/email-action-download.svg';
import GiftBox from '@streamlinehq/streamlinehq/img/streamline-bold/social-medias-rewards-rating/gifts/gift-box.svg';
import SingleNeutral from '@streamlinehq/streamlinehq/img/streamline-bold/users/natural-close-up-single-user-neutral/single-neutral.svg';
import Lock1 from '@streamlinehq/streamlinehq/img/streamline-bold/interface-essential/lock-unlock/lock-1.svg';
import StyleTwoPinInformation from '@streamlinehq/streamlinehq/img/streamline-bold/maps-navigation/pins-style-two/style-two-pin-information.svg';
import BrowserPageText2 from '@streamlinehq/streamlinehq/img/streamline-bold/programing-apps-websites/website-development/browser-page-text-2.svg';
import CommonFileTextCash from '@streamlinehq/streamlinehq/img/streamline-bold/files-folders/common-files/common-file-text-cash.svg';
import ReadEmailAtAlternate from '@streamlinehq/streamlinehq/img/streamline-bold/emails/read-email/read-email-at-alternate.svg';
import CoffeeRead from '@streamlinehq/streamlinehq/img/streamline-bold/food-drinks/coffee/coffee-read.svg';
import MultipleCircle from '@streamlinehq/streamlinehq/img/streamline-bold/users/geometric-full-body-multiple-users/multiple-circle.svg';
import SingleMan from '@streamlinehq/streamlinehq/img/streamline-bold/users/geomertic-close-up-single-user-man/single-man.svg';
import BuildingsModern from '@streamlinehq/streamlinehq/img/streamline-bold/building-construction/buildings/buildings-modern.svg';
import LoginKey2 from '@streamlinehq/streamlinehq/img/streamline-bold/interface-essential/login-logout/login-key-2.svg';
import SingleNeutralCircleAlternate from '@streamlinehq/streamlinehq/img/streamline-bold/users/natural-close-up-single-user-neutral/single-neutral-circle-alternate.svg';
import Logout2 from '@streamlinehq/streamlinehq/img/streamline-bold/interface-essential/login-logout/logout-2.svg';
import { getRouteId, isRouteActive } from '@src/shared/localized-path';
import LogoMain from '../../../../public/media/images/logo-main.svg';
import { HeaderMenuNotifications } from './HeaderMenuNotifications';
const StyledHolder = styled(Holder) `
	position: relative;
	display: block;
`;
const Logo = styled(Link) `
	display: block;
	position: absolute;
	top: 0.6rem;
	left: 1rem;
	z-index: 1;
	transition: transform 0.2s ease;

	&,
	&:link,
	&:focus,
	&:visited,
	&:active,
	&:hover {
		color: ${props => props.theme.color.white};
	}

	&:hover {
		transform: scale(1.1);
	}
`;
const Menu = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	top: 0;
	left: auto;
	right: auto;

	${down('lg')} {
		position: static;
	}

	${down('sm')} {
		display: none;
	}
`;
const MenuMobile = styled.div `
	position: absolute;
	top: 1rem;
	left: 0.5rem;
	color: ${props => props.theme.color.brand1};
	background: ${props => props.theme.color.white};
	width: calc(100% - 1rem);
	border-radius: 4px;
	z-index: 1;
	min-height: 10rem;
	max-height: 80vh;
	transform: scale(0);
	transform-origin: calc(100% - 0.8rem) 1rem;
	box-shadow: 0 0px 0 3px ${props => transparentize(0.8, props.theme.color.gray6)}, 0 4.2px 2.4px rgba(0, 0, 0, 0.004),
		0 10.7px 6.5px rgba(0, 0, 0, 0.015), 0 21.8px 14.3px rgba(0, 0, 0, 0.029), 0 44.9px 31.6px rgba(0, 0, 0, 0.05),
		0 123px 94px rgba(0, 0, 0, 0.09);
	overflow-y: scroll;

	// chrome fux up our transitions on initial load
	// this is a temporary fix
	${props => props.mounted &&
    css `
			transition: transform 0.2s ease;
		`}

	${props => props.open &&
    css `
			transform: scale(1);
		`}
`;
export const MenuContent = styled.div `
	top: 50px;
	left: 50%;
	position: absolute;
	display: flex;
	color: ${props => props.theme.color.black};
	background: ${props => props.theme.color.white};
	border-radius: 0.3rem;
	box-shadow: 0 0px 0 3px ${props => transparentize(0.8, props.theme.color.gray6)}, 0 4.2px 2.4px rgba(0, 0, 0, 0.004),
		0 10.7px 6.5px rgba(0, 0, 0, 0.015), 0 21.8px 14.3px rgba(0, 0, 0, 0.029), 0 44.9px 31.6px rgba(0, 0, 0, 0.05),
		0 123px 94px rgba(0, 0, 0, 0.09);
	padding: 50px 0 0 0;
	z-index: 1;
	opacity: 0;
	transform-origin: top center;
	transform: translateX(-50%) perspective(400px) rotateX(-15deg);
	pointer-events: none;
	padding: 0.5em;
	min-width: 250px;
	text-align: left;

	// chrome fux up our transitions on initial load
	// this is a temporary fix
	${props => props.mounted &&
    css `
			transition: opacity 0.3s ease, transform 0.3s ease;
		`}

	& > * + * {
		margin-left: 0.5rem;
	}

	${props => props.visible &&
    `
		transform: translateX(-50%) perspective(0) rotateX(0deg);
		opacity: 1;
		pointer-events: auto;
	`}

	${down('lg')} {
		left: unset;
		right: 0.6rem;
		top: 60px;
		transform: translateX(${props => (props.offset ? props.offset : 0)}) perspective(400px) rotateX(-15deg);

		${props => props.visible &&
    css `
				transform: translateX(${props.offset ? props.offset : 0}) perspective(0) rotateX(0deg);
			`}

		${props => props.userControls &&
    `
				left: unset;
				right: 0rem;
				top: 40px;
				transform: translateX(0) perspective(400px) rotateX(-15deg);

				${props.visible &&
        css `
						transform: translateX(0) perspective(0) rotateX(0deg);
					`}
			`}
	}

	${props => props.userControls &&
    `
		left: unset;
		right: 0rem;
		top: 40px;
		transform: translateX(0) perspective(400px) rotateX(-15deg);

		${props.visible &&
        css `
				transform: translateX(0) perspective(0) rotateX(0deg);
			`}
	`}
`;
export const MenuContentColumn = styled.div `
	display: flex;
	flex-direction: column;
`;
export const MenuContentInner = styled.div `
	border-radius: 0.3rem;
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0;
	width: 300px;
	${props => props.width &&
    css `
			width: ${props.width}px;
		`}
`;
const MenuContentInnerAside = styled(MenuContentInner) `
	background: ${props => transparentize(0.9, props.theme.color.brand2)};
	padding: 0.5rem;
	flex: 1;
`;
const MenuContentHighlighted = styled.div `
	border: 2px solid ${props => transparentize(0.9, props.theme.color.success)};
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	text-align: center;
	margin: 1rem 0 0 0;
	padding: 1rem;
	border-radius: 0.3rem;
	font-size: 1.2rem;
`;
const MenuMasterItem = styled.button `
	display: flex;
	align-items: center;
	border: 0;
	outline: 0;
	font-weight: bold;
	padding: 0;
	margin: 10px 0;
	position: relative;
	height: calc(${props => props.theme.size.headerHeight}px - 20px);
	font-size: ${props => props.theme.fontSizes.m};
	color: ${props => props.theme.color.white};

	${down('lg')} {
		font-size: 0.9rem;
		position: static;
	}
`;
const MenuLabel = styled(Link) `
	position: relative;
	padding: 0 1em 0;

	${down('lg')} {
		padding: 0 0.7em 0;
	}

	& > span {
		transition: opacity 0.2s ease;
		text-transform: uppercase;
		padding: 0 0 0.1rem 0;
		margin-bottom: -0.2rem;
		border-bottom: 2px solid transparent;
	}

	${props => props.hasBackground &&
    css `
			& > span {
				border-bottom: 2px solid ${props.theme.color.white};
			}
		`};

	&,
	&:link,
	&:visited,
	&:focus,
	&:hover,
	&:active {
		height: 100%;
		display: flex;
		align-items: center;
		color: ${props => props.theme.color.white};
	}
	&:after {
		content: '';
		left: 50%;
		display: block;
		position: absolute;
		width: 0px;
		height: 0px;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 6px solid ${props => props.theme.color.white};
		opacity: 0;
		bottom: 10px;
		transform: scale(0) translateX(-50%);
		transform-origin: left bottom;
		transition: opacity 0.3s ease, transform 0.3s ease;
		z-index: 2;
	}

	${props => props.hasContent &&
    props.active &&
    css `
			&:after {
				opacity: 1;
				transform: scale(1) translateX(-50%);
			}
		`};

	${props => props.otherLabelActive &&
    css `
			& > * {
				opacity: 0.4;
			}
		`};

	${props => props.active &&
    css `
			& > * {
				opacity: 1;
			}
		`};
`;
export const MenuLabelWrapper = styled(Link) `
	position: relative;
	padding: 0;

	&:after {
		content: '';
		left: 50%;
		display: block;
		position: absolute;
		width: 0px;
		height: 0px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid ${props => props.theme.color.white};
		opacity: 0;
		bottom: -8px;
		transform: scale(0) translateX(-50%);
		transform-origin: left bottom;
		transition: opacity 0.3s ease, transform 0.3s ease;
		z-index: 2;
	}

	${props => props.hasContent &&
    props.active &&
    css `
			&:after {
				opacity: 1;
				transform: scale(1) translateX(-50%);
			}
		`};
`;
const MenuLink = styled(Link) `
	padding: 0.6rem 1rem;
	width: 100%;
	font-size: 0.9rem;
	display: flex;
	align-items: center;
	transition: all 0.2s ease;
	border-radius: 4px;
	position: relative;

	& > * + * {
		margin-left: 0.7rem;
	}
	&:hover,
	&:active {
		background: ${props => transparentize(0.8, props.theme.color.brand4)};
	}
	span {
		margin-top: 0.2rem;
		flex: 1;
	}
	svg {
		transform: scale(0.9);
		width: 24px;
	}
`;
const MenuLinkCentered = styled(MenuLink) `
	justify-content: center;
	span {
		flex: unset;
	}
`;
const MenuContentSection = styled.h3 `
	color: ${props => transparentize(0.7, props.theme.color.black)};
	font-size: 0.8rem;
	padding: 0.4rem 1rem;
	font-weight: bold;
	text-transform: uppercase;

	${props => props.marginTop &&
    css `
			margin-top: 0.5rem;
		`};

	${props => props.paddingRight &&
    css `
			padding-right: 2.5rem;
		`};

	${props => props.ellipsis &&
    css `
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		`};
`;
const UserControls = styled.div `
	height: 2rem;
	display: flex;
	align-items: stretch;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 0.8rem;

	& > * + * {
		margin: 0 0 0 0.4rem;
	}
`;
const UserControlsWrapper = styled.div `
	top: 1.3rem;
	right: 1rem;
	position: absolute;
`;
const MyAccountLink = styled.div `
	display: flex;
	align-items: start;
	z-index: 0;
	height: 2.5rem;

	svg {
		color: ${props => props.theme.color.white};
		width: 0.8rem;
		height: 0.8rem;
		margin-top: -0.1rem;
		margin-right: 0.7rem;

		${down('md')} {
			margin-right: 0;
		}
	}
`;
const MyAccountLinkLabel = styled.div `
	display: flex;
	align-items: center;
	height: 100%;

	${down('md')} {
		display: none;
	}
`;
const MyAccountInner = styled.div `
	border-radius: 4px;
	background: ${props => transparentize(0.8, props.theme.color.white)};
	transition: all 0.2s ease;
	display: flex;
	align-items: center;
	padding: 0.3rem 0.7rem 0.2rem;
	height: 2rem;
	min-width: 2rem;
	color: ${props => props.theme.color.white};

	&:hover {
		background: ${props => transparentize(0.7, props.theme.color.white)};
	}

	${down('sm')} {
		&,
		&:hover {
			background: none;
		}
	}
`;
const HamburgerWrapper = styled.div `
	border-radius: 4px;
	align-items: center;
	height: 2rem;
	width: 2rem;
	display: none;
	z-index: 2;
	position: relative;

	& > * {
		top: -8px;
		left: -8px;
	}

	${down('sm')} {
		display: block;
	}
`;
const MenuMobileContent = styled.div `
	padding: 0.5em;
`;
const MenuMobileContentInner = styled.div ``;
// TODO - could be just typechecked to remove the duplicity
const isOtherLabelActive = (activeCategory) => {
    if (!activeCategory) {
        return false;
    }
    return ['candidate', 'company', 'contact', 'magazine', 'offers'].includes(activeCategory);
};
const HeaderMenu = ({ isMenuSticked, mobileMenuOpen, setMobileMenuOpen, setIsSubscriptionModalOpen }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const theme = useTheme();
    const config = getConfig();
    const history = useHistory();
    const currentRoute = history.location.pathname;
    const routeId = getRouteId(history.location.pathname);
    // @ts-ignore
    const user = useSelector(state => state.app.user);
    const mobileMenuRef = React.useRef(null);
    const [routeIdBuffer, setRouteIdBuffer] = React.useState();
    const [mounted, setMounted] = React.useState(false);
    const [activeCategory, setActiveCategory] = React.useState({ category: null });
    const getLoginLink = React.useCallback(() => {
        if (!user.email) {
            return '/login';
        }
        if (user.account_type_id === config.accountType.company) {
            return '/admin';
        }
        return '/profil';
    }, [user]);
    const onCategoryLeave = () => setActiveCategory({ category: null, offset: activeCategory.offset });
    useClickAway(mobileMenuRef, e => {
        // not really a bulletproof solution, but it it here to prevent glitch with closing and reopening the menu after clicking on the mobile menu close button
        // e.path is non-standard
        // @ts-ignore
        const path = e.path || (e.composedPath && e.composedPath());
        if (!path.find(item => item.className === 'hamburger-react')) {
            setMobileMenuOpen(false);
        }
    });
    React.useEffect(() => {
        if (routeId !== routeIdBuffer) {
            setMobileMenuOpen(false);
        }
        setRouteIdBuffer(routeId);
    }, [routeId, isMenuSticked]);
    React.useEffect(() => {
        if (mobileMenuOpen) {
            document.body.className = 'overflow-hidden';
        }
        else {
            document.body.className = '';
        }
    }, [mobileMenuOpen]);
    React.useEffect(() => {
        setMounted(true);
    }, []);
    return (React.createElement(StyledHolder, null,
        React.createElement(Logo, { to: "/", title: intl.formatMessage({ id: 'global.logo_alt' }) },
            React.createElement(LogoMain, { width: "80", height: "60" })),
        React.createElement(Menu, null,
            React.createElement(MenuMasterItem, { onMouseEnter: () => setActiveCategory({ category: 'offers', offset: '-30vw' }), onMouseLeave: onCategoryLeave },
                React.createElement(MenuLabel, { to: "/ponuky", active: (activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.category) === 'offers', otherLabelActive: isOtherLabelActive(activeCategory.category), hasBackground: isRouteActive(currentRoute, '/ponuky') },
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "general.offers" })))),
            React.createElement(MenuMasterItem, { onMouseEnter: () => setActiveCategory({ category: 'candidate', offset: '-25vw' }), onMouseLeave: onCategoryLeave },
                React.createElement(MenuLabel, { to: "/uchadzac", active: (activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.category) === 'candidate', otherLabelActive: isOtherLabelActive(activeCategory.category), hasContent: true, hasBackground: isRouteActive(currentRoute, '/uchadzac') },
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "general.candidate" }))),
                React.createElement(MenuContent, { mounted: mounted, visible: activeCategory.category === 'candidate', offset: activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.offset },
                    React.createElement(MenuContentColumn, null,
                        React.createElement(MenuContentInner, { width: 270 },
                            React.createElement(MenuContentSection, { marginTop: true },
                                React.createElement(FormattedMessage, { id: "general.for_candidates" })),
                            React.createElement(MenuLink, { to: "/ponuky" },
                                React.createElement(FormattedMessage, { id: "general.job_offers" })),
                            React.createElement(MenuLink, { to: "/uchadzac" },
                                React.createElement(FormattedMessage, { id: "general.service_description" }))),
                        React.createElement(MenuContentHighlighted, null,
                            React.createElement(MenuLinkCentered, { href: "#", onClick: e => {
                                    e.preventDefault();
                                    setMobileMenuOpen(false);
                                    setIsSubscriptionModalOpen(true);
                                } },
                                React.createElement(EmailActionDownload, null),
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "general.watchdog" }))))),
                    React.createElement(MenuContentColumn, null,
                        React.createElement(MenuContentInnerAside, { width: 200 },
                            React.createElement(MenuContentSection, null,
                                React.createElement(FormattedMessage, { id: "general.account" })),
                            React.createElement(MenuLink, { to: "/prihlasenie" },
                                React.createElement(FormattedMessage, { id: "general.login" })),
                            React.createElement(MenuLink, { to: "/registracia-student" },
                                React.createElement(FormattedMessage, { id: "general.registration_new" })))))),
            React.createElement(MenuMasterItem, { onMouseEnter: () => setActiveCategory({ category: 'company', offset: '-20vw' }), onMouseLeave: onCategoryLeave },
                React.createElement(MenuLabel, { to: "/firmy", active: (activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.category) === 'company', otherLabelActive: isOtherLabelActive(activeCategory.category), hasContent: true, hasBackground: isRouteActive(currentRoute, '/firmy') },
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "general.employer" }))),
                React.createElement(MenuContent, { mounted: mounted, visible: activeCategory.category === 'company', offset: activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.offset },
                    React.createElement(MenuContentColumn, null,
                        React.createElement(MenuContentInner, { width: 280 },
                            React.createElement(MenuContentSection, { marginTop: true },
                                React.createElement(FormattedMessage, { id: "general.for_employers" })),
                            React.createElement(MenuLink, { to: "/firmy" },
                                React.createElement(FormattedMessage, { id: "general.service_description_and_pricing" }))),
                        React.createElement(MenuContentHighlighted, null,
                            React.createElement(MenuLinkCentered, { to: "/firmy" },
                                React.createElement(GiftBox, null),
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "general.get_free_credit" }))))),
                    React.createElement(MenuContentColumn, null,
                        React.createElement(MenuContentInnerAside, { width: 200 },
                            React.createElement(MenuContentSection, null,
                                React.createElement(FormattedMessage, { id: "general.account_employer" })),
                            React.createElement(MenuLink, { to: "/prihlasenie" },
                                React.createElement(FormattedMessage, { id: "general.login" })),
                            React.createElement(MenuLink, { to: "/registracia" },
                                React.createElement(FormattedMessage, { id: "general.registration_new" })))))),
            React.createElement(MenuMasterItem, { onMouseEnter: () => setActiveCategory({ category: 'magazine', offset: '-10vw' }), onMouseLeave: onCategoryLeave },
                React.createElement(MenuLabel, { to: "/magazin", active: (activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.category) === 'magazine', otherLabelActive: isOtherLabelActive(activeCategory.category), hasBackground: isRouteActive(currentRoute, '/magazin') },
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "general.magazine" })))),
            React.createElement(MenuMasterItem, { onMouseEnter: () => setActiveCategory({ category: 'contact' }), onMouseLeave: onCategoryLeave },
                React.createElement(MenuLabel, { to: "/kontakt", active: (activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.category) === 'contact', otherLabelActive: isOtherLabelActive(activeCategory.category), hasContent: true, hasBackground: isRouteActive(currentRoute, '/kontakt') },
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "general.contact" }))),
                React.createElement(MenuContent, { mounted: mounted, visible: activeCategory.category === 'contact', offset: activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.offset },
                    React.createElement(MenuContentColumn, null,
                        React.createElement(MenuContentInner, null,
                            React.createElement(MenuContentSection, { marginTop: true },
                                React.createElement(FormattedMessage, { id: "general.contact_us" })),
                            React.createElement(MenuLink, { to: "/o-nas" },
                                React.createElement(FormattedMessage, { id: "general.team" })),
                            React.createElement(MenuLink, { to: "/kontakt" },
                                React.createElement(FormattedMessage, { id: "general.contact_form" }))),
                        React.createElement(MenuContentHighlighted, null,
                            React.createElement(MenuLinkCentered, { href: "mailto:info@praxuj.sk" },
                                React.createElement(EmailActionUnread, null),
                                React.createElement("span", null, "info@praxuj.sk")))),
                    React.createElement(MenuContentColumn, null,
                        React.createElement(MenuContentInnerAside, null,
                            React.createElement(MenuContentSection, null,
                                React.createElement(FormattedMessage, { id: "general.social_networks" })),
                            React.createElement(MenuLink, { href: "https://www.facebook.com/Praxuj", target: "_blank" },
                                React.createElement(SocialMediaFacebook1, null),
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "general.brand_name" }),
                                    " \u2014",
                                    ' ',
                                    React.createElement(FormattedMessage, { id: "general.claim" }))),
                            React.createElement(MenuLink, { href: "https://www.facebook.com/groups/vysokoskolaci", target: "_blank" },
                                React.createElement(SocialMediaFacebook1, null),
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "general.facebook_group_college" }))),
                            React.createElement(MenuLink, { href: "https://www.facebook.com/groups/stredoskolaci.na.slovensku", target: "_blank" },
                                React.createElement(SocialMediaFacebook1, null),
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "general.facebook_group_secondary_school" }))),
                            React.createElement(MenuLink, { href: "https://www.instagram.com/praxuj/", target: "_blank" },
                                React.createElement(SocialInstagram, null),
                                React.createElement("span", null, "Instagram")),
                            React.createElement(MenuLink, { href: "https://www.linkedin.com/company/praxuj", target: "_blank" },
                                React.createElement(ProfessionalNetworkLinkedin, null),
                                React.createElement("span", null, "LinkedIn"))))))),
        React.createElement(UserControlsWrapper, null,
            React.createElement(UserControls, null,
                user.email && (React.createElement(HeaderMenuNotifications, { isActive: activeCategory.category === 'notifications', setActiveCategory: setActiveCategory, onCategoryLeave: onCategoryLeave })),
                React.createElement(MyAccountLink, { onMouseEnter: isTouchDevice() ? undefined : () => setActiveCategory({ category: 'user', offset: '0' }), onMouseLeave: isTouchDevice() ? undefined : onCategoryLeave },
                    React.createElement(MenuLabelWrapper, { to: getLoginLink(), title: intl.formatMessage({
                            id: 'navigation.login_indicator.account_administration',
                        }), active: activeCategory.category === 'user', hasContent: Boolean(user.email) },
                        React.createElement(MyAccountInner, null, user.email ? (React.createElement(React.Fragment, null,
                            React.createElement(SingleNeutral, { viewBox: "0 0 24 24" }),
                            React.createElement(MyAccountLinkLabel, null,
                                React.createElement(FormattedMessage, { id: "general.my_account" })))) : (React.createElement(React.Fragment, null,
                            React.createElement(Lock1, { viewBox: "0 0 24 24" }),
                            React.createElement(MyAccountLinkLabel, null,
                                React.createElement(FormattedMessage, { id: "general.login" })))))),
                    user.email && (React.createElement(MenuContent, { mounted: mounted, visible: activeCategory.category === 'user', userControls: true },
                        React.createElement(MenuContentColumn, null,
                            React.createElement(MenuContentInner, null,
                                React.createElement(MenuContentSection, { marginTop: true, ellipsis: true }, user.email),
                                React.createElement(MenuLink, { to: user.account_type_id === config.accountType.company
                                        ? '/admin'
                                        : '/profil' },
                                    React.createElement(FormattedMessage, { id: "general.my_account" })),
                                React.createElement(MenuLink, { onClick: () => dispatch(actions.logoutStart()) },
                                    React.createElement(FormattedMessage, { id: "general.log_off" }))))))),
                React.createElement(HamburgerWrapper, null,
                    React.createElement(Hamburger, { size: 16, color: mobileMenuOpen ? theme.color.brand1 : theme.color.white, label: intl.formatMessage({
                            id: 'general.menu.show',
                        }), rounded: true, toggle: setMobileMenuOpen, toggled: mobileMenuOpen })))),
        React.createElement(MenuMobile, { mounted: mounted, open: mobileMenuOpen, ref: mobileMenuRef },
            React.createElement(MenuMobileContent, null,
                React.createElement(MenuMobileContentInner, null,
                    user.email && (React.createElement(React.Fragment, null,
                        React.createElement(MenuContentSection, { marginTop: true, ellipsis: true, paddingRight: true }, user.email),
                        React.createElement(MenuLink, { to: user.account_type_id === config.accountType.company ? '/admin' : '/profil' },
                            React.createElement(SingleNeutralCircleAlternate, null),
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "general.my_account" }))),
                        React.createElement(MenuLink, { onClick: () => dispatch(actions.logoutStart()) },
                            React.createElement(Logout2, null),
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "general.log_off" }))))),
                    !user.email && (React.createElement(React.Fragment, null,
                        React.createElement(MenuContentSection, { marginTop: true },
                            React.createElement(FormattedMessage, { id: "general.account" })),
                        React.createElement(MenuLink, { to: "/prihlasenie" },
                            React.createElement(LoginKey2, null),
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "general.login" }))),
                        React.createElement(MenuLink, { to: "/registracia-student" },
                            React.createElement(SingleMan, null),
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "general.registration_candidate" }))),
                        React.createElement(MenuLink, { to: "/registracia" },
                            React.createElement(BuildingsModern, null),
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "general.registration_company" }))))),
                    React.createElement(MenuContentSection, { marginTop: true },
                        React.createElement(FormattedMessage, { id: "general.for_candidates" })),
                    React.createElement(MenuLink, { to: "/ponuky" },
                        React.createElement(BrowserPageText2, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.job_offers" }))),
                    React.createElement(MenuLink, { to: "/uchadzac" },
                        React.createElement(StyleTwoPinInformation, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.service_description" }))),
                    React.createElement(MenuLink, { href: "#", onClick: e => {
                            e.preventDefault();
                            setIsSubscriptionModalOpen(true);
                        } },
                        React.createElement(EmailActionDownload, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.watchdog" }))),
                    React.createElement(MenuContentSection, { marginTop: true },
                        React.createElement(FormattedMessage, { id: "general.for_employers" })),
                    React.createElement(MenuLink, { to: "/firmy" },
                        React.createElement(CommonFileTextCash, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.service_description_and_pricing" }))),
                    React.createElement(MenuContentSection, { marginTop: true },
                        React.createElement(FormattedMessage, { id: "general.extra" })),
                    React.createElement(MenuLink, { to: "/magazin" },
                        React.createElement(CoffeeRead, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.magazine" }))),
                    React.createElement(MenuContentSection, { marginTop: true },
                        React.createElement(FormattedMessage, { id: "general.contact_us" })),
                    React.createElement(MenuLink, { to: "/o-nas" },
                        React.createElement(MultipleCircle, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.team" }))),
                    React.createElement(MenuLink, { to: "/kontakt" },
                        React.createElement(EmailActionUnread, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.contact_form" }))),
                    React.createElement(MenuLink, { href: "mailto:info@praxuj.sk" },
                        React.createElement(ReadEmailAtAlternate, null),
                        React.createElement("span", null, "info@praxuj.sk")),
                    React.createElement(MenuContentSection, { marginTop: true },
                        React.createElement(FormattedMessage, { id: "general.social_networks" })),
                    React.createElement(MenuLink, { href: "https://www.facebook.com/Praxuj", target: "_blank" },
                        React.createElement(SocialMediaFacebook1, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.brand_name" }),
                            " \u2014 ",
                            React.createElement(FormattedMessage, { id: "general.claim" }))),
                    React.createElement(MenuLink, { href: "https://www.facebook.com/groups/vysokoskolaci", target: "_blank" },
                        React.createElement(SocialMediaFacebook1, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.facebook_group_college" }))),
                    React.createElement(MenuLink, { href: "https://www.facebook.com/groups/stredoskolaci.na.slovensku", target: "_blank" },
                        React.createElement(SocialMediaFacebook1, null),
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "general.facebook_group_secondary_school" }))),
                    React.createElement(MenuLink, { href: "https://www.instagram.com/praxuj/", target: "_blank" },
                        React.createElement(SocialInstagram, null),
                        React.createElement("span", null, "Instagram")),
                    React.createElement(MenuLink, { href: "https://www.linkedin.com/company/praxuj", target: "_blank" },
                        React.createElement(ProfessionalNetworkLinkedin, null),
                        React.createElement("span", null, "LinkedIn")))))));
};
export { HeaderMenu };

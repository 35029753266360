import actionNames from '../actions';

const defaultState = {
	offerEditInit: {},
};

export default function offerEdit(state = defaultState, action) {
	switch (action.type) {

	case actionNames.offerEditInitSuccess().type:
		const offerEditInit = action.payload;
		offerEditInit.state = action.payload.state.map(obj => ({
			id: obj.id,
			name: obj.name,
		}));
		offerEditInit.job_area = action.payload.job_area.map(obj => ({
			id: obj.id,
			name: obj.name,
		}));
		return Object.assign({}, state, {
			offerEditInit,
		});

	case actionNames.offerEditInitStart().type:
		return Object.assign({}, state, {
			offerEditInit: {},
		});

	default:
		return state;
	}
}

import React from 'react'
import { Avatar, Link } from 'components/elements'

import { FormattedMessage } from 'react-intl'

const ProfileBox = function ({ firstName, lastName, email, logout, link }) {
	return (
		<div className="profile-box">
			<div className="profile-box__wrapper">
				<Link className="profile-box__icon" to={link}>
					<Avatar fullName={[firstName, lastName]} />
				</Link>
				<div className="profile-box__name">{`${firstName} ${lastName}`}</div>
				<div className="profile-box__email">{email}</div>
				<button className="profile-box__logout" data-cy="logout-button" onClick={() => logout()}>
					<FormattedMessage id="global.profile_box.logout" defaultMessage="Odhlásiť sa" />
				</button>
			</div>
		</div>
	)
}

export default ProfileBox

const EModal = {
	Confirm: 'Confirm',
	Alert: 'Alert',
	Prompt: 'Prompt',
};

const ENotification = {
	UpSell: 'UpSell',
	Feedback: 'Feedback',
};

export {
	EModal,
	ENotification,
}

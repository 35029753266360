import React from 'react'
import { Link, Icon } from '@src/components/elements'
import SlugOffer from 'shared/slug-offer'
import getSalaryString from 'shared/get-salary-string'
import { FormattedMessage } from 'react-intl'

const SimilarOffers = ({ similarOffers }) => {
	return (
		<div className="panel m-b-m">
			<aside className="offer-suggestions-company">
				<h5 className="offer-suggestions-company__heading">
					<FormattedMessage id="offer_detail.similar_offers.similar_offers" defaultMessage="Podobné ponuky" />
				</h5>
				{similarOffers.map(
					({
						id,
						title,
						city,
						salary_rate,
						salary_amount_from,
						salary_amount_to,
						salary_currency,
						isPaid,
					}) => {
						const slug = new SlugOffer({ text: title })
						return (
							<div key={`offer-suggestion-company-${id}`} className="offer-suggestions-company__item">
								<Link className="link" to={`/ponuka/${slug}-${id}`}>
									<p className="offer-suggestions-company__title">{title}</p>
									<div className="offer-suggestions-company__info">
										<div className="offer-suggestions-company__address">
											<Icon className="icon" type="maps-pin" />
											{city && <span>{city}</span>}
											{!city && (
												<span>
													<FormattedMessage
														id="offer_detail.similar_offers.location"
														defaultMessage="Remote"
													/>
												</span>
											)}
										</div>
										{isPaid === 1 && (
											<div className="offer-suggestions-company__salary">
												<Icon className="icon" type="money-bag-euro" />
												<span>
													{getSalaryString({
														rate: salary_rate,
														amountFrom: salary_amount_from,
														amountTo: salary_amount_to,
														currency: salary_currency,
													})}
												</span>
											</div>
										)}
									</div>
								</Link>
							</div>
						)
					}
				)}
			</aside>
		</div>
	)
}

export default SimilarOffers

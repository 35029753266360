var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
// import isTouchDevice from 'is-touch-device'
// import { FormattedMessage } from 'react-intl'
import IconAlarmBell from '@streamlinehq/streamlinehq/img/streamline-bold/interface-essential/alert/alarm-bell-1.svg';
import { SocketEvents } from '@src/types';
import * as Api from '@src/api';
import { useSocket } from '@src/context/socket-context';
import { Notifications } from './Notifications';
import { UserNotifications } from './HeaderMenuNotifications.style';
import { MenuLabelWrapper, MenuContent, MenuContentColumn, MenuContentInner } from './HeaderMenu';
export function HeaderMenuNotifications({ setActiveCategory, onCategoryLeave, isActive }) {
    const [notifications, setNotifications] = React.useState([]);
    const [mounted, setMounted] = React.useState(false);
    const socket = useSocket();
    const hasUnreadNotifications = React.useMemo(() => notifications.some(n => !n.seen_at), [notifications]);
    const init = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            const freshNotifications = yield Api.Account.getMyNotifications();
            setNotifications(freshNotifications);
        }
        catch (err) {
            console.error(err);
        }
    }), []);
    const watchNotificaitons = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            if (hasUnreadNotifications) {
                yield Api.Account.watchAllNotifications();
                yield init();
            }
        }
        catch (err) {
            console.error(err);
        }
    }), [notifications, hasUnreadNotifications]);
    React.useEffect(() => {
        setMounted(true);
        socket.on(SocketEvents.NOTIFICATION_RECEIVED, () => {
            init();
        });
        init();
    }, []);
    React.useEffect(() => {
        watchNotificaitons();
    }, [isActive]);
    return (React.createElement(UserNotifications, { active: hasUnreadNotifications, onMouseEnter: () => setActiveCategory({ category: 'notifications', offset: '0' }), onMouseLeave: onCategoryLeave },
        React.createElement(MenuLabelWrapper, { to: "#", active: isActive, hasContent: Boolean(notifications.length) },
            React.createElement(IconAlarmBell, { viewBox: "0 0 24 24" })),
        React.createElement(MenuContent, { mounted: mounted, visible: isActive, userControls: true },
            React.createElement(MenuContentColumn, null,
                React.createElement(MenuContentInner, { width: 350 },
                    React.createElement(Notifications, { notifications: notifications, refetchNotifications: init }))))));
}

import React from 'react'

const IconSocialWeb = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
		<path
			d="M122.22583 23.485a2.91667 2.91667 0 00-4.92333.945l-6.46917 17.90833a11.66667 11.66667 0 01-9.38583 7.245h-16.555a2.91667 2.91667 0 00-2.24583 4.2l5.1625 13.3a2.91667 2.91667 0 002.6075 1.61584h4.83583a2.91667 2.91667 0 012.6075 4.2175L81.66667 106.12a11.82417 11.82417 0 01-22.5575-1.4525L53.32833 80.36a2.91667 2.91667 0 00-2.6075-1.61H48.125a18.95833 18.95833 0 010-37.91667h23.91667a2.91667 2.91667 0 002.91666-2.91666v-6.685a11.66667 11.66667 0 00-7.29166-10.815l-1.65667-.665a2.91667 2.91667 0 01-.3675-5.25L81.515 6.335A2.91667 2.91667 0 0080.64.8575 67.92917 67.92917 0 0070 0a70.15167 70.15167 0 1052.22583 23.485z"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="0"
		/>
	</svg>
)

export default IconSocialWeb

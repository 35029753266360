const validate = (values, { intl }) => {
	const errors = {};

	if (!values.password) {
		errors.password = intl.formatMessage({
			id: "reset_password.sync-validation.password",
			defaultMessage: "Prosím, vyplňte heslo"
		});
	} else if (values.password.length < 8) {
		errors.password = intl.formatMessage({
			id: "reset_password.sync-validation.restricted_length_password",
			defaultMessage: "Heslo musí byť dlhé aspoň 8 znakov"
		});
	} else if (!/^.*(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/.test(values.password)) {
		errors.password = intl.formatMessage({
			id: "reset_password.sync-validation.restricted_symbols_password",
			defaultMessage: "Heslo musí obsahovať aspoň 1 malé, 1 veľké písmeno a 1 číslo"
		});
	}

	if (values.passwordRepeat !== values.password) {
		errors.passwordRepeat = intl.formatMessage({
			id: "reset_password.sync-validation.passwordRepeat",
			defaultMessage: "Heslá sa nezhodujú"
		});
	}

	return errors;
};

export default validate;

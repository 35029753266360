var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useGoogleLogin } from 'react-google-login';
import { FormattedMessage } from 'react-intl';
import { GoogleOutlined } from '@ant-design/icons';
import { Button } from '@src/components/elements';
import axios from '@src/api/clients/axios';
import { OAuthType } from '@src/types/auth';
import { useSocket } from '@src/context/socket-context';
import { SocketEvents, AccountType } from '@src/types';
import { useDispatch } from 'react-redux';
import actions from '@src/actions';
function GoogleLoginButton({ type, shouldStayOnPage, onLoginSuccess }) {
    const history = useHistory();
    const socket = useSocket();
    const dispatch = useDispatch();
    const onSuccessfulLogin = React.useCallback((response) => __awaiter(this, void 0, void 0, function* () {
        const { profileObj } = response;
        const account = yield axios.post('/0/auth/google', Object.assign({}, profileObj));
        dispatch(actions.authCheckSuccess(account));
        socket.on(SocketEvents.ACCOUNT_AUTHENTICATED, account.data);
        if (onLoginSuccess) {
            onLoginSuccess(account.data);
        }
        if (shouldStayOnPage) {
            return;
        }
        if (account.data.account_type_id === AccountType.student) {
            // @ts-ignore
            history.pushLocalized('/profil');
        }
        else {
            // @ts-ignore
            history.pushLocalized('/admin');
        }
    }), [shouldStayOnPage]);
    const { signIn } = useGoogleLogin({
        onSuccess: (res) => {
            onSuccessfulLogin(res);
        },
        clientId: '1009559601925-ef6jvniv9a3697l3nqlekq3cf330c5s1.apps.googleusercontent.com',
        fetchBasicProfile: true,
        onFailure: err => {
            console.error(err);
        },
    });
    return (React.createElement(Button, { className: "p-s m-t-s m-b-s flex-row align-center justify-start", size: "middle", type: "default", onClick: signIn },
        React.createElement(GoogleOutlined, { className: "m-r-m" }),
        type === OAuthType.registration && (React.createElement(FormattedMessage, { id: "login.signup_with_google", defaultMessage: "Registrova\u0165 cez Google" })),
        type === OAuthType.login && (React.createElement(FormattedMessage, { id: "login.login_with_google", defaultMessage: "Prihl\u00E1si\u0165 cez Google" }))));
}
export { GoogleLoginButton };

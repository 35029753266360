import React from 'react';
import classNames from 'classnames';

const getCheckboxClasses = show => classNames({
	'form__input-checkbox': true,
	'form__input-checkbox--error': show,
});

const renderCheckbox = ({
	input,
	label,
	labelClassName,
	type,
	meta: {
		touched,
		error,
	},
}) => (
	<div className="form__row">
		<label
			className={`form__label form__label--checkbox ${labelClassName}`}
		>
			<input
				{...input}
				id={input.name}
				className={getCheckboxClasses(touched && error)}
				type={type}
				data-cy={input.name}
			/>
			{label}
		</label>
		<br />
		{touched && error && <div className="form__input-rounded-error">{error}</div>}
	</div>
);

export default renderCheckbox;

import axios from 'axios';
import config from 'config';

const asyncValidate = (values, _, { intl }) => (
	new Promise((resolve, reject) => (
		axios.get(`${config.api}/0/account?email=${encodeURIComponent(values.email)}`, { withCredentials: true })
			.then(() => reject({
				email: intl.formatMessage({
					id: "profile.async_validation.this_email_is_taken",
					defaultMessage: 'Tento email je už obsadený',
				}),
			}))
			.catch(() => {
				resolve()
			})
	))
)

export default asyncValidate;

import scrollIntoView from 'dom-scroll-into-view'

const showError = fields => {
	const error = document.getElementById(Object.keys(fields)[0])
	if (error) {
		scrollIntoView(error, document, { alignWithTop: false })
	}
}

export default showError

const validate = (values, { intl }) => {
	const errors = {}

	if (!values.name) {
		errors.name = intl.formatMessage({
			id: 'admin.company_info_edit_validation.fill_company_name',
			defaultMessage: 'Vyplňte názov zamestnávateľa',
		})
	}

	if (values.cin && values.cin.toString().match(/\d/g) && values.cin.toString().match(/\d/g).length !== 8) {
		errors.cin = intl.formatMessage({
			id: 'admin.company_info_edit_validation.ico_wrong_format',
			defaultMessage: 'IČO je v nesprávnom tvare (8 číslic)',
		})
	} else if (values.cin && /\D/.test(values.cin.toString().replace(/\s/, ''))) {
		errors.cin = intl.formatMessage({
			id: 'admin.company_info_edit_validation.data_with_forbidden_chars',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (values.tin && values.tin.toString().match(/\d/g) && values.tin.toString().match(/\d/g).length !== 10) {
		errors.tin = intl.formatMessage({
			id: 'admin.company_info_edit_validation.dic_wrong_format',
			defaultMessage: 'DIČ je v nesprávnom tvare (10 číslic)',
		})
	} else if (values.tin && /\D/.test(values.tin.toString().replace(/\s/, ''))) {
		errors.tin = intl.formatMessage({
			id: 'admin.company_info_edit_validation.data_with_forbidden_chars',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (!values.formatted_address) {
		errors.formatted_address = intl.formatMessage({
			id: 'admin.company_info_edit_validation.fill_company_address',
			defaultMessage: 'Vyplňte adresu spoločnosti',
		})
	} else if (/[_~`!@#$%^&*(),;<>?]/.test(values.formatted_address)) {
		errors.formatted_address = intl.formatMessage({
			id: 'admin.company_info_edit_validation.data_with_forbidden_chars',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (!values.city) {
		errors.city = intl.formatMessage({
			id: 'admin.company_info_edit_validation.fill_city',
			defaultMessage: 'Vyplňte mesto',
		})
	} else if (/[0-9_~`!@#$%^&*(),;<>?]/.test(values.city)) {
		errors.city = intl.formatMessage({
			id: 'admin.company_info_edit_validation.data_with_forbidden_chars',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (!values.postal_code) {
		errors.postal_code = intl.formatMessage({
			id: 'admin.fill_company_address.fill_psc',
			defaultMessage: 'Vyplňte PSČ',
		})
	} else if (values.postal_code.match(/\d/g) && values.postal_code.match(/\d/g).length !== 5) {
		errors.postal_code = intl.formatMessage({
			id: 'admin.company_info_edit_validation.psc_wrong_format',
			defaultMessage: 'PSČ musí byť v tvare piatich číslic',
		})
	} else if (/\D/.test(values.postal_code.replace(/\s/, ''))) {
		errors.postal_code = intl.formatMessage({
			id: 'admin.company_info_edit_validation.data_with_forbidden_chars',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (!values.description || values.description.replace(/<[^>]*>?/g, '').replace(/\s/g, '').length === 0) {
		errors.description = intl.formatMessage({
			id: 'admin.company_info_edit_validation.fill_company_info',
			defaultMessage: 'Vyplňte popis spoločnosti',
		})
	}

	if (!values.company_url) {
		errors.company_url = intl.formatMessage({
			id: 'admin.company_info_edit_validation.fill_company_url',
			defaultMessage: 'Vyplňte Url firemného profilu',
		})
	} else if (!/^[A-Za-z-]*$/.test(values.company_url)) {
		errors.company_url = intl.formatMessage({
			id: 'registration.sync_validation.restricted_symbols_last_name',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (!values.area) {
		errors.area = intl.formatMessage({
			id: 'admin.company_info_edit_validation.fill_business_focus',
			defaultMessage: 'Prosím, vyplňte zameranie podnikania',
		})
	} else if (/[0-9_~`!@#$%^&*().;<>?]/.test(values.area)) {
		errors.area = intl.formatMessage({
			id: 'admin.company_info_edit_validation.data_with_forbidden_chars',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (!values.employees) {
		errors.employees = intl.formatMessage({
			id: 'admin.company_info_edit_validation.fill_number_of_employees',
			defaultMessage: 'Prosím, vyplňte počet zamestnancov',
		})
	} else if (/[A-Za-z_~`!@#$%^&*(),.;<>?]/.test(values.employees)) {
		errors.employees = intl.formatMessage({
			id: 'admin.company_info_edit_validation.data_with_forbidden_chars',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	return errors
}

export default validate

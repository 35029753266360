import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useWindowScroll } from 'react-use';
import { useScrollDirection } from '@src/hooks/useScrollDirection';
// TODO: move to a global modal component
import { useLocation } from 'react-router-dom';
import { OfferNotificationSubscriptionModal } from '@src/components/pages/offers/modules/OfferNotificationSubscriptionModal';
import { HeaderMenu } from './HeaderMenu';
const StyledHeader = styled.header `
	height: ${props => props.theme.size.headerHeight}px;
	color: ${props => props.theme.color.white};
	display: flex;
	z-index: 100;
	position: relative;
	margin: 0 0 20px 0;
	width: 100%;
	// chrome fux up our transitions on initial load
	// this is a temporary fix
	${props => props.mounted &&
    css `
			transition: all 0.2s ease;
		`}
	transform: translateY(0);
	margin: 0 0 -${props => props.theme.size.headerHeight}px 0;
	&:after {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		background: url('/media/images/background-gradient.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center 90%;
		width: 100%;
		height: 100%;
		z-index: -1;
		opacity: 0;
		transform: translateY(-100%);
		transition: all 0.2s ease;
	}

	${props => !props.withHero &&
    css `
			background: url('/media/images/background-gradient.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center 90%;
		`}

	${props => props.scrolled &&
    css `
			position: fixed;
			transform: translateY(-100%);
			transition: none;
		`}

	${props => props.scrolledFromTop &&
    css `
			transition: all 0.2s ease;
		`}

	${props => props.sticky &&
    css `
			position: fixed;
			transform: translateY(0);
			transition: all 0.2s ease;
			&:after {
				opacity: 1;
				transform: translateY(0);
			}
		`}
`;
const Header = props => {
    const theme = useTheme();
    const [isMenuSticked, setIsMenuSticked] = React.useState(false);
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = React.useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
        setMounted(true);
    }, []);
    const scrollDirection = useScrollDirection({ thresholdPixels: 0 });
    const { y: topOffset } = useWindowScroll();
    const location = useLocation();
    React.useEffect(() => {
        setMobileMenuOpen(false);
    }, [location]);
    React.useEffect(() => {
        if (scrollDirection === 'up') {
            if (topOffset > 0 && !isMenuSticked) {
                setIsMenuSticked(true);
            }
            else if (topOffset === 0) {
                setIsMenuSticked(false);
            }
        }
        else if (scrollDirection === 'down') {
            if (topOffset > theme.size.headerHeight) {
                setIsMenuSticked(false);
            }
        }
        if (mobileMenuOpen) {
            setMobileMenuOpen(false);
        }
    }, [topOffset]);
    return (React.createElement(StyledHeader, Object.assign({}, props.navigation, { sticky: isMenuSticked, scrolled: topOffset > theme.size.headerHeight, scrolledFromTop: topOffset > theme.size.headerHeight * 2, mounted: mounted }),
        React.createElement(HeaderMenu, { isMenuSticked: isMenuSticked, mobileMenuOpen: mobileMenuOpen, setIsSubscriptionModalOpen: setIsSubscriptionModalOpen, setMobileMenuOpen: setMobileMenuOpen }),
        React.createElement(OfferNotificationSubscriptionModal, { isOpen: isSubscriptionModalOpen, setIsOpen: setIsSubscriptionModalOpen })));
};
export default Header;

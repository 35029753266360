import React from 'react'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { EModal } from 'types/notification'
import Modal from 'components/modules/global/modal'
import './ArchiveOfferDialog.scss'

function ArchiveOfferDialog(props) {
	const { offerTitle, onConfirm, onCancel, isOpen } = props

	return (
		<Modal isOpen={isOpen} modalType={EModal.Dialog} onConfirm={onConfirm} onCancel={onCancel}>
			<div className="flex-center flex-col archive-offer-dialog-padding">
				<div className={cn('archive-offer-dialog-header', 'flex-center m-b-m m-t-m')}>
					<FormattedMessage
						id="admin.offers.archive_offer_dialog.are_you_sure_to_archive"
						defaultMessage="Ste si istý, že chcete archivovať ponuku"
					/>
				</div>
				<div className={cn('archive-offer-dialog-offer-title', 'flex-center m-b-l')}>{offerTitle}</div>
				<div className="m-b-l flex-center">
					<FormattedMessage
						id="admin.offers.archive_offer_dialog.you_will_not_lose_data"
						defaultMessage="Archivovaním nestratíte dáta, ale inzerát sa už nebude zobrazovať vo výpise ponúk."
					/>
				</div>
			</div>
		</Modal>
	)
}

export default ArchiveOfferDialog

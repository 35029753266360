import React from 'react'
import cn from 'classnames'
import { EModal } from 'types/notification'
import Modal from 'components/modules/global/modal'
import { FormattedMessage } from 'react-intl'
import { Link } from '@src/components/elements'
import './OfferArchivedAlert.scss'

function OfferArchivedAlert(props) {
	const { onConfirm, onCancel, isOpen, setIsOpen } = props

	const duplicateLink = {
		pathname: window.location.pathname,
		search: 'duplicate="true"',
	}

	return (
		<Modal isOpen={isOpen} modalType={EModal.Alert} onConfirm={onConfirm} onCancel={onCancel}>
			<div className="flex-center flex-col">
				<div className={cn('offer-archived-alert-header', 'flex-center m-b-xl m-t-m')}>
					<FormattedMessage
						id="admin.offer_edit.offer_archived_alert.this_offer_is_archived"
						defaultMessage="Táto ponuka je archivovaná"
					/>
				</div>
				<div className="m-b-xl flex">
					<FormattedMessage
						id="admin.offer_edit.offer_archived_alert.you_cant_edit_archived_offer"
						defaultMessage="Bohužiaľ neumožňujeme úpravy archivovaných inzerátov. Môžete však ponuku <Link>duplikovať</Link>, čím sa vytvorí nový inzerát."
						values={{
							Link: chunks => (
								<Link to={duplicateLink} onClick={() => setIsOpen(false)}>
									{chunks}
								</Link>
							),
						}}
					/>
				</div>
			</div>
		</Modal>
	)
}

export default OfferArchivedAlert

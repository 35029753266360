var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as Api from '@src/api';
export function getValidationRules(subscriptionToken, intl) {
    const emailValidationRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.validation_schema.email',
                defaultMessage: 'Vyplňte pole s emailovou adresou',
            }),
        },
        {
            type: 'email',
            message: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.validation_schema.enter_valid_email',
                defaultMessage: 'Zadajte validný email',
            }),
        },
        {
            validator: (_, value) => __awaiter(this, void 0, void 0, function* () {
                if (!value) {
                    return;
                }
                const emailIsTaken = yield Api.OfferNotificationSubscription.isEmailTaken(value, subscriptionToken);
                if (emailIsTaken) {
                    throw new Error(intl.formatMessage({
                        id: 'forms.offer_notification_subscription_form.validation_schema.email.taken',
                        defaultMessage: 'Email je už obsadený',
                    }));
                }
            }),
        },
    ];
    const selectedLocationsValidationRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.validation_schema.choose_location',
                defaultMessage: 'Prosím, vyberte aspoň 1 kraj',
            }),
        },
    ];
    const jobAreaIdsValidationRules = [
        {
            required: true,
            message: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.validation_schema.choose_job_area',
                defaultMessage: 'Prosím, vyberte aspoň 1 pracovné zameranie',
            }),
        },
    ];
    return {
        emailValidationRules,
        selectedLocationsValidationRules,
        jobAreaIdsValidationRules,
    };
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { notification, Form, Select, Input } from 'antd';
import styled from 'styled-components';
import { OnsFormAction, ABROAD, REMOTE, } from '@src/types';
import { Button, Separator } from '@src/components/elements';
import * as Api from '@src/api';
import { getValidationRules } from './validation-rules';
import { LocationSelect } from './LocationSelect';
const { Option } = Select;
const CancelSubscriptionButton = styled.button `
	color: ${props => props.theme.color.gray7};
	border: none;
	text-decoration: underline;
	cursor: pointer;
	transition: all 0.167s ease;
	&:hover {
		opacity: 0.8;
	}
`;
function OfferNotificationSubscriptionForm({ subscription, onSubmit, onDeleteRedirectToNotifications, showEmailField, onDelete, }) {
    const [formAction, setFormAction] = React.useState(OnsFormAction.CREATE);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [jobAreas, setJobAreas] = React.useState([]);
    const user = useSelector(state => state.app.user);
    const history = useHistory();
    const intl = useIntl();
    React.useEffect(() => {
        setFormAction((subscription === null || subscription === void 0 ? void 0 : subscription.id) ? OnsFormAction.UPDATE : OnsFormAction.CREATE);
    }, [subscription]);
    // @ts-ignore
    const subscriptionToken = history.getQuery().onst;
    const { emailValidationRules, selectedLocationsValidationRules, jobAreaIdsValidationRules } = React.useMemo(() => getValidationRules(subscriptionToken, intl), [subscriptionToken, intl]);
    const init = () => __awaiter(this, void 0, void 0, function* () {
        const jobAreasResponse = yield Api.JobArea.getOfferJobAreas();
        setJobAreas(jobAreasResponse);
    });
    React.useEffect(() => {
        init();
    }, []);
    const showSuccessNoficication = (action) => {
        let message = intl.formatMessage({
            id: 'forms.offer_notification_subscription_form.data_successfully_changed',
        });
        if (action === OnsFormAction.CREATE) {
            message = intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.subscription_created',
            });
        }
        if (action === OnsFormAction.DELETE) {
            message = intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.subscription_deleted',
            });
        }
        notification.success({
            message: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.notification',
            }),
            description: message,
        });
    };
    const showErrorNotification = () => {
        notification.error({
            message: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.error',
            }),
            description: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.something_went_wrong',
            }),
        });
    };
    const submitForm = (formData) => __awaiter(this, void 0, void 0, function* () {
        setIsSubmitting(true);
        try {
            const subscriptionData = formData;
            if (!formData.email) {
                subscriptionData.email = (subscription === null || subscription === void 0 ? void 0 : subscription.email) || user.email;
            }
            if (formAction === OnsFormAction.CREATE) {
                yield Api.OfferNotificationSubscription.createSubscription(subscriptionData);
            }
            else {
                yield Api.OfferNotificationSubscription.updateSubscription(subscriptionData, subscription.token);
            }
            showSuccessNoficication(formAction);
            onSubmit();
        }
        catch (err) {
            showErrorNotification();
        }
        finally {
            setIsSubmitting(false);
        }
    });
    const handleDelete = () => __awaiter(this, void 0, void 0, function* () {
        try {
            if (onDeleteRedirectToNotifications) {
                // @ts-ignore
                history.pushLocalized('/ucet/notifikacie');
            }
            else {
                yield Api.OfferNotificationSubscription.deleteSubscription(subscription.token);
                showSuccessNoficication(OnsFormAction.DELETE);
            }
            if (onDelete) {
                onDelete();
            }
        }
        catch (err) {
            showErrorNotification();
        }
    });
    const initialValues = {
        email: (subscription === null || subscription === void 0 ? void 0 : subscription.email) || user.email || '',
        jobAreaIds: (subscription === null || subscription === void 0 ? void 0 : subscription.jobAreaIds) || [],
        selectedLocations: (subscription === null || subscription === void 0 ? void 0 : subscription.locationIds) || [],
    };
    if ((subscription === null || subscription === void 0 ? void 0 : subscription.is_remote) && !initialValues.selectedLocations.includes(REMOTE)) {
        initialValues.selectedLocations.push(REMOTE);
    }
    if ((subscription === null || subscription === void 0 ? void 0 : subscription.is_abroad) && !initialValues.selectedLocations.includes(ABROAD)) {
        initialValues.selectedLocations.push(ABROAD);
    }
    return (React.createElement(Form, { layout: "vertical", onFinish: submitForm, initialValues: initialValues, requiredMark: false },
        showEmailField && (React.createElement(Form.Item, { className: "m-b-l", label: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.email',
            }), rules: emailValidationRules, name: "email" },
            React.createElement(Input, null))),
        React.createElement(Form.Item, { className: "m-b-l", label: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.location',
            }), name: "selectedLocations", rules: selectedLocationsValidationRules },
            React.createElement(LocationSelect, null)),
        React.createElement(Form.Item, { className: "m-b-l", label: intl.formatMessage({
                id: 'forms.offer_notification_subscription_form.job_area',
            }), name: "jobAreaIds", rules: jobAreaIdsValidationRules },
            React.createElement(Select, { mode: "multiple", showArrow: true, optionFilterProp: "children" }, jobAreas.map(jobArea => (React.createElement(Option, { key: jobArea.id, value: jobArea.id },
                React.createElement(FormattedMessage, { id: jobArea.translation_key })))))),
        formAction === OnsFormAction.UPDATE && (React.createElement("div", { className: "flex-center m-b-l" },
            React.createElement(CancelSubscriptionButton, { type: "button", onClick: handleDelete },
                React.createElement(FormattedMessage, { id: "forms.offer_notification_subscription_form.cancel_subscription" })))),
        React.createElement("div", { className: "flex-center" },
            React.createElement(Button, { type: "primary", htmlType: "submit", loading: isSubmitting },
                React.createElement("span", { className: "m-l-xxs" }, formAction === OnsFormAction.CREATE ? (React.createElement(FormattedMessage, { id: "forms.offer_notification_subscription_form.subscribe" })) : (React.createElement(FormattedMessage, { id: "forms.offer_notification_subscription_form.save_changes" }))))),
        React.createElement(Separator, { className: "m-t-l m-b-l" }),
        React.createElement("div", { className: "flex-center m-b-l" },
            React.createElement(FormattedMessage, { id: "forms.offer_notification_subscription_form.mandatory_fields" }))));
}
export { OfferNotificationSubscriptionForm };

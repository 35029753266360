import axios from 'axios'
import config from 'config'

const asyncValidate = (values, _, props) => {
	const { intl } = props
	return new Promise((resolve, reject) =>
		axios
			.get(`${config.api}/0/account?email=${encodeURIComponent(values.email)}`, { withCredentials: true })
			.then(() => {
				reject({
					email: intl.formatMessage({
						id: 'registration.async_validation.email_taken',
						defaultMessage: 'Email je už obsadený',
					}),
				})
			})
			.catch(() => {
				resolve()
			})
	)
}

export default asyncValidate

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import freeze from 'redux-freeze';
import { getLanguageFromPathname, getLocalizedPath } from '@shared/localized-path';
// import { routerMiddleware } from 'connected-react-router';
import { sagas } from '@src/sagas/main';
import createReducers from '../reducers';
let history = {
    location: {
        pathname: '',
    },
    listen: () => undefined,
    createHref: () => undefined,
    getQuery: () => undefined,
    push: () => undefined,
};
function configureStore(intialState) {
    if (typeof document !== 'undefined') {
        const { createBrowserHistory } = require('history');
        history = createBrowserHistory();
        // wrap history.push with localized path
        // @ts-ignore
        history.pushLocalized = (path) => {
            const language = getLanguageFromPathname(history.location.pathname);
            const localizedPath = getLocalizedPath(path, language);
            // @ts-ignore
            history.push(localizedPath);
        };
        history.getQuery = function () {
            const query = this.location.search
                .substring(1)
                .split(/&|=/)
                .reduce((acc, param, i, queries) => {
                if (i % 2 === 0) {
                    acc[param] = queries[i + 1];
                }
                return acc;
            }, {});
            return query;
        };
    }
    // add the middlewares
    const middlewares = [];
    const sagaMiddleware = createSagaMiddleware();
    if (typeof window !== 'undefined') {
        // add the saga middleware
        middlewares.push(sagaMiddleware);
    }
    // add the freeze dev middleware
    if (process.env.ENVIRONMENT === 'local') {
        middlewares.push(freeze);
    }
    // apply the middleware
    let middleware = applyMiddleware(...middlewares);
    // add the redux dev tools
    if (process.env.ENVIRONMENT !== 'production' &&
        typeof window !== 'undefined' &&
        window.devToolsExtension) {
        middleware = compose(middleware, window.devToolsExtension());
    }
    // create the store
    const store = createStore(createReducers(), intialState, middleware);
    if (typeof window !== 'undefined') {
        sagaMiddleware.run(sagas);
    }
    return { store, history };
}
// export
export { configureStore };

import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { notification } from 'antd';
import { SocketEvents } from '@src/types/socket-events';
import actions from '@src/actions';
import { socket } from '../socket';
const SocketContext = React.createContext(socket);
export const SocketProvider = props => {
    const dispatch = useDispatch();
    const intl = useIntl();
    React.useEffect(() => {
        socket.on(SocketEvents.ACCOUNT_AUTHENTICATED, (user) => {
            dispatch(actions.authCheckSuccess({ data: user }));
        });
        socket.on(SocketEvents.ACCOUNT_LOG_OUT, () => {
            dispatch(actions.logoutSuccess());
        });
        socket.on(SocketEvents.ACCOUNT_VERIFIED, (user) => {
            dispatch(actions.userVerificationSuccess());
            dispatch(actions.authCheckSuccess({ data: user }));
        });
        socket.on(SocketEvents.ACCOUNT_REGISTERED, (user) => {
            dispatch(actions.authCheckSuccess({ data: user }));
        });
        socket.on(SocketEvents.NOTIFICATION_RECEIVED, ({ context }) => {
            notification.success({
                message: React.createElement("strong", null, context.offerTitle),
                description: intl.formatMessage({ id: 'global.notification.company_new_offer_notification' }, { companyName: context.companyName, strong: chunks => React.createElement("strong", null, chunks) }),
                duration: 10,
            });
        });
    }, [dispatch]);
    return React.createElement(SocketContext.Provider, { value: socket }, props.children);
};
export const useSocket = () => {
    const context = React.useContext(SocketContext);
    return context;
};

import actionNames from '../actions';

const defaultState = {
	isSubmitting: false,
};

export default function offerPost(state = defaultState, action) {
	switch (action.type) {
	case actionNames.offerPostStart().type:
		return Object.assign({}, state, {
			isSubmitting: true,
		});

	case actionNames.offerPostError().type:
		return Object.assign({}, state, {
			isSubmitting: false,
        });

    case actionNames.offerPostSuccess().type:
        return Object.assign({}, state, {
            isSubmitting: false,
        });

	default:
		return state;
	}
}

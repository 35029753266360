var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useIntl } from 'react-intl';
import { notification, TreeSelect } from 'antd';
import * as Api from '@src/api';
import { ABROAD, REMOTE } from '@src/types';
import { normalizeStr } from '@src/helpers/normalize-string';
import { Spinner } from '@src/components/elements';
function buildLocationTreeData(currentSubhierarchy, intl) {
    return currentSubhierarchy.map(location => {
        var _a;
        const result = {
            title: intl.formatMessage({
                id: `${location.translation_key}`,
            }),
            value: location.id,
        };
        if ((_a = location.sublocations) === null || _a === void 0 ? void 0 : _a.length) {
            result.children = buildLocationTreeData(location.sublocations, intl);
        }
        return result;
    });
}
export function LocationSelect({ value, onChange }) {
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [locationHierarchy, setLocationHierarchy] = React.useState([]);
    const intl = useIntl();
    function showErrorNotification() {
        notification.error({
            message: intl.formatMessage({ id: 'general.error' }),
            description: intl.formatMessage({ id: 'general.something_went_wrong' }),
        });
    }
    function initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const fetchedHierarchicalLocations = yield Api.Location.getHierarchicalLocations();
                setLocationHierarchy([
                    {
                        title: intl.formatMessage({ id: 'location.abroad' }),
                        value: ABROAD,
                    },
                    {
                        title: intl.formatMessage({ id: 'location.remote' }),
                        value: REMOTE,
                    },
                    ...buildLocationTreeData(fetchedHierarchicalLocations, intl),
                ]);
                setIsInitialized(true);
            }
            catch (err) {
                console.error(err);
                showErrorNotification();
            }
        });
    }
    React.useEffect(() => {
        initialize();
    }, []);
    const defaultExpandedKeys = React.useMemo(() => {
        const startOfHierarchy = locationHierarchy.find(node => node.value !== ABROAD && node.value !== REMOTE);
        return startOfHierarchy ? [startOfHierarchy.value] : [];
    }, [locationHierarchy]);
    function handleChange(locations) {
        onChange === null || onChange === void 0 ? void 0 : onChange(locations);
    }
    function filterTreeNode(inputValue, treeNode) {
        return normalizeStr(treeNode.title).indexOf(normalizeStr(inputValue)) >= 0;
    }
    if (!isInitialized) {
        return (React.createElement("div", { className: "u-text-center m-m" },
            React.createElement(Spinner, null)));
    }
    return (React.createElement(TreeSelect, { treeNodeFilterProp: "title", treeDefaultExpandedKeys: defaultExpandedKeys, filterTreeNode: filterTreeNode, value: value, loading: !isInitialized, showArrow: true, style: { width: '100%' }, listHeight: 350, treeData: locationHierarchy, placeholder: intl.formatMessage({ id: 'location.select.placeholder' }), multiple: true, treeCheckable: true, showCheckedStrategy: TreeSelect.SHOW_PARENT, onChange: handleChange }));
}

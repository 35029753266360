import axios from './clients/axios';

export async function incrementPageView(pathname) {
	try {
		const response = await axios.post('/0/analytics/page-view', {
			pathname,
		})

		return response.data
	} catch (err) {
		throw err
	}
};

import styled from 'styled-components';
import { Collapse } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { up } from 'styled-breakpoints';
const { Panel: CollapsePanel } = Collapse;
export const FiltersTitle = styled.h1 `
	color: ${({ theme }) => theme.color.brand4};
	font-weight: bold;
	padding: 1rem 0;
	margin-bottom: 0.5rem;
`;
export const CollapseIcon = styled(CaretDownOutlined) `
	color: ${({ theme }) => theme.color.gray6};
	rotate: ${({ rotate }) => rotate};

	svg {
		width: 20px;
		height: 20px;
	}
`;
export const CollapseWrapper = styled(Collapse) `
	.ant-collapse-header {
		padding-left: 0 !important;
	}

	${up('lg')} {
		.ant-collapse-header {
			display: none;
		}
	}
`;
export const CollapsePanelWrapper = styled(CollapsePanel) `
	.ant-collapse-content-box {
		padding: 0;
	}
`;
export const ToggleFilterContentButton = styled.button `
	padding: 0.5rem;
	color: ${({ theme }) => theme.color.brand1};
	border-radius: 0.3rem;
	text-decoration: underline;
	font-weight: bold;
	font-size: 0.9rem;
	border: none;
	width: 100%;
	text-align: center;
	cursor: pointer;

	:focus {
		color: ${({ theme }) => theme.color.brand1};
		background: none;
		text-decoration: underline;
	}
	:hover {
		color: ${({ theme }) => theme.color.brand4};
		border-color: transparent;
		background: rgba(0, 0, 0, 0.018);
	}
`;

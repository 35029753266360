var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from './clients/axios';
export function getAccountExistsByEmail(email) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get('/0/account', { params: { email } });
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function getMyNotifications() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get('/0/account/notifications');
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function patchNotification(uuid, data) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.patch(`/0/account/notifications/${uuid}`, data);
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function watchAllNotifications() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.put('/0/account/notifications/watch-all');
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}

import React from 'react';
import { useIntl } from 'react-intl';
const Timestamp = ({ type, value }) => {
    const intl = useIntl();
    const formatRelative = (input) => {
        const hours = (new Date(input).valueOf() - new Date().valueOf()) / (1000 * 60 * 60);
        const days = (new Date(input).valueOf() - new Date().valueOf()) / (1000 * 60 * 60 * 24);
        if (Math.abs(hours) > 24) {
            return intl.formatRelativeTime(Math.floor(days), 'day');
        }
        return intl.formatRelativeTime(Math.floor(hours), 'hour');
    };
    let result = intl.formatDate(value);
    if (type === 'relative') {
        result = formatRelative(value);
    }
    if (type === 'humanized') {
        result = intl.formatDate(value, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    }
    return React.createElement(React.Fragment, null, result);
};
export default Timestamp;

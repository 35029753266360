var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from './clients/axios';
export function setStage(id, stage) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.put(`/0/offer-application/${id}/set-stage`, { stage });
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function updateViewedAt(id, viewedAt) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.patch(`/0/offer-application/${id}/`, { viewed_at: viewedAt });
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function sendMessage(id, message, interviewDate, interviewDurationMinutes) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.put(`/0/offer-application/${id}/send-message`, {
                message,
                interviewDate,
                interviewDurationMinutes,
            });
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function sendGroupedMessage(groups) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.put(`/0/offer-application/send-grouped-message`, { groups });
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}

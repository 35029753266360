import React from 'react';

const Tag = function (props) {
	return (
		<div className="tag">
			{props.children}
		</div>
	);
};

export default Tag;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthContextValue } from '@src/context/auth-context';
import * as Api from '@src/api';
import actions from '@src/actions';
export function useCompanyProfileBoxInfo(company) {
    var _a, _b;
    const [subscribersCount, setSubscribersCount] = React.useState((_a = company === null || company === void 0 ? void 0 : company.subscribers) === null || _a === void 0 ? void 0 : _a.length);
    const [isSubscriptionLoading, setIsSubscriptionLoading] = React.useState(false);
    // @ts-ignore
    const account = useSelector(state => state.app.user);
    const dispatch = useDispatch();
    const { checkAuthentication } = useAuthContextValue();
    const isAccountSubscribed = (_b = company === null || company === void 0 ? void 0 : company.subscribers) === null || _b === void 0 ? void 0 : _b.find(({ account_id }) => account_id === account.id);
    React.useEffect(() => {
        var _a, _b;
        if (((_a = company === null || company === void 0 ? void 0 : company.subscribers) === null || _a === void 0 ? void 0 : _a.length) !== subscribersCount) {
            setSubscribersCount((_b = company === null || company === void 0 ? void 0 : company.subscribers) === null || _b === void 0 ? void 0 : _b.length);
        }
    }, [company]);
    const toggleSubscription = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            setIsSubscriptionLoading(true);
            const authenticatedAccount = yield checkAuthentication();
            dispatch(actions.authCheckSuccess({ data: authenticatedAccount }));
            yield Api.Company.toggleCompanySubscription(company.id);
            setSubscribersCount(isAccountSubscribed ? subscribersCount - 1 : subscribersCount + 1);
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setIsSubscriptionLoading(false);
        }
    }), [company, isAccountSubscribed]);
    return { isAccountSubscribed, subscribersCount, toggleSubscription, isSubscriptionLoading };
}

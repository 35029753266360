import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import app from './app'
import offerDetail from './offer-detail'
import offerEdit from './offer-edit'
import offerPost from './offer-post'
import login from './login'
import personalInfo from './personal-info'
import admin from './admin'
import company from './company'
import profile from './profile'
import registration from './registration'
import resetPasswordEmail from './reset-password-email'
import offerApplication from './offer-application'

const reducers = () =>
	combineReducers({
		form,
		app,
		admin,
		company,
		offerDetail,
		offerEdit,
		offerPost,
		login,
		profile,
		personalInfo,
		registration,
		resetPasswordEmail,
		offerApplication,
	})

export default reducers

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SearchOutlined } from '@ant-design/icons';
import { Separator, Input } from '@src/components/elements';
import { normalizeStr } from '@src/helpers/normalize-string';
import { Filter } from './filter/Filter';
import { FilterTitle } from './filter/Filter.style';
import { ToggleFilterContentButton } from './Filters.style';
import { useOffersFilterContextValue } from '../../filter-context';
const JobAreaFilter = ({ jobAreaFilterItems, loading }) => {
    const { jobAreaFiltersSelected, setJobAreaFiltersSelected } = useOffersFilterContextValue();
    const [search, setSearch] = React.useState('');
    const [isOpen, setIsOpen] = React.useState(false);
    const intl = useIntl();
    const defaultJobAreaFiltersShownNum = 5;
    const areCurrentFiltersNotEmpty = Boolean(jobAreaFiltersSelected && jobAreaFiltersSelected.length !== 0);
    function isJobAreaFilterItemSelected(item) {
        return Boolean(jobAreaFiltersSelected === null || jobAreaFiltersSelected === void 0 ? void 0 : jobAreaFiltersSelected.includes(item.id));
    }
    const toggleJobArea = (toggledJobAreaId) => {
        let newSelectedJobAreaIds = [];
        if (jobAreaFiltersSelected.includes(toggledJobAreaId)) {
            newSelectedJobAreaIds = jobAreaFiltersSelected.filter(jobAreaId => jobAreaId !== toggledJobAreaId);
        }
        else {
            newSelectedJobAreaIds = [...jobAreaFiltersSelected, toggledJobAreaId];
        }
        setJobAreaFiltersSelected(newSelectedJobAreaIds);
    };
    const jobAreaFilters = jobAreaFilterItems
        .map(filter => (Object.assign(Object.assign({}, filter), { isDisabled: !isJobAreaFilterItemSelected(filter) && filter.count === 0 })))
        .filter(jobAreaFilterItem => normalizeStr(intl.formatMessage({ id: jobAreaFilterItem.translationKey })).indexOf(normalizeStr(search)) >= 0)
        .sort((a, b) => {
        if (a.isDisabled && !b.isDisabled) {
            return 1;
        }
        if (!a.isDisabled && b.isDisabled) {
            return -1;
        }
        return 0;
    })
        .slice(0, isOpen ? jobAreaFilterItems.length : defaultJobAreaFiltersShownNum);
    return (React.createElement(React.Fragment, null,
        React.createElement(FilterTitle, null,
            React.createElement(FormattedMessage, { id: "offers.filter.job_area" })),
        React.createElement(Separator, null),
        React.createElement(Input, { prefix: React.createElement(SearchOutlined, null), placeholder: intl.formatMessage({ id: 'offers.job_area_filter.search_placeholder' }), className: "m-b-l", onChange: e => {
                const inputValue = e.target.value;
                if (inputValue.length === 0) {
                    setIsOpen(false);
                }
                else if (inputValue.length >= 2) {
                    setIsOpen(true);
                }
                setSearch(inputValue);
            } }),
        React.createElement("div", { className: "m-b-l font-size-m" },
            jobAreaFilters.map(filterItem => (React.createElement(Filter, { key: filterItem.id, filterItem: filterItem, loading: loading, areCurrentFiltersNotEmpty: areCurrentFiltersNotEmpty, isSelected: jobAreaFiltersSelected === null || jobAreaFiltersSelected === void 0 ? void 0 : jobAreaFiltersSelected.includes(filterItem.id), isDisabled: filterItem.isDisabled, toggleFilter: toggleJobArea }))),
            jobAreaFilterItems.length > defaultJobAreaFiltersShownNum && !search && (React.createElement(ToggleFilterContentButton, { onClick: () => setIsOpen(!isOpen), type: "button" },
                isOpen && React.createElement(FormattedMessage, { id: "general.hide_more_options" }),
                !isOpen && (React.createElement(React.Fragment, null,
                    React.createElement(FormattedMessage, { id: "general.see_more_options" }),
                    ` (${jobAreaFilterItems.length - defaultJobAreaFiltersShownNum})`)))))));
};
export { JobAreaFilter };

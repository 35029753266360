var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Steps, Alert, notification } from 'antd';
import produce from 'immer';
import styled from 'styled-components';
import * as Api from '@src/api';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from '@src/components/elements';
import ReactGA from '@src/helpers/react-ga';
import { ApplicationColumnsSendMessageModalStep } from './ApplicationColumnsSendMessageModalStep';
const getInitialStepState = () => ({
    new: {},
    interview: {},
    hired: {},
    rejected: {},
});
const StyledInfoAlert = styled(Alert) `
	background-color: #e6f7ff;
	border: 1px solid #91d5ff;
	color: rgba(0, 0, 0, 0.85);

	.anticon {
		color: #1890ff;
	}
`;
export function ApplicationColumnsSendMessageModal({ isOpen, onClose, setApplications, applications }) {
    var _a;
    const [stepsState, setStepsState] = React.useState(getInitialStepState());
    const [isLoading, setIsLoading] = React.useState(false);
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [currentStep, setCurrentStep] = React.useState(0);
    const intl = useIntl();
    React.useEffect(() => {
        const newApplications = Object.keys(applications).reduce((acc, stage) => {
            if (applications[stage].length > 0) {
                acc[stage] = {
                    applications: applications[stage],
                    message: null,
                };
            }
            return acc;
        }, {});
        setStepsState(newApplications);
        setIsInitialized(true);
    }, [applications]);
    const sendMessages = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            setApplications(stepsState);
            yield Api.OfferApplication.sendGroupedMessage(Object.values(stepsState));
            onClose();
            ReactGA.event({
                category: 'Company',
                action: 'Group message sent',
                label: 'Admin - Applicants',
            });
            notification.success({
                message: intl.formatMessage({
                    id: 'admin.applicants.send_message.successuly_sent_notification.title',
                }),
                description: intl.formatMessage({
                    id: 'admin.applicants.send_message.successuly_sent_notification.message',
                }, { count: 1 }),
            });
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setIsLoading(false);
        }
    }), [stepsState, setApplications]);
    const modalTitleMap = {
        new: intl.formatMessage({ id: 'offer_application.stage.new' }),
        viewed: intl.formatMessage({ id: 'offer_application.stage.new' }),
        interview: intl.formatMessage({ id: 'offer_application.stage.interview' }),
        rejected: intl.formatMessage({ id: 'offer_application.stage.rejected' }),
        hired: intl.formatMessage({ id: 'offer_application.stage.hired' }),
    };
    const nonEmptyApplicationGroup = Object.keys(applications).filter(stage => applications[stage].length > 0);
    const isLastStep = nonEmptyApplicationGroup.length === currentStep + 1;
    const okText = isLastStep
        ? intl.formatMessage({
            id: 'general.buttons.send',
        })
        : intl.formatMessage({
            id: 'general.buttons.next',
        });
    const currentStepStage = nonEmptyApplicationGroup[currentStep];
    const currentStepState = Object.values(stepsState)[currentStep];
    const doAllStepsHaveMessages = Object.values(stepsState).every(step => Boolean(step.message));
    const isOkButtonDisabled = !(currentStepState === null || currentStepState === void 0 ? void 0 : currentStepState.message) || ((_a = currentStepState === null || currentStepState === void 0 ? void 0 : currentStepState.message) === null || _a === void 0 ? void 0 : _a.length) <= 0 || (isLastStep && !doAllStepsHaveMessages);
    if (!isInitialized) {
        return null;
    }
    return (React.createElement(Modal, { zIndex: 10000, title: React.createElement(FormattedMessage, { id: "admin.applicants.send_message.title" }), visible: isOpen, onOk: () => {
            if (!isLastStep) {
                setCurrentStep(currentStep + 1);
            }
            else {
                sendMessages();
            }
        }, width: 720, confirmLoading: isLoading, okButtonProps: {
            disabled: isOkButtonDisabled,
        }, onCancel: () => onClose(), cancelText: intl.formatMessage({ id: 'admin.applicants.send_message.do_not_send' }), okText: okText },
        nonEmptyApplicationGroup.length > 1 && (React.createElement(Steps, { current: currentStep, onChange: current => setCurrentStep(current), type: "navigation", size: "small", className: "m-b-l" }, nonEmptyApplicationGroup.map(stage => (React.createElement(Steps.Step, { key: modalTitleMap[stage], description: modalTitleMap[stage] }))))),
        React.createElement(StyledInfoAlert, { className: "m-b-l", message: intl.formatMessage({ id: 'admin.applicants.send_message.info_group_message' }), type: "info", showIcon: true }),
        nonEmptyApplicationGroup
            .filter(stage => stage === currentStepStage)
            .map(stage => (React.createElement(ApplicationColumnsSendMessageModalStep, { key: `application-send-modal-step-${stage}`, updateInterviewDate: (applicationId, interviewDate) => {
                const updatedState = produce(stepsState, draft => {
                    // eslint-disable-next-line no-param-reassign
                    const currentApplication = draft[stage].applications.find(app => app.id === applicationId);
                    currentApplication.interviewDate = interviewDate;
                });
                setStepsState(updatedState);
            }, updateInterviewDurationMinutes: (applicationId, interviewDurationMinutes) => {
                const updatedState = produce(stepsState, draft => {
                    // eslint-disable-next-line no-param-reassign
                    const currentApplication = draft[stage].applications.find(app => app.id === applicationId);
                    currentApplication.interviewDurationMinutes = interviewDurationMinutes;
                    currentApplication.interview_duration_minutes = interviewDurationMinutes;
                });
                setStepsState(updatedState);
            }, updateMessage: updatedMessage => {
                const updatedState = produce(stepsState, draft => {
                    // eslint-disable-next-line no-param-reassign
                    draft[stage].message = updatedMessage;
                });
                setStepsState(updatedState);
            }, applications: currentStepState.applications, type: stage, message: currentStepState.message })))));
}

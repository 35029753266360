import { call, put } from 'redux-saga/effects';
import { history } from '@src/app/index';
import axios from 'axios';
import URI from 'urijs';
import { notification } from 'antd';
import config from '@src/config';
import { socket } from '@src/socket';
import { SocketEvents } from '@src/types';
import actions from '../actions';
export function* loginStart(values) {
    const uri = new URI(`${config.api}/0/login`);
    const apiUri = uri.toString();
    const postData = data => axios
        .post(apiUri, data, {
        withCredentials: true,
        validateStatus: status => (status >= 200 && status < 300) || status === 429,
    })
        .then(res => {
        if (res.status === 429) {
            notification.error({
                message: 'Ochrana proti útokom',
                description: 'Snažíme sa ochrániť údaje užívateľov. Z toho dôvodu sme zablokovali vašu IP adresu na 30 minút.',
            });
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw res;
        }
        else {
            return res;
        }
    })
        .catch(err => {
        throw err;
    });
    try {
        const response = yield call(postData, {
            email: values.payload.email,
            password: values.payload.password,
        });
        yield put(actions.loginSuccess({ user: response.data, shouldStayOnSameUrl: values.payload.shouldStayOnSameUrl }));
    }
    catch (err) {
        yield put(actions.loginError());
    }
}
export function* loginSuccess({ payload }) {
    notification.success({
        message: 'Upozornenie',
        description: 'Boli ste úspešne prihlásený',
    });
    const login = () => {
        // @ts-ignore
        if (history.location.search === '?ref=back') {
            // @ts-ignore
            history.goBack();
        }
        else {
            let landingUrl = null;
            if (payload.user.account_type_id === config.accountType.company) {
                landingUrl = '/admin';
            }
            else {
                landingUrl = payload.shouldStayOnSameUrl ? history.location.pathname : '/profil';
            }
            // @ts-ignore
            history.pushLocalized(landingUrl);
        }
    };
    socket.emit(SocketEvents.ACCOUNT_AUTHENTICATED, payload.user);
    yield call(login);
    yield put(actions.loadMyResumesStart());
}

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Button as AntdButton } from 'antd';
import { Link } from '../Link';
const defaultProps = {
    shape: 'round',
    size: 'large',
    type: 'primary',
};
const StyledLink = styled(Link) `
	width: fit-content;
	height: fit-content;
`;
function Button(props) {
    const _a = Object.assign(Object.assign({}, defaultProps), props), { children, link, type, className } = _a, otherProps = __rest(_a, ["children", "link", "type", "className"]);
    if (link) {
        return (React.createElement(StyledLink, { to: link, className: className },
            React.createElement(AntdButton, Object.assign({ type: props.type || 'default' }, otherProps), children)));
    }
    return (React.createElement(AntdButton, Object.assign({ type: type, className: className }, otherProps), children));
}
export { Button };

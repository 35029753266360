var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { reduxForm, Field, formValueSelector, Fields } from 'redux-form';
import { useIntl, FormattedMessage, injectIntl } from 'react-intl';
import { useHistory } from 'react-router';
import _get from 'lodash/get';
import deepEqual from 'deep-equal';
import { notification, Col, Row } from 'antd';
// field components
import actions from 'actions';
import showError from 'components/modules/forms/showError';
import renderField from 'components/modules/forms/renderField';
import renderCheckbox from 'components/modules/forms/renderCheckbox';
import renderRichTextEditor from 'components/modules/forms/renderRichTextEditor';
import renderDateTimePicker from 'components/modules/forms/renderDateTimePicker';
import renderMultiselect from 'components/modules/forms/renderMultiselect';
import renderRadioGroup from 'components/modules/forms/renderRadioGroup';
import { Button } from '@src/components/elements';
import validate from 'components/pages/admin/modules/offer/validation/sync-validation';
import { getConfig } from '@src/config';
import renderSimpleSelect from 'components/modules/forms/renderSimpleSelect';
import Tooltip from 'components/modules/global/tooltip';
import renderSalaryAmountFields from 'components/modules/forms/renderSalaryAmountFields';
import * as Api from '@src/api';
import { EOfferType } from '@src/types';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
import { transformOffer } from '../OfferPostFormModel';
let OfferPostFormComponent = props => {
    const { handleSubmit, change, isHomeOffice } = props;
    const intl = useIntl();
    const config = getConfig(intl.locale);
    // @ts-ignore
    const formState = useSelector(state => state.form.offerPost.values);
    const formStateRef = React.useRef();
    const dispatch = useDispatch();
    const isBelowSm = useBreakpoint(down('sm'));
    const history = useHistory();
    const shouldSaveDraftRef = React.useRef(true);
    // @ts-ignore
    const draftSlug = history.getQuery().slug;
    const saveOrUpdateOfferDraft = React.useCallback((e) => __awaiter(void 0, void 0, void 0, function* () {
        if (e) {
            delete e.returnValue;
        }
        if (!deepEqual(formStateRef.current, props.initialValues)) {
            if (draftSlug) {
                yield Api.Offer.updateOffer(Object.assign(Object.assign({}, formStateRef.current), { 
                    // @ts-ignore
                    is_paid: formStateRef.current.is_paid === 'true' ? 1 : 0 }));
            }
            else {
                yield Api.Offer.postOfferDraft(formStateRef.current);
            }
            notification.success({
                message: intl.formatMessage({
                    id: 'admin.offer_form.draft_saved',
                    defaultMessage: 'Rozpracovaný inzerát bol uložený ako koncept',
                }),
                description: '',
            });
        }
    }), [formStateRef.current, draftSlug]);
    const onSubmitForm = React.useCallback(e => {
        const syncErrors = props.validate(formState, props);
        const hasErrors = Object.keys(syncErrors).length > 0;
        const shouldCreate = !draftSlug;
        // @ts-ignore
        const notDraftOffer = Object.assign(Object.assign({}, formStateRef.current), { is_draft: false, is_active: true });
        shouldSaveDraftRef.current = false;
        e.preventDefault();
        handleSubmit(e);
        if (!hasErrors) {
            if (shouldCreate) {
                dispatch(actions.offerPostStart(notDraftOffer));
            }
            else {
                dispatch(actions.offerEditStart(notDraftOffer));
            }
        }
    }, [formStateRef.current, draftSlug, actions, handleSubmit]);
    React.useEffect(() => {
        // temporary workaround for redux form after initializing
        if (draftSlug) {
            const syncErrors = props.validate(formState, props);
            dispatch({
                type: '@@redux-form/UPDATE_SYNC_ERRORS',
                payload: {
                    syncErrors,
                },
                meta: {
                    form: 'offerPost',
                },
            });
        }
        formStateRef.current = formState;
    }, [formState, draftSlug]);
    const onSaveDraftButtonClick = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            shouldSaveDraftRef.current = false;
            yield saveOrUpdateOfferDraft();
            // @ts-ignore
            history.pushLocalized('/admin');
        }
        catch (err) {
            console.error(err);
        }
    }), [shouldSaveDraftRef.current, history]);
    const fetchDraft = React.useCallback((slug) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const splittedSlug = slug.split('-');
            const id = splittedSlug.pop();
            let offerData = yield Api.Offer.getOffer(id, splittedSlug.join('-'), true);
            offerData = transformOffer(offerData);
            const initialHomeOffice = offerData.state && offerData.state.find(s => s.id === 9);
            props.initialize(Object.assign(Object.assign({}, offerData), { home_office: initialHomeOffice }));
        }
        catch (err) {
            console.error(err);
        }
    }), []);
    // @ts-ignore
    React.useEffect(() => {
        window.addEventListener('beforeunload', saveOrUpdateOfferDraft);
        if (draftSlug) {
            fetchDraft(draftSlug);
        }
        return () => __awaiter(void 0, void 0, void 0, function* () {
            window.removeEventListener('beforeunload', saveOrUpdateOfferDraft);
            if (shouldSaveDraftRef.current) {
                if (!deepEqual(formStateRef.current, props.initialValues)) {
                    if (draftSlug) {
                        yield Api.Offer.updateOffer(formStateRef.current);
                    }
                    else {
                        yield Api.Offer.postOfferDraft(formStateRef.current);
                    }
                }
            }
        });
    }, []);
    return (React.createElement("form", { className: "form", onSubmit: onSubmitForm },
        React.createElement(Field, { component: renderField, name: "title", label: intl.formatMessage({
                id: 'admin.offer_form.title',
                defaultMessage: 'Názov pozície ',
            }) }),
        React.createElement(Field, { label: intl.formatMessage({
                id: 'admin.offer_form.choose_position_type',
                defaultMessage: 'Vyberte typ pozície ',
            }), 
            // @ts-ignore
            component: renderRadioGroup, name: "offer_type_id", onChange: (event, newValue) => {
                const newOfferType = Number(newValue);
                if ([EOfferType.graduate, EOfferType.work_and_travel, EOfferType.temporary_job].includes(newOfferType)) {
                    change('is_paid', 'true');
                    change('duration_min', null);
                    change('duration_max', null);
                    change('duration_type', null);
                }
                else if (newOfferType === EOfferType.internship) {
                    change('contract_type_id', null);
                }
                else if (newOfferType === EOfferType.volunteering) {
                    change('is_paid', 'false');
                    change('duration_min', null);
                    change('duration_max', null);
                    change('duration_type', null);
                    change('contract_type_id', null);
                }
            }, options: config.offerTypes }),
        props.offerType !== EOfferType.work_and_travel && (React.createElement(Field
        // @ts-ignore
        , { 
            // @ts-ignore
            component: renderCheckbox, name: "home_office", label: "Je t\u00E1to pr\u00E1ca z domu?", onChange: (event, value) => {
                if (value) {
                    change('state', [{ id: 9, name: 'Práca z domu' }]);
                    change('address', null);
                    change('city', null);
                }
                else {
                    change('state', null);
                }
            }, type: "checkbox" })),
        React.createElement("div", { className: "form__row" },
            React.createElement(Row, { gutter: [16, 16] },
                React.createElement(Col, { xs: 24, md: 12 },
                    React.createElement(Field, { component: renderMultiselect, name: "job_area", onBlur: () => props.onBlur(), data: config.jobAreas, label: intl.formatMessage({
                            id: 'admin.offer_form.choose_job_area',
                            defaultMessage: 'Vyberte zameranie *',
                        }) })),
                !isHomeOffice && (React.createElement(Col, { xs: 24, md: 12 },
                    React.createElement(Field, { component: renderMultiselect, name: "state", onBlur: () => props.onBlur(), label: intl.formatMessage({
                            id: 'admin.offer_form.choose_state',
                            defaultMessage: 'Vyberte kraj výkonu *',
                        }), data: config.states }))))),
        React.createElement(Field, { component: renderRichTextEditor, name: "description", label: intl.formatMessage({
                id: 'admin.offer_form.description',
                defaultMessage: 'Náplň práce *',
            }), type: "textfield" }),
        React.createElement(Field, { component: renderRichTextEditor, name: "required", label: intl.formatMessage({
                id: 'admin.offer_form.required',
                defaultMessage: 'Čo požadujete od kandidáta? *',
            }), type: "textfield" }),
        React.createElement(Field, { component: renderRichTextEditor, name: "nice_to_have", label: intl.formatMessage({
                id: 'admin.offer_form.nice_to_have',
                defaultMessage: 'Výhodou pre kandidáta je *',
            }), type: "textfield" }),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Field, { component: renderDateTimePicker, name: "deadline", label: intl.formatMessage({
                        id: 'admin.offer_form.deadline',
                        defaultMessage: 'Dátum uzávierky *',
                    }), time: false }),
                React.createElement(Field, { label: intl.formatMessage({
                        id: 'admin.offer_form.is_position_paid',
                        defaultMessage: 'Bude pozícia platená? *',
                    }), 
                    // @ts-ignore
                    component: renderRadioGroup, name: "is_paid", onChange: (event, newValue) => {
                        if (newValue === 'false') {
                            change('salary_rate', null);
                            change('salary_amount_from', null);
                            change('salary_amount_to', null);
                            change('salary_currency', null);
                            change('salary_description', null);
                            change('duration_min', null);
                            change('duration_max', null);
                        }
                        else {
                            change('salary_currency', '€');
                        }
                    }, options: [
                        {
                            title: intl.formatMessage({
                                id: 'admin.offer_form.yes',
                                defaultMessage: 'áno',
                            }),
                            value: 'true',
                        },
                        {
                            title: intl.formatMessage({
                                id: 'admin.offer_form.no',
                                defaultMessage: 'nie',
                            }),
                            value: 'false',
                        },
                    ], disabled: props.offerType !== EOfferType.internship })),
            !isHomeOffice && (React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Field, { component: renderField, name: "address", label: intl.formatMessage({
                        id: 'admin.offer_form.address',
                        defaultMessage: 'Adresa výkonu *',
                    }) }),
                React.createElement(Field, { component: renderField, name: "city", label: intl.formatMessage({
                        id: 'admin.offer_form.city',
                        defaultMessage: 'Mesto výkonu *',
                    }) })))),
        props.isPaid && (React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24, lg: 8 },
                React.createElement(Field, { component: renderSimpleSelect, name: "salary_rate", label: intl.formatMessage({
                        id: 'admin.offer_form.salary_rate',
                    }), options: config.salaryRates, placeholder: intl.formatMessage({
                        id: 'admin.offer_form.choose',
                        defaultMessage: 'Vyberte...',
                    }), clearable: false })),
            React.createElement(Col, { xs: 24, lg: 10 },
                React.createElement(Tooltip, { content: [
                        React.createElement("p", { key: "tooltip-salary-format" },
                            React.createElement(FormattedMessage, { id: "admin.offer_form.to_enter_single_amount", values: {
                                    strong: chunks => React.createElement("strong", null, chunks),
                                } })),
                    ] },
                    React.createElement(Fields, { names: ['salary_amount_from', 'salary_amount_to'], component: renderSalaryAmountFields, parse: value => (value === '' ? null : Number(value)), amountFromLabel: "Minimum\u00A0*", amountToLabel: "Maximum" }))),
            React.createElement(Col, { xs: 24, lg: 6 },
                React.createElement(Field, { component: renderSimpleSelect, name: "salary_currency", label: intl.formatMessage({
                        id: 'admin.offer_form.currency',
                        defaultMessage: 'Mena *',
                    }), options: config.salaryCurrencies, clearable: false, disabled: true })),
            React.createElement(Col, { xs: 24 },
                React.createElement(Field, { component: renderField, name: "salary_description", label: intl.formatMessage({
                        id: 'admin.offer_form.salary_description',
                    }) })))),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Field, { component: renderRichTextEditor, name: "benefits", label: intl.formatMessage({
                        id: 'admin.offer_form.benefits',
                        defaultMessage: 'Aké benefity ponúkate?',
                    }) })),
            React.createElement(Col, { xs: 24, md: 12 },
                props.offerType === EOfferType.internship && (React.createElement(Tooltip, { position: isBelowSm ? 'top' : 'left', content: [
                        React.createElement("div", { key: "tooltip-internship-length" },
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "admin.offer_form.setting_from_value", defaultMessage: "Nastaven\u00EDm hodnoty <i>OD</i> samostatne, ur\u010D\u00EDte minim\u00E1lnu d\u013A\u017Eku st\u00E1\u017Ee:", values: {
                                        i: chunks => React.createElement("i", null, chunks),
                                    } })),
                            React.createElement("p", { className: "u-text-center u-text-bold" },
                                React.createElement(FormattedMessage, { id: "admin.offer_form.example_minimal", defaultMessage: "Minim\u00E1lne 3 mesiace" })),
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "admin.offer_form.setting_from_to_same_values", defaultMessage: "Nastaven\u00EDm <i>OD - DO</i> rovnak\u00FDmi hodnotami, ur\u010D\u00EDte pevn\u00FA d\u013A\u017Eku st\u00E1\u017Ee:", values: {
                                        i: chunks => React.createElement("i", null, chunks),
                                    } })),
                            React.createElement("p", { className: "u-text-center u-text-bold" },
                                React.createElement(FormattedMessage, { id: "admin.offer_form.example_exact", defaultMessage: "3 mesiace" })),
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "admin.offer_form.setting_from_to_different_values", defaultMessage: "Nastaven\u00EDm <i>OD - DO</i> r\u00F4znymi hodnotami, ur\u010D\u00EDte rozsah d\u013A\u017Eky st\u00E1\u017Ee:", values: {
                                        i: chunks => React.createElement("i", null, chunks),
                                    } })),
                            React.createElement("p", { className: "u-text-center u-text-bold" },
                                React.createElement(FormattedMessage, { id: "admin.offer_form.example_range", defaultMessage: "3 - 6 mesiacov" })),
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "admin.offer_form.internship_type_is_same", defaultMessage: "Typ st\u00E1\u017Ee plat\u00ED pre obe hodnoty. Nie je mo\u017En\u00E9 ich kombinova\u0165" }))),
                    ] },
                    React.createElement(Row, { gutter: [16, 16] },
                        React.createElement(Col, { xs: 12, lg: 12 },
                            React.createElement(Field, { component: renderField, noWhiteSpaceWrap: true, name: "duration_min", label: intl.formatMessage({
                                    id: 'admin.offer_form.duration_from',
                                    defaultMessage: 'Dĺžka stáže od *',
                                }), type: "number", placeholder: "2" })),
                        React.createElement(Col, { xs: 12, lg: 12 },
                            React.createElement(Field, { component: renderField, name: "duration_max", label: intl.formatMessage({
                                    id: 'admin.offer_form.duration_to',
                                    defaultMessage: 'Dĺžka stáže do',
                                }), placeholder: "12", type: "number" })),
                        React.createElement(Col, { xs: 24 },
                            React.createElement(Field, { component: renderSimpleSelect, name: "duration_type", label: intl.formatMessage({
                                    id: 'admin.offer_form.duration_type',
                                    defaultMessage: 'Typ *',
                                }), options: config.durationTypes, placeholder: intl.formatMessage({
                                    id: 'admin.offer_form.duration_type.placeholder',
                                    defaultMessage: '... Mesiacov',
                                }), clearable: false }))))),
                [EOfferType.graduate].includes(props.offerType) && (React.createElement(Field, { component: renderSimpleSelect, name: "contract_type_id", label: intl.formatMessage({
                        id: 'admin.offer_form.contract_type',
                        defaultMessage: 'Typ úväzku *',
                    }), options: config.contractTypes, placeholder: intl.formatMessage({
                        id: 'admin.offer_form.choose',
                        defaultMessage: 'Vyberte...',
                    }), clearable: false })))),
        React.createElement("h2", { className: "form__title" },
            React.createElement(FormattedMessage, { id: "admin.offer_form.documents_required", defaultMessage: "Ak\u00E9 dokumenty po\u017Eadujete od kandid\u00E1ta?" })),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Field, { component: renderCheckbox, name: "resume_required", label: intl.formatMessage({
                        id: 'admin.offer_form.resume',
                        defaultMessage: 'Životopis',
                    }), type: "checkbox" }),
                React.createElement(Field, { component: renderCheckbox, name: "motivational_required", label: intl.formatMessage({
                        id: 'admin.offer_form.motivational_letter',
                        defaultMessage: 'Motivačný list',
                    }), type: "checkbox" })),
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Field, { component: renderField, name: "contact_email", label: intl.formatMessage({
                        id: 'admin.offer_form.contact_email',
                        defaultMessage: 'Na aký email Vám má chodiť odpoveď? *',
                    }), type: "email" }))),
        React.createElement("div", { className: "form__submit" },
            React.createElement(Button, { type: "ghost", onClick: onSaveDraftButtonClick }, intl.formatMessage({
                id: 'admin.offer_form.save_draft',
                defaultMessage: 'Uložiť koncept',
            })),
            React.createElement(Button, { htmlType: "submit", className: "m-l-m", disabled: props.isSubmitting }, intl.formatMessage({
                id: 'admin.offer_form.publish',
                defaultMessage: 'Zverejniť',
            }))),
        React.createElement("div", { className: "bottom-line" },
            React.createElement(FormattedMessage, { id: "admin.offer_form.required_fields", defaultMessage: "* Polia ozna\u010Den\u00E9 hviezdi\u010Dkou s\u00FA povinn\u00E9" }))));
};
// @ts-ignore
OfferPostFormComponent = reduxForm({
    form: 'offerPost',
    destroyOnUnmount: true,
    onSubmitFail: showError,
    enableReinitialize: true,
    // @ts-ignore
    validate,
})(OfferPostFormComponent);
const selector = formValueSelector('offerPost');
// @ts-ignore
OfferPostFormComponent = connect(state => {
    // can select values individually
    const isPaid = selector(state, 'is_paid') === 'true';
    const offerType = Number(selector(state, 'offer_type_id'));
    const isHomeOffice = selector(state, 'home_office');
    return {
        isPaid,
        offerType,
        isHomeOffice,
        initialValues: {
            resume_required: true,
            contact_email: _get(state, 'app.user.email'),
            salary_currency: '€',
        },
    };
})(OfferPostFormComponent);
// exporting const because of no-mutable-exports
const OfferPostForm = OfferPostFormComponent;
export default injectIntl(OfferPostForm);

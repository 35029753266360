import React from 'react';
import classNames from 'classnames';

class CompanyImage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			companyImageDimensions: null,
		};
		this.onLoadCompanyImage = this.onLoadCompanyImage.bind(this);
	}

	onLoadCompanyImage({
		target: img,
	}) {
		this.setState({
			companyImageDimensions: {
				width: img.clientWidth,
				height: img.clientHeight,
			},
		});
	}

	get companyImageClasses() {
		let classes = { 'company-image': true };

		if (this.state.companyImageDimensions) {
			const imageRatio = this.state.companyImageDimensions.width / this.state.companyImageDimensions.height;
			const modifierClasses = {
				'company-image--square': imageRatio === 1,
				'company-image--landscape': imageRatio > 1,
				'company-image--portrait': imageRatio < 1,
			};
			classes = { ...classes, ...modifierClasses };
		}

		return classNames(classes);
	}

	render() {
		const {
			name,
			imageUrl,
		} = this.props;

		return (
			<div className={this.companyImageClasses}>
				<img
					className="company-image__img"
					onLoad={this.onLoadCompanyImage}
					src={imageUrl}
					alt={name}
				/>
			</div>
		);
	}
}

export default CompanyImage;

import React from 'react';
import { reduxForm, Field, reset } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import _get from 'lodash/get';
import { injectIntl, FormattedMessage } from 'react-intl';
// field components
import showError from 'components/modules/forms/showError';
import renderField from 'components/modules/forms/renderField';
import renderTextArea from 'components/modules/forms/renderTextArea';
// import renderRecaptcha from 'components/modules/forms/renderRecaptcha'
import { Button, Holder } from '@src/components/elements';
import { Typography, Col, Row } from 'antd';
import actions from '../../../../../actions';
import validate from './validation/sync-validation';
let ContactUsFormComponent = props => {
    const { intl } = props;
    const dispatch = useDispatch();
    // field level validation function - recaptcha
    // const requiredRecaptcha = React.useCallback(
    // 	value =>
    // 		value
    // 			? undefined
    // 			: intl.formatMessage({
    // 					id: 'contact.contact_us.confirm_you_are_not_robot',
    // 					defaultMessage: 'Potvrďte, že nie ste robot',
    // 			  }),
    // 	[]
    // )
    const { Title } = Typography;
    return (React.createElement(Holder, { marginVertical: true, "data-cy": "contact-us" },
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, null,
                React.createElement(Title, { level: 2, type: "secondary" },
                    React.createElement(FormattedMessage, { id: "general.contact_us" })))),
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, { xs: 24, lg: 12 },
                React.createElement("div", { className: "panel", "data-cy": "panel" },
                    React.createElement("form", { onSubmit: props.handleSubmit(values => {
                            dispatch(actions.contactUsStart(values));
                        }), className: "form form--login" },
                        React.createElement("div", { className: "form__row" },
                            React.createElement(Field, { component: renderField, name: "name", label: intl.formatMessage({
                                    id: 'contact.contact_us.name_and_surename',
                                    defaultMessage: 'Meno a priezvisko *',
                                }) }),
                            React.createElement(Field, { component: renderField, type: "email", name: "email", label: intl.formatMessage({
                                    id: 'contact.contact_us.email',
                                    defaultMessage: 'Email *',
                                }) })),
                        React.createElement(Field, { component: renderTextArea, name: "message", label: intl.formatMessage({
                                id: 'contact.contact_us.message',
                                defaultMessage: 'Správa *',
                            }) }),
                        React.createElement("div", { className: "form__submit" },
                            React.createElement(Button, { htmlType: "submit" }, intl.formatMessage({
                                id: 'contact.contact_us.send',
                                defaultMessage: 'Odoslať',
                            })))))))));
};
// @ts-ignore
ContactUsFormComponent = reduxForm({
    form: 'contactUsform',
    destroyOnUnmount: true,
    enableReinitialize: true,
    onSubmitSuccess: (values, dispatch) => dispatch(reset('contactUsform')),
    onSubmitFail: showError,
    // @ts-ignore
    validate,
})(ContactUsFormComponent);
// @ts-ignore
ContactUsFormComponent = connect(state => ({
    initialValues: {
        email: _get(state, 'app.user.email'),
        // @ts-ignore
        name: _get(state, 'app.user.first_name') && `${state.app.user.first_name} ${state.app.user.last_name}`,
    },
}))(ContactUsFormComponent);
const ContactUsForm = ContactUsFormComponent;
export default injectIntl(ContactUsForm);

import produce from 'immer'
import actionNames from '../actions'

const defaultState = {
	myOffers: [],
	company: {},
	invoices: [],
	order: {},
	// useRememberedCard: true,
	isOpenCreditsReplenishedDialog: false,
	isOpenNotEnoughCreditsDialog: false,
	ui: {
		loading: false,
	},
}

const toggleIsOfferActive = (myOffers, offerId) => {
	const newOffers = produce(myOffers, draft => {
		const offerToEdit = draft.find(offer => offer.id === offerId)
		if (offerToEdit) {
			offerToEdit.is_active = !offerToEdit.is_active
		}
	})

	return newOffers
}

export default function admin(state = defaultState, action) {
	switch (action.type) {
		case actionNames.myCompanyInfoInitSuccess().type:
			return { ...state, company: action.payload.data, invoices: action.payload.data.invoices }

		case actionNames.adminToggleCreditsReplenishedDialog().type:
			return { ...state, isOpenCreditsReplenishedDialog: action.payload }

		case actionNames.adminToggleLoading().type:
			return { ...state, ui: { loading: action.payload } }

		case actionNames.adminClearUpOrder().type:
			return { ...state, order: {}, company: {} }

		case actionNames.offerToggleStart().type:
			return { ...state, myOffers: toggleIsOfferActive(state.myOffers, action.payload.id) }

		case actionNames.offerToggleError().type:
			return { ...state, myOffers: toggleIsOfferActive(state.myOffers, action.payload.offerId) }

		case actionNames.adminToggleNotEnoughCreditsDialog().type:
			return { ...state, isOpenNotEnoughCreditsDialog: action.payload }

		default:
			return state
	}
}

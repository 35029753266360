import React from 'react';
export var Language;
(function (Language) {
    Language["sk"] = "sk";
    Language["en"] = "en";
})(Language || (Language = {}));
const AppContext = React.createContext({
    language: Language.sk,
    setLanguage: () => undefined,
});
// eslint-disable-next-line no-underscore-dangle
const currentLanguage = typeof window !== 'undefined' && window.__LANGUAGE__;
export const useAppContextProvider = () => {
    // todo take default language from url
    const [language, setLanguage] = React.useState(currentLanguage || Language.sk);
    return {
        AppContextProvider: AppContext.Provider,
        value: {
            language,
            setLanguage,
        },
    };
};
export const useAppContextValue = () => {
    const context = React.useContext(AppContext);
    return context;
};

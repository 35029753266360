var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Row, Col } from 'antd';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import * as Api from '@src/api';
import config from '@src/config';
import { Head, Holder } from '@src/components/elements';
import Pagination from '@src/components/elements/Pagination';
import { Offer } from '@src/components/pages/offers/modules/Offer';
import LoadingWrapper from '@src/components/modules/global/loading-wrapper';
import { FilterTags } from '@src/components/pages/offers/modules/FilterTags';
import { Filters } from '@src/components/pages/offers/modules/filters/Filters';
import MissingContentView from '@src/components/modules/MissingContentView/MissingContentView';
import { OfferNotificationSubscriptionModal } from './modules/OfferNotificationSubscriptionModal';
import { OfferNotificationSubscriptionPanel } from './modules/OfferNotificationSubscriptionPanel';
import { useOffersFilterContextValue } from './filter-context';
export const StyledPagination = styled(Pagination) `
	display: flex;
	justify-content: center;
	padding: 2em 0;
`;
function OffersList({ offers }) {
    const areOffersNotEmpty = offers && offers.length;
    return (React.createElement(React.Fragment, null, areOffersNotEmpty &&
        offers.map(offer => (React.createElement("li", { className: classNames({
                panel: true,
                'panel--offer': true,
            }), key: `offer-${offer.id}` },
            React.createElement(Offer, { values: offer }))))));
}
function Offers() {
    const user = useSelector(state => state.app.user);
    const intl = useIntl();
    const { offers, areOffersLoading, areFiltersLoading, changePage } = useOffersFilterContextValue();
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = React.useState(false);
    const [userIsSubscribed, setUserIsSubscribed] = React.useState(false);
    const isOfferListEmpty = Boolean(offers && ((offers.items && Array.isArray(offers.items) && !offers.items.length) || !offers.items));
    const fetchAndSetIsSubscribed = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            const isUserLoggedIn = Boolean(user) && Boolean(user.email);
            if (isUserLoggedIn) {
                yield Api.OfferNotificationSubscription.getSubscription();
                setUserIsSubscribed(true);
            }
            else {
                setUserIsSubscribed(false);
            }
        }
        catch (err) {
            setUserIsSubscribed(false);
        }
    }), [user]);
    React.useEffect(() => {
        fetchAndSetIsSubscribed();
    }, [fetchAndSetIsSubscribed, isSubscriptionModalOpen]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Head, { id: "offers" }),
        React.createElement(Holder, { marginVertical: true },
            React.createElement(Row, { gutter: [16, 0] },
                React.createElement(Col, { xs: 24, lg: 6 },
                    React.createElement(Filters, { loading: areFiltersLoading }),
                    !userIsSubscribed && (React.createElement(OfferNotificationSubscriptionPanel, { setIsModalOpen: setIsSubscriptionModalOpen }))),
                React.createElement(Col, { xs: 24, lg: 18 },
                    React.createElement(FilterTags, { offerCounts: {
                            offersCount: offers === null || offers === void 0 ? void 0 : offers.rowCount,
                            allOffersCount: offers === null || offers === void 0 ? void 0 : offers.allOffersCount,
                        } }),
                    !isOfferListEmpty && offers && (React.createElement(LoadingWrapper, { isLoading: areOffersLoading, wrapperClassNames: "panel panel--offers" },
                        React.createElement("ul", { key: "list", className: "offers__list", "data-cy": "offers-list" },
                            React.createElement(OffersList, { offers: offers.items })))),
                    !areOffersLoading && isOfferListEmpty && (React.createElement(MissingContentView, { heading: intl.formatMessage({
                            id: 'offers.missing_content_heading',
                        }), message: intl.formatMessage({
                            id: 'offers.missing_content_message',
                        }) })),
                    !areOffersLoading && !isOfferListEmpty && offers && (React.createElement(StyledPagination, { current: offers.page, total: offers.rowCount, defaultPageSize: config.offers.itemsPerPage, onChange: changePage, showSizeChanger: false })))),
            React.createElement(OfferNotificationSubscriptionModal, { isOpen: isSubscriptionModalOpen, setIsOpen: setIsSubscriptionModalOpen }))));
}
export { Offers };

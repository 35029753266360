import React from 'react';
import { connect, useDispatch } from 'react-redux';
import actions from 'actions';
import LoginInfoEditForm from './modules/login-info-edit-form';

const LoginInfoEdit = props => {
	const dispatch = useDispatch()
	React.useEffect(() => {
		dispatch(actions.loginInfoInitStart())
	}, [])

	return (
		<div className="panel">
			<LoginInfoEditForm
				onSubmit={props.handleSubmit}
			/>
		</div>
	);
}

const mapDispatchToProps = dispatch => ({
	handleSubmit: (values) => {
		dispatch(actions.loginInfoEditStart(values));
	},
});

export default connect(
	null,
	mapDispatchToProps,
)(LoginInfoEdit);

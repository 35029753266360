import React, { PureComponent } from 'react'
import Modal from 'react-modal'
import { Button } from '@src/components/elements'
import { EModal } from 'types/notification'
import { Col, Row } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

export default class BaseModal extends PureComponent {
	// eslint-disable-next-line no-undef
	Actions = () => {
		const { modalType, onConfirm, onCancel, confirmText = 'Potvrdiť', cancelText = 'Zavrieť' } = this.props
		return (
			<Row justify="center">
				{modalType === EModal.Alert && (
					<Col>
						<Button onClick={onConfirm}>{cancelText}</Button>
					</Col>
				)}
				{modalType === EModal.Dialog && (
					<Row justify="center" align="middle">
						<Col>
							<Button type="link" data-cy="modal-cancel" onClick={onCancel} className="link link--basic">
								{cancelText}
							</Button>
						</Col>
						<Col data-cy="modal-confirm">
							<Button onClick={onConfirm}>{confirmText}</Button>
						</Col>
					</Row>
				)}
			</Row>
		)
	}

	render() {
		const { isOpen, children, onCancel } = this.props

		return (
			<Modal isOpen={isOpen} className="modal" overlayClassName="modal-overlay" onRequestClose={onCancel}>
				<>
					<span className="modal__close" onClick={onCancel}>
						<CloseOutlined />
					</span>
					{children}
					{
						// eslint-disable-next-line no-undef, prettier/prettier
					} <this.Actions />
				</>
			</Modal>
		)
	}
}

import React from 'react';
import { connect } from 'react-redux';
import actions from 'actions';
import { useIntl, FormattedMessage } from 'react-intl';
import FormResponse from 'components/modules/global/form-response';
import Button from 'components/modules/global/button';
import ApplicationListItem from './application-list-item';

const ApplicationList = props => (
	<div>
		{props.applications.map(item => (
			<ApplicationListItem
				key={item.id}
				application={item}
			/>
		))}
	</div>
);

const ApplicationHistory = props => {
	const intl = useIntl();
	return (
		<div className="panel">
			{props.total === 0 &&
				<div>
					<FormResponse
						icon={{
							type: 'copy',
						}}
						text={ intl.formatMessage({
							id: "profile.appilcation_history.didnt_send_any_requests",
							defaultMessage: "Zatiaľ ste neposlali žiadne žiadosti.",
						})}
						link={{
							route: '/ponuky',
							text: intl.formatMessage({
								id: "profile.appilcation_history.go_to_offers_list",
								defaultMessage: 'Prejsť na výpis ponúk',
							}),
						}}
					/>
				</div>
			}
			<ApplicationList applications={props.applications} />
			{props.total > 6 && props.page < props.pageCount &&
				<div className="bottom-line">
					<Button
						onClick={() => props.loadMoreOfferApplicationsStart(props.page + 1)}
						color="brand"
						text={ intl.formatMessage({
							id: "profile.appilcation_history.show_more",
							defaultMessage: "Zobraziť ďalšie",
						})}
					/>
				</div>
			}
		</div>
	)
};

const mapStateToProps = state => ({
	applications: state.profile.items,
	page: state.profile.page,
	pageCount: state.profile.pageCount,
	total: state.profile.total,
});

const mapDispatchToProps = dispatch => ({
	loadMoreOfferApplicationsStart: (page) => {
		dispatch(actions.loadMoreOfferApplicationsStart(page));
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ApplicationHistory);

const theme = {
	color: {
		brand1: 'hsl(288, 51%, 22%)',
		brand2: 'hsl(348, 65%, 62%)',
		brand3: 'hsl(321, 35%, 49%)',
		brand4: 'hsl(234, 15%, 52%)',
		background: 'hsl(0, 0%, 99%)',
		white: 'hsl(0, 0%, 100%)',
		black: 'hsl(0, 0%, 0%)',
		gray1: 'hsl(0, 0%, 95%)',
		gray2: 'hsl(0, 0%, 87%)',
		gray3: 'hsl(0, 0%, 77%)',
		gray4: 'hsl(0, 0%, 67%)',
		gray5: 'hsl(0, 0%, 57%)',
		gray6: 'hsl(0, 0%, 47%)',
		gray7: 'hsl(0, 0%, 37%)',
		gray8: 'hsl(0, 0%, 27%)',
		gray9: 'hsl(0, 0%, 17%)',
		success: 'hsl(160, 93%, 33%)',
		warning: '#FAA84F', // TODO adjust for a new color scheme
		error: '#E82809', // TODO adjust
		facebook: 'hsl(214deg 89% 52%)',
		linkedin: 'hsl(210deg 89% 40%)',
		twitter: 'hsl(203, 89%, 53%)',
		instagram: '#d6249f',
		instagramGradient: 'url(#instagramGradient)',
	},
	breakpoints: {
		xs: '0px',
		sm: '576px',
		md: '768px',
		lg: '992px',
		xl: '1200px',
		xxl: '1600px',
	},
	zIndex: {
		loader: 100000,
		modal: 1000,
		scrollTop: 500,
		navigationMain: 300,
		cookieConsent: 200,
		logo: 150,
		navigationMobile: 100,
		loading: 50,
	},
	fontSizes: {
		xs: '0.5rem',
		s: '0.75rem',
		m: '1rem',
		l: '1.25rem',
		xl: '1.5rem',
		xxl: '1.75rem',
		xxxl: '2rem',
	},
	size: {
		headerHeight: 80,
	},
}

module.exports = theme

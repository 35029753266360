import { offerTypeMap } from '@src/types/offer';
// eg. type:brigada,absolventska-pozicia
export function getOfferTypeIdsFromFilterTypeParameter(filterTypeParameter, locale) {
    if (!filterTypeParameter) {
        return {};
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [_, values] = filterTypeParameter.split(':');
    if (!values.length) {
        return {};
    }
    const offerTypes = values.split(',');
    let isTypeParamWrong = false;
    let typeParamsForCurrentLocale;
    let offerTypeIds = offerTypes.map(type => {
        const offerTypeFromMap = offerTypeMap[locale][type];
        if (offerTypeFromMap) {
            return offerTypeFromMap;
        }
        isTypeParamWrong = true;
        const allAccessibleLocales = Object.keys(offerTypeMap);
        const foundMatchingLocale = allAccessibleLocales.find(accessibleLocale => accessibleLocale !== locale && Boolean(offerTypeMap[accessibleLocale][type]));
        if (foundMatchingLocale) {
            const foundId = offerTypeMap[foundMatchingLocale][type];
            return foundId;
        }
        return undefined;
    });
    if (isTypeParamWrong) {
        offerTypeIds = offerTypeIds.filter(Boolean);
        typeParamsForCurrentLocale = offerTypeIds
            .map(offerTypeId => Object.keys(offerTypeMap[locale]).find(key => offerTypeMap[locale][key] === offerTypeId))
            .join(',');
    }
    return { offerTypeIds, typeParamsForCurrentLocale, isTypeParamWrong };
}
export function replaceWrongTypeUrlParamsToNewUrl(urlParts, typeParamsForCurrentLocale) {
    const typeParamIndex = urlParts.findIndex(urlPart => urlPart.includes('type:'));
    if (typeParamIndex >= 0) {
        const newUrlParts = urlParts.map((urlPart, index) => {
            if (index === typeParamIndex) {
                return `type:${typeParamsForCurrentLocale}`;
            }
            return urlPart;
        });
        return newUrlParts.join('/');
    }
    return '';
}

import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import * as Api from 'api';
import { useSSE } from 'use-sse';
import { useParams } from 'react-router';
import { Row, Col } from 'antd';
import { Pagination, Holder } from '@src/components/elements';
import config from '@src/config';
import Excerpt from '../excerpt';
const Homepage = () => {
    // @ts-ignore
    const { page } = useParams();
    const history = useHistory();
    const location = useLocation();
    const currentPage = page !== undefined ? parseInt(page, 10) : 1;
    const [posts] = useSSE(() => {
        return Api.Cms.Magazine.getPosts({
            skip: (currentPage - 1) * config.magazine.postsPerPage,
        });
    }, [location.pathname]);
    const [postsCount] = useSSE(() => {
        return Api.Cms.Magazine.getPostsCount();
    }, [location.pathname]);
    const onChangePage = newPage => {
        if (newPage === 1) {
            // @ts-ignore
            return history.pushLocalized('/magazin');
        }
        // @ts-ignore
        return history.pushLocalized(`/magazin/archiv/${newPage}`);
    };
    return (React.createElement(Holder, { marginVertical: true },
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, null,
                posts &&
                    posts.map(post => {
                        return React.createElement(Excerpt, { key: post.title, post: post });
                    }),
                posts && postsCount && postsCount > config.magazine.postsPerPage && (React.createElement(Pagination, { current: currentPage, total: postsCount, defaultPageSize: config.magazine.postsPerPage, onChange: onChangePage, showSizeChanger: false })),
                currentPage > 1 && (React.createElement(Helmet, null,
                    React.createElement("meta", { name: "robots", content: "noindex, follow" })))))));
};
export default Homepage;

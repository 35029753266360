import * as Cookies from 'js-cookie'
import * as Api from '@src/api'

const TrackingCodeName = 'praxuj-tracking'

function getThisMidnight() {
	return new Date(new Date().setHours(24, 0, 0, 0))
}

export function startTracking() {
	let cookie = Cookies.get(TrackingCodeName)

	if (!cookie) {
		const expires = getThisMidnight()
		Cookies.set(TrackingCodeName, '', { expires })
		cookie = Cookies.get(TrackingCodeName)
	}
	return cookie
}

export async function trackPageView() {
	try {
		let cookie = Cookies.get(TrackingCodeName)

		if (!cookie) {
			cookie = startTracking()
		}
		const { pathname } = window.location
		const wasPathnameVisited = cookie.split(',').includes(pathname)

		if (!wasPathnameVisited) {
			const expires = getThisMidnight()
			Cookies.set(TrackingCodeName, `${cookie},${pathname}`, { expires })

			await Api.Analytics.incrementPageView(pathname)
		}
	} catch (err) {
		console.error(err)
	}
}

import React from 'react';
import _get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Col, Row, Skeleton } from 'antd';
import striptags from 'striptags';
import axios from '@src/api/clients/axios';
import actions from '@src/actions';
import { Holder, Panel } from '@src/components/elements';
import getSocialImageUrl from '@src/backend/helpers/get-social-image-url';
import Details from './modules/details';
function OfferDetail() {
    const params = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const { isLoading, initial, details, offerSuggestionsCompany, similarOffers } = useSelector(
    // @ts-ignore
    state => state.offerDetail);
    React.useEffect(() => {
        const { slug } = params;
        const payload = {};
        payload.slug = slug.substring(0, slug.lastIndexOf('-'));
        payload.id = slug.substring(slug.lastIndexOf('-') + 1, slug.length);
        if (!initial) {
            dispatch(actions.loadOfferDetailStart(payload));
            dispatch(actions.loadOfferSuggestionsCompanyStart({ id: payload.id }));
            dispatch(actions.loadSimilarOffersStart({ id: payload.id }));
        }
        else {
            dispatch(actions.changeOfferDetailInitial({ initial: false }));
        }
        axios
            .get('/0/auth-check')
            .then(response => {
            dispatch(actions.authCheckSuccess(response));
            dispatch(actions.loadMyResumesStart());
        })
            .catch(() => {
            console.log('noop');
        });
    }, [location.pathname]);
    // metadata resolution
    const companyName = _get(details, 'account.company.name', '');
    const companyImageUrl = getSocialImageUrl({
        offerId: details.id,
        companyImage: _get(details, 'account.company.image', ''),
        jobAreaId: _get(details, 'job_area[0].id', ''),
    });
    const metaOfferDescription = striptags(details.description);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, `${details.title} - ${companyName}`),
            React.createElement("meta", { name: "description", content: metaOfferDescription }),
            React.createElement("meta", { property: "og:title", content: `${details.title} - ${companyName}` }),
            React.createElement("meta", { property: "og:type", content: "article" }),
            React.createElement("meta", { property: "og:description", content: metaOfferDescription }),
            React.createElement("meta", { property: "og:image", content: companyImageUrl }),
            React.createElement("link", { rel: "canonical", href: `${process.env.PRACUJ_REDIRECT_TARGET}${location.pathname}` })),
        React.createElement(Holder, { marginVertical: true },
            isLoading && (React.createElement(React.Fragment, null,
                React.createElement(Row, { justify: "center", gutter: [16, 16] },
                    React.createElement(Col, { xs: { span: 24 }, md: { span: 16 } },
                        React.createElement(Panel, null,
                            React.createElement(Skeleton, { active: true, paragraph: {
                                    rows: 12,
                                } }))),
                    React.createElement(Col, { xs: { span: 24 }, md: { span: 8 } },
                        React.createElement(Panel, null,
                            React.createElement(Skeleton, { active: true, avatar: {
                                    shape: 'circle',
                                    size: 'large',
                                } })))))),
            !isLoading && (React.createElement(Details, { details: details, offerSuggestionsCompany: offerSuggestionsCompany, similarOffers: similarOffers })))));
}
export default OfferDetail;

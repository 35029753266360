import React from 'react';
import { Table } from 'antd';
import './Table.scss';
const defaultProps = {
    rowClassName: () => 'element-table-row',
};
function TableComponent(props) {
    return React.createElement(Table, Object.assign({}, defaultProps, props));
}
export default TableComponent;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import moment from 'moment';
import { Input, DatePicker, notification } from 'antd';
import produce from 'immer';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Api from '@src/api';
import { Avatar, Modal } from '@src/components/elements';
import { EOfferApplicationStage } from '@src/types';
export function ApplicationSendMessageModal({ application, onClose, setApplications, applications }) {
    const [message, setMessage] = React.useState('');
    const [interviewTimeDate, setInterviewTimeDate] = React.useState(null);
    const [interviewDurationMinutes, setInterviewDurationMinutes] = React.useState(60);
    const [isLoading, setIsLoading] = React.useState(false);
    const intl = useIntl();
    const modalTitleMap = {
        interview: intl.formatMessage({ id: 'admin.applicants.send_message.interview_invitation' }),
        new: intl.formatMessage({ id: 'admin.applicants.send_message' }),
        rejected: intl.formatMessage({ id: 'admin.applicants.send_message' }),
        hired: intl.formatMessage({ id: 'admin.applicants.send_message' }),
    };
    const handleClose = React.useCallback((shouldSendMessage) => __awaiter(this, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            if (shouldSendMessage) {
                yield Api.OfferApplication.sendMessage(application.id, message, interviewTimeDate === null || interviewTimeDate === void 0 ? void 0 : interviewTimeDate.toDate(), interviewDurationMinutes);
                if (interviewTimeDate) {
                    const newApplications = produce(applications, draft => {
                        const app = draft.find(a => a.id === application.id);
                        app.interview_date = interviewTimeDate.toDate();
                        app.stage = application.stage;
                    });
                    setApplications(newApplications);
                }
                notification.success({
                    message: intl.formatMessage({
                        id: 'admin.applicants.send_message.successuly_sent_notification.title',
                    }),
                    description: intl.formatMessage({
                        id: 'admin.applicants.send_message.successuly_sent_notification.message',
                    }, { count: 1 }),
                });
            }
            onClose();
        }
        catch (err) {
            console.error(err);
            notification.error({
                message: intl.formatMessage({
                    id: 'forms.offer_notification_subscription_form.error',
                    defaultMessage: 'Chyba',
                }),
                description: intl.formatMessage({
                    id: 'forms.offer_notification_subscription_form.something_went_wrong',
                    defaultMessage: 'Niekde nastala chyba.',
                }),
            });
        }
        finally {
            setIsLoading(false);
        }
    }), [application, applications, interviewTimeDate, message]);
    if (!application) {
        return null;
    }
    return (React.createElement(Modal, { title: React.createElement(React.Fragment, null,
            React.createElement("div", null, modalTitleMap[application.stage]),
            React.createElement("div", { className: "m-t-m" },
                React.createElement(Avatar, { className: "m-r-m", size: 24, fullName: [application.account.first_name, application.account.last_name] }),
                application.account.first_name,
                "\u00A0",
                application.account.last_name)), visible: Boolean(application), onOk: () => handleClose(true), confirmLoading: isLoading, onCancel: () => handleClose(false), cancelText: intl.formatMessage({ id: 'admin.applicants.send_message.do_not_send' }), okText: intl.formatMessage({ id: 'global.send' }) },
        (application === null || application === void 0 ? void 0 : application.stage) === EOfferApplicationStage.interview && (React.createElement("div", { className: "flex-col m-b-m" },
            React.createElement("label", { className: "m-b-s" },
                React.createElement(FormattedMessage, { id: "admin.applicants.send_message.interview_time" })),
            React.createElement(DatePicker, { className: "m-b-m", showTime: {
                    minuteStep: 15,
                    format: 'HH:mm',
                }, disabledDate: (current) => current && current < moment().subtract(1, 'd').endOf('day'), value: interviewTimeDate, onChange: (date) => setInterviewTimeDate(date), format: "D. M. YYYY HH:mm" }),
            React.createElement("label", { className: "m-b-s" },
                React.createElement(FormattedMessage, { id: "admin.applicants.send_message.interview_duration_minutes" })),
            React.createElement(Input, { onChange: e => setInterviewDurationMinutes(Number(e.target.value)), type: "number", value: interviewDurationMinutes, min: 10, step: 10, max: 8 * 60 }))),
        React.createElement("div", null,
            React.createElement("label", { className: "m-b-s" },
                React.createElement(FormattedMessage, { id: "admin.applicants.send_message.message" })),
            React.createElement(Input.TextArea, { rows: 5, value: message, onChange: (e) => setMessage(e.target.value) }))));
}

import React from 'react'

const IconSocialFacebook = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
		<path
			d="M114.04167 84.93333A2.91667 2.91667 0 01111.125 87.5H99.16667A2.91667 2.91667 0 0096.25 90.41667v46.66666A2.91667 2.91667 0 0099.16667 140h35A5.83333 5.83333 0 00140 134.16667V5.83333A5.83333 5.83333 0 00134.16667 0H5.83333A5.83333 5.83333 0 000 5.83333v128.33334A5.83333 5.83333 0 005.83333 140H70a2.91667 2.91667 0 002.91667-2.91667V90.41667A2.91667 2.91667 0 0070 87.5H58.33333a2.91667 2.91667 0 01-2.91666-2.91667v-17.5a2.91667 2.91667 0 012.91666-2.91666H70A2.91667 2.91667 0 0072.91667 61.25v-7.64167a33.19167 33.19167 0 0133.19166-33.19166H113.75a2.91667 2.91667 0 012.91667 2.91666v17.5A2.91667 2.91667 0 01113.75 43.75h-7.64167A9.85833 9.85833 0 0096.25 53.60833V61.25a2.91667 2.91667 0 002.91667 2.91667h14.175a2.91667 2.91667 0 012.91666 3.26666z"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="0"
		/>
	</svg>
)

export default IconSocialFacebook

import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Avatar, Badge, Button } from '@src/components/elements';
import Timestamp from '@src/components/modules/global/timestamp';
import { CheckCircleFilled } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Application, TimestampWrapper, MainBlock, ApplicationContent, CheckIconWrapper, NameWrapper, } from './ApplicationCard.style';
function BadgeWrapper({ application, children }) {
    if (application.viewed_at) {
        return children;
    }
    return (React.createElement(Badge, { offset: [7, -7], dot: true }, children));
}
export function ApplicationCard({ application, index, onOpenDetailClick, onCardSelected, isCardSelected }) {
    return (React.createElement(Draggable, { draggableId: String(application.id), index: index }, (provided, snapshot) => (React.createElement(Application, Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { 
        // @ts-ignore
        isDragging: snapshot.isDragging, isCardSelected: isCardSelected, onClick: () => {
            onCardSelected(application);
        } }),
        React.createElement(BadgeWrapper, { application: application },
            React.createElement(ApplicationContent, null,
                React.createElement(MainBlock, null,
                    React.createElement(NameWrapper, null,
                        React.createElement(Avatar, { className: "m-r-m", size: 24, fullName: [application.account.first_name, application.account.last_name] }),
                        application.account.first_name,
                        "\u00A0",
                        application.account.last_name,
                        isCardSelected && (React.createElement(CheckIconWrapper, null,
                            React.createElement(CheckCircleFilled, null)))),
                    isCardSelected && (React.createElement(CheckIconWrapper, null,
                        React.createElement(CheckCircleFilled, null))),
                    React.createElement("div", { className: "flex-row justify-space-between align-end" },
                        application.interview_date && (React.createElement(TimestampWrapper, null,
                            React.createElement("div", null,
                                React.createElement(FormattedMessage, { id: "offer_application.stage.interview" })),
                            React.createElement(Timestamp, { value: application.interview_date }))),
                        !application.interview_date && (React.createElement(TimestampWrapper, null,
                            React.createElement(Timestamp, { value: application.created_at }))),
                        React.createElement(Button, { onClick: e => {
                                e.stopPropagation();
                                onOpenDetailClick(application);
                            }, size: "small", type: "default" },
                            React.createElement(FormattedMessage, { id: "global.detail" }))))))))));
}

import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import URI from 'urijs'
import { notification } from 'antd'
import config from 'config'
import actions from '../actions'

export function* myCompanyInfoInitStart() {
	const uri = new URI(`${config.api}/0/company`)
	const apiUri = uri.toString()

	const getData = () =>
		axios
			.get(apiUri, { withCredentials: true })
			.then(res => res)
			.catch(err => {
				throw err
			})

	try {
		const response = yield call(getData)
		yield put(actions.myCompanyInfoInitSuccess(response))
	} catch (err) {
		yield put(actions.myCompanyInfoInitError(err))
	}
}

export function* myCompanyInfoInitError(err) {
	const showError = () => {
		notification.error({
			message: 'Chyba',
			description: 'Užívateľ nie je prihlásený',
		})
	}
	yield call(showError, err)
}

import { Select } from 'antd';
import styled from 'styled-components';
export const OfferApplicationsHeader = styled.div `
	margin-bottom: 2rem;
	position: relative;
	height: 5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;
export const OfferSelect = styled(Select) `
	width: 300px;
`;
export const LayoutSwitchWrapper = styled.div `
	position: absolute;
	display: flex;
	flex-direction: column;
	right: 1rem;
	bottom: 0;
	font-size: 0.75rem;

	.anticon {
		vertical-align: 1px;
	}

	& .anticon[type='three-columns'] > svg {
		stroke: white;
		fill: none;
	}

	svg {
		width: 10px;
		fill: white;
	}
`;

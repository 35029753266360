var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// @ts-nocheck
import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Col, Row } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import classNames from 'classnames';
import _get from 'lodash/get';
import { Link, Icon, Panel, Holder } from '@src/components/elements';
import config from 'config';
import axios from '@src/api/clients/axios';
import actions from 'actions';
import * as Api from 'api';
import { CopyOutlined, UserOutlined, UnlockOutlined, CreditCardOutlined, CloudDownloadOutlined, } from '@ant-design/icons';
import FormResponse from 'components/modules/global/form-response';
import { CompanyProfileBox } from '@src/components/modules/global/CompanyProfileBox';
import { isRouteActive } from '@src/shared/localized-path';
import { CompanyAccountRole } from '@src/types';
import { CreditsReplenishedDialog } from './modules/CreditsReplenishedDialog';
import { NotEnoughCreditsDialog } from './modules/NotEnoughCreditsDialog';
import { useAdminContextProvider } from './admin-context';
import UserVerificationNotice from './modules/user-verification-notice';
import { AdminAcceptationPending } from './AdminAcceptationPending';
let AdminComponent = ({ userVerified, user, company, children }) => {
    var _a, _b;
    const [isInitialized, setIsInitialized] = React.useState(false);
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const AdminContext = useAdminContextProvider();
    const location = useLocation();
    const currentRoute = history.location.pathname;
    React.useEffect(() => {
        dispatch(actions.adminToggleLoading(true));
        axios
            .get('/0/auth-check')
            .then((response) => __awaiter(void 0, void 0, void 0, function* () {
            dispatch(actions.authCheckSuccess(response));
            // TODO - this should not be here and company image/name should be stored e.g. in user info
            dispatch(actions.myCompanyInfoInitStart());
            const myOffers = yield Api.Admin.getMyOffers();
            AdminContext.value.setMyOffers(myOffers);
        }))
            .catch(() => {
            // @ts-ignore
            history.pushLocalized('/prihlasenie?ref=back');
        })
            .finally(() => {
            dispatch(actions.adminToggleLoading(false));
            setIsInitialized(true);
        });
    }, []);
    React.useEffect(() => {
        if (isInitialized && !user.email) {
            history.pushLocalized('/prihlasenie');
        }
    }, [user]);
    React.useEffect(() => {
        if (isInitialized) {
            dispatch(actions.myCompanyInfoInitStart());
        }
    }, [isInitialized, location.pathname]);
    if (!isInitialized) {
        return null;
    }
    const isCompany = _get(user, 'account_type_id', null) === config.accountType.company;
    const isAcceptationPending = ((_a = user.companyAccountRole) === null || _a === void 0 ? void 0 : _a.role) === CompanyAccountRole.acceptation_pending;
    if (isAcceptationPending) {
        return React.createElement(AdminAcceptationPending, { companyName: company.name });
    }
    return (React.createElement(AdminContext.AdminContextProvider, { value: {
            myOffers: AdminContext.value.myOffers,
            setMyOffers: AdminContext.value.setMyOffers,
        } },
        React.createElement(Holder, { marginVertical: true },
            !isCompany && (React.createElement(Row, { justify: "center" },
                React.createElement(Col, { xs: 24, md: 18 },
                    React.createElement(Panel, null,
                        React.createElement(FormResponse, { icon: {
                                color: 'stop',
                                type: 'cancel',
                            }, text: intl.formatMessage({
                                id: 'admin.access_to_section_after_login',
                                defaultMessage: 'Prístup do sekcie pre firmy je možný len po prihlásení do firemného účtu.',
                            }) }))))),
            isCompany && (React.createElement(React.Fragment, null,
                !userVerified && React.createElement(UserVerificationNotice, null),
                React.createElement(Row, { gutter: [16, 16] },
                    React.createElement(Col, { xs: 24, md: 9, lg: 6 },
                        React.createElement(CompanyProfileBox, { company: company, companyOfferCount: (_b = AdminContext.value.myOffers) === null || _b === void 0 ? void 0 : _b.active.length, email: user.email }),
                        React.createElement("ul", { className: "profile__menu" },
                            React.createElement("li", { className: classNames({
                                    'profile__menu-item': true,
                                    'profile__menu-item--plan': true,
                                    active: isRouteActive(currentRoute, '/admin/kredity'),
                                }) },
                                React.createElement(Link, { className: "profile__menu-item-link", to: "/admin/kredity" },
                                    React.createElement("span", { className: "profile__menu-item-button-text flex-center" },
                                        React.createElement(Icon, { className: "icon", type: "coin-stacks", width: 14 }),
                                        !company.availableCredits ? (React.createElement(FormattedMessage, { id: "general.credits.buy" })) : (React.createElement(React.Fragment, null,
                                            React.createElement(FormattedMessage, { id: "general.you_have" }),
                                            ' ',
                                            React.createElement(FormattedMessage, { values: { count: company.availableCredits }, id: "general.credits.pluralized" }))))))),
                        React.createElement("ul", { className: "profile__menu" },
                            React.createElement("li", { className: classNames({
                                    'profile__menu-item': true,
                                    active: isRouteActive(currentRoute, '/admin', true),
                                }) },
                                React.createElement(Link, { className: "profile__menu-item-link", to: "/admin" },
                                    React.createElement("span", { className: "profile__menu-item-button-text flex-center" },
                                        React.createElement(CopyOutlined, { className: "icon", width: 14 }),
                                        React.createElement(FormattedMessage, { id: "admin.offers", defaultMessage: "Ponuky" })))),
                            React.createElement("li", { className: classNames({
                                    'profile__menu-item': true,
                                    active: isRouteActive(currentRoute, '/admin/uchadzaci'),
                                }) },
                                React.createElement(Link, { className: "profile__menu-item-link", to: "/admin/uchadzaci" },
                                    React.createElement("span", { className: "profile__menu-item-button-text flex-center" },
                                        React.createElement(Icon, { className: "icon", type: "ranking-people", width: 14 }),
                                        React.createElement(FormattedMessage, { id: "admin.applicants", defaultMessage: "Uch\u00E1dza\u010Di" })))),
                            React.createElement("li", { className: classNames({
                                    'profile__menu-item': true,
                                    active: isRouteActive(currentRoute, '/admin/firemne-udaje'),
                                }) },
                                React.createElement(Link, { className: "profile__menu-item-link", to: "/admin/firemne-udaje" },
                                    React.createElement(Icon, { className: "icon", type: "buildings", width: 14 }),
                                    React.createElement(FormattedMessage, { id: "admin.company_details", defaultMessage: "Firemn\u00E9 \u00FAdaje" }))),
                            React.createElement("li", { className: classNames({
                                    'profile__menu-item': true,
                                    active: isRouteActive(currentRoute, '/admin/osobne-udaje'),
                                }) },
                                React.createElement(Link, { className: "profile__menu-item-link", to: "/admin/osobne-udaje" },
                                    React.createElement(UserOutlined, { className: "icon", width: 14 }),
                                    React.createElement(FormattedMessage, { id: "admin.personal_details", defaultMessage: "Osobn\u00E9 \u00FAdaje" }))),
                            React.createElement("li", { className: classNames({
                                    'profile__menu-item': true,
                                    active: isRouteActive(currentRoute, '/admin/prihlasovacie-udaje'),
                                }) },
                                React.createElement(Link, { className: "profile__menu-item-link", to: "/admin/prihlasovacie-udaje" },
                                    React.createElement(UnlockOutlined, { className: "icon", width: 14 }),
                                    React.createElement(FormattedMessage, { id: "admin.login_details", defaultMessage: "Prihlasovacie \u00FAdaje" }))),
                            React.createElement("li", { className: classNames({
                                    'profile__menu-item': true,
                                    active: isRouteActive(currentRoute, '/admin/pouzivatelia'),
                                }) },
                                React.createElement(Link, { className: "profile__menu-item-link", to: "/admin/pouzivatelia" },
                                    React.createElement(Icon, { type: "users-circle", className: "icon", width: 14 }),
                                    React.createElement(FormattedMessage, { id: "admin.users" }))),
                            React.createElement("li", { className: classNames({
                                    'profile__menu-item': true,
                                    active: isRouteActive(currentRoute, '/admin/platby'),
                                }) },
                                React.createElement(Link, { className: "profile__menu-item-link", to: "/admin/platby" },
                                    React.createElement(CreditCardOutlined, { className: "icon", width: 14 }),
                                    React.createElement(FormattedMessage, { id: "admin.payments", defaultMessage: "Platby" }))),
                            React.createElement("li", { className: classNames({
                                    'profile__menu-item': true,
                                    active: isRouteActive(currentRoute, '/admin/api'),
                                }) },
                                React.createElement(Link, { className: "profile__menu-item-link", to: "/admin/api" },
                                    React.createElement(CloudDownloadOutlined, { className: "icon", width: 14 }),
                                    React.createElement(FormattedMessage, { id: "admin.api", defaultMessage: "API" }))))),
                    React.createElement(Col, { xs: 24, md: 15, lg: 18 }, children)))),
            React.createElement(CreditsReplenishedDialog, null),
            React.createElement(NotEnoughCreditsDialog, null))));
};
AdminComponent = connect(state => ({
    user: state.app.user,
    company: state.admin.company,
    userVerified: state.app.userVerified,
}))(AdminComponent);
const Admin = AdminComponent;
export { Admin };

import { call, put } from 'redux-saga/effects'
import { history } from '@src/app/index'
import axios from 'axios'
import URI from 'urijs'
import { notification } from 'antd'
import ReactGA from 'helpers/react-ga'
import config from 'config'
import { socket } from '@src/socket'
import { SocketEvents } from '@src/types'
import actions from '../actions'

export function* sendResumeStart({ payload }) {
	const formData = new FormData()

	Object.keys(payload).forEach(key => {
		const value = payload[key]
		if (value) {
			if (key === 'newsletter') {
				formData.append(key, value === 'true')
			} else {
				formData.append(key, value)
			}
		}
	})

	const uri = new URI(`${config.api}/0/send-resume-register`)
	const apiUri = uri.toString()

	const postData = async data => {
		try {
			const response = await axios.post(apiUri, data, {
				headers: {
					'content-type': 'multipart/form-data; boundary=uzpraxujes',
				},
				withCredentials: true,
				validateStatus: status => (status >= 200 && status < 300) || status === 429,
			})
			// eslint-disable-next-line no-undef
			fbq('track', 'SubmitApplication')

			socket.emit(SocketEvents.ACCOUNT_AUTHENTICATED, response.data.data)

			if (response.status === 429) {
				notification.error({
					message: 'Ochrana proti útokom',
					description:
						'Snažíme sa ochrániť údaje užívateľov. Z toho dôvodu sme zablokovali vašu IP adresu na 30 minút.',
				})
				// eslint-disable-next-line @typescript-eslint/no-throw-literal
				throw response
			}

			return response
		} catch (err) {
			throw err
		}
	}

	try {
		const response = yield call(postData, formData)
		yield put(actions.sendResumeSuccess(response.data.data))
	} catch (err) {
		yield put(actions.sendResumeError(err))
	}
}

export function* sendResumeSuccess(action) {
	ReactGA.event({
		category: 'Student',
		action: 'Send resume',
		label: action.payload.email,
	})
	const showNotification = () => {
		notification.success({
			message: 'Dobrá správa',
			description: 'Zaslanie správy prebehlo úspešne.',
			duration: 15,
		})
	}
	history.pushLocalized('/profil')
	yield call(showNotification)
}

export function* sendResumeError() {
	const showNotification = () => {
		notification.error({
			message: 'Chyba je na našej strane.',
			description: 'Zaznamenali sme chybu a už pracujeme na oprave',
		})
	}
	yield call(showNotification)
}

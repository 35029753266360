export var AccountType;
(function (AccountType) {
    AccountType[AccountType["company"] = 1] = "company";
    AccountType[AccountType["student"] = 2] = "student";
    AccountType[AccountType["graduate"] = 3] = "graduate";
})(AccountType || (AccountType = {}));
export var CompanyAccountRole;
(function (CompanyAccountRole) {
    CompanyAccountRole["owner"] = "owner";
    CompanyAccountRole["admin"] = "admin";
    CompanyAccountRole["publisher"] = "publisher";
    CompanyAccountRole["viewer"] = "viewer";
    CompanyAccountRole["acceptation_pending"] = "acceptation_pending";
})(CompanyAccountRole || (CompanyAccountRole = {}));
export var DriverLicence;
(function (DriverLicence) {
    DriverLicence["AM"] = "AM";
    DriverLicence["A1"] = "A1";
    DriverLicence["A2"] = "A2";
    DriverLicence["A"] = "A";
    DriverLicence["B1"] = "B1";
    DriverLicence["B"] = "B";
    DriverLicence["BE"] = "BE";
    DriverLicence["C1"] = "C1";
    DriverLicence["C1E"] = "C1E";
    DriverLicence["C"] = "C";
    DriverLicence["CE"] = "CE";
    DriverLicence["D1"] = "D1";
    DriverLicence["D1E"] = "D1E";
    DriverLicence["D"] = "D";
    DriverLicence["DE"] = "DE";
    DriverLicence["T"] = "T";
})(DriverLicence || (DriverLicence = {}));

import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import MoveUp1 from '@streamlinehq/streamlinehq/img/streamline-bold/interface-essential/move/move-up-1.svg';
import { transparentize } from 'polished';
import useScrollPosition from '@react-hook/window-scroll';
import { animateScroll } from 'react-scroll';
import { down } from 'styled-breakpoints';
export const BackToTopWrapper = styled.button `
	padding: 0;
	margin: 0;
	border: 0;
	display: flex;
	width: 53px;
	height: 53px;
	position: fixed;
	bottom: 24px;
	right: 11px;
	z-index: ${props => props.theme.zIndex.scrollTop};
	background: ${props => props.theme.color.white};
	color: ${props => props.theme.color.brand3};
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	box-shadow: 0 0px 0 3px ${props => transparentize(0.8, props.theme.color.gray6)}, 0 4.2px 2.4px rgba(0, 0, 0, 0.004),
		0 10.7px 6.5px rgba(0, 0, 0, 0.015), 0 21.8px 14.3px rgba(0, 0, 0, 0.029), 0 44.9px 31.6px rgba(0, 0, 0, 0.05),
		0 123px 94px rgba(0, 0, 0, 0.09);
	opacity: 0;
	transform: scale(0);
	transition: all 0.2s ease;

	${props => props.active &&
    css `
			opacity: 1;
			transform: scale(1);
		`};

	${down('sm')} {
		width: 45px;
		height: 45px;
		bottom: 14px;
	}
`;
const BackToTop = () => {
    const theme = useTheme();
    const topOffset = useScrollPosition();
    return (React.createElement(BackToTopWrapper, { active: topOffset > theme.size.headerHeight * 2, onClick: () => {
            animateScroll.scrollToTop();
            return false;
        } },
        React.createElement(MoveUp1, { width: 24, height: 24 })));
};
export { BackToTop };

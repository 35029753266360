import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import _get from 'lodash/get'
import actions from 'actions'
import OfferEditForm from './modules/offer-edit-form'
import OfferArchivedAlert from './modules/OfferArchivedAlert'

const OfferEdit = props => {
	const history = useHistory()
	const [isOfferArchivedAlertOpen, setIsOfferArchivedAlertOpen] = React.useState(false)
	const isDuplicate = history.getQuery && history.getQuery().duplicate === 'true'
	const dispatch = useDispatch()
	const { slug } = useParams()
	const handleSubmit = values => {
		if (isDuplicate) {
			dispatch(actions.offerPostStart(values))
		} else {
			dispatch(actions.offerEditStart(values))
		}
	}
	React.useEffect(() => {
		const payload = {}
		payload.slug = slug.substring(0, slug.lastIndexOf('-'))
		payload.id = slug.substring(slug.lastIndexOf('-') + 1, slug.length)
		dispatch(actions.offerEditInitStart(payload))
	}, [])

	React.useEffect(() => {
		const isAlertOpen = props.initialValues.is_active === false && !isDuplicate

		setIsOfferArchivedAlertOpen(isAlertOpen)
	}, [history, props.initialValues.is_active, isDuplicate])

	const RedirectToAdmin = React.useCallback(() => {
		setIsOfferArchivedAlertOpen(false)
		history.pushLocalized('/admin')
	}, [history])

	return (
		<div className="panel">
			<OfferEditForm
				offerId={props.offerId}
				offerTitle={props.offerTitle}
				onSubmit={handleSubmit}
				initialValues={props.initialValues}
			/>
			{isOfferArchivedAlertOpen && (
				<OfferArchivedAlert
					isOpen={isOfferArchivedAlertOpen}
					onConfirm={RedirectToAdmin}
					onCancel={RedirectToAdmin}
					setIsOpen={setIsOfferArchivedAlertOpen}
				/>
			)}
		</div>
	)
}

const mapStateToProps = state => ({
	offerId: _get(state, 'offerEdit.offerEditInit.id'),
	offerTitle: _get(state, 'offerEdit.offerEditInit.title'),
	initialValues: {
		...state.offerEdit.offerEditInit,
		home_office: Boolean(_get(state, 'offerEdit.offerEditInit.state', []).find(s => s.id === 9)),
	},
})

export default connect(mapStateToProps)(OfferEdit)

const validate = (values, props) => {
	const isUserRegisteredOAuth = Boolean(props.initialValues.facebook_user_id) || Boolean(props.initialValues.google_user_id);
	const errors = {};
	const { intl } = props;

	if (!values.first_name) {
		errors.first_name = intl.formatMessage({
			id: "profile.sync_validation.fill_name",
			defaultMessage: 'Vyplňte meno',
		});
	} else if (/[0-9_~`!@#$%^&*(),.;<>?]/.test(values.first_name)) {
		errors.first_name = intl.formatMessage({
			id: "profile.sync_validation.data_with_forbidden_chars",
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		});
	}

	if (!values.last_name) {
		errors.last_name = intl.formatMessage({
			id: "profile.sync_validation.fill_surename",
			defaultMessage: 'Vyplňte priezvisko',
		});
	} else if (/[0-9_~`!@#$%^&*(),.;<>?]/.test(values.last_name)) {
		errors.last_name = intl.formatMessage({
			id: "profile.sync_validation.data_with_forbidden_chars",
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		});
	}

	if (!values.email) {
		errors.email = intl.formatMessage({
			id: "profile.sync_validation.fill_email",
			defaultMessage: 'Vyplňte pole s emailovou adresou',
		});
	} else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
		errors.email = intl.formatMessage({
			id: "profile.sync_validation.fill_correct_email",
			defaultMessage: 'Zadajte validný email',
		});
	}

	if (values.password && values.password.length < 8) {
		errors.password = intl.formatMessage({
			id: "profile.sync_validation.password_is_short",
			defaultMessage: 'Heslo musí byť dlhé aspoň 8 znakov',
		});
	} else if (values.password && !/^.*(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/.test(values.password)) {
		errors.password = intl.formatMessage({
			id: "profile.sync_validation.password_must_contain",
			defaultMessage: 'Heslo musí obsahovať aspoň 1 malé, 1 veľké písmeno a 1 číslo',
		});
	}

	if (values.passwordRepeat !== values.password) {
		errors.passwordRepeat = intl.formatMessage({
			id: "profile.sync_validation.passwords_doesnt_match",
			defaultMessage: 'Heslá sa nezhodujú',
		});
	}

	if (!isUserRegisteredOAuth && !values.currentPassword) {
		errors.currentPassword = intl.formatMessage({
			id: "profile.sync_validation.for_saving_changes",
			defaultMessage: 'Pre vykonanie zmeny vyplňte prosím Vaše aktuálne heslo',
		});
	}

	if (!values.job_area || values.job_area.length === 0) {
		errors.job_area = intl.formatMessage({
			id: "profile.sync_validationplease_fill_one_job_area",
			defaultMessage: 'Prosím, vyberte aspoň 1 pracovné zameranie',
		});
	}

	return errors;
};

export default validate;

import React from 'react';
import {
	reduxForm,
	Field,
} from 'redux-form';
import { useIntl, injectIntl } from 'react-intl';
// field components
import showError from 'components/modules/forms/showError';
import renderField from 'components/modules/forms/renderField';
import validate from '../validation/sync-validation';
import Button from '../../../modules/global/button';

let ResetPasswordFormComponent = (props) => {
	const {
		handleSubmit,
	} = props;
	const intl = useIntl();
	return (
		<form
			onSubmit={handleSubmit}
			className="form form--reset-password-email"
		>
			<Field
				component={renderField}
				type="password"
				name="password"
				label= { intl.formatMessage({
					id: "reset_password.reset_password.password",
					defaultMessage: "Nové heslo"
				})}
			/>
			<Field
				component={renderField}
				type="password"
				name="passwordRepeat"
				label= { intl.formatMessage({
					id: "reset_password.reset_password.passwordRepeat",
					defaultMessage: "Overenie hesla"
				})}
			/>
			<div className="form__submit">
				<Button
					type="submit"
					color="brand"
					text= { intl.formatMessage({
						id: "reset_password.reset_password.password_recovery",
						defaultMessage: "Obnoviť heslo k účtu"
					})}	
					disabled={props.submitting}
				/>
			</div>
		</form>
	);
};

ResetPasswordFormComponent = reduxForm({
	form: 'resetPasswordForm',
	destroyOnUnmount: false,
	validate,
	onSubmitFail: showError,
})(ResetPasswordFormComponent);

const ResetPasswordForm = ResetPasswordFormComponent;


export default injectIntl(ResetPasswordForm);

const validate = (values, { intl }) => {
	const errors = {};

	if (!values.email) {
		errors.email = intl.formatMessage({
			id: "reset_password_email.sync_validation.email",
			defaultMessage: "Vyplňte pole s emailovou adresou"
		});
	} else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
		errors.email = intl.formatMessage({
			id: "reset_password_email.sync_validation.invalid_email",
			defaultMessage: "Zadajte validný email"
		});
	}

	return errors;
};

export default validate;

import actionNames from 'actions';
import config from 'config';
import cloneState from 'reducers/helpers';

const defaultState = {
	plans: null,
	monthCycle: 1,
};

export default function Company(state = defaultState, action) {
	switch (action.type) {
	case actionNames.getPlansSuccess().type:
		return cloneState(state, {
			plans: {
				free: action.payload.filter(item => item.id === config.plan.free)[0],
				startup: {
					1: action.payload.filter(item => item.id === config.plan.startup[1])[0],
					12: action.payload.filter(item => item.id === config.plan.startup[12])[0],
				},
				profi: {
					1: action.payload.filter(item => item.id === config.plan.profi[1])[0],
					12: action.payload.filter(item => item.id === config.plan.profi[12])[0],
				},
				corporate: {
					1: action.payload.filter(item => item.id === config.plan.corporate[1])[0],
					12: action.payload.filter(item => item.id === config.plan.corporate[12])[0],
				},
			},
		});


	case actionNames.companySetCycle().type:
		return cloneState(state, {
			monthCycle: action.payload,
		});

	default:
		return state;
	}
}

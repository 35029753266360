import React from 'react';
import { Link, Button } from '@src/components/elements';
import { Form, Input } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Recaptcha from 'react-google-recaptcha';
import { FacebookLoginButton } from '@src/components/elements/FacebookLoginButton';
import { GoogleLoginButton } from '@src/components/elements/GoogleLoginButton';
import { OAuthType } from '@src/types/auth';
import { useRoute } from '@src/hooks/use-route';
const LoginForm = props => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const route = useRoute();
    const shouldStayOnPage = (route === null || route === void 0 ? void 0 : route.id) !== '/prihlasenie';
    const { onSubmit, loginError, onOAuthSuccess } = props;
    return (React.createElement("div", { id: "login-form" },
        loginError && (React.createElement("div", { className: "form__errors" },
            React.createElement("p", { className: "form__errors-item" },
                React.createElement(FormattedMessage, { id: "login_form.wrong_email_or_password", defaultMessage: "Nespr\u00E1vny email alebo heslo" })))),
        React.createElement(Form, { onFinish: onSubmit, form: form, layout: "vertical", className: "form form--login" },
            React.createElement(Form.Item, { rules: [
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'login_form.sync_validation.email',
                            defaultMessage: 'Vyplňte pole s emailovou adresou',
                        }),
                    },
                    {
                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: intl.formatMessage({
                            id: 'login_form.sync_validation.invalid_email',
                            defaultMessage: 'Zadajte validný email',
                        }),
                    },
                ], name: "email", label: intl.formatMessage({
                    id: 'login_form.email',
                    defaultMessage: 'Email',
                }) },
                React.createElement(Input, { type: "email", placeholder: "name@email.com" })),
            React.createElement(Form.Item, { rules: [
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'login_form.sync_validation.password',
                            defaultMessage: 'Vyplňte pole s heslom',
                        }),
                    },
                ], name: "password", label: intl.formatMessage({
                    id: 'login_form.password',
                    defaultMessage: 'Heslo',
                }) },
                React.createElement(Input, { type: "password", placeholder: "password" })),
            props.loginErrorCount >= 5 && (React.createElement(Form.Item, { name: "recaptcha", rules: [
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'login_form.not_robot',
                            defaultMessage: 'Potvrďte, že nie ste robot',
                        }),
                    },
                ] },
                React.createElement(Recaptcha, { sitekey: process.env.RECAPTCHA_SITE_KEY }))),
            React.createElement("div", { className: "flex-row justify-space-between" },
                React.createElement(Link, { className: "link link--subtle", to: "/reset-hesla-email", "data-cy": "password-reset-button" },
                    React.createElement(FormattedMessage, { id: "login_form.forgot_password", defaultMessage: "Zabudli ste heslo?" })),
                props.showRegistrationButton && (React.createElement(Link, { className: "link link--subtle", to: "/registracia" },
                    React.createElement(FormattedMessage, { id: "login.registration", defaultMessage: "Registr\u00E1cia" })))),
            React.createElement("div", { className: "form__submit" },
                React.createElement("div", { className: "flex-col" },
                    React.createElement("div", { className: "m-b-l" },
                        React.createElement(Button, { type: "primary", htmlType: "submit", size: "middle", "data-cy": "login-button" },
                            React.createElement(FormattedMessage, { id: "login_form.login", defaultMessage: "Prihl\u00E1si\u0165 sa" }))),
                    React.createElement("div", { className: "flex-col" },
                        React.createElement(FacebookLoginButton, { type: OAuthType.login }),
                        React.createElement(GoogleLoginButton, { type: OAuthType.login, shouldStayOnPage: shouldStayOnPage, onLoginSuccess: onOAuthSuccess })))))));
};
export default LoginForm;

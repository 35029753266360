import React from 'react'
import { Document, Page } from 'react-pdf'
import { FileTextOutlined, DownloadOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import { Col, Row } from 'antd'

const PDFPreview = props => {
	const { documentId, documentUrl, documentName } = props

	return (
		<div
			className="pdf-preview"
			onMouseOver={() => {
				document.getElementById(documentId).classList.add('pdf-preview__document--active')
			}}
			onMouseOut={() => {
				document.getElementById(documentId).classList.remove('pdf-preview__document--active')
			}}
		>
			<Document
				file={documentUrl}
				error={<FileTextOutlined className="pdf-preview__document__icon" />}
				loading={<FileTextOutlined className="pdf-preview__document__icon" />}
			>
				<Page pageNumber={1} />
			</Document>
			<div className="pdf-preview__document-name">{documentName}</div>
			<a
				id={documentId}
				className="pdf-preview__document__download"
				href={documentUrl}
				rel="noopener noreferrer"
				target="_blank"
			>
				<span className="pdf-preview__document__download__name">{documentName}</span>
				<span>
					<FormattedMessage id="global.pdf_preview.download" defaultMessage="Stiahnuť" />
				</span>
				<DownloadOutlined className="pdf-preview__document__icon" />
			</a>
		</div>
	)
}

export default PDFPreview

// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled, { useTheme } from 'styled-components';
import { transparentize } from 'polished';
import { up } from 'styled-breakpoints';
import { Link, SliderCredits, Button, Switch } from '@src/components/elements';
import { getCreditPricing } from '@src/shared/get-credit-pricing';
import config from '@src/config';
export const ButtonFreeText = styled.div ``;
export const Box = styled.div `
	width: 100%;
	min-height: 300px;
	position: relative;
	background: ${props => props.theme.color.brand1};
	color: ${props => props.theme.color.white};
	border-radius: 0 0 4px 4px;
	box-shadow: 0 15px 35px rgb(50 50 66 / 30%), 0 5px 15px rgb(0 0 0 / 7%);
	overflow: hidden;

	${up('md')} {
		border-radius: 4px;
		width: 50%;
	}
`;
export const BoxHead = styled.div `
	padding: 1.5em 3em 1.2em;
	text-align: center;
	background: ${props => transparentize(0.7, props.theme.color.brand4)};

	font-weight: 400;
	font-size: 1.3em;
	color: ${props => props.theme.color.white};

	strong {
		font-weight: 700;
		text-transform: uppercase;
		color: ${props => props.theme.color.gray2};
	}
`;
export const CreditsIntroduction = styled.div `
	text-align: center;
	color: ${props => transparentize(0.2, props.theme.color.white)};
	font-size: 1.2em;
	padding: 2em 3em 2em;

	small {
		color: ${props => transparentize(0.5, props.theme.color.white)};
		font-size: 0.8em;
	}
`;
export const AboveCredits = styled.div `
	text-align: center;
	color: ${props => transparentize(0.6, props.theme.color.white)};
	font-weight: 700;
	padding: 0 3em 0.5em;
`;
export const SmallTextBox = styled.div `
	text-align: center;
	color: ${props => transparentize(0.1, props.theme.color.white)};
	font-size: 1em;
	padding: 0 2em 1em;

	small {
		color: ${props => transparentize(0.6, props.theme.color.white)};
		font-size: 0.9em;
	}
`;
export const BoxChosen = styled.div `
	padding: 0 1em 0.1em;
	text-align: center;
	font-size: 3em;
	font-weight: 700;
`;
export const SliderWrapper = styled.div `
	padding: 1.1em 5em 1.5em;
	text-align: center;
`;
export const BoxTop = styled.div ``;
export const BoxBottom = styled.div ``;
export const Block = styled.div `
	width: 100%;
`;
export const ContactSales = styled.div `
	width: 100%;
	padding: 2em 3em 1.8em;
	text-align: center;
	color: ${props => transparentize(0.3, props.theme.color.white)};
	background: ${props => transparentize(0.85, props.theme.color.black)};
`;
export const FreeCredit = styled.div `
	width: 100%;
	padding: 2em 0;
	text-align: center;
`;
export const FreeCreditFeatures = styled.div `
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 2em 3em 1.5em;
	border-top: 1px solid ${props => props.theme.color.gray2};

	ul {
		width: 400px;
		max-width: 100%;
		margin: 0 auto;
		padding: 0 0 0 2.5em;
		color: ${props => props.theme.color.gray6};
		font-size: 1.2em;
	}
`;
export const Introduction = styled.div `
	text-align: center;
	color: ${props => props.theme.color.gray6};
	font-size: 1.2em;
	padding: 2em 2em 0;
`;
export const MoreDetials = styled.a `
	margin: 1.5em auto 0;
	display: inline-block;
	text-decoration: underline;
	color: ${props => props.theme.color.gray5};
	font-size: 0.9em;
`;
export const CreditsExplanation = styled.div `
	margin: 1.5em auto 0;
	display: block;
	text-align: center;
	color: ${props => props.theme.color.gray5};
	font-size: 0.9em;
`;
export const PricingCalculation = styled.div `
	display: flex;
	justify-content: center;
	padding: 0;
`;
export const PricingCalculationBox = styled.div `
	display: flex;
	width: 50%;
	justify-content: center;

	dl {
		margin: 0;
	}
	dt {
		color: ${props => transparentize(0.6, props.theme.color.white)};
		font-size: 0.9em;
	}
	dd {
		color: ${props => props.theme.color.white};
		font-size: 2em;
	}
`;
export const PricingTotal = styled.div `
	border-top: 1px solid ${props => transparentize(0.85, props.theme.color.white)};
	padding: 1.5em 0 0;
	width: 100%;
	justify-content: center;
`;
export const VATSelector = styled.div `
	text-align: center;
	color: ${props => transparentize(0.6, props.theme.color.white)};
	font-size: 0.9em;
	padding: 2em 3em 2em;
	display: flex;
	justify-content: center;
	align-items: center;
	& > * + * {
		margin-left: 0.7em;
	}
`;
export const ContactLink = styled(Link) `
	display: block;
	margin: 0.5em auto 0;
	text-decoration: underline;
	&:link,
	&:visited,
	&:focus {
		color: ${props => props.theme.color.white};
	}
`;
const BoxCredits = () => {
    const theme = useTheme();
    const [withVAT, setWithVAT] = useState(false);
    const [creditCount, setCreditCount] = useState(10);
    const [creditPricing, setCreditPricing] = useState(null);
    useEffect(() => {
        setCreditPricing(getCreditPricing(creditCount));
    }, [creditCount]);
    return (React.createElement(Box, null,
        React.createElement(BoxHead, null,
            React.createElement(FormattedMessage, { id: "companies.credits.headline" })),
        React.createElement(CreditsIntroduction, null,
            React.createElement(FormattedMessage, { id: "companies.credits.bulk_1" }),
            React.createElement("br", null),
            React.createElement("small", null,
                React.createElement(FormattedMessage, { id: "companies.credits.bulk_2" }))),
        React.createElement(AboveCredits, null,
            React.createElement(FormattedMessage, { id: "companies.credits.want_order" })),
        React.createElement(BoxChosen, null,
            React.createElement(FormattedMessage, { values: { count: creditCount }, id: "general.credits.pluralized" })),
        React.createElement(SliderWrapper, null,
            React.createElement(SliderCredits, { value: creditCount, onChange: value => setCreditCount(value), dark: true })),
        creditPricing && (React.createElement(Introduction, null,
            React.createElement(PricingCalculation, null,
                React.createElement(PricingCalculationBox, null,
                    React.createElement("dl", null,
                        React.createElement("dt", null,
                            React.createElement(FormattedMessage, { id: "companies.credits.price_single" })),
                        React.createElement("dd", null,
                            React.createElement(FormattedNumber, { value: withVAT ? creditPricing.creditPrice : creditPricing.creditPriceWithoutVAT, style: "currency", currency: "EUR" })))),
                creditPricing.discountRelative > 0 && (React.createElement(PricingCalculationBox, null,
                    React.createElement("dl", null,
                        React.createElement("dt", null,
                            React.createElement(FormattedMessage, { id: "companies.credits.your_discount" })),
                        React.createElement("dd", null,
                            "~",
                            React.createElement(FormattedNumber, { value: creditPricing.discountRelative, style: "unit", unit: "percent" })))))),
            React.createElement(PricingTotal, null,
                React.createElement(SmallTextBox, null,
                    React.createElement(FormattedMessage, { id: "companies.credits.total" }),
                    ' ',
                    React.createElement(FormattedNumber, { value: withVAT ? creditPricing.totalPrice : creditPricing.totalPriceWithoutVAT, style: "currency", currency: "EUR" }),
                    creditPricing.discountRelative > 0 && (React.createElement("small", null,
                        React.createElement("br", null),
                        React.createElement(FormattedMessage, { id: "companies.credits.you_save" }),
                        ' ',
                        React.createElement(FormattedNumber, { value: withVAT ? creditPricing.discount : creditPricing.discountWithoutVAT, style: "currency", currency: "EUR" }),
                        ' ',
                        "(",
                        React.createElement(FormattedMessage, { id: "companies.credits.price_without_discount" }),
                        ' ',
                        React.createElement(FormattedNumber, { value: withVAT
                                ? creditPricing.originalPrice
                                : creditPricing.originalPriceWithoutVAT, style: "currency", currency: "EUR" }),
                        ")"))),
                React.createElement(Button, { style: {
                        background: theme.color.success,
                        borderColor: theme.color.success,
                        color: theme.color.white,
                    }, link: `/admin/objednavka/${creditCount}` },
                    React.createElement(ButtonFreeText, null,
                        React.createElement(FormattedMessage, { id: "companies.credits.order" }),
                        ' ',
                        React.createElement(FormattedMessage, { values: { count: creditCount }, id: "general.credits.pluralized" })))),
            React.createElement(VATSelector, null,
                React.createElement("span", null,
                    React.createElement(FormattedMessage, { id: "companies.credits.show_prices_with_vat" }),
                    ' ',
                    React.createElement(FormattedNumber, { value: config.pricing.VAT, style: "unit", unit: "percent" })),
                React.createElement(Switch, { checked: withVAT, onChange: checked => setWithVAT(checked) })))),
        React.createElement(ContactSales, null,
            React.createElement(FormattedMessage, { id: "companies.credits.want_more" }),
            React.createElement(ContactLink, { to: "/kontakt" },
                React.createElement(FormattedMessage, { id: "companies.credits.contact_us" })))));
};
export { BoxCredits };

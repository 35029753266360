import React from 'react'
import Select from 'react-select'
import { Col, Row } from 'antd'

const renderSelect = ({
	intl,
	input,
	options,
	isLoading,
	onClick,
	onInputChange,
	label,
	disabled,
	meta: { touched, error },
}) => {
	const showError = Boolean(touched) && Boolean(error)
	return (
		<div className="form__row">
			<Row>
				<Col xs={24}>
					<label className="form__label" htmlFor={input.name}>
						{label}
					</label>
					<Select
						id={input.name}
						className={`col registration__name ${showError ? 'registration__name--error' : ''}`}
						{...input}
						isLoading={isLoading}
						onChange={value => {
							input.onChange(value ? value.label : null)
							if (value && (value.cin || value.label)) {
								onClick({
									value: value.cin || value.label,
									valueKey: value.cin ? 'cin' : 'name',
								})
							}
							document.activeElement.blur()
						}}
						onBlurResetsInput={false}
						onCloseResetsInput={false}
						disabled={disabled}
						options={options}
						onInputChange={value => {
							input.onChange(value)
							if (value.length > 0) {
								onInputChange(value)
							}
						}}
						valueKey="label"
						placeholder={intl.formatMessage({
							id: 'forms.offer_notification_subscription_form.render_select.write',
							defaultMessage: 'Začnite písať...',
						})}
						noResultsText={false}
					/>
					{showError && <div className="form__input-error">{error}</div>}
				</Col>
			</Row>
		</div>
	)
}

export default renderSelect

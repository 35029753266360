import React from 'react';
import { Panel, Holder, Button } from '@src/components/elements';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
const StyledPanel = styled(Panel) `
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 2rem;
	max-width: 600px;
`;
export function AdminAcceptationPending({ companyName }) {
    return (React.createElement(Holder, null,
        React.createElement("div", { className: "flex-center" },
            React.createElement(StyledPanel, { className: "flex-col align-center m-b-l" },
                React.createElement("h1", { className: "m-b-l" },
                    React.createElement(FormattedMessage, { id: "admin.users.registration_awaits_approval" })),
                React.createElement("img", { src: "/media/images/icon-app-performance.svg", alt: "" }),
                React.createElement("div", { className: "font-size-m m-t-l m-b-l" },
                    React.createElement(FormattedMessage, { id: "admin.users.administrators_has_to_accept", values: {
                            companyName,
                            strong: chunks => React.createElement("strong", null, chunks),
                        } })),
                React.createElement(Button, { link: "/kontakt" },
                    React.createElement(FormattedMessage, { id: "admin.users.go_to_contact" }))))));
}

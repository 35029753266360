import axios from 'axios'
import { notification } from 'antd'
import config from 'config'

const asyncValidate = (values, _, { intl }) =>
	new Promise((resolve, reject) =>
		axios
			.get(`${config.api}/0/account?email=${encodeURIComponent(values.email)}`, { withCredentials: true })
			.then(() => {
				notification.warning({
					message: intl.formatMessage({
						id: 'registration_student.async_validation.email_taken',
						defaultMessage: 'Email je už obsadený',
					}),
					description: intl.formatMessage({
						id: 'registration_student.async_validation.email_used',
						defaultMessage: 'Účet pod týmto emailom už existuje.',
					}),
				})
				// eslint-disable-next-line prefer-promise-reject-errors
				reject({
					email: intl.formatMessage({
						id: 'registration_student.async_validation.email_taken',
						defaultMessage: 'Email je už obsadený',
					}),
				})
			})
			.catch(() => {
				resolve()
			})
	)

export default asyncValidate

const getCmsAsset = (asset, size = 'preview') => {
    if (!asset) {
        return '/media/images/placeholder.png';
    }
    if (size === 'original') {
        return `${process.env.CMS_URL}/storage/uploads${asset.path}`;
    }
    return `${process.env.CMS_URL}/storage/uploads${asset.sizes[size].path}`;
};
export default getCmsAsset;

import actionNames from '../actions'

const defaultState = {
	cookieConsent: true,
	mobileMenuOpen: false,
	userLoggedIn: false,
	userVerified: true,
	user: {
		email: null,
	},
	image: null,
	loading: false,
}

export default function app(state = defaultState, action) {
	switch (action.type) {
		case actionNames.authCheckError().type:
			return {
				...state,
				user: {
					email: null,
				},
				userVerified: true,
			}

		case actionNames.sendResumeSuccess().type:
			return {
				...state,
				user: {
					...state.user,
					...action.payload,
				},
			}

		case actionNames.cookieConsentSuccess().type:
			return { ...state, cookieConsent: true }

		case actionNames.cookieConsentError().type:
			return { ...state, cookieConsent: false }

		case actionNames.mobileMenuOpen().type:
			return { ...state, mobileMenuOpen: true }

		case actionNames.mobileMenuClose().type:
			return { ...state, mobileMenuOpen: false }

		case actionNames.authCheckSuccess().type:
			return {
				...state,
				user: action.payload.data,
				userLoggedIn: true,
				userVerified: action.payload.data.verified,
			}

		case actionNames.loginSuccess().type:
			return { ...state, user: action.payload.user, userLoggedIn: true }

		case actionNames.loginInfoEditSuccess().type:
			return {
				...state,
				user: {
					...state.user,
					email: action.payload.email,
				},
			}

		case actionNames.logoutSuccess().type:
			return {
				...state,
				user: {
					email: null,
					type: null,
				},
				userLoggedIn: false,
			}

		case actionNames.userVerificationSuccess().type:
			return { ...state, userVerified: true }

		case actionNames.loadMyResumesSuccess().type:
			return {
				...state,
				user: {
					...state.user,
					CVs: action.payload,
				},
			}

		default:
			return state
	}
}

/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react'
import { connect, useSelector } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import showError from 'components/modules/forms/showError'
import renderField from 'components/modules/forms/renderField'
import inputFieldUrl from 'components/modules/forms/input-field/input-field-url'
import renderUpload from 'components/modules/forms/renderUpload'
import renderRichTextEditor from 'components/modules/forms/renderRichTextEditor'
import { useConfig } from '@src/hooks/useConfig'
import { Button } from '@src/components/elements'
import { Alert, notification, Col, Row } from 'antd'
import validate from '../validation/sync-validation'
import asyncValidate from '../validation/async-validation'

let CompanyInfoEditFormComponent = props => {
	const { handleSubmit, change } = props
	const intl = useIntl()
	const config = useConfig()
	const { instagram_url, twitter_url, facebook_url, linkedin_url } = useSelector(
		state => state.form.CompanyInfo.values
	)

	const checkImageSize = (e, imageSizeMb) => {
		const isImageSizeInBounds = e.size / 1024 / 1024 < imageSizeMb
		if (!isImageSizeInBounds) {
			notification.error({
				message: intl.formatMessage({
					id: 'forms.offer_notification_subscription_form.render_dropzone.notification',
					defaultMessage: 'Upozornenie',
				}),
				description: intl.formatMessage({
					id: 'forms.offer_notification_subscription_form.render_dropzone.max_img_size',
					defaultMessage: 'Maximálne veľkosť obrázku je 1 MB',
				}),
			})
		}
		return isImageSizeInBounds
	}

	React.useEffect(() => {
		if (instagram_url && instagram_url.includes(config.socialBaseUrl.instagram)) {
			change('instagram_url', instagram_url.replace(config.socialBaseUrl.instagram, ''))
		}
		if (twitter_url && twitter_url.includes(config.socialBaseUrl.twitter)) {
			change('twitter_url', twitter_url.replace(config.socialBaseUrl.twitter, ''))
		}
		if (facebook_url && facebook_url.includes(config.socialBaseUrl.facebook)) {
			change('facebook_url', facebook_url.replace(config.socialBaseUrl.facebook, ''))
		}
		if (linkedin_url && linkedin_url.includes(config.socialBaseUrl.linkedin)) {
			change('linkedin_url', linkedin_url.replace(config.socialBaseUrl.linkedin, ''))
		}
	}, [instagram_url, twitter_url, facebook_url, linkedin_url])

	return (
		<form className="form" onSubmit={handleSubmit}>
			<div className="form__row">
				<div className="flex-center">
					<Alert
						message={intl.formatMessage({
							id: 'registration.registration_form.alert',
							defaultMessage: 'Tieto údaje budú zobrazené na stánke Profil firmy.',
						})}
						showIcon
						type="info"
						className="m-b-l form_alert"
						banner
					/>
				</div>
				<Row gutter={[16, 16]}>
					<Col xs={24} md={12}>
						<Field
							component={renderUpload}
							accept="image/*"
							label={intl.formatMessage({
								id: 'registration.registration_form.image',
								defaultMessage: 'Logo spoločnosti',
							})}
							name="image"
							beforeUpload={e => checkImageSize(e, 1)}
						/>
					</Col>
					<Col xs={24} md={12}>
						<Field
							component={renderUpload}
							accept="image/*"
							aspect={6 / 1}
							label={intl.formatMessage({
								id: 'registration.registration_form.title_image',
								defaultMessage: 'Titulný obrázok spoločnosti',
							})}
							name="title_image_url"
							beforeUpload={e => checkImageSize(e, 2)}
						/>
					</Col>
				</Row>
			</div>
			<Row gutter={[16, 16]}>
				<Col xs={24}>
					<Field
						component={renderField}
						name="name"
						label={intl.formatMessage({
							id: 'admin.company_info_edit_form.emplyer_name',
							defaultMessage: 'Názov zamestnávateľa *',
						})}
						type="text"
					/>
				</Col>
			</Row>
			<div className="form__row">
				<Row gutter={[16, 16]}>
					<Col xs={24} md={12}>
						<Field
							component={renderField}
							name="formatted_address"
							label={intl.formatMessage({
								id: 'admin.company_info_edit_form.adress',
								defaultMessage: 'Adresa *',
							})}
							type="text"
						/>
						<Field
							component={renderField}
							name="city"
							label={intl.formatMessage({
								id: 'admin.company_info_edit_form.city',
								defaultMessage: 'Mesto *',
							})}
							type="text"
						/>
						<Row gutter={[16, 16]}>
							<Col xs={24} md={12}>
								<Field
									component={renderField}
									name="postal_code"
									label={intl.formatMessage({
										id: 'admin.company_info_edit_form.psc',
										defaultMessage: 'PSČ *',
									})}
									type="text"
								/>
							</Col>
						</Row>
					</Col>
					<Col xs={24} md={12}>
						<Field
							component={renderField}
							name="cin"
							label={intl.formatMessage({
								id: 'admin.company_info_edit_form.ico',
								defaultMessage: 'IČO',
							})}
							type="text"
						/>
						<Field
							component={renderField}
							name="tin"
							label={intl.formatMessage({
								id: 'admin.company_info_edit_form.dic',
								defaultMessage: 'DIČ',
							})}
							type="text"
						/>
						<Field
							component={renderField}
							name="tin_vat"
							label={intl.formatMessage({
								id: 'admin.company_info_edit_form.ic_dph',
								defaultMessage: 'IČ DPH',
							})}
							type="text"
						/>
					</Col>
				</Row>
			</div>
			<div className="form__row">
				<Row gutter={[16, 16]}>
					<Col xs={24}>
						<Field
							component={renderRichTextEditor}
							name="description"
							label={intl.formatMessage({
								id: 'admin.company_info_edit_form.about_company',
								defaultMessage: 'Popis spoločnosti *',
							})}
						/>
					</Col>
				</Row>
			</div>
			<div className="form__row">
				<Row gutter={[16, 16]}>
					<Col xs={24} md={12}>
						<Field
							component={inputFieldUrl}
							name="facebook_url"
							label={intl.formatMessage({
								id: 'admin.company_info_edit_form.company_facebook_page',
								defaultMessage: 'Adresa na Facebook',
							})}
							type="text"
							addonBefore={config.socialBaseUrl.facebook}
						/>

						<Field
							component={inputFieldUrl}
							name="twitter_url"
							label={intl.formatMessage({
								id: 'admin.company_info_edit_form.company_twitter_page',
								defaultMessage: 'Adresa na Twitter',
							})}
							type="text"
							addonBefore={config.socialBaseUrl.twitter}
						/>

						<Field
							component={inputFieldUrl}
							name="instagram_url"
							label={intl.formatMessage({
								id: 'admin.company_info_edit_form.company_instagram_page',
								defaultMessage: 'Adresa na Instagram',
							})}
							type="text"
							addonBefore={config.socialBaseUrl.instagram}
						/>
						<Field
							component={inputFieldUrl}
							name="linkedin_url"
							label={intl.formatMessage({
								id: 'admin.company_info_edit_form.company_linkedin_page',
								defaultMessage: 'Adresa na LinkedIn',
							})}
							type="text"
							addonBefore={config.socialBaseUrl.linkedin}
						/>
					</Col>
					<Col xs={24} md={12}>
						<Field
							component={inputFieldUrl}
							name="company_url"
							label={intl.formatMessage({
								id: 'registration.registration_form.company_url',
								defaultMessage: 'Url firemného profilu',
							})}
							type="text"
							addonBefore={intl.formatMessage({
								id: 'registration.registration_form.company_url_host',
								defaultMessage: 'https://praxuj.sk/firma/',
							})}
							placeholder="meno-firmy"
						/>
						<Field
							component={renderField}
							name="area"
							label={intl.formatMessage({
								id: 'admin.company_info_edit_form.operation_area',
								defaultMessage: 'Oblasť pôsobenia *',
							})}
							type="text"
						/>
						<Field
							component={renderField}
							name="website"
							label={intl.formatMessage({
								id: 'admin.company_info_edit_form.company_web_page',
								defaultMessage: 'Webová stránka',
							})}
							type="text"
						/>

						<Field
							component={renderField}
							name="employees"
							label={intl.formatMessage({
								id: 'admin.company_info_edit_form.number_of_employee',
								defaultMessage: 'Približný počet zamestnancov *',
							})}
							type="text"
						/>
					</Col>
				</Row>
			</div>
			<div className="form__submit">
				<Button htmlType="submit" type="primary" size="large" loading={props.submitting}>
					{intl.formatMessage({
						id: 'admin.company_info_edit_form.save_changes',
						defaultMessage: 'Uložiť zmeny',
					})}
				</Button>
			</div>
			<div className="bottom-line">
				<FormattedMessage
					id="admin.company_info_edit_form.mandatory_fields"
					defaultMessage="* Polia označené hviezdičkou sú povinné"
				/>
			</div>
		</form>
	)
}

CompanyInfoEditFormComponent = reduxForm({
	form: 'CompanyInfo',
	destroyOnUnmount: true,
	enableReinitialize: true,
	onSubmitFail: showError,
	validate,
	asyncValidate,
	asyncBlurFields: ['email'],
})(CompanyInfoEditFormComponent)

CompanyInfoEditFormComponent = injectIntl(
	connect(state => ({
		initialValues: state.admin.company,
	}))(CompanyInfoEditFormComponent)
)

// exporting const because of no-mutable-exports
const CompanyInfoEditForm = CompanyInfoEditFormComponent

export default CompanyInfoEditForm

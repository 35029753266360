var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from './clients/axios';
export function getMyOffers() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get('/0/offer/my-offers');
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function patchOfferTitle(id, value) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.patch(`/0/offer/${id}/title`, { title: value });
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function getOfferApplications(id) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`/0/offer/${id}/offer-applications`);
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function buyOfferCredits(creditsCount, savePaymentMethod, nonce, deviceData) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.post(`/0/company/buy-offer-credits`, {
                creditsCount,
                nonce,
                savePaymentMethod,
                deviceData,
            });
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function deletePaymentMethod() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.delete(`/0/company/payment-method`, {});
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function getPaymentMethods() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`/0/company/payment-method`);
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function getCompanyAccounts() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`/0/company/accounts`);
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function getClientToken() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`/0/company/client-token`);
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function getPaymentMethodNonce() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`/0/company/payment-method-nonce`);
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function createInvitation(emails) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.post(`/0/company/invitation`, { emails });
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function removeInvitation(invitationId) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.delete(`/0/company/invitation/${invitationId}`);
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function changeUserRole(userId, role) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.patch(`/0/company/account/${userId}`, { role });
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function deleteUser(userId) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.delete(`/0/company/account/${userId}`);
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}

import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import * as Cookies from 'js-cookie';
import URI from 'urijs';
import { notification } from 'antd';
import { history, store } from '@src/app/index';
import config from '@src/config';
import { socket } from '@src/socket';
import { SocketEvents } from '@src/types';
import actions from '../actions';
export function* logoutStart() {
    const uri = new URI(`${config.api}/0/logout`);
    const apiUri = uri.toString();
    const { app } = store.getState();
    const logout = () => {
        axios
            .get(apiUri, { withCredentials: true })
            .then(() => {
            // eslint-disable-next-line no-undef
            FB.logout();
            Cookies.remove('connect.sid');
            Cookies.remove('praxujSocketCookieSession');
            socket.emit(SocketEvents.ACCOUNT_LOG_OUT, app.user);
        })
            .catch(err => {
            throw err;
        });
    };
    try {
        yield call(logout);
        yield put(actions.logoutSuccess());
    }
    catch (err) {
        yield put(actions.logoutError(err));
    }
}
export function* logoutSuccess() {
    notification.success({
        message: 'Upozornenie',
        description: 'Boli ste úspešne odhlásený',
    });
    // @ts-ignore
    const logoutRedirect = () => history.pushLocalized('/');
    yield call(logoutRedirect);
}
export function* logoutError(err) {
    const showError = error => console.error(error);
    yield call(showError, err);
}

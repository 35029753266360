import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from 'components/modules/global/button'
import FormResponse from 'components/modules/global/form-response'
import renderRadioGroup from 'components/modules/forms/renderRadioGroup'
import { store } from '@src/app/index'
import actions from 'actions'
import { Col, Row } from 'antd'
import { Holder, Title, Panel } from '@src/components/elements'

let FeedbackFormComponent = props => {
	const { handleSubmit, submitSucceeded, offerApplication } = props
	const intl = useIntl()
	const offerTitle = offerApplication ? offerApplication.offer_title : undefined
	return (
		<Holder marginVertical>
			<Row>
				<Col>
					<Title level={1} type="secondary">
						<FormattedMessage id="student_feedback.feedback_form.feedback" defaultMessage="Feedback" />
					</Title>
				</Col>
			</Row>
			<Row justify="center">
				<Col xs={24} md={20} lg={12}>
					<Panel>
						{submitSucceeded && (
							<FormResponse
								icon={{
									type: 'check-circle',
									color: 'success',
								}}
								text={intl.formatMessage({
									id: 'student_feedback.feedback_form.thanks',
									defaultMessage:
										'Ďakujeme za feedback. Pomôže nám to pri zlepšovaní našich služieb.',
								})}
								link={{
									route: '/ponuky',
									text: intl.formatMessage({
										id: 'student_feedback.feedback_form.continue_to_offers',
										defaultMessage: 'Pokračovať na ponuky',
									}),
								}}
							/>
						)}
						{!submitSucceeded && (
							<div>
								<h2 className="headline headline--1">
									<FormattedMessage
										id="student_feedback.feedback_form.feedback"
										defaultMessage="Feedback k ponuke {offerTitle}"
										values={{
											offerTitle,
										}}
									/>
								</h2>
								<form
									onSubmit={handleSubmit(values => {
										store.dispatch(
											actions.studentFeedbackStart({
												...values,
												offerApplicationId: offerApplication ? offerApplication.id : undefined,
											})
										)
									})}
									className="form form--login"
								>
									<Field
										label={intl.formatMessage({
											id: 'student_feedback.feedback_form.employer_response',
											defaultMessage: 'Dostali ste odpoveď od zamestnávateľa? *',
										})}
										component={renderRadioGroup}
										name="got_answer"
										options={[
											{
												title: intl.formatMessage({
													id: 'student_feedback.feedback_form.yes',
													defaultMessage: 'áno',
												}),
												value: 'true',
											},
											{
												title: intl.formatMessage({
													id: 'student_feedback.feedback_form.no',
													defaultMessage: 'nie',
												}),
												value: 'false',
											},
										]}
									/>
									<Field
										label={intl.formatMessage({
											id: 'student_feedback.feedback_form.interview_invite',
											defaultMessage: 'Boli ste pozvaný na pohovor? *',
										})}
										component={renderRadioGroup}
										name="got_interview"
										options={[
											{
												title: intl.formatMessage({
													id: 'student_feedback.feedback_form.yes',
													defaultMessage: 'áno',
												}),
												value: 'true',
											},
											{
												title: intl.formatMessage({
													id: 'student_feedback.feedback_form.no',
													defaultMessage: 'nie',
												}),
												value: 'false',
											},
										]}
									/>
									<Field
										label={intl.formatMessage({
											id: 'student_feedback.feedback_form.hired',
											defaultMessage: 'Získali ste danú pozíciu? *',
										})}
										component={renderRadioGroup}
										name="got_hired"
										options={[
											{
												title: intl.formatMessage({
													id: 'student_feedback.feedback_form.yes',
													defaultMessage: 'áno',
												}),
												value: 'true',
											},
											{
												title: intl.formatMessage({
													id: 'student_feedback.feedback_form.no',
													defaultMessage: 'nie',
												}),
												value: 'false',
											},
										]}
									/>
									<div className="form__submit">
										<Button
											type="submit"
											color="brand"
											text={intl.formatMessage({
												id: 'student_feedback.feedback_form.send',
												defaultMessage: 'Odoslať',
											})}
										/>
									</div>
								</form>
							</div>
						)}
					</Panel>
				</Col>
			</Row>
		</Holder>
	)
}

FeedbackFormComponent = reduxForm({
	form: 'feedbackStudentForm',
	destroyOnUnmount: true,
	enableReinitialize: true,
})(FeedbackFormComponent)

const mapStateToProps = state => ({
	offerApplication: state.offerApplication.application,
})

FeedbackFormComponent = connect(mapStateToProps)(FeedbackFormComponent)

const FeedbackForm = FeedbackFormComponent

export default FeedbackForm

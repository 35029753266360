import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import * as Cookies from 'js-cookie';
import { getLocalizedPath, getLanguageFromPathname, findMatchingRouteFromPathname } from '@src/shared/localized-path';
import { animateScroll } from 'react-scroll';
function Link(props) {
    var _a;
    const location = useLocation();
    if (!props.to) {
        return (React.createElement("a", { className: props.className, onClick: props.onClick, href: props.href }, props.children));
    }
    const language = getLanguageFromPathname(location.pathname);
    const localizedPath = getLocalizedPath(props.to, language);
    const shouldRedirectToPracuj = (_a = findMatchingRouteFromPathname(props.to)) === null || _a === void 0 ? void 0 : _a.shouldRedirect;
    let { search } = location;
    const sid = Cookies.get('connect.sid');
    if (sid) {
        search = `${search ? `${search}&` : '?'}sid=${sid}`;
    }
    if (!shouldRedirectToPracuj) {
        return (React.createElement(RouterLink, { onClick: location.pathname === localizedPath
                ? () => {
                    animateScroll.scrollToTop();
                    return false;
                }
                : undefined, className: props.className, to: localizedPath }, props.children));
    }
    return (React.createElement("a", { className: props.className, onClick: props.onClick, href: `${process.env.PRACUJ_REDIRECT_TARGET}${localizedPath}${search}` }, props.children));
}
export { Link };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Big } from 'big.js';
import { FormattedMessage, useIntl, FormattedNumber } from 'react-intl';
import { EditOutlined } from '@ant-design/icons';
import { Link, Panel } from '@src/components/elements';
import actions from '@src/actions';
import { Checkout } from '@src/components/modules/global/Checkout';
import { getCreditPricing } from '@src/shared/get-credit-pricing';
import styled from 'styled-components';
import config from '@src/config';
import { Col, Row } from 'antd';
export const TotalPrice = styled.span `
	color: ${props => props.theme.color.success};
`;
export const CreditsNote = styled.div `
	color: ${props => props.theme.color.gray5};
	padding: 1em 0 0 0;
	font-size: 0.9em;
`;
export const BoxChosen = styled.div `
	padding: 0;
	font-size: 1.5em;
	color: ${props => props.theme.color.brand1};
	font-weight: 700;
`;
export const TotalPriceSmall = styled.small `
	padding: 0;
	font-size: 0.7em;
	font-weight: 400;
	color: ${props => props.theme.color.gray4};
`;
function AdminOrder() {
    const dispatch = useDispatch();
    const params = useParams();
    // @ts-ignore
    const company = useSelector(state => state.admin.company);
    const intl = useIntl();
    const creditAmountFromURL = new Big(params.creditAmount);
    const maximumPricingLevel = config.pricing.levels[config.pricing.levels.length - 1];
    let creditAmount = creditAmountFromURL.toNumber();
    if (creditAmountFromURL.gt(maximumPricingLevel.limit)) {
        creditAmount = maximumPricingLevel.limit;
    }
    const { totalPrice, totalPriceWithoutVAT } = getCreditPricing(creditAmount);
    const init = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        dispatch(actions.myCompanyInfoInitStart());
    }), []);
    React.useEffect(() => {
        init();
    }, []);
    if (!company.id) {
        return null;
    }
    return (React.createElement("div", { className: "order" },
        React.createElement(Panel, null,
            React.createElement(Row, { gutter: [16, 16] },
                React.createElement(Col, { xs: 24, md: 14 },
                    React.createElement("div", { className: "order__features" },
                        React.createElement("h4", { className: "order__company-header" },
                            React.createElement(FormattedMessage, { id: "admin.order" })),
                        React.createElement(BoxChosen, null,
                            React.createElement(FormattedMessage, { values: { count: creditAmount }, id: "general.credits.pluralized" })),
                        React.createElement(CreditsNote, null,
                            React.createElement(FormattedMessage, { values: { count: creditAmount }, id: "general.credits.note_expiration" })))),
                React.createElement(Col, { xs: 24, md: 10 },
                    React.createElement("div", { className: "order__company" },
                        React.createElement("h4", { className: "order__company-header" },
                            React.createElement(FormattedMessage, { id: "admin.order.billing_informations", defaultMessage: "Faktura\u010Dn\u00E9 \u00FAdaje" })),
                        false && (React.createElement(Link, { to: "/admin/firemne-udaje", title: intl.formatMessage({
                                id: 'admin.order.change_billing_data',
                                defaultMessage: 'Zmeniť fakturačné údaje',
                            }) },
                            React.createElement(EditOutlined, { className: "order__edit" }))),
                        React.createElement("div", { className: "order__company-group" },
                            React.createElement("div", null, company.name),
                            React.createElement("div", null, company.formatted_address),
                            React.createElement("div", null,
                                company.postal_code,
                                "\u00A0",
                                company.city)),
                        React.createElement("div", { className: "order__company-group" },
                            React.createElement("div", null,
                                React.createElement("strong", null,
                                    React.createElement(FormattedMessage, { id: "admin.order.cin", defaultMessage: "I\u010CO" })),
                                ' ',
                                company.cin),
                            React.createElement("div", null,
                                React.createElement("strong", null,
                                    React.createElement(FormattedMessage, { id: "admin.order.tin", defaultMessage: "DI\u010C" })),
                                ' ',
                                company.tin),
                            React.createElement("div", null,
                                React.createElement("strong", null,
                                    React.createElement(FormattedMessage, { id: "admin.order.tin_vat", defaultMessage: "I\u010C DPH" })),
                                ' ',
                                company.tin_vat)))),
                React.createElement(Col, { xs: 24 },
                    React.createElement("div", { className: "panel panel--blank" },
                        React.createElement("div", { className: "order__summary" },
                            React.createElement("div", { className: "order__summary-price" },
                                React.createElement(FormattedMessage, { id: "admin.order.full_amount" }),
                                ' ',
                                React.createElement(TotalPrice, null,
                                    React.createElement(FormattedNumber, { value: totalPrice, style: "currency", currency: "EUR" })),
                                ' ',
                                React.createElement(FormattedMessage, { id: "admin.order.vat_included" }),
                                ' ',
                                React.createElement(TotalPriceSmall, null,
                                    "(",
                                    React.createElement(FormattedNumber, { value: totalPriceWithoutVAT, style: "currency", currency: "EUR" }),
                                    ' ',
                                    React.createElement(FormattedMessage, { id: "admin.order.vat_excluded" }),
                                    ")")),
                            React.createElement("div", { className: "order__billing-info" },
                                React.createElement("div", { className: "order__autobilling" },
                                    React.createElement(FormattedMessage, { id: "admin.order.note_invoice" }),
                                    ' ',
                                    React.createElement(FormattedMessage, { id: "admin.order.note_vat", values: {
                                            vatPercent: config.pricing.VAT,
                                        } }))))))),
            React.createElement(Checkout, { creditCount: creditAmount, hasRememberedCard: Boolean(company.payment_method_token) }))));
}
export { AdminOrder };

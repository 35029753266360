import cdnLink from '@shared/cdn-link'

const colorMap = new Map([
	[1, 'C93433'],
	[2, '348498'],
	[3, 'C34919'],
	[4, '009975'],
	[5, '2592A3'],
	[6, '085f63'],
	[7, '464CA2'],
	[8, 'A39F49'],
	[9, '974BA3'],
	[10, 'A48834'],
	[11, '3DA33A'],
	[12, '6E48A4'],
])

const backgroundMap = new Map([
	[1, 'information-technology'],
	[2, 'construction'],
	[3, 'economics'],
	[4, 'construction'],
	[5, 'biology'],
	[6, 'other'],
	[7, 'forestry'],
	[8, 'economics'],
	[9, 'other'],
	[10, 'other'],
	[11, 'pharmacy'],
	[12, 'social-sciences'],
	[13, 'police'],
	[14, 'social-sciences'],
	[15, 'construction'],
	[16, 'information-technology'],
	[17, 'arts'],
	[18, 'agriculture'],
	[19, 'mathematics'],
	[20, 'marketing'],
	[21, 'marketing'],
	[22, 'other'],
	[23, 'police'],
	[24, 'social-sciences'],
	[25, 'veterinary'],
	[26, 'law'],
	[27, 'social-sciences'],
	[28, 'information-technology'],
	[29, 'other'],
	[30, 'other'],
	[31, 'other'],
	[32, 'social-sciences'],
	[33, 'law'],
	[34, 'law'],
	[35, 'architecture'],
	[36, 'construction'],
	[37, 'environmental'],
	[38, 'information-technology'],
	[39, 'other'],
	[40, 'medicine'],
	[41, 'information-technology'],
	[42, 'education'],
	[43, 'medicine'],
	[44, 'environmental'],
	[45, 'information-technology'],
])

const getOverlayColor = id => {
	const result = (id % Array.from(colorMap).length) + 1
	return colorMap.get(result)
}

const getSocialImageUrl = args => {
	const { offerId, companyImage, jobAreaId } = args
	const overlayColorIndex = offerId || Math.floor(Math.random() * Array.from(colorMap).length)
	const backgroundId = jobAreaId || Math.floor(Math.random() * Array.from(backgroundMap).length)
	const file = `web/meta/company_area_${backgroundMap.get(backgroundId)}.png`

	let result = `${process.env.PUBLIC_URL}/media/images/meta-homepage.jpg`

	if (companyImage) {
		result = cdnLink({
			transformation: `l_${
				process.env.ENVIRONMENT
			}:web:meta:overlay-neutral,o_85,w_1200,h_630,e_colorize,co_rgb:${getOverlayColor(overlayColorIndex)}/l_${
				process.env.ENVIRONMENT
			}:web:meta:overlay-neutral,w_500,h_390,c_fill,e_colorize,co_rgb:ffffff,r_30,y_-30/l_${
				process.env.ENVIRONMENT
			}:web:meta:logo-address-text,w_180,y_240,c_fit/l_${
				process.env.ENVIRONMENT
			}:web:company:${companyImage},w_450,h_340,c_fit,r_15,y_-30`,
			file,
		})
	}

	return result
}

export default getSocialImageUrl

import React from 'react'
import cn from 'classnames'
import produce from 'immer'
import styled from 'styled-components'
import SlugOffer from 'shared/slug-offer'
import { history } from '@src/app/index'
import { useIntl, FormattedMessage } from 'react-intl'
import { notification } from 'antd'

import * as Api from 'api'
import { Table, ComboButton, Link } from 'components/elements'
import { TeamOutlined, CopyOutlined, EyeOutlined, DeleteOutlined, LineChartOutlined } from '@ant-design/icons'
import { EOffersType } from './Offers'
import { useAdminContextValue } from '../../admin-context'

const ApplicantsCell = styled(Link)`
	&& {
		display: flex;
		margin: -16px;
		padding: 18px 16px;
		justify-content: center;
		transition: 0.3s;
		color: inherit;

		&:hover {
			background: #f7eae4;
		}
	}
`

function MenuItem(props) {
	return (
		<span className="flex-row justify-start align-center">
			<props.icon className="m-r-s" />
			{props.label}
		</span>
	)
}

function TitleInput(props) {
	const [internalOfferTitle, setInternalOfferTitle] = React.useState(props.title)
	const [lastSavedTitle, setLastSavedTitle] = React.useState(props.title)
	const [isSaveNecessary, setIsSaveNecessary] = React.useState(false)
	const { intl } = props
	const saveOffer = React.useCallback(async () => {
		try {
			if (!isSaveNecessary) {
				return
			}

			const trimmedValue = internalOfferTitle ? internalOfferTitle.trim() : lastSavedTitle.trim()

			setInternalOfferTitle(trimmedValue)
			props.updateTitle(props.id, trimmedValue)
			setLastSavedTitle(trimmedValue)
			await Api.Admin.patchOfferTitle(props.id, trimmedValue)

			setIsSaveNecessary(false)

			notification.success({
				message: intl.formatMessage({
					id: 'admin.offers.offers_table.successfuly_saved',
					defaultMessage: 'Úspešne uložené',
				}),
				description: '',
			})
		} catch (err) {
			console.error(err)
		}
	}, [isSaveNecessary, internalOfferTitle, lastSavedTitle, props])

	const setTitle = React.useCallback(e => {
		setInternalOfferTitle(e.target.value)
		setIsSaveNecessary(true)
	}, [])

	return (
		<input
			className="title-input"
			onChange={setTitle}
			value={internalOfferTitle}
			onBlur={saveOffer}
			maxLength="100"
		/>
	)
}

function OffersTable(props) {
	const [internalOffers, setInternalOffers] = React.useState([])
	const intl = useIntl()
	const { offers, type, openArchiveDialog, onArchive } = props
	const AdminContextValue = useAdminContextValue()

	React.useEffect(() => {
		// add key for react indexing
		setInternalOffers(offers.map(o => ({ ...o, key: `offer-row-${o.id}` })))
	}, [offers])

	const updateTitle = React.useCallback(
		(id, title) => {
			const newOffers = produce(offers, draft => {
				const offerToUpdate = draft.find(o => o.id === id)
				// eslint-disable-next-line no-param-reassign
				offerToUpdate.title = title
			})
			setInternalOffers(newOffers)

			const newAdminOffers = produce(AdminContextValue.myOffers, draft => {
				const offerToUpdate = draft[type].find(o => o.id === id)
				// eslint-disable-next-line no-param-reassign
				offerToUpdate.title = title
			})
			AdminContextValue.setMyOffers(newAdminOffers)
		},
		[offers, AdminContextValue.myOffers]
	)

	const archiveDraft = React.useCallback(async id => {
		try {
			await Api.Offer.archiveOffer(id)
			await onArchive()
			notification.success({
				message: intl.formatMessage({
					id: 'admin.offers.offers_table.successfuly_archived',
					defaultMessage: 'Úspešne archivované',
				}),
				description: '',
			})
		} catch (err) {
			console.error(err)
		}
	}, [])

	const isArchived = type === EOffersType.notActive
	const isActive = type === EOffersType.active
	const isDraft = type === EOffersType.drafts

	const rootCN = cn('offers-table', {
		'offers-table-archived': isArchived,
	})

	return (
		<Table
			className={rootCN}
			bordered={true}
			pagination={false}
			dataSource={internalOffers}
			columns={[
				{
					title: intl.formatMessage({
						id: 'general.position',
						defaultMessage: 'Pozícia',
					}),
					dataIndex: 'title',
					key: 'title',
					width: '50%',
					align: 'left',
					render: (data, offer) => (
						<>
							{isArchived && <span>{data}</span>}
							{(isActive || isDraft) && <TitleInput {...offer} updateTitle={updateTitle} intl={intl} />}
						</>
					),
				},
				{
					title: intl.formatMessage({
						id: 'general.candidates',
						defaultMessage: 'Uchádzači',
					}),
					dataIndex: 'applicationCount',
					key: 'applicationCount',
					width: '15%',
					align: 'center',
					// TODO: Offer applicants
					render: (data, offer) => (
						<ApplicantsCell to={`/admin/uchadzaci/${offer.id}`}>
							<span className="offers-table-row">
								<TeamOutlined />
								{data}
							</span>
						</ApplicantsCell>
					),
				},
				{
					title: intl.formatMessage({
						id: 'general.views',
						defaultMessage: 'Návštevnosť',
					}),
					dataIndex: 'viewsCount',
					key: 'viewsCount',
					width: '15%',
					align: 'center',
					render: data => (
						<span className="offers-table-row">
							<LineChartOutlined />
							{data}
						</span>
					),
				},
				{
					title: '',
					width: '20%',
					align: 'center',
					render: data => {
						const offerSlug = new SlugOffer({ suffix: data.id, text: data.title })
						return (
							<span>
								{isActive && (
									<ComboButton
										onClick={() =>
											history.pushLocalized(`/admin/uprava-ponuky/${offerSlug.result}`)
										}
										menu={[
											{
												label: (
													<MenuItem
														label={intl.formatMessage({
															id: 'general.duplicate',
															defaultMessage: 'Duplikovať',
														})}
														icon={CopyOutlined}
													/>
												),
												onClick: () =>
													history.pushLocalized(
														`/admin/uprava-ponuky/${offerSlug.result}?duplicate=true`
													),
											},
											{
												label: (
													<MenuItem
														label={intl.formatMessage({
															id: 'general.insight',
															defaultMessage: 'Náhľad',
														})}
														icon={EyeOutlined}
													/>
												),
												onClick: () => window.open(`/ponuka/${offerSlug.result}`, '_blank'),
											},
											{
												label: (
													<MenuItem
														label={intl.formatMessage({
															id: 'general.archive',
															defaultMessage: 'Archivovať',
														})}
														icon={DeleteOutlined}
													/>
												),
												onClick: () => {
													openArchiveDialog(data.id)
												},
											},
										]}
									>
										<FormattedMessage id="general.edit" defaultMessage="Upraviť" />
									</ComboButton>
								)}
								{isDraft && (
									<ComboButton
										type="default"
										onClick={() =>
											history.pushLocalized(`/admin/pridat-ponuku?slug=${offerSlug.result}`)
										}
										menu={[
											{
												label: (
													<MenuItem
														label={intl.formatMessage({
															id: 'general.archive',
															defaultMessage: 'Archivovať',
														})}
														icon={DeleteOutlined}
													/>
												),
												onClick: () => {
													archiveDraft(data.id)
												},
											},
										]}
									>
										<FormattedMessage id="general.edit" defaultMessage="Upraviť" />
									</ComboButton>
								)}
								{isArchived && (
									<ComboButton
										type="default"
										onClick={() =>
											history.pushLocalized(
												`/admin/uprava-ponuky/${offerSlug.result}?duplicate=true`
											)
										}
										menu={[
											{
												label: (
													<MenuItem
														label={intl.formatMessage({
															id: 'general.insight',
															defaultMessage: 'Náhľad',
														})}
														icon={EyeOutlined}
													/>
												),
												onClick: () => window.open(`/ponuka/${offerSlug.result}`, '_blank'),
											},
										]}
									>
										<FormattedMessage id="general.duplicate" defaultMessage="Duplikovať" />
									</ComboButton>
								)}
							</span>
						)
					},
				},
			]}
		/>
	)
}

export default OffersTable

import React from 'react'
import { Col, Row } from 'antd'
import { Holder, Title, Panel } from '@src/components/elements'

const Protection = () => {
	return (
		<Holder marginVertical>
			<Row>
				<Col>
					<Title level={1} type="secondary">
						Ochrana osobných údajov
					</Title>
					<Panel>
						<Title level={2} type="secondary">
							Zásady spracúvania osobných údajov
						</Title>
						<h3 className="u-text-center">Praxuj s.r.o.</h3>
						<p className="u-text-center u-margin-bottom">
							<a href="https://praxuj.sk">praxuj.sk</a>
						</p>
						<div className="u-margin-bottom">
							<h4 className="u-text-center">I. Úvodné ustanovenia</h4>
							<ol>
								<li>
									Spoločnosť Praxuj s.r.o., vedomá si významu ochrany osobných údajov a zabezpečenia
									pri poskytovaní služieb užívateľom, prijala adekvátne opatrenia pre zaistenie
									zabezpečenia osobných údajov všetkých užívateľov.
								</li>
								<li>
									Spoločnosť Praxuj s.r.o. spracúva osobné údaje dotknutých osôb v súlade so zákonom
									č. 18/2018 Z.z., o ochrane osobných údajov a Nariadením Európskeho parlamentu a Rady
									(EÚ) 2016/679 z 27.4.2016.
								</li>
								<li>
									Spoločnosť Praxuj s.r.o. ako prevádzkovateľ osobných údajov vydáva tieto zásady,
									ktoré obsahujú základné parametre spracúvania osobných údajov pri poskytovaní
									služieb na internetovej stránke praxuj.sk (ďalej len “praxuj.sk”).
								</li>
								<li>
									Spoločnosť Praxuj s.r.o. si vyhradzuje právo na zmenu a doplnenie týchto zásad,
									pričom o zmenách bezodkladne informuje zverejnením aktuálneho znenia na praxuj.sk s
									uvedením dátumu, od ktorého sú zmeny platné.
								</li>
								<li>
									Prevádzkovateľom praxuj.sk je spoločnosť Praxuj s.r.o., Nový Svet 1182/40, Banská
									Bystrica 974 01, IČO 50 597 523, email:{' '}
									<a href="mailto:gdpr@praxuj.sj">gdpr@praxuj.sk</a>, zapísaná v Obchodnom registri
									Okresného súdu Banská Bystrica, oddiel Sro, vložka č. 30871/S (ďalej len
									“Prevádzkovateľ”).
								</li>
							</ol>
						</div>
						<div className="u-margin-bottom">
							<h4 className="u-text-center">II. Definície</h4>
							<ul>
								<li>
									Osobné údaje - údaje týkajúce sa identifikovanej fyzickej osoby alebo
									identifikovateľnej fyzickej osoby, ktorú možno identifikovať priamo alebo nepriamo,
									najmä na základe všeobecne použiteľného identifikátora, iného identifikátora ako je
									napríklad meno, priezvisko, identifikačné číslo, lokalizačné údaje, alebo online
									identifikátor, alebo na základe jednej alebo viacerých charakteristík alebo znakov,
									ktoré tvoria jej fyzickú identitu, fyziologickú identitu, genetickú identitu,
									psychickú identitu, mentálnu identitu, ekonomickú identitu, kultúrnu identitu alebo
									sociálnu identitu;
								</li>
								<li>
									Súhlas dotknutej osoby - akýkoľvek vážny a slobodne daný, konkrétny, informovaný a
									jednoznačný prejav vôle dotknutej osoby vo forme vyhlásenia alebo jednoznačného
									potvrdzujúceho úkonu, ktorým dotknutá osoba vyjadruje súhlas so spracúvaním svojich
									osobných údajov;
								</li>
								<li>
									Spracúvania osobných údajov - spracovateľská operácia alebo súbor spracovateľských
									operácií s osobnými údajmi alebo súbormi osobných údajov, najmä získavanie,
									zaznamenávanie, usporadúvanie, štruktúrovanie, uchovávanie, zmena, vyhľadávanie,
									prehliadanie, využívanie, poskytovanie prenosom, šírením alebo iným spôsobom,
									preskupovanie alebo kombinovanie, obmedzenie, vymazanie, bez ohľadu na to, či sa
									vykonáva automatizovanými prostriedkami alebo neautomatizovanými prostriedkami;
								</li>
								<li>Dotknutá osoba - každá fyzická osoba, ktorej osobné údaje sa spracúvajú;</li>
								<li>
									Prevádzkovateľ - každý, kto sám alebo spoločne s inými vymedzí účel a prostriedky
									spracúvania osobných údajov a spracúva osobné údaje vo vlastnom mene; prevádzkovateľ
									alebo konkrétne požiadavky na jeho určenie môžu byť ustanovené v osobitnom predpise
									alebo medzinárodnej zmluve, ktorou je Slovenská republika viazaná, ak takýto predpis
									alebo táto zmluva ustanovuje účel a prostriedky spracúvania osobných údajov;
								</li>
								<li>Sprostredkovateľ - každý, kto spracúva osobnú údaje v mene prevádzkovateľa</li>
								<li>
									Zodpovedná osoba - osoba určená prevádzkovateľom alebo sprostredkovateľom, ktorá
									plní úlohy podľa zákona;
								</li>
								<li>
									Užívateľ - osoba, ktorá používa praxuj.sk bez ohľadu na to, či sa jedná o Klienta
									alebo o neregistrovaného návštevníka;
								</li>
								<li>
									Firma - fyzická alebo právnická osoba, ktorá využíva služby Prevádzkovateľa na
									praxuj.sk za účelom nájsť vhodného stážistu alebo zamestnanca;
								</li>
								<li>
									Záujemca - fyzická osoba - študent alebo absolvent - ktorá využíva služby praxuj.sk
									za účelom nájsť si stáž alebo zamestnanie;
								</li>
								<li>Klient - spoločné označenie Firiem a Záujemcov;</li>
								<li>
									Praxuj.sk - webový portál umiestnený na adrese{' '}
									<a href="https://praxuj.sk">https://praxuj.sk</a>;
								</li>
								<li>Účet - účet Klientov na praxuj.sk;</li>
								<li>
									Služby - služby, ktoré poskytuje Prevádzkovateľ prostredníctvom portálu praxuj.sk.
								</li>
							</ul>
						</div>
						<div className="u-margin-bottom">
							<h4 className="u-text-center">III. Zásady spracúvania osobných údajov</h4>
							<p>
								Zásada zákonnosti - Osobné údaje možno spracúvať len zákonným spôsobom, tak aby nedošlo
								k porušeniu základných práv dotknutej osoby.
							</p>
							<p>
								Zásada obmedzenia účelu - Osobné údaje sa môžu získavať len na konkrétne určený,
								výslovne uvedený a oprávnený účel a nesmú sa ďalej spracúvať spôsobom, ktorý nie je
								zlučiteľný s týmto účelom.
							</p>
							<p>
								Zásada minimalizácie osobných údajov - Spracúvané osobné údaje musia byť primerané,
								relevantné a obmedzené na nevyhnutný rozsah daný účelom, na ktorý sa spracúvajú.
							</p>
							<p>
								Zásada správnosti - Spracúvané osobné údaje musia byť správne a podľa potreby
								aktualizované; musia sa prijať primerané a účinné opatrenia na zabezpečenie toho, aby sa
								osobné údaje, ktoré sú nesprávné z hľadiska účelov, na ktoré sa spracúvajú, bez
								zbytočného odkladu vymazali alebo opravili.
							</p>
							<p>
								Zásada minimalizácie uchovávania - Osobné údaje musia byť uchovávané vo forme, ktorá
								umožňuje identifikáciu dotknutej osoby najneskôr dovtedy, kým je to potrebné na účel, na
								ktorý sa osobné údaje spracúvajú; osobné údaje sa môžu uchovávať dlhšie, ak sa majú
								spracúvať výlučne na účel archivácie, na vedecký účel, na účel historického výskumu
								alebo na štatistický účel na základe osobitného predpisu, a ak sú dodržané primerané
								záruky ochrany práv dotknutej osoby podľa § 78 ods. 8.
							</p>
							<p>
								Zásada integrity a dôvernosti - Osobné údaje musia byť spracúvané spôsobom, ktorý
								prostredníctvom primeraných technických a organizačných opatrení zaručuje primeranú
								bezpečnosť osobných údajov vrátane ochrany pred neoprávneným spracúvaním osobných
								údajov, nezákonným spracúvaním osobných údajov, náhodnou stratou osobných údajov,
								výmazom osobných údajov alebo poškodením osobných údajov.
							</p>
							<p>
								Zásada zodpovednosti - Prevádzkovateľ je zodpovedný za dodržiavanie základných zásad
								spracúvania osobných údajov, za súlad spracúvania osobných údajov so zásadami
								spracúvania osobných údajov a je povinný tento súlad so zásadami spracúvania osobných
								údajov na požiadanie úradu preukázať.
							</p>
						</div>
						<div className="u-margin-bottom">
							<h4 className="u-text-center">IV. Newsletter</h4>
							<ol>
								<li>
									Prevádzkovateľ na praxuj.sk umožňuje zasielanie newsletteru s aktuálnymi reklamnými
									a marketingovými informáciami.
								</li>
								<li>
									Užívateľ zaškrtnutím príslušnej kolonky udeľuje súhlas so spracúvaním svojho emailu
									pre účely zasielania newsletteru s reklamnými a marketingovými informáciami
									týkajúcich sa Služieb Prevádzkovateľa.
								</li>
								<li>
									Svoj súhlas so spracúvaním údajov môže užívateľ kedykoľvek odvolať v odkaze v
									prijatom newsletteri alebo prostredníctvom emailu Prevádzkovateľa{' '}
									<a href="mailto:gdpr@praxuj.sk">gdpr@praxuj.sk</a>.
								</li>
								<li>
									Tento systém Prevádzkovateľa je registrovaný v Evidencii informačných systémov
									osobných údajov na Úrade pre ochranu osobných údajov.
								</li>
								<li>
									Účelom spracúvania je poskytnutie reklamných a marketingových informácií týkajúcich
									sa Služieb Prevádzkovateľa Užívateľom. Súhlas je daný na dobu určitú na obdobie 3
									rokov.
								</li>
							</ol>
						</div>
						<div className="u-margin-bottom">
							<h4 className="u-text-center">V. Registrácia Záujemcu</h4>
							<ol>
								<li>
									Prevádzkovateľ na praxuj.sk umožňuje vytvorenie Účtu Záujemcu pre využitie ďalších
									Služieb Prevádzkovateľa.
								</li>
								<li>
									Záujemca zaškrtnutím príslušnej kolonky prehlasuje, že všetky uvedené údaje sú
									pravdivé a následne udeľuje súhlas so spracúvaním Osobných údajov (meno, priezvisko,
									email).
								</li>
								<li>
									Účelom spracúvania Osobných údajov je vedenie Účtu a poskytnutie Služieb
									Prevádzkovateľa. Súhlas je daný na dobu určitú na obdobie 3 rokov.
								</li>
							</ol>
						</div>
						<div className="u-margin-bottom">
							<h4 className="u-text-center">VI. Životopis</h4>
							<ol>
								<li>
									Prevádzkovateľ na praxuj.sk umožňuje Záujemcom prostredníctvom Účtu vytvorenie
									svojho Životopisu a jeho zmien. Záujemca má možnosť vo svojom Účte prehliadať
									históriu svojich životopisov.
								</li>
								<li>
									Vzhľadom ku skutočnosti, že Životopis uložený v Účte Záujemcu obsahuje údaje, ktoré
									sú podľa právnych predpisov považované za Osobné údaje, je ich spracúvanie možné iba
									so súhlasom Záujemcu. Záujemca pred vytvorením, resp. uložením Životopisu
									prehlasuje, že všetky uvedené Osobné údaje sú pravdivé a následne udeľuje svoj
									súhlas so spracúvaním Osobných údajov.
								</li>
								<li>
									Účelom spracúvania Osobných údajov je poskytnutie možnosti Záujemcu vytvoriť si v
									Účte svoj životopis. Súhlas je daný na dobu určitú počas obdobia 3 rokov.
								</li>
								<li>
									Prevádzkovateľ nestanovuje žiadne minimálné obsahové požiadavky a uvedenie Osobných
									údajov v Životopise je výlučne na rozhodnutí Záujemcu, a ďalej Prevádzkovateľ v
									žiadnom prípade nepožaduje od Záujemcu uvedenie údajov z osobitnej kategórie
									osobných údajov podle § 16 Zákona alebo čl. 9 Nariadenia.
								</li>
							</ol>
						</div>
						<div className="u-margin-bottom">
							<h4 className="u-text-center">VII. Mám záujem</h4>
							<ol>
								<li>
									Prevádzkovateľ na praxuj.sk umožňuje Záujemcom prostredníctvom Účtu odpovedať na
									zverejněnú Ponuku včetně zaslání motivačného listu alebo životopisu (ak je vyžadován
									alebo Záujemce jej chce zaslať).
								</li>
								<li>
									Prevádzkovateľ archivuje odpovede odoslané Záujemcami na Ponuku zverejnene na
									praxuj.sk. Vzhledem ke skutečnosti, že odpovědi obsahují údaje, které jsou podle
									právních předpisů považovány za Osobné údaje, je spracúvanie možné iba so súhlasom
									Záujemca.
								</li>
								<li>
									Užívateľ zaškrtnutím príslušnej kolonky prehlasuje, že všetky uvedené údaje sú
									pravdivé a následne udeľuje súhlas so spracúvaním Osobných údajov (meno, priezvisko,
									email, životopis, motivačný list, sprievodná správa) spoločnosti Praxuj s.r.o. a
									Firme, ktorá Ponuku uverejnila, ako prevádzkovateľom.
								</li>
								<li>Záujemca má možnosť vo svojom Účte prehliadať svoje odpovede na Ponuky.</li>
								<li>
									Účelom spracúvania Osobných údajov je poskytnutie možnosti prepojiť Firmy hľadajúce
									stážistov a zamestnancov a uchádzačov o stáže a zamestnanie. Spoločnosť Praxuj
									s.r.o. ako Prevádzkovateľ archivuje odpovede odoslané Záujemcami na Ponuky
									uverejnené na praxuj.sk. Súhlas je daný na dobu určitú na obdobie 3 rokov.
								</li>
							</ol>
						</div>
						<div className="u-margin-bottom">
							<h4 className="u-text-center">VIII. Osobné údaje zamestnancov firmy</h4>
							<ol>
								<li>
									Pro využitie Služieb Firmou je Firma povinná vytvoriť Účet. Pri registrácii sú
									zároveň povinnou položkou údaje kontaktnej osoby (meno, priezvisko, telefónne číslo,
									email a heslo). Jedná se o údaje, ktoré Firma ako zamestnávateľ je oprávnená
									poskytovať alebo, ak je to potrebné v súvislosti s plnením pracovných povinností
									Dotknutej osoby.
								</li>
								<li>
									Poskytovanie Osobných údajov nesmie narušiť bezpečnosť a práva Dotknutej osoby.
									Tieto Osobné údaje budú spracúvané najdlhšie 10 rokov od ukončenia zmluvného
									záväzku.
								</li>
							</ol>
						</div>
						<div className="u-margin-bottom">
							<h4 className="u-text-center">IX. Spoločné ustanovenie</h4>
							<p>
								Spoločnosť Praxuj s.r.o. je Prevádzkovateľom - osobou podle § 5 písm. o) zákona č.
								18/2018 Z. z.
							</p>
							<p>
								Osobné údaje sú uchovávané na obdobie 3 rokov od udelenia súhlasu. Užívateľ môže tento
								súhlas opakovane predĺžiť potvrdením predlžovacieho emailu. Po uplynutí tejto doby budú
								údaje v anonymizovanej podobe uchovávané pre štatistické účely. Udelený súhlas je
								nevyhnutný k vedeniu Účtu a ku sprostredkovaniu ponúk.
							</p>
							<p>
								Súhlas je možné kedykoľvek odvolať prostredníctvom emailu{' '}
								<a href="mailto:gdpr@praxuj.sj">gdpr@praxuj.sk</a>.
							</p>
							<p>Osobné údaje sú spracúvané automaticky.</p>
							<p>
								Pri spracúvaní Osobných údajov dochádza k cezhraničnému prenosu do USA. Európska komisia
								vydala 12.7.2016 vykonávacie rozhodnutie o primeranosti ochrany poskytovanej štítom na
								ochranu osobných údajov medzi EÚ a USA.
							</p>
							<p>Užívateľ má právo:</p>
							<ul>
								<li>na prístup k Osobným údajom,</li>
								<li>na opravu Osobných údajov,</li>
								<li>na vymazanie Osobných údajov,</li>
								<li>na obmedzenie spracúvania Osobných údajov,</li>
								<li>namietať spracúvanie Osobných údajov,</li>
								<li>na prenosnosť Osobných údajov,</li>
								<li>kedykoľvek svoj súhlas odvolať,</li>
								<li>podať návrh na začatie konania podľa § 100 zákona o ochrane osobných údajov.</li>
							</ul>
							<p>Užívateľ bude informovaný o porušení zabezpečenia osobných údajov.</p>
							<p>
								Užívateľ môže na Prevádzkovateľa podať sťažnosť k Úradu pre ochranu osobných údajov
								Slovenskej republiky.
							</p>
							<p>
								Prevádzkovateľ zriadil funkciu zodpovednej osoby, je ňou Jozef Gašparík, email:{' '}
								<a href="mailto:gdpr@praxuj.sj">gdpr@praxuj.sk</a>. Zodpovedná osoba je zároveň
								kontaktom pre všetky záležitosti týkajúce sa ochrany osobných údajov.
							</p>
							<p>Dozorným orgánom je Úrad na ochranu osobných údajov SR.</p>
							<p>V Banskej Bystrici dňa 5.3.2019, Praxuj s.r.o.</p>
						</div>
					</Panel>
				</Col>
			</Row>
		</Holder>
	)
}

export default Protection

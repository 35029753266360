import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
const AuthContext = React.createContext({
    isModalOpen: false,
    setIsModalOpen: () => { },
    checkAuthentication: () => { },
    authModalPromise: { resolve: () => { }, reject: () => { } },
});
export const useAuthContextProvider = () => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [authModalPromise, setAuthModalPromise] = React.useState({
        resolve: () => { },
        reject: () => { },
    });
    // @ts-ignore
    const account = useSelector(state => { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.user; });
    const location = useLocation();
    React.useEffect(() => {
        setIsModalOpen(false);
    }, [location]);
    const checkAuthentication = () => {
        if (account.email) {
            return Promise.resolve(account);
        }
        setIsModalOpen(true);
        return new Promise((resolve, reject) => {
            setAuthModalPromise({ resolve, reject });
        });
    };
    return {
        AuthContextProvider: AuthContext.Provider,
        value: {
            isModalOpen,
            setIsModalOpen,
            checkAuthentication,
            authModalPromise,
        },
    };
};
export const useAuthContextValue = () => {
    const context = React.useContext(AuthContext);
    return context;
};

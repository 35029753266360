import React from 'react';
import classNames from 'classnames';
import { Input, Row, Col } from 'antd';
const getInputClasses = show => classNames({
    form__inputUrl: true,
    'form__inputUrl-error': show,
});
const getLabelClasses = noWhiteSpaceWrap => classNames({
    form__label: true,
    'form__label--noWhiteSpaceWrap': noWhiteSpaceWrap,
});
const inputFieldUrl = ({ input, label, type, placeholder, noWhiteSpaceWrap, className, disabled, addonBefore, meta: { touched, error }, }) => (React.createElement("div", { className: `form__row ${className || ''}` },
    React.createElement(Row, null,
        React.createElement(Col, { xs: 24, id: input.name },
            React.createElement("label", { className: getLabelClasses(noWhiteSpaceWrap), htmlFor: input.name }, label),
            React.createElement(Input, Object.assign({ addonBefore: addonBefore }, input, { className: getInputClasses(touched && error), type: type, placeholder: placeholder, disabled: disabled, "data-cy": input.name })),
            touched && error && React.createElement("div", { className: "form__inputUrl-error" }, error)))));
export default inputFieldUrl;

/* eslint-disable no-restricted-globals */
import React from 'react';
import { Link, Icon, Panel, Button } from '@src/components/elements';
import { history } from '@src/app/index';
import { FacebookFilled } from '@ant-design/icons';
import { Parser } from 'html-to-react';
import SlugOffer from 'shared/slug-offer';
import { FormattedMessage, useIntl } from 'react-intl';
import Timestamp from 'components/modules/global/timestamp';
import DeprecatedButton from 'components/modules/global/button';
import getSalaryString from 'shared/get-salary-string';
import { CompanyProfileBox } from '@src/components/modules/global/CompanyProfileBox';
import { Col, Row } from 'antd';
import { useLocation } from 'react-router-dom';
import { WorkingTimeTypeMap } from '@src/types';
import styled from 'styled-components';
import SimilarOffers from './similar-offers';
import translateType from './details.model';
import { DeletedOffer } from './DeletedOffer';
import { RequiredDriverLicences } from './DriverLicences';
import { RequiredLanguages } from './RequiredLanguages';
import { OfferDetailsSkillTag } from './SkillTags';
import { WorkingTimeRangeHours } from './WorkingTimeRangeHours';
// import SendResumeForm from './send-resume-form'
const HeadlineSubtitle = styled.h3 `
	margin: 1.5rem 0 1rem 0;
	color: ${({ theme }) => theme.color.brand1};
`;
const SkillTagsWrapper = styled.div `
	margin-top: 1.5rem;
`;
const translateWorkingTimeType = ({ type, intl }) => {
    const language = intl.locale;
    return WorkingTimeTypeMap[language][type];
};
function removeTagsAndSpaces(input) {
    return input.replace(/<[^>]*>?/g, '').replace(/\s/g, '');
}
const htmlToReactParser = new Parser();
const FBShare = () => {
    // eslint-disable-next-line no-undef
    FB.ui({
        // @ts-ignore
        method: 'share',
        href: window.location.href,
    }, null);
};
const OfferSuggestionsCompany = ({ offerSuggestionsCompany }) => (React.createElement("div", { className: "panel m-b-m" },
    React.createElement("aside", { className: "offer-suggestions-company" },
        React.createElement("h5", { className: "offer-suggestions-company__heading" },
            React.createElement(FormattedMessage, { id: "offer_detail.details.more_copany_offers", defaultMessage: "\u010Eal\u0161ie ponuky spolo\u010Dnosti" })),
        offerSuggestionsCompany.map(({ id, title, city, salary_rate, salary_amount_from, salary_amount_to, salary_currency, isPaid }) => {
            const slug = new SlugOffer({ text: title });
            return (React.createElement("div", { key: `offer-suggestion-company-${id}`, className: "offer-suggestions-company__item" },
                React.createElement(Link, { className: "link", to: `/ponuka/${slug}-${id}` },
                    React.createElement("p", { className: "offer-suggestions-company__title" }, title),
                    React.createElement("div", { className: "offer-suggestions-company__info" },
                        React.createElement("div", { className: "offer-suggestions-company__address" },
                            React.createElement(Icon, { className: "icon", type: "maps-pin" }),
                            React.createElement("span", null, city)),
                        isPaid === 1 && (React.createElement("div", { className: "offer-suggestions-company__salary" },
                            React.createElement(Icon, { className: "icon", type: "money-bag-euro" }),
                            React.createElement("span", null, getSalaryString({
                                rate: salary_rate,
                                amountFrom: salary_amount_from,
                                amountTo: salary_amount_to,
                                currency: salary_currency,
                            }))))))));
        }))));
const OfferNotActive = ({ intl }) => (React.createElement("div", { className: "panel panel--payment u-text-center" },
    React.createElement("h4", { className: "offer-detail__cta-not-active__text" },
        React.createElement(FormattedMessage, { id: "offer_detail.details.this_offer_is_not_available", defaultMessage: "Bohu\u017Eia\u013E, t\u00E1to ponuka u\u017E nie je dostupn\u00E1." })),
    React.createElement(DeprecatedButton, { text: intl.formatMessage({
            id: 'offer_detail.details.go_to_offers',
            defaultMessage: 'Prejsť na ponuky',
        }), 
        // @ts-ignore
        onClick: () => history.pushLocalized('/ponuky') })));
const OfferDetailCta = ({ isActive, intl }) => {
    return (React.createElement("div", { className: "offer-detail__cta" },
        isActive && (React.createElement("div", null,
            React.createElement(DeprecatedButton, { text: intl.formatMessage({
                    id: 'offer_detail.details.i_am_interested',
                    defaultMessage: 'Mám záujem',
                }), onClick: () => {
                    var _a;
                    // @ts-ignore
                    scrollTo(document.body, (_a = document.getElementById('submit')) === null || _a === void 0 ? void 0 : _a.offsetTop);
                } }),
            React.createElement(DeprecatedButton, { text: intl.formatMessage({
                    id: 'offer_detail.details.share',
                    defaultMessage: 'Zdieľať',
                }), onClick: FBShare, IconBeforeText: React.createElement(FacebookFilled, { className: "icon-left" }), color: "socialFb" }))),
        !isActive && React.createElement(OfferNotActive, { intl: intl })));
};
const Details = ({ details, offerSuggestionsCompany, similarOffers }) => {
    const showSuggestions = offerSuggestionsCompany.length > 0;
    const showSimilarOffers = similarOffers.length > 0;
    const isActive = details.is_active === 1;
    const wasOfferDeleted = !details.account && !isActive;
    const isInternship = details.offer_type_id === 1;
    const showExactDuration = isInternship && details.duration_min === details.duration_max;
    const showMinDuration = isInternship && Boolean(details.duration_min) && !details.duration_max;
    const showDurationRange = isInternship && !showExactDuration && !showMinDuration;
    const intl = useIntl();
    const location = useLocation();
    const isHomeOffice = Boolean(!details.address_id);
    const { working_time: workingTime, skill_tags: skillTags, skill_tags_rates: skillTagsRates, driver_licences: driverLicences, offer_required_languages: languages, required, timezone, } = details;
    if (wasOfferDeleted) {
        return React.createElement(DeletedOffer, { similarOffers: similarOffers });
    }
    const { company } = details.account;
    const companyActiveOffers = company.offers.filter(o => Boolean(o.is_active));
    const hasWorkingTimeSet = workingTime ? Boolean(Object.values(workingTime).filter(value => value).length) : false;
    const requiredOthers = required ? removeTagsAndSpaces(required) : null;
    const hasAtLeastOneRequiredField = [skillTags, driverLicences, languages, requiredOthers].some(x => Boolean(x === null || x === void 0 ? void 0 : x.length));
    const shouldShowOtherRequirementsHeading = [skillTags, driverLicences, languages].some(x => Boolean(x === null || x === void 0 ? void 0 : x.length));
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { justify: "center", gutter: [16, 16] },
            React.createElement(Col, { xs: { span: 24 }, md: { span: 16 } },
                React.createElement(Panel, null,
                    React.createElement("h1", { className: "offer-detail__headline offer-detail__headline--main u-word-break" }, details.title),
                    React.createElement("div", { className: "offer-detail__section offer-detail__section--blank" },
                        React.createElement("div", { className: "offer-detail__section-text" }, htmlToReactParser.parse(details.description))),
                    React.createElement(OfferDetailCta, { isActive: isActive, intl: intl }),
                    React.createElement("div", { className: "offer-detail__section" },
                        React.createElement("div", { className: "offer-detail__section-text" },
                            React.createElement("strong", null,
                                React.createElement(FormattedMessage, { id: "offer_detail.details.deadline" })),
                            ' ',
                            React.createElement(Timestamp, { value: details.deadline })),
                        showExactDuration && (React.createElement("div", { className: "offer-detail__section-text" },
                            React.createElement("strong", null,
                                React.createElement(FormattedMessage, { id: "offer_detail.details.internship_length" })),
                            ' ',
                            details.duration_min,
                            ' ',
                            translateType(details.duration_min, details.duration_type, intl))),
                        showMinDuration && (React.createElement("div", { className: "offer-detail__section-text" },
                            React.createElement(FormattedMessage, { id: "offer_detail.details.internship_length_minimal", values: {
                                    strong: chunks => React.createElement("strong", null, chunks),
                                } }),
                            details.duration_min,
                            ' ',
                            translateType(details.duration_min, details.duration_type, intl))),
                        showDurationRange && (React.createElement("div", { className: "offer-detail__section-text" },
                            React.createElement("strong", null,
                                React.createElement(FormattedMessage, { id: "offer_detail.details.internship_length" })),
                            ' ',
                            details.duration_min,
                            " - ",
                            details.duration_max,
                            ' ',
                            translateType(details.duration_max, details.duration_type, intl))),
                        details.contract_type && (React.createElement("div", { className: "offer-detail__section-text" },
                            React.createElement("strong", null,
                                React.createElement(FormattedMessage, { id: "offer_detail.details.contract_type" })),
                            ' ',
                            details.contract_type.name)),
                        !isHomeOffice && (React.createElement("div", null,
                            React.createElement("div", { className: "offer-detail__section-text" },
                                React.createElement("strong", null,
                                    React.createElement(FormattedMessage, { id: "offer_detail.details.address" })),
                                ' ',
                                details.address.formatted_address),
                            React.createElement("div", { className: "offer-detail__section-text" },
                                React.createElement("strong", null,
                                    React.createElement(FormattedMessage, { id: "offer_detail.details.city" })),
                                ' ',
                                details.address.city))),
                        isHomeOffice && (React.createElement("div", { className: "offer-detail__section-text" },
                            React.createElement("strong", null,
                                React.createElement(FormattedMessage, { id: "offer_detail.details.location" })),
                            ' ',
                            React.createElement(FormattedMessage, { id: "offer_detail.details.remote_location" }))),
                        React.createElement("div", { className: "offer-detail__section-text" },
                            React.createElement("strong", null,
                                React.createElement(FormattedMessage, { id: "offer_detail.details.offer_type_name" })),
                            ' ',
                            details.offer_type.name)),
                    workingTime && hasWorkingTimeSet && (React.createElement("div", { className: "offer-detail__section" },
                        React.createElement("h2", { className: "offer-detail__headline" },
                            React.createElement(FormattedMessage, { id: "offer_detail.details.working_time_information" })),
                        (workingTime.time_from || workingTime.time_to) && (React.createElement("div", { className: "offer-detail__section-text" },
                            React.createElement("div", { className: "flex-row" },
                                React.createElement("strong", null,
                                    React.createElement(FormattedMessage, { id: "offer_detail.details.working_time" })),
                                "\u00A0",
                                React.createElement(WorkingTimeRangeHours, { timeFrom: workingTime.time_from, timeTo: workingTime.time_to, timezone: timezone, isRemote: isHomeOffice })))),
                        workingTime.time_grant && (React.createElement("div", { className: "offer-detail__section-text flex-row" },
                            React.createElement("strong", null,
                                React.createElement(FormattedMessage, { id: "offer_detail.details.time_grant" })),
                            "\u00A0",
                            React.createElement("span", { className: "flex-row" },
                                workingTime.time_grant,
                                React.createElement(FormattedMessage, { id: "offer_detail.details.hours_day" })))),
                        workingTime.time_type && (React.createElement("div", { className: "offer-detail__section-text" },
                            React.createElement("strong", null,
                                React.createElement(FormattedMessage, { id: "offer_detail.details.working_time_type" })),
                            ' ',
                            translateWorkingTimeType({ type: workingTime.time_type, intl }))),
                        workingTime.additional_information && (React.createElement("div", { className: "offer-detail__section-text" },
                            React.createElement("strong", null,
                                React.createElement(FormattedMessage, { id: "offer_detail.details.additional_information" })),
                            ' ',
                            workingTime.additional_information)))),
                    React.createElement("div", { className: "offer-detail__section" },
                        React.createElement("h2", { className: "offer-detail__headline" },
                            React.createElement(FormattedMessage, { id: "offer_detail.details.focus" })),
                        React.createElement("div", { className: "offer-detail__section-tags" },
                            React.createElement("ul", { className: "offer-detail__tags" }, details.job_area.map(item => (React.createElement("li", { className: "offer-detail__tag", key: item.id },
                                React.createElement("div", { className: "offer-detail__tag-inner" }, item.name))))))),
                    hasAtLeastOneRequiredField && (React.createElement("div", { className: "offer-detail__section" },
                        React.createElement("h2", { className: "offer-detail__headline" },
                            React.createElement(FormattedMessage, { id: "offer_detail.details.candidate_requirements" })),
                        Boolean(skillTags.length) && (React.createElement(SkillTagsWrapper, null,
                            React.createElement(OfferDetailsSkillTag, { skillTags: skillTags, skillTagsRates: skillTagsRates, areSkillsRated: Boolean(skillTagsRates === null || skillTagsRates === void 0 ? void 0 : skillTagsRates.length) }))),
                        Boolean(languages === null || languages === void 0 ? void 0 : languages.length) && (React.createElement(React.Fragment, null,
                            React.createElement(HeadlineSubtitle, null,
                                React.createElement(FormattedMessage, { id: "offer_detail.details.required_languages" })),
                            React.createElement(RequiredLanguages, { languages: languages }))),
                        Boolean(driverLicences === null || driverLicences === void 0 ? void 0 : driverLicences.length) && (React.createElement(React.Fragment, null,
                            React.createElement(HeadlineSubtitle, null,
                                React.createElement(FormattedMessage, { id: "offer_detail.details.required_driver_licences" })),
                            React.createElement(RequiredDriverLicences, { driverLicences: driverLicences }))),
                        Boolean(requiredOthers === null || requiredOthers === void 0 ? void 0 : requiredOthers.length) && (React.createElement(React.Fragment, null,
                            shouldShowOtherRequirementsHeading && (React.createElement(HeadlineSubtitle, null,
                                React.createElement(FormattedMessage, { id: "offer_detail.details.other_requirements" }))),
                            React.createElement("div", { className: "offer-detail__section-text" }, htmlToReactParser.parse(required)))))),
                    details.nice_to_have && (React.createElement("div", { className: "offer-detail__section" },
                        React.createElement("h2", { className: "offer-detail__headline" },
                            React.createElement(FormattedMessage, { id: "offer_detail.details.advantage_for_candidate", defaultMessage: "V\u00FDhodou pre kandid\u00E1ta je" })),
                        React.createElement("div", { className: "offer-detail__section-text" }, htmlToReactParser.parse(details.nice_to_have)))),
                    details.benefits && (React.createElement("div", { className: "offer-detail__section" },
                        React.createElement("h2", { className: "offer-detail__headline" },
                            React.createElement(FormattedMessage, { id: "offer_detail.details.benefits_offer", defaultMessage: "Ponuka benefitov" })),
                        React.createElement("div", { className: "offer-detail__section-text" }, htmlToReactParser.parse(details.benefits)))),
                    details.is_paid === 1 && (React.createElement("div", { className: "offer-detail__section" },
                        React.createElement("h2", { className: "offer-detail__headline" },
                            React.createElement(FormattedMessage, { id: "offer_detail.details.reward_amount", defaultMessage: "V\u00FD\u0161ka odmeny" })),
                        React.createElement("div", { className: "offer-detail__section-text" },
                            getSalaryString({
                                rate: details.salary_rate,
                                amountFrom: details.salary_amount_from,
                                amountTo: details.salary_amount_to,
                                currency: details.salary_currency,
                            }),
                            React.createElement("br", null),
                            details.salary_description)))),
                !isActive && (React.createElement(Panel, { className: "m-t-m" },
                    React.createElement(OfferNotActive, { intl: intl }))),
                isActive && (React.createElement("div", { id: "submit" },
                    React.createElement(Panel, { className: "m-t-m" },
                        React.createElement("div", { className: "text-center" },
                            React.createElement("strong", null, intl.formatMessage({
                                id: 'offer_detail.send_resume_form.pracuj',
                            }))),
                        React.createElement("div", { className: "form__submit m-b-none m-t-m", "data-cy": "form-submit" },
                            React.createElement(Button, { href: `${process.env.PRACUJ_REDIRECT_TARGET}${location.pathname}`, type: "primary", style: { color: '#fff' } }, intl.formatMessage({
                                id: 'offer_detail.send_resume_form.send',
                            }))))))),
            React.createElement(Col, { xs: { span: 24 }, md: { span: 8 } },
                React.createElement(CompanyProfileBox, { className: "m-b-m", company: company, companyOfferCount: companyActiveOffers.length }),
                showSuggestions && React.createElement(OfferSuggestionsCompany, { offerSuggestionsCompany: offerSuggestionsCompany }),
                showSimilarOffers && React.createElement(SimilarOffers, { similarOffers: similarOffers })))));
};
export default Details;

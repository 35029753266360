import config from '@src/config';
import { EOfferType } from '@src/types';
const validate = (values, props) => {
    const errors = {};
    const { isPaid, intl, isHomeOffice, offerType } = props;
    if (!values.title) {
        errors.title = intl.formatMessage({
            id: 'admin.offer_validation.please_fill_offer_name',
            defaultMessage: 'Prosím, vyplňte názov ponuky',
        });
    }
    if (!values.offer_type_id) {
        errors.offer_type_id = intl.formatMessage({
            id: 'admin.offer_validation.please_select_position_type',
            defaultMessage: 'Prosím, vyberte typ pozície',
        });
    }
    if (!values.state || values.state.length === 0) {
        errors.state = intl.formatMessage({
            id: 'admin.offer_validation.please_select_at_least_one_region',
            defaultMessage: 'Prosím, vyberte aspoň 1 kraj',
        });
    }
    if (!values.job_area || values.job_area.length === 0) {
        errors.job_area = intl.formatMessage({
            id: 'admin.offer_validation.please_select_at_least_one_trend',
            defaultMessage: 'Prosím, vyberte aspoň 1 pracovné zameranie',
        });
    }
    if (!values.description || values.description.replace(/<[^>]*>?/g, '').replace(/\s/g, '').length === 0) {
        errors.description = intl.formatMessage({
            id: 'admin.offer_validation.please_fill_offer_description',
            defaultMessage: 'Prosím, vyplňte popis pozície',
        });
    }
    if (!values.required || values.required.replace(/<[^>]*>?/g, '').replace(/\s/g, '').length === 0) {
        errors.required = intl.formatMessage({
            id: 'admin.offer_validation.please_describe_needed_knowledge',
            defaultMessage: 'Prosím, popíšte požadované znalosti',
        });
    }
    if (!values.nice_to_have || values.nice_to_have.replace(/<[^>]*>?/g, '').replace(/\s/g, '').length === 0) {
        errors.nice_to_have = intl.formatMessage({
            id: 'admin.offer_validation.please_describe_benefit_knowledge',
            defaultMessage: 'Prosím, popíšte znalosti, ktoré sú výhodou',
        });
    }
    if (!values.is_paid) {
        errors.is_paid = intl.formatMessage({
            id: 'admin.offer_validation.please_select_if_salaried',
            defaultMessage: 'Prosím, vyberte či je pozícia platená',
        });
    }
    if (!values.address && !isHomeOffice) {
        errors.address = intl.formatMessage({
            id: 'admin.offer_validation.please_fill_address',
            defaultMessage: 'Prosím, vyplňte adresu výkonu',
        });
    }
    if (!values.city && !isHomeOffice) {
        errors.city = intl.formatMessage({
            id: 'admin.offer_validation.please_fill_city',
            defaultMessage: 'Prosím, vyplňte mesto výkonu stáže',
        });
    }
    const durationFrom = parseInt(values.duration_min, 10);
    const durationTo = parseInt(values.duration_max, 10);
    if (!values.duration_min && offerType === EOfferType.internship) {
        errors.duration_min = intl.formatMessage({
            id: 'admin.offer_validation.please_fill_lower_limit_internship_length',
            defaultMessage: 'Prosím, vyplňte spodnú dĺžku výkonu stáže',
        });
    }
    else if (!Number.isFinite(durationFrom) && offerType === EOfferType.internship) {
        errors.duration_min = intl.formatMessage({
            id: 'admin.offer_validation.number_has_wrong_format',
            defaultMessage: 'Číslo má nesprávny formát',
        });
    }
    if (values.duration_max && !Number.isFinite(durationTo) && offerType === EOfferType.internship) {
        errors.duration_max = intl.formatMessage({
            id: 'admin.offer_validation.number_has_wrong_format',
            defaultMessage: 'Číslo má nesprávny formát',
        });
    }
    if (Number.isFinite(durationFrom) && Number.isFinite(durationTo) && durationFrom > durationTo) {
        errors.duration_max = intl.formatMessage({
            id: 'admin.offer_validation.higher_limit_lower_than',
            defaultMessage: 'Vrchná hranica dĺžky stáže nemôže byť menšia ako spodná',
        });
        errors.duration_min = intl.formatMessage({
            id: 'admin.offer_validation.higher_length_limit_lower_than',
            defaultMessage: 'Vrchná hranica dĺžky stáže nemôže byť menšia ako spodná',
        });
    }
    if (!values.duration_type && offerType === EOfferType.internship) {
        errors.duration_type = intl.formatMessage({
            id: 'admin.offer_validation.please_select_performance_type',
            defaultMessage: 'Prosím, vyberte typ výkonu',
        });
    }
    if (!values.contract_type_id && offerType === EOfferType.graduate) {
        errors.contract_type_id = intl.formatMessage({
            id: 'admin.offer_validation.please_select_contract_type',
            defaultMessage: 'Prosím, vyplňte typ úväzku',
        });
    }
    if (!values.deadline) {
        errors.deadline = intl.formatMessage({
            id: 'admin.offer_validation.please_fill_deadline',
            defaultMessage: 'Prosím, vyplňte dátum uzávierky',
        });
    }
    if (!values.contact_email) {
        errors.contact_email = intl.formatMessage({
            id: 'admin.offer_validation.fill_email_field',
            defaultMessage: 'Vyplňte pole s emailovou adresou',
        });
    }
    else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.contact_email)) {
        errors.contact_email = intl.formatMessage({
            id: 'admin.offer_validation.fill_valid_email',
            defaultMessage: 'Zadajte validný email',
        });
    }
    if (isPaid) {
        const allowedSalaryRates = config.salaryRates.map(rate => rate.value);
        const allowedSalaryCurrencies = config.salaryCurrencies.map(curr => curr.value);
        const salaryAmountFrom = parseFloat(values.salary_amount_from);
        const salaryAmountTo = parseFloat(values.salary_amount_to);
        if (!allowedSalaryRates.includes(values.salary_rate)) {
            errors.salary_rate = intl.formatMessage({
                id: 'admin.offer_validation.please_select_payment_type',
            });
        }
        if (!allowedSalaryCurrencies.includes(values.salary_currency)) {
            errors.salary_currency = intl.formatMessage({
                id: 'admin.offer_validation.please_choose_currency',
                defaultMessage: 'Prosím, vyberte menu',
            });
        }
        if (!values.salary_amount_from) {
            errors.salary_amount_from = intl.formatMessage({
                id: 'admin.offer_validation.please_fill_income_lower_limit',
                defaultMessage: 'Prosím, vyplňte spodnú hranicu platu',
            });
        }
        else if (!Number.isFinite(salaryAmountFrom)) {
            errors.salary_amount_from = intl.formatMessage({
                id: 'admin.offer_validation.number_has_wrong_format',
                defaultMessage: 'Číslo má nesprávny formát',
            });
        }
        if (values.salary_amount_to && !Number.isFinite(salaryAmountTo)) {
            errors.salary_amount_to = intl.formatMessage({
                id: 'admin.offer_validation.number_has_wrong_format',
                defaultMessage: 'Číslo má nesprávny formát',
            });
        }
        if (Number.isFinite(salaryAmountFrom) && Number.isFinite(salaryAmountTo) && salaryAmountFrom > salaryAmountTo) {
            errors.salary_amount_to = intl.formatMessage({
                id: 'admin.offer_validation.higher_income_limit_lower_than',
                defaultMessage: 'Vrchná hranica platu nemôže byť menšia ako spodná',
            });
        }
    }
    return errors;
};
export default validate;

import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
const Head = (props) => {
    const intl = useIntl();
    const id = props.id || 'app';
    const imageUrl = props.imageUrl || `${process.env.PUBLIC_URL}/media/images/meta-homepage.jpg`;
    const title = props.title || intl.formatMessage({ id: `${id}.head.title` });
    const titleSocial = props.customSocialTitle ? intl.formatMessage({ id: `${id}.head.title.social` }) : title;
    const description = props.description || intl.formatMessage({ id: `${id}.head.description` });
    const descriptionSocial = props.customSocialDescription
        ? intl.formatMessage({ id: `${id}.head.description.social` })
        : description;
    return (React.createElement(Helmet, null,
        React.createElement("title", null, title),
        React.createElement("meta", { property: "og:title", content: titleSocial }),
        React.createElement("meta", { name: "description", content: description }),
        React.createElement("meta", { property: "og:description", content: descriptionSocial }),
        React.createElement("meta", { property: "og:type", content: "article" }),
        React.createElement("meta", { property: "og:image", content: imageUrl }),
        props.id === 'offers' && React.createElement("link", { rel: "canonical", href: `${process.env.PRACUJ_REDIRECT_TARGET}/ponuky` })));
};
export { Head };

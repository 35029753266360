import React from 'react';
import { IntlProvider } from 'react-intl';
import Modal from 'react-modal';
// ant design stuff
import { ConfigProvider, notification } from 'antd';
import skSK from 'antd/es/locale/sk_SK';
import * as Analytics from '@src/helpers/analytics-tracking';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { useAppContextProvider } from './context/app-context';
import messages_sk from './translations/sk.json';
import messages_en from './translations/en.json';
import { SSRDataContext, SSRFetchConfig } from './hooks/useSSRFetch';
Sentry.init({
    dsn: 'https://8724211dfc814a20903f47cfa75a6234@o437627.ingest.sentry.io/5441158',
    environment: process.env.ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});
notification.config({
    placement: 'bottomRight',
});
const messagesMap = {
    sk: messages_sk,
    en: messages_en,
};
const isServer = typeof window === 'undefined';
const initialSSRData = {};
if (!isServer) {
    Object.values(SSRFetchConfig).forEach(({ key }) => {
        if (window[key]) {
            initialSSRData[key] = window[key];
        }
    });
}
Modal.setAppElement('#root');
const App = ({ children }) => {
    const { value, AppContextProvider } = useAppContextProvider();
    return (React.createElement(Sentry.ErrorBoundary, null,
        React.createElement(SSRDataContext.Provider, { value: initialSSRData },
            React.createElement(AppContextProvider, { value: value },
                React.createElement(ConfigProvider, { locale: skSK },
                    React.createElement(IntlProvider, { locale: value.language, messages: messagesMap[value.language] }, children))))));
};
Analytics.startTracking();
export default App;

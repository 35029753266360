import React from 'react'
import { Provider } from 'react-redux'
import Axios from 'axios'
import { configureStore } from '@src/store/Store'
import { AccountType } from '@src/types/account'
import { SocketProvider } from '@src/context/socket-context'
import { SocketEvents } from '@src/types'
import { socket } from '@src/socket'
import { RouterComponent as Router } from '../router'
import '../App.scss'

const preloadedState = typeof window !== 'undefined' ? window.__PRELOADED_STATE__ : undefined

if (typeof window !== 'undefined') {
	// Allow the passed state to be garbage-collected
	delete window.__PRELOADED_STATE__
}

export const { store, history } = configureStore(preloadedState)

function App() {
	React.useEffect(() => {
		if (typeof FB !== 'undefined') {
			FB.Event.subscribe('auth.login', async e => {
				if (e && e.authResponse) {
					try {
						const { data: user } = await Axios.post('/api/0/auth/facebook', {
							accessToken: e.authResponse.accessToken,
						})

						socket.on(SocketEvents.ACCOUNT_AUTHENTICATED, user)

						if (user.account_type_id === AccountType.student) {
							history.pushLocalized('/profil')
						} else {
							history.pushLocalized('/admin')
						}
					} catch (err) {
						console.error(err)
					}
				}
			})

			return () => {
				FB.Event.unsubscribe('auth.login', e => {
					console.error(e)
				})
			}
		}
	}, [])
	return (
		<Provider store={store}>
			<SocketProvider>
				<Router store={store} history={history} />
			</SocketProvider>
		</Provider>
	)
}

export default App

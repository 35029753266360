import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
const RowDiv = styled.div `
	display: flex;
	width: 100%;
	max-width: 300px;
	justify-content: space-between;
`;
const TitleWrapper = styled.span `
	color: ${({ theme }) => theme.color.gray6};
	margin-bottom: 0.5rem;
	width: 80px;
	${({ alignEnd }) => alignEnd &&
    css `
			text-align: end;
		`}
`;
export const RequiredDriverLicences = ({ driverLicences }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(RowDiv, null,
            React.createElement(TitleWrapper, null,
                React.createElement(FormattedMessage, { id: "offer_detail.details.licence_type" })),
            React.createElement(TitleWrapper, { alignEnd: true },
                React.createElement(FormattedMessage, { id: "offer_detail.details.mileage" }))),
        (driverLicences || []).map((licence, i) => {
            const { mileage } = licence;
            const driverLicence = licence.driving_licence_type;
            return (
            // eslint-disable-next-line react/no-array-index-key
            React.createElement(RowDiv, { key: `${i}-${driverLicence}` },
                React.createElement("div", null, driverLicence),
                Boolean(mileage) && React.createElement("div", null, mileage)));
        })));
};

import React from 'react'
import Icon from '@ant-design/icons'

function IconComponent(props) {
	// eslint-disable-next-line global-require
	// eslint-disable-next-line import/no-dynamic-require
	const component = require(`./custom/${props.type}`).default

	return <Icon {...props} component={component} />
}

export default IconComponent

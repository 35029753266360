import speakingurl from 'speakingurl';
import config from '../config-shared';

class Slug {
	constructor(options) {
		this.speakingurlConfig = {};
		if (config.locale) {
			this.speakingurlConfig.lang = config.locale;
		}

		this.text = options.text;
		this.prefix = options.prefix;
		this.suffix = options.suffix;

		this.result = this.getSlug();
	}

	getSlug() {
		let result = speakingurl(this.text, this.speakingurlConfig);

		if (this.prefix) {
			result = `${this.prefix}-${result}`;
		}

		if (this.suffix) {
			result = `${result}-${this.suffix}`;
		}

		return result;
	}

	toString() {
		return this.result;
	}
}

export default Slug;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EditOutlined } from '@ant-design/icons';
import { ActionsPanel, ActionButton } from './ApplicationCardActionsPanel.style';
export function ApplicationCardActionsPanel({ openSendMessageModal, selectedCount }) {
    if (selectedCount === 0) {
        return null;
    }
    return (React.createElement(ActionsPanel, null,
        React.createElement("div", { className: "flex-row align-center justify-space-between" },
            React.createElement("div", { className: "flex-center flex-1" },
                React.createElement(FormattedMessage, { values: { count: selectedCount }, id: "admin.applicants.plural" })),
            React.createElement("div", null,
                React.createElement(ActionButton, { onClick: () => openSendMessageModal() },
                    React.createElement(EditOutlined, { className: "p-r-xs" }),
                    React.createElement(FormattedMessage, { id: "admin.applicants.write_message" }))))));
}

import React from 'react';

const Spinner = function () {
	return (
		<div className="spinner">
			<div className="spinner__bounce-1" />
			<div className="spinner__bounce-2" />
		</div>
	);
};

export default Spinner;

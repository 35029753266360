import React from 'react'
import { Col, Row } from 'antd'

const renderRadioGroup = ({ input, label, meta: { touched, error }, options, disabled }) => (
	<div className="form__row">
		<Row>
			<Col xs={24} id={input.name}>
				<label className="form__label" htmlFor={input.name}>
					{label}
				</label>
				{options.map(item => (
					<div key={item.title}>
						<label>
							<input
								className="form__input-radiobutton"
								data-cy={item.value}
								type="radio"
								{...input}
								value={item.value}
								checked={item.value === input.value}
								disabled={disabled}
							/>
							{item.title}
						</label>
					</div>
				))}
				{touched && error && <div className="form__input-error">{error}</div>}
			</Col>
		</Row>
	</div>
)

export default renderRadioGroup

import React from 'react'
import classNames from 'classnames'
import { Col, Row } from 'antd'
import RichTextEditor from '../global/rich-text-editor'

const renderRichTextEditor = ({ input, label, disabled, meta: { touched, error } }) => {
	const getInputClasses = show =>
		classNames({
			'form__rich-text-editor': true,
			'form__input--error': show.error,
			'form__rich-text-editor--disabled': show.disabled,
		})
	return (
		<div className="form__row">
			<Row>
				<Col xs={24} data-cy="rich-text" id={input.name}>
					<label className="form__label" htmlFor={input.name}>
						{label}
					</label>
					<RichTextEditor
						className={getInputClasses({
							error: touched && error,
							disabled,
						})}
						input={input}
						readOnly={disabled}
					/>
					{touched && error && <div className="form__input-error">{error}</div>}
				</Col>
			</Row>
		</div>
	)
}

export default renderRichTextEditor

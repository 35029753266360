import React from 'react';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Avatar } from '@src/components/elements';
import { EOfferApplicationStage } from '@src/types/offer-application';
import { roundToNearestMinutes } from 'date-fns';
import { DateSelector } from './ApplicationColumnsSendMessageModalStep.style';
function roundNumberToNearestStep(val, min, max, step) {
    if (val > max) {
        return max;
    }
    if (val < min) {
        return min;
    }
    return Math.ceil(val / step) * step;
}
const inputNumberConfig = {
    min: 10,
    max: 8 * 60,
    step: 10,
};
export function ApplicationColumnsSendMessageModalStep({ updateInterviewDate, updateInterviewDurationMinutes, updateMessage, applications, message, type, }) {
    const intl = useIntl();
    return (React.createElement("div", { className: "flex-col" },
        applications.map(app => (React.createElement("div", { key: `send-message-modal-step-application-${app.id}`, className: "m-b-m flex-row align-center" },
            React.createElement(Avatar, { shape: "circle", size: "small", className: "m-r-s", fullName: [app.account.first_name, app.account.last_name] }),
            React.createElement("div", { className: "m-r-m" },
                app.account.first_name,
                " ",
                app.account.last_name),
            type === EOfferApplicationStage.interview && (React.createElement(React.Fragment, null,
                React.createElement(DateSelector, { popupStyle: { zIndex: 10001 }, className: "flex-1", value: app.interviewDate, placeholder: intl.formatMessage({
                        id: 'admin.applicants.send_message.set_interview_time',
                    }), 
                    // @ts-ignore
                    showTime: {
                        minuteStep: 15,
                        format: 'HH:mm',
                    }, disabledDate: (current) => current && current < moment().subtract(1, 'd').endOf('day'), onSelect: (interviewDate) => {
                        const roundedTimeDate = moment(roundToNearestMinutes(interviewDate.toDate(), { nearestTo: 15 }));
                        updateInterviewDate(app.id, roundedTimeDate);
                    }, format: "D. M. YYYY HH:mm" }),
                React.createElement("div", { className: "m-l-m flex-center", style: { maxWidth: '150px' } },
                    React.createElement(Input, { onChange: e => updateInterviewDurationMinutes(app.id, Number(e.target.value)), onBlur: () => updateInterviewDurationMinutes(app.id, roundNumberToNearestStep(app.interview_duration_minutes, inputNumberConfig.min, inputNumberConfig.max, inputNumberConfig.step)), type: "number", value: app.interview_duration_minutes, min: inputNumberConfig.min, step: inputNumberConfig.step, suffix: intl.formatMessage({
                            id: 'admin.applicants.send_message.interview_duration_minutes.minutes',
                        }), max: inputNumberConfig.max }))))))),
        React.createElement("p", { className: "m-b-m m-t-l font-bold" },
            React.createElement(FormattedMessage, { id: "admin.applicants.send_message.message" }),
            " *"),
        React.createElement(TextArea, { value: message, onChange: e => updateMessage(e.target.value), rows: 8 })));
}

import React from 'react';
import { Tooltip as ReactTooltip } from 'react-lightweight-tooltip';

const greenRoundedStyle = {
	wrapper: {
		display: 'block',
		zIndex: 0,
	},
	tooltip: {
		pointerEvents: 'none',
		background: '#FFF',
		border: '1px solid #FBEADE',
		width: 'calc(90vw - 2em)',
		minWidth: '250px',
		maxWidth: 'calc(100% - 2em)',
		WebkitTransform: '0',
		msTransform: '0',
		OTransform: '0',
		transform: '0',
		left: '0',
		boxShadow: '0px 3px 15px rgba(0,0,0,0.1)',
		borderRadius: '4px',
		padding: '1em',
		fontSize: '0.8rem',
	},
	content: {
		color: '#555555',
		background: '#FFF',
		fontSize: 'inherit',
		padding: '0',
	},
	arrow: {
		display: 'none',
	},
};
const greenRoundedStyleLeft = {
	wrapper: {
		display: 'block',
		zIndex: 0,
	},
	tooltip: {
		pointerEvents: 'none',
		background: '#FFF',
		border: '1px solid #FBEADE',
		width: 'calc(130vw - 2em)',
		minWidth: '250px',
		maxWidth: '100%',
		WebkitTransform: '0',
		msTransform: '0',
		OTransform: '0',
		transform: '0',
		left: '-103%',
		bottom: '-68%',
		boxShadow: '0px 3px 15px rgba(0,0,0,0.1)',
		borderRadius: '4px',
		padding: '1em',
		fontSize: '0.8rem',
	},
	content: {
		display: 'inline-block',
		color: '#555555',
		background: '#FFF',
		fontSize: 'inherit',
		padding: '0',
	},
	arrow: {
		display: 'none',
	},
};

const Tooltip = props => (
	<ReactTooltip
		content={props.content}
		styles={props.position === 'left' ? greenRoundedStyleLeft : greenRoundedStyle}
	>
		{props.children}
	</ReactTooltip>
);

export default Tooltip;

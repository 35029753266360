import React from 'react';
import _get from 'lodash/get';
import { useHistory, useLocation } from 'react-router-dom';
import { Holder, Link, Panel } from '@src/components/elements';
import { connect, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';
import axios from '@src/api/clients/axios';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import config from '@src/config';
import actions from 'actions';
import FormResponse from 'components/modules/global/form-response';
import ProfileBox from 'components/modules/global/profile-box';
import { getPathWithoutLocale, getLanguageFromPathname } from '@src/shared/localized-path';
import { Language } from '@src/context/app-context';
import { Col, Row } from 'antd';
import { routes } from '@src/router/Routes';
const Profile = ({ user, children }) => {
    const [isInitialized, setIsInitialized] = React.useState(false);
    const isStudent = _get(user, 'account_type_id') === config.accountType.student;
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    React.useEffect(() => {
        axios
            .get('/0/auth-check')
            .then(response => {
            dispatch(actions.authCheckSuccess(response));
            dispatch(actions.loadMyOfferApplicationsStart());
            setIsInitialized(true);
        })
            .catch(() => {
            // @ts-ignore
            history.pushLocalized('/prihlasenie?ref=back');
        });
    }, []);
    React.useEffect(() => {
        if (isInitialized && !user.email) {
            // @ts-ignore
            history.pushLocalized('/prihlasenie');
        }
    }, [user]);
    if (!isInitialized) {
        return null;
    }
    const getIsCurrentRouteId = routeId => {
        const { pathWithoutLocale } = getPathWithoutLocale(location.pathname.substring(1));
        const language = getLanguageFromPathname(location.pathname);
        const profileRoute = routes.find(route => route.id === '/profil');
        const expectedRoute = profileRoute.routes.find(route => route.id === routeId);
        if (language === Language.sk) {
            return expectedRoute.paths[language] === location.pathname;
        }
        return expectedRoute.paths[language] === pathWithoutLocale;
    };
    return (React.createElement(Holder, { marginVertical: true },
        !isStudent && (React.createElement(Row, { justify: "center" },
            React.createElement(Col, { xs: 24, md: 18 },
                React.createElement(Panel, null,
                    React.createElement(FormResponse, { icon: {
                            color: 'stop',
                            type: 'cancel',
                        }, text: intl.formatMessage({
                            id: 'profile.access_to_student_profile',
                            defaultMessage: 'Prístup do profilu študenta je možný len po prihlásení do študentského účtu.',
                        }) }))))),
        isStudent && (React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24, md: 9, lg: 6 },
                React.createElement(ProfileBox, { firstName: user.first_name, lastName: user.last_name, email: user.email, link: "/profil", logout: () => dispatch(actions.logoutStart()) }),
                React.createElement("ul", { className: "profile__menu" },
                    React.createElement("li", { className: classNames({
                            'profile__menu-item': true,
                            active: getIsCurrentRouteId('/profil'),
                        }) },
                        React.createElement(Link, { className: "profile__menu-item-link", to: "/profil" },
                            React.createElement("span", { className: "profile__menu-item-button-text flex-center" },
                                React.createElement(FormattedMessage, { id: "profile.application_overview", defaultMessage: "Preh\u013Ead \u017Eiadost\u00ED" })))),
                    React.createElement("li", { className: classNames({
                            'profile__menu-item': true,
                            active: getIsCurrentRouteId('/profil/zivotopisy'),
                        }) },
                        React.createElement(Link, { className: "profile__menu-item-link", to: "/profil/zivotopisy" },
                            React.createElement("span", { className: "profile__menu-item-button-text flex-center" },
                                React.createElement(FormattedMessage, { id: "profile.resumes", defaultMessage: "\u017Divotopisy" })))),
                    React.createElement("li", { className: classNames({
                            'profile__menu-item': true,
                            active: getIsCurrentRouteId('/profil/nastavenia'),
                        }) },
                        React.createElement(Link, { className: "profile__menu-item-link", to: "/profil/nastavenia" },
                            React.createElement(FormattedMessage, { id: "profile.settings", defaultMessage: "Nastavenia" }))),
                    React.createElement("li", { className: classNames({
                            'profile__menu-item': true,
                            active: getIsCurrentRouteId('/profil/odber'),
                        }) },
                        React.createElement(Link, { className: "profile__menu-item-link", to: "/profil/odber" },
                            React.createElement(FormattedMessage, { id: "profile.new_offers_subscription", defaultMessage: "Odber nov\u00FDch pon\u00FAk" }))))),
            React.createElement(Col, { xs: 24, md: 15, lg: 18 }, children)))));
};
const mapStateToProps = state => ({
    user: _get(state, 'app.user', {}),
});
export default connect(mapStateToProps)(Profile);

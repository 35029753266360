import { call, put } from 'redux-saga/effects'
import { history, store } from '@src/app/index'
import axios from 'axios'
import URI from 'urijs'
import { notification } from 'antd'
import ReactGA from 'helpers/react-ga'
import config from 'config'
import actions from '../actions'

export function* offerEditInitStart(action) {
	const uri = new URI(`${config.api}/0/offer`)
	if (action.payload && action.payload.id) {
		uri.query({
			id: action.payload.id,
			slug: action.payload.slug,
			edit: true,
		})
	}
	const apiUri = uri.toString()

	const getData = () =>
		axios
			.get(apiUri, { withCredentials: true })
			.then(res => ({
				id: res.data.id,
				title: res.data.title,
				offer_type_id: `${res.data.offer_type_id}`,
				state: res.data.state,
				job_area: res.data.job_area,
				description: `${res.data.description} `,
				required: res.data.required,
				nice_to_have: res.data.nice_to_have,
				is_paid: res.data.is_paid === 1 ? 'true' : 'false',
				is_active: res.data.is_active === 1,
				salary_rate: res.data.salary_rate,
				salary_amount_from: res.data.salary_amount_from,
				salary_amount_to: res.data.salary_amount_to,
				salary_currency: res.data.salary_currency,
				salary_description: res.data.salary_description,
				address: res.data.address,
				city: res.data.city,
				benefits: res.data.benefits,
				duration_min: res.data.duration_min,
				duration_max: res.data.duration_max,
				duration_type: res.data.duration_type,
				deadline: res.data.deadline,
				contact_email: res.data.contact_email,
				resume_required: res.data.resume_required,
				motivational_required: res.data.motivational_required,
				contract_type_id: res.data.contract_type_id,
			}))
			.catch(err => {
				throw err
			})

	try {
		const response = yield call(getData)
		yield put(actions.offerEditInitSuccess(response))
	} catch (err) {
		yield put(actions.offerEditInitError(err))
	}
}

export function* offerEditInitError(err) {
	if (err.payload.response.status === 403 || err.payload.response.status === 404) {
		const redirectError = () => history.replace('/error')
		yield call(redirectError)
	}
	const showError = error => {
		notification.error({
			message: 'Chyba',
			description: error.payload.response.data.error,
		})
	}
	yield call(showError, err)
}

export function* offerEditStart(values) {
	const uri = new URI(`${config.api}/0/offer?id=${values.payload.id}`)
	const apiUri = uri.toString()

	const offerData = {
		...values.payload,
		is_paid: values.payload.is_paid === 'true',
	}

	const putData = () =>
		axios
			.put(apiUri, offerData, { withCredentials: true })
			.then(res => res)
			.catch(err => {
				throw err
			})

	try {
		const response = yield call(putData)
		yield put(actions.offerEditSuccess(response))
	} catch (err) {
		yield put(actions.offerEditError(err))
	}
}

export function* offerEditError({ payload }) {
	if (payload.response.status === 403) {
		const redirectError = () => history.pushLocalized('/error')
		yield call(redirectError)
	}
	const showError = () => {
		if (payload.response.status === 400) {
			store.dispatch(actions.adminToggleNotEnoughCreditsDialog(true))
			ReactGA.event({
				category: 'Company',
				action: 'Offers limit reached',
				label: 'Offer Edit',
			})
		} else {
			notification.error({
				message: 'Chyba',
				description: payload.response.data.error,
			})
		}
	}
	yield call(showError)
}

export function* offerEditSuccess() {
	notification.success({
		message: 'Upozornenie',
		description: 'Zmena údajov prebehla úspešne',
	})
	const redirect = () => history.pushLocalized('/admin')
	yield call(redirect)
}

export function* offerToggleStart(values) {
	const offerId = values.payload.id
	const uri = new URI(`${config.api}/0/offer?id=${offerId}`)
	const apiUri = uri.toString()

	const putData = data =>
		axios
			.put(apiUri, data, { withCredentials: true })
			.then(res => res)
			.catch(err => {
				throw err
			})

	try {
		yield call(putData, values.payload)
		yield put(actions.offerToggleSuccess(values.payload))
	} catch (err) {
		yield put(actions.offerToggleError({ err: err.response, offerId }))
	}
}

export function* offerToggleSuccess(value) {
	const notify = payload => {
		if (payload.is_active) {
			notification.success({
				message: 'Upozornenie',
				description: `vaša ponuka "${payload.title}" bola aktivovaná`,
			})
		} else {
			notification.error({
				message: 'Upozornenie',
				description: `vaša ponuka "${payload.title}" bola deaktivovaná`,
			})
		}
	}

	yield call(notify, value.payload)
}

export function* offerToggleError({ payload }) {
	const showError = () => {
		if (payload.err.status === 400) {
			store.dispatch(actions.adminToggleNotEnoughCreditsDialog(true))
			ReactGA.event({
				category: 'Company',
				action: 'Offers limit reached',
				label: 'Offers toggle is active',
			})
		} else {
			notification.error({
				message: 'Chyba',
				description: payload.err.data.error,
			})
		}
	}
	yield call(showError)
}

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, Fields } from 'redux-form';
import { isBefore, add } from 'date-fns';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
import { Link, Button as AntButton } from '@src/components/elements';
import { useIntl, FormattedMessage, injectIntl } from 'react-intl';
// field components
import showError from 'components/modules/forms/showError';
import renderField from 'components/modules/forms/renderField';
import renderCheckbox from 'components/modules/forms/renderCheckbox';
import renderRichTextEditor from 'components/modules/forms/renderRichTextEditor';
import renderDateTimePicker from 'components/modules/forms/renderDateTimePicker';
import renderMultiselect from 'components/modules/forms/renderMultiselect';
import renderRadioGroup from 'components/modules/forms/renderRadioGroup';
import Button from 'components/modules/global/button';
import { getConfig } from '@src/config';
import validate from 'components/pages/admin/modules/offer/validation/sync-validation';
import { store, history } from '@src/app/index';
import actions from 'actions';
import renderSimpleSelect from 'components/modules/forms/renderSimpleSelect';
import Tooltip from 'components/modules/global/tooltip';
import renderSalaryAmountFields from 'components/modules/forms/renderSalaryAmountFields';
import { EOfferType } from '@src/types';
import { Col, Row } from 'antd';
let OfferEditFormComponent = props => {
    const { handleSubmit, offerId, offerTitle, change, deadline, isHomeOffice } = props;
    // @ts-ignore
    const isDuplicate = history.getQuery && history.getQuery().duplicate === 'true';
    const intl = useIntl();
    const isBelowSm = useBreakpoint(down('sm'));
    const config = getConfig(intl.locale);
    React.useEffect(() => {
        const isDeadlineInPast = isBefore(new Date(deadline), new Date());
        if (isDuplicate && isDeadlineInPast) {
            change('deadline', add(new Date(), { days: 31 }));
        }
    }, [deadline, isDuplicate]);
    return (React.createElement("form", { className: "form", onSubmit: handleSubmit },
        React.createElement(Field, { component: renderField, name: "title", label: intl.formatMessage({
                id: 'admin.offer_form.title',
                defaultMessage: 'Názov pozície ',
            }) }),
        React.createElement(Field, { label: intl.formatMessage({
                id: 'admin.offer_form.choose_position_type',
                defaultMessage: 'Vyberte typ pozície ',
            }), 
            // @ts-ignore
            component: renderRadioGroup, name: "offer_type_id", onChange: (event, newValue) => {
                const newOfferType = Number(newValue);
                if ([EOfferType.graduate, EOfferType.work_and_travel, EOfferType.temporary_job].includes(newOfferType)) {
                    change('is_paid', 'true');
                    change('duration_min', null);
                    change('duration_max', null);
                    change('duration_type', null);
                }
                else if (newOfferType === EOfferType.internship) {
                    change('contract_type_id', null);
                }
                else if (newOfferType === EOfferType.volunteering) {
                    change('is_paid', 'false');
                    change('duration_min', null);
                    change('duration_max', null);
                    change('duration_type', null);
                    change('contract_type_id', null);
                }
            }, options: config.offerTypes }),
        props.offerType !== EOfferType.work_and_travel && (React.createElement(Field
        // @ts-ignore
        , { 
            // @ts-ignore
            component: renderCheckbox, name: "home_office", label: "Je t\u00E1to pr\u00E1ca z domu?", onChange: (event, value) => {
                if (value) {
                    change('state', [{ id: 9, name: 'Práca z domu' }]);
                    change('address', null);
                    change('city', null);
                }
                else {
                    change('state', null);
                }
            }, type: "checkbox" })),
        React.createElement("div", { className: "form__row" },
            React.createElement(Row, { gutter: [16, 16] },
                React.createElement(Col, { xs: 24, md: 12 },
                    React.createElement(Field, { component: renderMultiselect, name: "job_area", onBlur: () => props.onBlur(), data: config.jobAreas, label: intl.formatMessage({
                            id: 'admin.offer_form.choose_job_area',
                            defaultMessage: 'Vyberte zameranie *',
                        }) })),
                !isHomeOffice && (React.createElement(Col, { xs: 24, md: 12 },
                    React.createElement(Field, { component: renderMultiselect, name: "state", onBlur: () => props.onBlur(), label: intl.formatMessage({
                            id: 'admin.offer_form.choose_state',
                            defaultMessage: 'Vyberte kraj výkonu *',
                        }), data: config.states }))))),
        React.createElement(Field, { component: renderRichTextEditor, name: "description", label: intl.formatMessage({
                id: 'admin.offer_form.description',
                defaultMessage: 'Náplň práce *',
            }), type: "textfield" }),
        React.createElement(Field, { component: renderRichTextEditor, name: "required", label: intl.formatMessage({
                id: 'admin.offer_form.required',
                defaultMessage: 'Čo požadujete od kandidáta? *',
            }), type: "textfield" }),
        React.createElement(Field, { component: renderRichTextEditor, name: "nice_to_have", label: intl.formatMessage({
                id: 'admin.offer_form.nice_to_have',
                defaultMessage: 'Výhodou pre kandidáta je *',
            }), type: "textfield" }),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Field, { component: renderDateTimePicker, name: "deadline", label: intl.formatMessage({
                        id: 'admin.offer_form.deadline',
                        defaultMessage: 'Dátum uzávierky *',
                    }), time: false }),
                React.createElement(Field, { label: intl.formatMessage({
                        id: 'admin.offer_form.is_position_paid',
                        defaultMessage: 'Bude pozícia platená? *',
                    }), 
                    // @ts-ignore
                    component: renderRadioGroup, name: "is_paid", onChange: (event, newValue) => {
                        if (newValue === 'false') {
                            change('salary_rate', null);
                            change('salary_amount_from', null);
                            change('salary_amount_to', null);
                            change('salary_currency', null);
                            change('salary_description', null);
                            change('duration_min', null);
                            change('duration_max', null);
                        }
                        else {
                            change('salary_currency', '€');
                        }
                    }, options: [
                        {
                            title: intl.formatMessage({
                                id: 'admin.offer_form.yes',
                                defaultMessage: 'áno',
                            }),
                            value: 'true',
                        },
                        {
                            title: intl.formatMessage({
                                id: 'admin.offer_form.no',
                                defaultMessage: 'nie',
                            }),
                            value: 'false',
                        },
                    ], disabled: props.offerType !== EOfferType.internship })),
            !isHomeOffice && (React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Field, { component: renderField, name: "address", label: intl.formatMessage({
                        id: 'admin.offer_form.address',
                        defaultMessage: 'Adresa výkonu *',
                    }) }),
                React.createElement(Field, { component: renderField, name: "city", label: intl.formatMessage({
                        id: 'admin.offer_form.city',
                        defaultMessage: 'Mesto výkonu *',
                    }) })))),
        props.isPaid === 'true' && (React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24, lg: 8 },
                React.createElement(Field, { component: renderSimpleSelect, name: "salary_rate", label: intl.formatMessage({
                        id: 'admin.offer_form.salary_rate',
                    }), options: config.salaryRates, placeholder: intl.formatMessage({
                        id: 'admin.offer_form.choose',
                        defaultMessage: 'Vyberte...',
                    }), clearable: false })),
            React.createElement(Col, { xs: 24, lg: 10 },
                React.createElement(Tooltip, { content: [
                        React.createElement("p", { key: "tooltip-salary-format" },
                            React.createElement(FormattedMessage, { id: "admin.offer_form.to_enter_single_amount", values: {
                                    strong: chunks => React.createElement("strong", null, chunks),
                                } })),
                    ] },
                    React.createElement(Fields, { names: ['salary_amount_from', 'salary_amount_to'], component: renderSalaryAmountFields, parse: value => (value === '' ? null : Number(value)), amountFromLabel: "Minimum\u00A0*", amountToLabel: "Maximum" }))),
            React.createElement(Col, { xs: 24, lg: 6 },
                React.createElement(Field, { component: renderSimpleSelect, name: "salary_currency", label: intl.formatMessage({
                        id: 'admin.offer_form.currency',
                        defaultMessage: 'Mena *',
                    }), options: config.salaryCurrencies, clearable: false, disabled: true })),
            React.createElement(Col, { xs: 24 },
                React.createElement(Field, { component: renderField, name: "salary_description", label: intl.formatMessage({
                        id: 'admin.offer_form.salary_description',
                    }) })))),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Field, { component: renderRichTextEditor, name: "benefits", label: intl.formatMessage({
                        id: 'admin.offer_form.benefits',
                        defaultMessage: 'Aké benefity ponúkate?',
                    }) })),
            React.createElement(Col, { xs: 24, md: 12 },
                props.offerType === EOfferType.internship && (React.createElement(Tooltip, { position: isBelowSm ? 'top' : 'left', content: [
                        React.createElement("div", { key: "tooltip-internship-length" },
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "admin.offer_form.setting_from_value", defaultMessage: "Nastaven\u00EDm hodnoty <i>OD</i> samostatne, ur\u010D\u00EDte minim\u00E1lnu d\u013A\u017Eku st\u00E1\u017Ee:", values: {
                                        i: chunks => React.createElement("i", null, chunks),
                                    } })),
                            React.createElement("p", { className: "u-text-center u-text-bold" },
                                React.createElement(FormattedMessage, { id: "admin.offer_form.example_minimal", defaultMessage: "Minim\u00E1lne 3 mesiace" })),
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "admin.offer_form.setting_from_to_same_values", defaultMessage: "Nastaven\u00EDm <i>OD - DO</i> rovnak\u00FDmi hodnotami, ur\u010D\u00EDte pevn\u00FA d\u013A\u017Eku st\u00E1\u017Ee:", values: {
                                        i: chunks => React.createElement("i", null, chunks),
                                    } })),
                            React.createElement("p", { className: "u-text-center u-text-bold" },
                                React.createElement(FormattedMessage, { id: "admin.offer_form.example_exact", defaultMessage: "3 mesiace" })),
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "admin.offer_form.setting_from_to_different_values", defaultMessage: "Nastaven\u00EDm <i>OD - DO</i> r\u00F4znymi hodnotami, ur\u010D\u00EDte rozsah d\u013A\u017Eky st\u00E1\u017Ee:", values: {
                                        i: chunks => React.createElement("i", null, chunks),
                                    } })),
                            React.createElement("p", { className: "u-text-center u-text-bold" },
                                React.createElement(FormattedMessage, { id: "admin.offer_form.example_range", defaultMessage: "3 - 6 mesiacov" })),
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "admin.offer_form.internship_type_is_same", defaultMessage: "Typ st\u00E1\u017Ee plat\u00ED pre obe hodnoty. Nie je mo\u017En\u00E9 ich kombinova\u0165" }))),
                    ] },
                    React.createElement(Row, { gutter: [16, 16] },
                        React.createElement(Col, { xs: 12, lg: 12 },
                            React.createElement(Field, { component: renderField, noWhiteSpaceWrap: true, name: "duration_min", label: intl.formatMessage({
                                    id: 'admin.offer_form.duration_from',
                                    defaultMessage: 'Dĺžka stáže od *',
                                }), type: "number", disabled: props.offerType === EOfferType.graduate })),
                        React.createElement(Col, { xs: 12, lg: 12 },
                            React.createElement(Field, { component: renderField, name: "duration_max", label: intl.formatMessage({
                                    id: 'admin.offer_form.duration_to',
                                    defaultMessage: 'Dĺžka stáže do',
                                }), type: "number", disabled: props.offerType === EOfferType.graduate })),
                        React.createElement(Col, { xs: 24 },
                            React.createElement(Field, { component: renderSimpleSelect, name: "duration_type", label: intl.formatMessage({
                                    id: 'admin.offer_form.duration_type',
                                    defaultMessage: 'Typ *',
                                }), options: config.durationTypes, placeholder: intl.formatMessage({
                                    id: 'admin.offer_form.choose',
                                    defaultMessage: 'Vyberte...',
                                }), clearable: false, disabled: props.offerType === EOfferType.graduate }))))),
                [EOfferType.graduate].includes(props.offerType) && (React.createElement(Field, { component: renderSimpleSelect, name: "contract_type_id", label: intl.formatMessage({
                        id: 'admin.offer_form.contract_type',
                        defaultMessage: 'Typ úväzku *',
                    }), options: config.contractTypes, placeholder: intl.formatMessage({
                        id: 'admin.offer_form.choose',
                        defaultMessage: 'Vyberte...',
                    }), clearable: false })))),
        React.createElement("h2", { className: "form__title" },
            React.createElement(FormattedMessage, { id: "admin.offer_form.documents_required", defaultMessage: "Ak\u00E9 dokumenty po\u017Eadujete od kandid\u00E1ta?" })),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Field, { component: renderCheckbox, name: "resume_required", label: intl.formatMessage({
                        id: 'admin.offer_form.resume',
                        defaultMessage: 'Životopis',
                    }), type: "checkbox" }),
                React.createElement(Field, { component: renderCheckbox, name: "motivational_required", label: intl.formatMessage({
                        id: 'admin.offer_form.motivational_letter',
                        defaultMessage: 'Motivačný list',
                    }), type: "checkbox" })),
            React.createElement(Col, { xs: 24, md: 12 },
                React.createElement(Field, { component: renderField, name: "contact_email", label: intl.formatMessage({
                        id: 'admin.offer_form.contact_email',
                        defaultMessage: 'Na aký email Vám má chodiť odpoveď? *',
                    }), type: "email" }))),
        React.createElement("div", { className: "form__submit" },
            React.createElement("div", { className: "form__submit-item", "data-cy": "form-submit" },
                React.createElement(AntButton, { htmlType: "submit", type: "primary", loading: props.submitting }, isDuplicate
                    ? intl.formatMessage({
                        id: 'admin.offer_form.publish',
                        defaultMessage: 'Zverejniť',
                    })
                    : intl.formatMessage({
                        id: 'admin.offer_form.save_changes',
                        defaultMessage: 'Uložiť zmeny',
                    }))),
            React.createElement("div", { className: "form__submit-item" },
                React.createElement(Link, { className: "link link--basic", to: "/admin", "data-cy": "cancel-edit" },
                    React.createElement(FormattedMessage, { id: "admin.offer_form.cancel_changes", defaultMessage: "Zru\u0161i\u0165 edit\u00E1ciu" })))),
        !isDuplicate && (React.createElement("div", { className: "form__delete", "data-cy": "delete-button" },
            React.createElement("div", { className: "form__label" },
                React.createElement(FormattedMessage, { id: "admin.offer_form.completly_remove_offer", defaultMessage: "Chcete ponuku \u00FAplne vymaza\u0165?" })),
            React.createElement("div", null,
                React.createElement(Button, { className: "link link--basic", text: intl.formatMessage({
                        id: 'admin.offer_form.delete',
                        defaultMessage: 'Zmazať',
                    }), color: "danger", type: "button", dataCy: "delete-confirm", onClick: () => {
                        if (
                        // eslint-disable-next-line no-restricted-globals
                        confirm(intl.formatMessage({
                            id: 'admin.offer_form.sure_to_delete',
                            defaultMessage: 'Určite chcete vymazať ponuku: {offerTitle}',
                        }, { offerTitle }))) {
                            store.dispatch(actions.offerDeleteStart(offerId));
                        }
                    } })))),
        React.createElement("div", { className: "bottom-line" },
            React.createElement(FormattedMessage, { id: "admin.offer_form.required_fields", defaultMessage: "* Polia ozna\u010Den\u00E9 hviezdi\u010Dkou s\u00FA povinn\u00E9" }))));
};
// @ts-ignore
OfferEditFormComponent = reduxForm({
    form: 'offerEdit',
    destroyOnUnmount: true,
    enableReinitialize: true,
    onSubmitFail: showError,
    // @ts-ignore
    validate,
})(OfferEditFormComponent);
const selector = formValueSelector('offerEdit');
// @ts-ignore
OfferEditFormComponent = connect(state => {
    // can select values individually
    const isPaid = selector(state, 'is_paid');
    const offerType = Number(selector(state, 'offer_type_id'));
    const isHomeOffice = selector(state, 'home_office');
    const deadline = selector(state, 'deadline');
    return {
        isPaid,
        offerType,
        isHomeOffice,
        deadline,
    };
})(OfferEditFormComponent);
// exporting const because of no-mutable-exports
const OfferEditForm = OfferEditFormComponent;
export default injectIntl(OfferEditForm);

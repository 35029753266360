/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SearchOutlined } from '@ant-design/icons';
import { Tree } from 'antd';
import { Separator, Spinner, Input } from '@src/components/elements';
import { ABROAD, REMOTE } from '@src/types';
import { normalizeStr } from '@src/helpers/normalize-string';
import { useAppContextValue } from '@src/context/app-context';
import { FilterCount, FilterTitle } from './filter/Filter.style';
import { useOffersFilterContextValue } from '../../filter-context';
const SLOVAKIA_LOCATION_ID = 1; // todo better ?
function buildLocationTree(currentLocations, selectedLocations, intl) {
    return currentLocations
        .map(location => {
        var _a;
        const result = {
            title: intl.formatMessage({
                id: `${location.translation_key}`,
            }),
            count: location.count,
            key: location.id,
            disabled: !location.count && !selectedLocations.includes(location.id),
        };
        if ((_a = location.sublocations) === null || _a === void 0 ? void 0 : _a.length) {
            result.children = buildLocationTree(location.sublocations, selectedLocations, intl);
        }
        return result;
    })
        .sort((a, b) => {
        if (a.disabled && !b.disabled) {
            return 1;
        }
        if (!a.disabled && b.disabled) {
            return -1;
        }
        return 0;
    });
}
function getFilteredLocationTree(searchValue, currentNodes) {
    var _a, _b;
    if (!(searchValue === null || searchValue === void 0 ? void 0 : searchValue.length)) {
        return currentNodes;
    }
    const result = [];
    for (const node of currentNodes) {
        const isMatchingSearchValue = normalizeStr(node.title).indexOf(normalizeStr(searchValue)) >= 0;
        if (!((_a = node.children) === null || _a === void 0 ? void 0 : _a.length) && isMatchingSearchValue) {
            result.push(node);
        }
        if ((_b = node.children) === null || _b === void 0 ? void 0 : _b.length) {
            const filteredLocationSubNodes = getFilteredLocationTree(searchValue, node.children);
            if (filteredLocationSubNodes.length) {
                node.children = filteredLocationSubNodes;
            }
            if (isMatchingSearchValue || filteredLocationSubNodes.length) {
                result.push(node);
            }
        }
    }
    return result;
}
function getExpandedKeys(searchValue, currentNodes) {
    var _a;
    const result = [];
    for (const node of currentNodes) {
        if ((_a = node.children) === null || _a === void 0 ? void 0 : _a.length) {
            const expandedKeysInSubTree = getExpandedKeys(searchValue, node.children);
            const isAtLeastOneChildExpandedOrHasMatchingTitle = node.children.find(child => normalizeStr(child.title).indexOf(normalizeStr(searchValue)) >= 0 ||
                expandedKeysInSubTree.includes(child.key));
            if (isAtLeastOneChildExpandedOrHasMatchingTitle) {
                result.push(node.key);
            }
            for (const key of expandedKeysInSubTree) {
                result.push(key);
            }
        }
    }
    return result;
}
function getParentTreeNodeById(targetLocationId, currentTreeNodes) {
    for (const treeNode of currentTreeNodes) {
        if (treeNode.children) {
            for (const childNode of treeNode.children) {
                if (childNode.key === targetLocationId) {
                    return treeNode;
                }
            }
            const parent = getParentTreeNodeById(targetLocationId, treeNode.children);
            if (parent) {
                return parent;
            }
        }
    }
    return null;
}
function isAncestorNodeSelected(locationId, selectedLocations, treeNodes) {
    const parent = getParentTreeNodeById(locationId, treeNodes);
    if (!parent) {
        return false;
    }
    return (selectedLocations.includes(parent.key) ||
        isAncestorNodeSelected(parent.key, selectedLocations, treeNodes));
}
function TitleRender({ treeNode, isAtLeastOneLocationSelected, isLoading, isSelected, isParentSelected, }) {
    return (React.createElement("div", { className: "m-l-s word-break-keep-all" },
        treeNode.title,
        !isLoading && !isSelected && !isParentSelected && (React.createElement(FilterCount, null,
            Boolean(treeNode.count) && (React.createElement(React.Fragment, null,
                isAtLeastOneLocationSelected && ` (+${treeNode.count})`,
                !isAtLeastOneLocationSelected && ` (${treeNode.count})`)),
            !treeNode.count && ` (0)`)),
        isLoading && React.createElement(Spinner, { size: "small", delay: 0, className: "m-l-xs flex-inline align-center" })));
}
function LocationFilter({ locationFilterItems, isLoading }) {
    const { locationFiltersSelected, setLocationFilersSelected } = useOffersFilterContextValue();
    const [searchValue, setSearchValue] = React.useState('');
    const [expandedKeys, setExpandedKeys] = React.useState([SLOVAKIA_LOCATION_ID]);
    const appContext = useAppContextValue();
    const intl = useIntl();
    const isAtLeastOneLocationSelected = locationFiltersSelected.length > 0;
    const locationTreeData = [
        {
            title: intl.formatMessage({ id: 'location.abroad' }),
            count: locationFilterItems.abroadOfferCount,
            key: ABROAD,
            disabled: locationFilterItems.abroadOfferCount === 0 && !locationFiltersSelected.includes(ABROAD),
        },
        {
            title: intl.formatMessage({ id: 'location.remote' }),
            count: locationFilterItems.remoteOfferCount,
            key: REMOTE,
            disabled: locationFilterItems.remoteOfferCount === 0 && !locationFiltersSelected.includes(REMOTE),
        },
        ...buildLocationTree(locationFilterItems.locations, locationFiltersSelected, intl),
    ];
    const filteredLocationTree = React.useMemo(() => getFilteredLocationTree(searchValue, locationTreeData), [searchValue, locationFilterItems, appContext.language]);
    React.useEffect(() => {
        if (!(searchValue === null || searchValue === void 0 ? void 0 : searchValue.length)) {
            setExpandedKeys([SLOVAKIA_LOCATION_ID]);
        }
        else {
            setExpandedKeys(getExpandedKeys(searchValue, locationTreeData));
        }
    }, [searchValue]);
    function removeUselessLocations(checkedLocations) {
        // if all sibling locations are selected, ignore them, they are covered by parent
        return checkedLocations.filter((checkedLocation) => {
            if (checkedLocation === REMOTE || checkedLocation === ABROAD) {
                return true;
            }
            const parentLocation = getParentTreeNodeById(checkedLocation, locationTreeData);
            if (!parentLocation) {
                return true;
            }
            const siblings = parentLocation.children.filter(childNode => childNode.key !== checkedLocation);
            if (!siblings.length) {
                return false;
            }
            return siblings.some(sib => !checkedLocations.includes(sib.key) && !sib.disabled);
        });
    }
    function handleCheck(checkedLocations) {
        setLocationFilersSelected(removeUselessLocations(checkedLocations));
    }
    function handleExpand(newExpandedKeys) {
        setExpandedKeys(newExpandedKeys);
    }
    return (React.createElement("div", null,
        React.createElement(FilterTitle, null,
            React.createElement(FormattedMessage, { id: "offers.filter.location" })),
        React.createElement(Separator, null),
        React.createElement(Input, { prefix: React.createElement(SearchOutlined, null), placeholder: intl.formatMessage({ id: 'offers.locations_filter.search_placeholder' }), className: "m-b-l", onChange: e => setSearchValue(e.target.value) }),
        React.createElement("div", { className: "m-b-l font-size-m" },
            React.createElement(Tree, { checkedKeys: locationFiltersSelected, checkable: true, treeData: filteredLocationTree, expandedKeys: expandedKeys, selectable: false, titleRender: treeNode => (React.createElement(TitleRender, { treeNode: treeNode, isAtLeastOneLocationSelected: isAtLeastOneLocationSelected, isLoading: isLoading, isSelected: locationFiltersSelected.includes(treeNode.key), isParentSelected: isAncestorNodeSelected(treeNode.key, locationFiltersSelected, locationTreeData) })), onCheck: handleCheck, onExpand: handleExpand }))));
}
export { LocationFilter };

import React from 'react'
import axios from 'axios'
import config from 'config'
import { history } from '@src/app/index'
import { useIntl, FormattedMessage } from 'react-intl'
import { OfferNotificationSubscriptionForm } from 'components/modules/forms/offer-notification-subscription-form'
import { Holder, Link } from '@src/components/elements'
import LoadingWrapper from 'components/modules/global/loading-wrapper'
import { Col, Row } from 'antd'
import Button from '../../modules/global/button'

const offerNotificationSubscription = () => {
	const [subscription, setSubscription] = React.useState({})
	const [subscriptionJustDeleted, setSubscriptionJustDeleted] = React.useState(false)
	const [isLoading, setIsLoading] = React.useState(true)
	const intl = useIntl()

	const initialize = React.useCallback(async () => {
		setIsLoading(true)
		try {
			const token = history.getQuery().onst
			const apiUri = `${config.api}/0/offer-notification-subscription${token ? `?onst=${token}` : ''}`
			const response = await axios.get(apiUri, { withCredentials: true })
			setSubscription(response.data)
		} catch (err) {
			if (subscription.id) {
				setSubscriptionJustDeleted(true)
			}
			setSubscription({})
		} finally {
			setIsLoading(false)
		}
	}, [subscription.id])

	React.useEffect(() => {
		initialize()
	}, [initialize])

	return (
		<LoadingWrapper isLoading={isLoading}>
			{subscription.id ? (
				<Holder marginVertical>
					<Row gutter={[16, 16]}>
						<Col xs={24}>
							<h1 className="headline headline--1 headline--line-behind">
								<FormattedMessage
									id="offer_notification_subscription.new_subscription"
									defaultMessage="Odoberanie nových ponúk"
								/>
							</h1>
						</Col>
						<Col xs={24}>
							<div className="panel">
								<OfferNotificationSubscriptionForm
									subscription={subscription}
									onSuccess={initialize}
									intl={intl}
								/>
							</div>
						</Col>
					</Row>
				</Holder>
			) : (
				<Holder marginVertical>
					<Row gutter={[16, 16]}>
						<Col xs={24} className="text-center">
							<h1 className="headline headline--1 headline--line-behind">
								{subscriptionJustDeleted
									? intl.formatMessage({
											id: 'offer_notification_subscription.subscription_successfully_deleted',
											defaultMessage: 'Odoberanie úspešne zmazané',
									  })
									: intl.formatMessage({
											id: 'offer_notification_subscription.subscription_not_found',
											defaultMessage: 'Odoberanie nebolo nájdené',
									  })}
							</h1>
							<div className="page-not-found__content">
								<p>
									{subscriptionJustDeleted
										? intl.formatMessage({
												id: 'offer_notification_subscription.subscription_deletion_was_successful',
												defaultMessage: 'Vymazanie odberu prebehlo úspešne',
										  })
										: intl.formatMessage({
												id: 'offer_notification_subscription.we_are_sorry_but',
												defaultMessage: 'Ľutujeme, ale hľadaný odber nebol nájdený',
										  })}
								</p>
							</div>
							<Link to="/">
								<Button
									color="brand"
									text={intl.formatMessage({
										id: 'offer_notification_subscription.continue_to_home_page',
										defaultMessage: 'Pokračovať na hlavnú stránku',
									})}
								/>
							</Link>
						</Col>
					</Row>
				</Holder>
			)}
		</LoadingWrapper>
	)
}

export default offerNotificationSubscription

import actionNames from '../actions';

const defaultState = {
	loginError: false,
	loginErrorCount: 0,
};

export default function login(state = defaultState, action) {
	switch (action.type) {
	case actionNames.loginError().type:
		return Object.assign({}, state, {
			loginError: true,
			loginErrorCount: state.loginErrorCount + 1,
		});

	case actionNames.loginSuccess().type:
		return Object.assign({}, state, {
			loginError: false,
			loginErrorCount: 0,
		});

	default:
		return state;
	}
}

/* eslint-disable react/no-danger */
import React from 'react';
import sanitizeHtml from 'sanitize-html';
const addImageSource = (input) => {
    const result = input.replace(/\/storage\/uploads\/original\//g, `${process.env.CMS_URL}/storage/uploads/body/`);
    return result;
};
const addImageCaptions = (input) => {
    const replacer = match => {
        const hasDescription = match.includes('alt=');
        if (hasDescription) {
            const altTextMatch = match.match(/alt="(.[^"]+)"/g);
            const altText = altTextMatch[0].slice(5, -1);
            return `${match}<div class="imageCaption"><small>${altText}</small></div>`;
        }
        return match;
    };
    const result = input.replace(/(<\s*\/?\s*)img(\s*([^>]*)?\s*>)/gi, replacer);
    return result;
};
const addRetinaImages = (input) => {
    const replacer = match => {
        const srcOriginal = match.match(/src="(.[^"]+)"/g)[0].slice(5, -1);
        const srcRetina = srcOriginal.replace('/body/', '/body_2x/');
        const result = match.replace('/>', `srcset="${srcOriginal} 1x, ${srcRetina} 2x" />`);
        return result;
    };
    const result = input.replace(/(<\s*\/?\s*)img(\s*([^>]*)?\s*>)/gi, replacer);
    return result;
};
const RenderHtml = ({ html, cmsImagePrefix = true, extended = false }) => {
    let result;
    if (extended) {
        result = sanitizeHtml(html, {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'br', 'iframe', 'blockquote']),
            allowedAttributes: {
                a: ['href', 'name', 'target'],
                img: ['src', 'alt'],
                iframe: ['src'],
            },
        });
    }
    else {
        result = sanitizeHtml(html);
    }
    if (cmsImagePrefix) {
        result = addImageSource(result);
    }
    result = addImageCaptions(result);
    result = addRetinaImages(result);
    return React.createElement("div", { dangerouslySetInnerHTML: { __html: result } });
};
export default RenderHtml;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from './clients/axios';
export function getOfferApplications() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get('/0/offer-applications');
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function getMyResumes() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get('/0/account-resume');
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function uploadResume(resume) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const data = new FormData();
            data.append('resume', resume);
            const response = yield axios.post('/0/account-resume', data);
            return response.data;
        }
        catch (err) {
            throw err;
        }
    });
}
export function archiveResume(e) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.put('/0/account-resume', {
                resume_id: e,
                is_archived: 1,
            });
            return yield response;
        }
        catch (err) {
            throw err;
        }
    });
}

import { call, put } from 'redux-saga/effects'
import { history } from '@src/app/index'
import axios from 'axios'
import URI from 'urijs'
import { notification } from 'antd'
import config from 'config'
import actions from '../actions'

export function* offerDeleteStart(action) {
	const uri = new URI(`${config.api}/0/offer?id=${action.payload}`)
	const apiUri = uri.toString()

	const deleteOffer = () =>
		axios
			.delete(apiUri, { withCredentials: true })
			.then(res => res)
			.catch(err => {
				throw err
			})

	try {
		const response = yield call(deleteOffer)
		yield put(actions.offerDeleteSuccess(response))
	} catch (err) {
		yield put(actions.offerDeleteError(err))
	}
}

export function* offerDeleteError(err) {
	const showError = () => {
		notification.error({
			message: 'Chyba',
			description: 'Užívateľ nie je prihlásený',
		})
	}
	yield call(showError, err)
}

export function* offerDeleteSuccess() {
	const showNotification = () => {
		notification.success({
			message: 'Upozornenie',
			description: 'Ponuka bola úspešne odstránená',
		})
	}
	yield call(showNotification)
	yield history.pushLocalized('/admin')
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Panel } from '@src/components/elements';
import IconBuildings from '@streamlinehq/streamlinehq/img/streamline-bold/building-construction/buildings/buildings-1.svg';
import cdnLink from '@src/shared/cdn-link';
import { useHistory } from 'react-router';
import * as Api from '@src/api';
import { useCompanyProfileBoxInfo } from './use-company-profile-box-info';
import { ImageBlockWrapper, CompanyName, Header, CompanyNumbers, CompanyNumbersTitle, ImageBlock, ProfileBoxBody, PrimaryColorWrapper, ProfileButton, } from './CompanyProfileBox.style';
export function CompanyProfileBox({ company, className, companyOfferCount, email }) {
    const [localCompany, setLocalCompany] = React.useState(company);
    const [isRefetchCompanyLoading, setIsRefetchCompanyLoading] = React.useState(false);
    const history = useHistory();
    const imageBackgroundUrl = localCompany.title_image_url
        ? cdnLink({
            file: `web/company/${localCompany.title_image_url}`,
            transformation: '',
        })
        : '/media/images/background-gradient.jpg';
    const isInAdmin = Boolean(email);
    React.useEffect(() => {
        setLocalCompany(company);
    }, [company]);
    const { isAccountSubscribed, subscribersCount, toggleSubscription, isSubscriptionLoading } = useCompanyProfileBoxInfo(localCompany);
    const refetchCompany = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!localCompany.id) {
            return;
        }
        try {
            setIsRefetchCompanyLoading(true);
            const freshCompany = yield Api.Company.getCompanyById(localCompany.id);
            setLocalCompany(freshCompany);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setIsRefetchCompanyLoading(false);
        }
    }), [localCompany]);
    React.useEffect(() => {
        refetchCompany();
    }, [subscribersCount]);
    /* @ts-ignore */
    const goToSettings = () => isInAdmin && history.pushLocalized('/admin/firemne-udaje');
    return (React.createElement(Panel, { className: `${className} p-none` },
        React.createElement(Header, { isInAdmin: isInAdmin, onClick: goToSettings, className: "flex-center", background: imageBackgroundUrl }),
        React.createElement(ImageBlockWrapper, null,
            React.createElement(ImageBlock, { isInAdmin: isInAdmin, onClick: goToSettings },
                company.image && (React.createElement("img", { src: cdnLink({
                        file: `web/company/${company.image}`,
                        transformation: '',
                    }), alt: "logo" })),
                !company.image && React.createElement(IconBuildings, { viewBox: "0 0 24 24" }))),
        React.createElement(ProfileBoxBody, null,
            React.createElement("div", null,
                React.createElement(CompanyName, null,
                    React.createElement(Link, { to: `/firma/${company.company_url}` }, company.name))),
            React.createElement(CompanyNumbers, null,
                React.createElement("div", { className: "flex-1 flex-col align-center" },
                    React.createElement(CompanyNumbersTitle, null,
                        React.createElement(FormattedMessage, { id: "company.profile_box.offers" })),
                    React.createElement("div", { className: "font-size-xl font-bold" }, companyOfferCount || 0)),
                React.createElement("div", { className: "flex-1 flex-col align-center" },
                    React.createElement(CompanyNumbersTitle, null,
                        React.createElement(FormattedMessage, { id: "company.profile_box.followers" })),
                    React.createElement("div", { className: "font-size-xl font-bold" }, subscribersCount))),
            React.createElement("div", { className: "flex-col justify-space-around p-t-m" },
                React.createElement(ProfileButton, { link: `/firma/${company.company_url}` },
                    React.createElement(PrimaryColorWrapper, null,
                        React.createElement(FormattedMessage, { id: "offer_detail.company_profile", defaultMessage: "Profil Firmy" })))))));
}

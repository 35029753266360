import {
	call,
	put,
} from 'redux-saga/effects';
import axios from 'axios';
import URI from 'urijs';
import config from 'config';
import actions from '../actions';

export function* loadMyResumesStart() {
	const uri = new URI(`${config.api}/0/account-resume`);
	const apiUri = uri.toString();

	const getData = () => (
		axios.get(apiUri, { withCredentials: true })
			.then(res => res)
			.catch((err) => { throw err; })
	);

	try {
		const response = yield call(getData);
		yield put(actions.loadMyResumesSuccess(response.data));
	} catch (err) {
		yield put(actions.loadMyResumesError(err));
	}
}

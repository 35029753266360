import actionNames from '../actions';

const defaultState = {
	personalInfoInit: {},
};

export default function personalInfo(state = defaultState, action) {
	switch (action.type) {

	case actionNames.personalInfoInitSuccess().type:
		return Object.assign({}, state, {
			personalInfoInit: action.payload.data,
		});

	default:
		return state;
	}
}

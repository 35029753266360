/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Link, Icon } from '@src/components/elements';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Col, Row, Tag } from 'antd';
import SlugOffer from 'shared/slug-offer';
import cdnLink from 'shared/cdn-link';
import { HomeOutlined } from '@ant-design/icons';
import Timestamp from 'components/modules/global/timestamp';
import CompanyImage from 'components/modules/global/company-image';
import getSalaryString from 'shared/get-salary-string';
const OfferIntoTagGroup = styled.div `
	display: flex;
	flex-direction: column;
	width: fit-content;

	${down('sm')} {
		width: 100%;
	}
`;
const Offer = props => {
    const intl = useIntl();
    const { address, salary_rate, salary_amount_from, salary_amount_to, salary_currency, updated_at, id, is_paid, title, company, address_id, } = props.values;
    const slug = new SlugOffer({
        text: title,
        suffix: id,
    });
    const isRemote = Boolean(address_id) === false;
    const salaryString = getSalaryString({
        rate: salary_rate,
        amountFrom: salary_amount_from,
        amountTo: salary_amount_to,
        currency: salary_currency,
    });
    return (React.createElement(Link, { className: "offer-link", to: `/ponuka/${slug}` },
        React.createElement("article", { className: "offer" },
            React.createElement(Row, { gutter: [16, 16] },
                React.createElement(Col, { xs: 24 },
                    React.createElement("div", { className: "offer__main" },
                        company.image && (React.createElement("div", { className: "offer__company-image" },
                            React.createElement(CompanyImage, { name: company && company.name, imageUrl: cdnLink({
                                    file: `web/company/${company.image}`,
                                    transformation: 'w_300,h_120,c_limit',
                                }) }))),
                        React.createElement("h1", { className: "offer__title" }, title),
                        React.createElement("div", { className: "offer__company" }, company && company.name),
                        React.createElement(OfferIntoTagGroup, null,
                            React.createElement("div", { className: "offer__info-tag" },
                                React.createElement(Icon, { className: "icon", type: "maps-pin" }),
                                !isRemote && React.createElement("span", null, address.city),
                                isRemote && React.createElement("span", null, "Remote")),
                            is_paid && (React.createElement("div", { className: "offer__info-tag" },
                                React.createElement(Icon, { className: "icon", type: "money-bag-euro" }),
                                React.createElement("span", null, salaryString))))))),
            React.createElement("div", { className: "offer__bottom" },
                React.createElement(Row, { gutter: [16, 16] },
                    React.createElement(Col, { xs: 24, md: 18 },
                        React.createElement("div", { className: "offer__tags" }, isRemote && (React.createElement(Tag, { className: "m-b-s flex-inline align-center", color: "magenta", icon: React.createElement(HomeOutlined, null) }, intl.formatMessage({
                            id: 'offers.offer.remote',
                            defaultMessage: 'Práca z domu',
                        }))))),
                    React.createElement(Col, { xs: 24, md: 6 },
                        React.createElement("div", { className: "offer__created-at" },
                            React.createElement(Timestamp, { value: updated_at, type: "relative" }))))))));
};
export { Offer };

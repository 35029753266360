import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import URI from 'urijs'
import { notification } from 'antd'
import config from 'config'
import actions from '../actions'

export function* studentFeedbackStart(action) {
	const uri = new URI(`${config.api}/0/student-feedback`)
	const apiUri = uri.toString()

	const postData = data => {
		try {
			const response = axios.post(apiUri, data, {
				withCredentials: true,
			})
			return response
		} catch (err) {
			throw err
		}
	}

	try {
		yield call(postData, action.payload)
		yield put(actions.studentFeedbackSuccess())
	} catch (err) {
		console.log('Error in action: ', err)
		yield put(actions.studentFeedbackError(err))
	}
}

export function* studentFeedbackSuccess() {
	const showNotification = () => {
		notification.success({
			message: 'Dobrá správa',
			description: 'Zaslanie feedbacku prebehlo úspešne.',
		})
	}
	yield call(showNotification)
}

export function* studentFeedbackError(action) {
	const showNotification = () => {
		if (action.payload.response.status === 500) {
			notification.error({
				message: 'Niekde nastala chyba',
				description: 'Zaznamenali sme chybu a už pracujeme na oprave',
			})
		} else {
			notification.success({
				message: 'Ďakujeme',
				description: 'Feedback od vás už máme zaznamenaný',
			})
		}
	}
	yield call(showNotification)
}

import { createAction } from 'redux-actions'

const actions = {
	authCheckStart: createAction('AUTH_CHECK_START'),
	authCheckSuccess: createAction('AUTH_CHECK_SUCCESS'),
	authCheckError: createAction('AUTH_CHECK_ERROR'),

	cookieConsentStart: createAction('COOKIE_CONSENT_START'),
	cookieConsentSuccess: createAction('COOKIE_CONSENT_SUCCESS'),
	cookieConsentError: createAction('COOKIE_CONSENT_ERROR'),

	contactUsStart: createAction('CONTACT_US_START'),
	contactUsSuccess: createAction('CONTACT_US_SUCCESS'),
	contactUsError: createAction('CONTACT_US_ERROR'),

	companySetCycle: createAction('COMPANY_SET_CYCLE'),

	mobileMenuOpen: createAction('MOBILE_MENU_OPEN'),
	mobileMenuClose: createAction('MOBILE_MENU_CLOSE'),

	mainInitStart: createAction('MAIN_INIT_START'),
	mainInitSuccess: createAction('MAIN_INIT_SUCCESS'),
	mainInitError: createAction('MAIN_INIT_ERROR'),

	offerPostStart: createAction('OFFER_POST_START'),
	offerPostSuccess: createAction('OFFER_POST_SUCCESS'),
	offerPostError: createAction('OFFER_POST_ERROR'),

	offerToggleStart: createAction('OFFER_TOGGLE_START'),
	offerToggleError: createAction('OFFER_TOGGLE_ERROR'),
	offerToggleSuccess: createAction('OFFER_TOGGLE_SUCCESS'),

	offerEditStart: createAction('OFFER_EDIT_START'),
	offerEditSuccess: createAction('OFFER_EDIT_SUCCESS'),
	offerEditError: createAction('OFFER_EDIT_ERROR'),

	offerDeleteStart: createAction('OFFER_DELETE_START'),
	offerDeleteSuccess: createAction('OFFER_DELETE_SUCCESS'),
	offerDeleteError: createAction('OFFER_DELETE_ERROR'),

	offerEditInitStart: createAction('OFFER_EDIT_INIT_START'),
	offerEditInitSuccess: createAction('OFFER_EDIT_INIT_SUCCESS'),
	offerEditInitError: createAction('OFFER_EDIT_INIT_ERROR'),

	loadOfferDetailStart: createAction('LOAD_OFFER_DETAIL_START'),
	loadOfferDetailSuccess: createAction('LOAD_OFFER_DETAIL_SUCCESS'),
	loadOfferDetailError: createAction('LOAD_OFFER_DETAIL_ERROR'),

	changeOfferDetailInitial: createAction('CHANGE_OFFER_DETAIL_INITIAL'),

	saveSelectedFilters: createAction('SAVE_SELECTED_FILTERS'),

	getPlanStart: createAction('GET_PLAN_START'),
	getPlanSuccess: createAction('GET_PLAN_SUCCESS'),
	getPlanError: createAction('GET_PLAN_ERROR'),

	getPlansStart: createAction('GET_PLANS_START'),
	getPlansSuccess: createAction('GET_PLANS_SUCCESS'),
	getPlansError: createAction('GET_PLANS_ERROR'),

	loginStart: createAction('LOGIN_START'),
	loginSuccess: createAction('LOGIN_SUCCESS'),
	loginError: createAction('LOGIN_ERROR'),

	logoutStart: createAction('LOGOUT_START'),
	logoutSuccess: createAction('LOGOUT_SUCCESS'),
	logoutError: createAction('LOGOUT_ERROR'),

	registrationSubmitStart: createAction('REGISTRATION_SUBMIT_START'),
	registrationSubmitSuccess: createAction('REGISTRATION_SUBMIT_SUCCESS'),
	registrationSubmitError: createAction('REGISTRATION_SUBMIT_ERROR'),

	registrationStudentStart: createAction('REGISTRATION_STUDENT_START'),
	registrationStudentSuccess: createAction('REGISTRATION_STUDENT_SUCCESS'),
	registrationStudentError: createAction('REGISTRATION_STUDENT_ERROR'),

	registrationLoadCompanyNamesStart: createAction('REGISTRATION_LOAD_COMPANY_NAMES_START'),
	registrationLoadCompanyNamesSuccess: createAction('REGISTRATION_LOAD_COMPANY_NAMES_SUCCESS'),
	registrationLoadCompanyNamesError: createAction('REGISTRATION_LOAD_COMPANY_NAMES_ERROR'),

	registrationLoadCompanyInfoStart: createAction('REGISTRATION_LOAD_COMPANY_INFO_START'),
	registrationLoadCompanyInfoSuccess: createAction('REGISTRATION_LOAD_COMPANY_INFO_SUCCESS'),
	registrationLoadCompanyInfoError: createAction('REGISTRATION_LOAD_COMPANY_INFO_ERROR'),

	registrationCompanyNameFocus: createAction('REGISTRATION_COMPANY_NAME_FOCUS'),
	registrationCompanyNameBlur: createAction('REGISTRATION_COMPANY_NAME_BLUR'),

	resetPasswordEmailStart: createAction('RESET_PASSWORD_EMAIL_START'),
	resetPasswordEmailSuccess: createAction('RESET_PASSWORD_EMAIL_SUCCESS'),
	resetPasswordEmailError: createAction('RESET_PASSWORD_EMAIL_ERROR'),

	resetPasswordStart: createAction('RESET_PASSWORD_START'),
	resetPasswordSuccess: createAction('RESET_PASSWORD_SUCCESS'),
	resetPasswordError: createAction('RESET_PASSWORD_ERROR'),

	sendResumeStart: createAction('SEND_RESUME_START'),
	sendResumeSuccess: createAction('SEND_RESUME_SUCCESS'),
	sendResumeError: createAction('SEND_RESUME_ERROR'),

	userVerificationStart: createAction('USER_VERIFICATION_START'),
	userVerificationSuccess: createAction('USER_VERIFICATION_SUCCESS'),
	userVerificationError: createAction('USER_VERIFICATION_ERROR'),

	personalInfoEditStart: createAction('PERSONAL_INFO_EDIT_START'),
	personalInfoEditSuccess: createAction('PERSONAL_INFO_EDIT_SUCCESS'),
	personalInfoEditError: createAction('PERSONAL_INFO_EDIT_ERROR'),

	personalInfoInitStart: createAction('PERSONAL_INFO_INIT_START'),
	personalInfoInitSuccess: createAction('PERSONAL_INFO_INIT_SUCCESS'),
	personalInfoInitError: createAction('PERSONAL_INFO_INIT_ERROR'),

	loginInfoEditStart: createAction('LOGIN_INFO_EDIT_START'),
	loginInfoEditSuccess: createAction('LOGIN_INFO_EDIT_SUCCESS'),
	loginInfoEditError: createAction('LOGIN_INFO_EDIT_ERROR'),

	loginInfoInitStart: createAction('LOGIN_INFO_INIT_START'),
	loginInfoInitSuccess: createAction('LOGIN_INFO_INIT_SUCCESS'),
	loginInfoInitError: createAction('LOGIN_INFO_INIT_ERROR'),

	createCompanyApiKeyStart: createAction('CREATE_COMPANY_API_KEY_START'),
	createCompanyApiKeySuccess: createAction('CREATE_COMPANY_API_KEY_SUCCESS'),
	createCompanyApiKeyError: createAction('CREATE_COMPANY_API_KEY_ERROR'),

	getCompanyApiKeyStart: createAction('GET_COMPANY_API_KEY_START'),
	getCompanyApiKeySuccess: createAction('GET_COMPANY_API_KEY_SUCCESS'),
	getCompanyApiKeyError: createAction('GET_COMPANY_API_KEY_ERROR'),

	loadOfferApplicationStart: createAction('LOAD_OFFER_APPLICATION_START'),
	loadOfferApplicationSuccess: createAction('LOAD_OFFER_APPLICATION_SUCCESS'),
	loadOfferApplicationError: createAction('LOAD_OFFER_APPLICATION_ERROR'),

	loadMyOfferApplicationsStart: createAction('LOAD_MY_OFFER_APPLICATIONS_START'),
	loadMyOfferApplicationsSuccess: createAction('LOAD_MY_OFFER_APPLICATIONS_SUCCESS'),
	loadMyOfferApplicationsError: createAction('LOAD_MY_OFFER_APPLICATIONS_ERROR'),

	loadMyResumesStart: createAction('LOAD_MY_RESUMES_START'),
	loadMyResumesSuccess: createAction('LOAD_MY_RESUMES_SUCCESS'),
	loadMyResumesError: createAction('LOAD_MY_RESUMES_ERROR'),

	loadMoreOfferApplicationsStart: createAction('LOAD_MORE_OFFER_APPLICATIONS_START'),
	loadMoreOfferApplicationsSuccess: createAction('LOAD_MORE_OFFER_APPLICATIONS_SUCCESS'),
	loadMoreOfferApplicationsError: createAction('LOAD_MORE_OFFER_APPLICATIONS_ERROR'),

	loadOfferSuggestionsCompanyStart: createAction('LOAD_OFFER_SUGGESTIONS_COMPANY_START'),
	loadOfferSuggestionsCompanySuccess: createAction('LOAD_OFFER_SUGGESTIONS_COMPANY_SUCCESS'),
	loadOfferSuggestionsCompanyError: createAction('LOAD_OFFER_SUGGESTIONS_COMPANY_ERROR'),

	loadSimilarOffersStart: createAction('LOAD_SIMILAR_OFFERS_START'),
	loadSimilarOffersSuccess: createAction('LOAD_SIMILAR_OFFERS_SUCCESS'),
	loadSimilarOffersError: createAction('LOAD_SIMILAR_OFFERS_ERROR'),

	companyInfoEditStart: createAction('COMPANY_INFO_EDIT_START'),
	companyInfoEditSuccess: createAction('COMPANY_INFO_EDIT_SUCCESS'),
	companyInfoEditError: createAction('COMPANY_INFO_EDIT_ERRORT'),

	myCompanyInfoInitStart: createAction('MY_COMPANY_INFO_INIT_START'),
	myCompanyInfoInitSuccess: createAction('MY_COMPANY_INFO_INIT_SUCCESS'),
	myCompanyInfoInitError: createAction('MY_COMPANY_INFO_INIT_ERROR'),

	logoEditStart: createAction('LOGO_EDIT_START'),
	logoEditSuccess: createAction('LOGO_EDIT_SUCCESS'),
	logoEditError: createAction('LOGO_EDIT_ERROR'),

	accountEditStart: createAction('ACCOUNT_EDIT_START'),
	accountEditSuccess: createAction('ACCOUNT_EDIT_SUCCESS'),
	accountEditError: createAction('ACCOUNT_EDIT_ERROR'),

	adminToggleNotEnoughCreditsDialog: createAction('ADMIN_TOGGLE_NOT_ENOUGH_CREDITS_DIALOG'),

	adminToggleCreditsReplenishedDialog: createAction('ADMIN_TOGGLE_CREDITS_REPLENISHED_DIALOG'),
	adminClearUpOrder: createAction('ADMIN_CLEAR_UP_ORDER'),

	adminToggleLoading: createAction('ADMIN_TOGGLE_LOADING'),

	changeProfileMenuActive: createAction('CHANGE_PROFILE_MENU_ACTIVE'),

	registrationFormSetSuccess: createAction('REGISTRATION_FORM_SET_SUCCESS'),

	resendVerificationEmailStart: createAction('RESEND_VERIFICATION_EMAIL_START'),
	resendVerificationEmailSuccess: createAction('RESEND_VERIFICATION_EMAIL_SUCCESS'),
	resendVerificationEmailError: createAction('RESEND_VERIFICATION_EMAIL_ERROR'),

	offerFeedbackStart: createAction('OFFER_FEEDBACK_START'),
	offerFeedbackSuccess: createAction('OFFER_FEEDBACK_SUCCESS'),
	offerFeedbackError: createAction('OFFER_FEEDBACK_ERROR'),

	studentFeedbackStart: createAction('STUDENT_FEEDBACK_START'),
	studentFeedbackSuccess: createAction('STUDENT_FEEDBACK_SUCCESS'),
	studentFeedbackError: createAction('STUDENT_FEEDBACK_ERROR'),
}

export default actions

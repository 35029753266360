import React from 'react'
import * as uuid from 'uuid'
import { Dropdown, Menu } from 'antd'

const defaultProps = {
	type: 'primary',
}

function ComboButton(props) {
	return (
		<Dropdown.Button
			data-cy="edit-offer"
			{...defaultProps}
			{...props}
			overlay={
				<Menu>
					{props.menu.map(menuItem => (
						<Menu.Item data-cy="menu-offer" key={uuid.v4()} onClick={menuItem.onClick}>
							{menuItem.label}
						</Menu.Item>
					))}
				</Menu>
			}
		>
			{props.children}
		</Dropdown.Button>
	)
}

export default ComboButton

import axios from 'axios';
import config from 'config';

const asyncValidate = (values, _, { intl }) => {
	return new Promise((resolve, reject) => (
		axios.get(`${config.api}/0/account?email=${encodeURIComponent(values.email)}`, { withCredentials: true })
			.then(() => reject({
				email: intl.formatMessage({
					id: "admin.login_info_edit_async_validation.already_busy_email",
					defaultMessage: 'Tento email je už obsadený'
				})
			}))
			.catch(() => {
				resolve();
			})
	));
}

export default asyncValidate;

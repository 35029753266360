import React from 'react'
import classNames from 'classnames'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'

Moment.locale('en')
momentLocalizer()

export default class RenderDatetimepicker extends DateTimePicker {
	constructor(props) {
		super(props)
		this.state = {
			open: props.open ? props.open : false,
		}
		this.handleChange = this.handleChange.bind(this)
	}

	handleChange(date) {
		this.props.input.onChange(date ? new Date(date) : null)
	}

	render() {
		const getInputClasses = show =>
			classNames({
				'form__date-time-picker-error': show,
			})
		const {
			input,
			label,
			time,
			meta: { touched, error },
		} = this.props
		return (
			<div id={input.name} className="form__row">
				<label htmlFor={input.name} className="form__label">
					{label}
				</label>
				<DateTimePicker
					className={getInputClasses(touched && error)}
					onClick={this._handleInputClick.bind(this, this.props.onClick)}
					{...input}
					onChange={this.handleChange}
					onBlur={() => this.props.input.onBlur(input.value)}
					onToggle={this._handleToggle.bind(this, this.props.onToggle)}
					open={this.state.open}
					min={new Date(Moment().add(1, 'd'))}
					max={new Date(Moment().add(31, 'd'))}
					format="DD. MM. YYYY"
					selected={input.value ? new Date(input.value) : null}
					value={!input.value ? null : new Date(input.value)}
					time={time}
				/>
				{touched && error && <span className="form__input-error">{error}</span>}
			</div>
		)
	}

	_handleInputClick(callback, event) {
		if (event.target.tagName === 'INPUT') {
			this._open(true)
		}
		return callback && callback(event)
	}

	_handleToggle(callback, view) {
		this._open(!this.state.open, view)
		return callback && callback()
	}

	_open(shouldOpen, view = null) {
		if (!view) {
			view = this.props.time && !this.props.calendar ? 'time' : 'date'
		}
		const toggledState = shouldOpen ? view : false
		this.setState({ open: toggledState })
	}
}

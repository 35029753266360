import React from 'react';
import { getLocalizedPath, getLanguageFromPathname } from '@src/shared/localized-path';
import Slug from '@src/shared/slug';
import { NotificationType } from '@src/types';
export function getNotificationContent(intl, notificationTypeId, context) {
    switch (notificationTypeId) {
        case NotificationType.COMPANY_SUBSCRIPTION_NOTIFICATION:
            return intl.formatMessage({ id: 'global.notification.company_new_offer.content' }, Object.assign(Object.assign({}, context), { strong: chunks => React.createElement("strong", null, chunks) }));
        default:
            return '';
    }
}
export function getNotificationLink(notificationTypeId, context) {
    let page = '/';
    const locale = typeof window !== 'undefined' ? getLanguageFromPathname(window.location.pathname) : 'sk';
    switch (notificationTypeId) {
        case NotificationType.COMPANY_SUBSCRIPTION_NOTIFICATION:
            page = `/ponuka/${new Slug({ text: context.offerTitle, suffix: context.offerId }).result}`;
            break;
        default:
            page = '/';
    }
    return getLocalizedPath(page, locale);
}

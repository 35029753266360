import actionNames from 'actions'
import cloneState from 'reducers/helpers'

const defaultState = {
	isLoading: true,
	isSendingResume: false,
	details: {},
	offerSuggestionsCompany: [],
	similarOffers: [],
	initial: false,
}

export default function offerDetail(state = defaultState, action) {
	switch (action.type) {
		case actionNames.loadOfferDetailSuccess().type:
			return cloneState(state, {
				isLoading: false,
				details: action.payload.details,
				initial: action.payload.initial || false,
			})

		case actionNames.loadOfferDetailStart().type:
			return cloneState(state, {
				isLoading: true,
			})

		case actionNames.sendResumeStart().type:
			return cloneState(state, {
				isSendingResume: true,
			})

		case actionNames.sendResumeSuccess().type:
		case actionNames.sendResumeError().type:
			return cloneState(state, {
				isSendingResume: false,
			})

		case actionNames.loadOfferSuggestionsCompanyStart().type:
			return cloneState(state, {
				offerSuggestionsCompany: [],
			})

		case actionNames.loadOfferSuggestionsCompanySuccess().type:
			return cloneState(state, {
				offerSuggestionsCompany: action.payload,
			})

		case actionNames.loadSimilarOffersStart().type:
			return cloneState(state, {
				similarOffers: [],
			})

		case actionNames.loadSimilarOffersSuccess().type:
			return cloneState(state, {
				similarOffers: action.payload,
			})

		case actionNames.changeOfferDetailInitial().type:
			return cloneState(state, {
				initial: action.payload.initial,
			})

		default:
			return state
	}
}

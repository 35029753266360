import configShared from '@src/config-shared';
import { configFrontendSK } from './config.sk';
import { configFrontendEN } from './config.en';
import { configFrontendUK } from './config.uk';
export function getConfig(locale = 'sk') {
    const frontendConfigs = {
        sk: configFrontendSK,
        en: configFrontendEN,
        uk: configFrontendUK,
    };
    return Object.assign(Object.assign({}, configShared), frontendConfigs[locale]);
}
const config = Object.assign(Object.assign({}, configShared), getConfig());
export default config;

import React from 'react'
import { Button } from '@src/components/elements'
import * as Cookies from 'js-cookie'
import { connect, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import { WarningOutlined } from '@ant-design/icons'
import actions from 'actions'

const UserNotVerified = props => {
	const [wasVerificationEmailSent, setWasVerificationEmailSent] = React.useState(props.verificationResent)
	const dispatch = useDispatch()

	const resendVerificationEmail = React.useCallback(() => {
		dispatch(actions.resendVerificationEmailStart())
		setWasVerificationEmailSent(true)
	}, [])

	return (
		<div className="admin__user-verification-notice">
			<div className="admin__user-verification-notice-message">
				<span className="admin__user-verification-notice-icon">
					<WarningOutlined />
				</span>
				<span className="flex-center">
					<FormattedMessage
						id="admin.user_verification_notice.email_is_not_verified"
						defaultMessage="Váš email nie je overený"
					/>
				</span>
			</div>
			<div className="admin__user-verification-notice-text">
				{wasVerificationEmailSent ? (
					<div className="admin__user-verification-notice-text-item">
						<FormattedMessage
							id="admin.user_verification_notice.email_has_been_resent"
							defaultMessage="Bol vám znovu odoslaný verifikačný email."
						/>{' '}
						<FormattedMessage
							id="admin.user_verification_notice.check_your_spam_folder"
							defaultMessage="Pre istotu si skontrolujte priečinok spam a overte, že ste správne zadali email."
						/>
					</div>
				) : (
					<div className="admin__user-verification-notice-text-item">
						<FormattedMessage
							id="admin.user_verification_notice.did_not_recieve_email"
							defaultMessage="Neprišiel vám verifikačný email? "
						/>
						<Button size="small" type="text" onClick={resendVerificationEmail}>
							<FormattedMessage
								id="admin.user_verification_notice.send_again"
								defaultMessage="Poslať znovu"
							/>
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}

const mapStateToProps = () => ({
	verificationResent: Cookies.get('verificationResent'),
})

export default connect(mapStateToProps)(UserNotVerified)

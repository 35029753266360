export const configFrontendSK = {
    api: process.env.API,
    adSenseClientId: 'ca-pub-2352455228582816',
    cookieConsentDuration: 365,
    dateDisplayFormat: 'DD. MM. YYYY',
    environment: process.env.NODE_ENV,
    sizeBreakpointXs: 568,
    sizeBreakpointSm: 768,
    sizeBreakpointMd: 1024,
    sizeBreakpointLg: 1280,
    transitionUiSlow: 667,
    socialBaseUrl: {
        facebook: 'https://www.facebook.com/',
        linkedin: 'https://www.linkedin.com/',
        instagram: 'https://www.instagram.com/',
        twitter: 'https://www.twitter.com/',
    },
    accountType: {
        company: 1,
        student: 2,
    },
    offerTypes: [
        { name: 'Stáž', id: 1 },
        { name: 'Absolventská pozícia', id: 2 },
        { name: 'Dobrovoľníctvo', id: 3 },
        { name: 'Brigáda', id: 4 },
        { name: 'Work & travel', id: 5 },
        { name: 'Živnosť', id: 6 },
    ],
    locations: [
        { name: 'Slovensko', id: 1 },
        { name: 'Banskobystrický kraj', id: 2 },
        { name: 'Bratislavský kraj', id: 3 },
        { name: 'Košický kraj', id: 4 },
        { name: 'Nitriansky kraj', id: 5 },
        { name: 'Prešovský kraj', id: 6 },
        { name: 'Trenčiansky kraj', id: 7 },
        { name: 'Trnavský kraj', id: 8 },
        { name: 'Žilinský kraj', id: 9 },
        { name: 'Bratislava', id: 10 },
        { name: 'Košice', id: 11 },
        { name: 'okres Lučenec', id: 12 },
        { name: 'okres Rimavská Sobota', id: 13 },
        { name: 'okres Galanta', id: 14 },
        { name: 'okres Bardejov', id: 15 },
        { name: 'okres Kežmarok', id: 16 },
        { name: 'okres Turčianske Teplice', id: 17 },
        { name: 'okres Prešov', id: 18 },
        { name: 'okres Trenčín', id: 19 },
        { name: 'okres Humenné', id: 20 },
        { name: 'okres Nitra', id: 21 },
        { name: 'okres Nové Zámky', id: 22 },
        { name: 'okres Topoľčany', id: 23 },
        { name: 'okres Rožňava', id: 24 },
        { name: 'okres Spišská Nová Ves', id: 25 },
        { name: 'okres Vranov nad Topľou', id: 26 },
        { name: 'okres Námestovo', id: 27 },
        { name: 'okres Zvolen', id: 28 },
        { name: 'okres Dunajská Streda', id: 29 },
        { name: 'okres Trebišov', id: 30 },
        { name: 'okres Košice - okolie', id: 31 },
        { name: 'okres Brezno', id: 32 },
        { name: 'okres Banská Štiavnica', id: 33 },
        { name: 'okres Banská Bystrica', id: 34 },
        { name: 'okres Pezinok', id: 35 },
        { name: 'okres Michalovce', id: 36 },
        { name: 'okres Komárno', id: 37 },
        { name: 'okres Sabinov', id: 38 },
        { name: 'okres Levice', id: 39 },
        { name: 'okres Levoča', id: 40 },
        { name: 'okres Veľký Krtíš', id: 41 },
        { name: 'okres Stropkov', id: 42 },
        { name: 'okres Piešťany', id: 43 },
        { name: 'okres Bánovce nad Bebravou', id: 44 },
        { name: 'okres Žiar nad Hronom', id: 45 },
        { name: 'okres Sobrance', id: 46 },
        { name: 'okres Poprad', id: 47 },
        { name: 'okres Nové Mesto nad Váhom', id: 48 },
        { name: 'okres Žilina', id: 49 },
        { name: 'okres Martin', id: 50 },
        { name: 'okres Snina', id: 51 },
        { name: 'okres Zlaté Moravce', id: 52 },
        { name: 'okres Svidník', id: 53 },
        { name: 'okres Púchov', id: 54 },
        { name: 'okres Liptovský Mikuláš', id: 55 },
        { name: 'okres Senec', id: 56 },
        { name: 'okres Ružomberok', id: 57 },
        { name: 'okres Trnava', id: 58 },
        { name: 'okres Senica', id: 59 },
        { name: 'okres Považská Bystrica', id: 60 },
        { name: 'okres Ilava', id: 61 },
        { name: 'okres Prievidza', id: 62 },
        { name: 'okres Hlohovec', id: 63 },
        { name: 'okres Malacky', id: 64 },
        { name: 'okres Partizánske', id: 65 },
        { name: 'okres Bratislava V', id: 66 },
        { name: 'okres Bratislava IV', id: 67 },
        { name: 'okres Bratislava III', id: 68 },
        { name: 'okres Bratislava II', id: 69 },
        { name: 'okres Bratislava I', id: 70 },
        { name: 'okres Žarnovica', id: 71 },
        { name: 'okres Medzilaborce', id: 72 },
        { name: 'okres Myjava', id: 73 },
        { name: 'okres Poltár', id: 74 },
        { name: 'okres Tvrdošín', id: 75 },
        { name: 'okres Skalica', id: 76 },
        { name: 'okres Bytča', id: 77 },
        { name: 'okres Dolný Kubín', id: 78 },
        { name: 'okres Krupina', id: 79 },
        { name: 'okres Čadca', id: 80 },
        { name: 'okres Stará Ľubovňa', id: 81 },
        { name: 'okres Revúca', id: 82 },
        { name: 'okres Detva', id: 83 },
        { name: 'okres Šaľa', id: 84 },
        { name: 'okres Kysucké Nové Mesto', id: 85 },
        { name: 'okres Gelnica', id: 86 },
        { name: 'okres Košice IV', id: 87 },
        { name: 'okres Košice III', id: 88 },
        { name: 'okres Košice I', id: 89 },
        { name: 'okres Košice II', id: 90 },
    ],
    contractTypes: [
        { value: 1, label: 'Plný úväzok' },
        { value: 2, label: 'Skrátený úväzok' },
    ],
    salaryRates: [
        { value: 'hourly', label: 'Na hodinu' },
        { value: 'monthly', label: 'Mesačne' },
        { value: 'once', label: 'Jednorázovo' },
    ],
    salaryCurrencies: [{ value: '€', label: '€' }],
    durationTypes: [
        { value: 'day', label: 'Deň' },
        { value: 'week', label: 'Týždeň' },
        { value: 'month', label: 'Mesiac' },
        { value: 'year', label: 'Rok' },
    ],
    languages: [
        { code: 'sk', value: 'Slovenský' },
        { code: 'en', value: 'Anglický' },
        { code: 'fr', value: 'Francúzsky' },
        { code: 'cs', value: 'Český' },
        { code: 'de', value: 'Nemecký' },
        { code: 'pl', value: 'Poľský' },
        { code: 'es', value: 'Španielský' },
        { code: 'pt', value: 'Portugalský' },
        { code: 'zh', value: 'Čínsky' },
        { code: 'uk', value: 'Ukrajinský' },
        { code: 'ru', value: 'Ruský' },
        { code: 'hu', value: 'Maďarský' },
    ],
    planFeatures: new Map([
        [
            'activeInternships',
            {
                name: 'Inzercia stáží',
                description: 'Maximálny počet aktívnych ponúk',
                type: Number,
            },
        ],
        [
            'activeGraduateOffers',
            {
                name: 'Inzercia absolventských pozícií',
                description: 'Maximálny počet aktívnych ponúk pre absolventov VŠ',
                type: Number,
            },
        ],
        [
            'companyLogo',
            {
                name: 'Logo firmy v profile',
                description: 'Možnosť zobrazovať logo firmy v profile a vo vašich ponukách',
                type: Boolean,
            },
        ],
        [
            'analytics',
            {
                name: 'Analytické dáta',
                description: 'Poskyneme základné analytické dáta (zobrazenia ponúk atď.)',
                type: Boolean,
            },
        ],
        [
            'socialMarketing',
            {
                name: 'Propagácia ponúk na sociálnych sieťach',
                description: 'Zvýšime dosah vašich ponúk organickým spôsobom',
                type: Boolean,
            },
        ],
        [
            'ppcMarketing',
            {
                name: 'Platená propagácia ponúk na sociálnych sieťach',
                description: 'Platená reklama cielená na potenciálnych záujemcov prostredníctvom PPC',
                type: Boolean,
            },
        ],
        [
            'studentGroupsMarketing',
            {
                name: 'Zverejnenie ponúk v internetových skupinách a spolkoch',
                description: 'Vašu ponuku dostaneme medzi študentov a absolventov, ktorí sa špecializujú na daný odbor',
                type: Boolean,
            },
        ],
        [
            'highlightOffers',
            {
                name: 'Možnosť zvýrazňovania ponúk v zozname',
                description: 'Získate možnosť odlíšiť svoje ponuky oproti ostatným ponukám v zozname',
                type: Boolean,
                comingSoon: true,
            },
        ],
        [
            'api',
            {
                name: 'Podpora API',
                description: 'Možnosť prepojiť službu Praxuj s vašim IT systémom',
                type: Boolean,
            },
        ],
        [
            'emailMarketing',
            {
                name: 'Zaradenie ponúk do emailových kampaní',
                description: 'Propagácia vašich ponúk direct mailingom relevantným uchádzačom',
                type: Boolean,
            },
        ],
        [
            'istp',
            {
                name: 'Prepojenie s ISTP',
                description: 'Možnosť automatického publikovania ponúk na štátny portál Internetový sprievodca trhom práce',
                type: Boolean,
            },
        ],
        [
            'importantEmployer',
            {
                name: 'Zvýraznenie ponúk oproti ostatným',
                description: 'Označenie významného zamestnávateľa v zozname ponúk',
                type: Boolean,
            },
        ],
        [
            'homepageLogo',
            {
                name: 'Logo na homepage',
                description: 'Propagácia ponúk vašej firmy ponúk priamo na homepage služby Praxuj',
                type: Boolean,
            },
        ],
    ]),
};

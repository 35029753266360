import React from 'react';
import { Modal as AntModal } from 'antd';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
const StyledModal = styled(AntModal) `
	${down('sm')} {
		margin: 0;
		max-width: 100vw;
		width: 100vw !important;
		top: 0;
		min-height: 100vh;
		padding: 0;
		display: flex;

		.ant-modal-content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 100%;
		}

		.ant-modal-body {
			padding: 1rem;
		}
	}
`;
export function Modal(props) {
    const onCancel = e => {
        if (props === null || props === void 0 ? void 0 : props.onCancel) {
            // @ts-ignore
            document.body.style = '';
            props === null || props === void 0 ? void 0 : props.onCancel(e);
        }
    };
    return (React.createElement(StyledModal, Object.assign({}, props, { onCancel: onCancel }), props.children));
}

import styled from 'styled-components';
export const Header = styled.div `
	display: flex;
	height: fit-content;
	align-items: center;
`;
export const Documents = styled.div `
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1rem;
`;
export const Message = styled.div `
	margin-top: 1rem;
	background: #ffffff;
	border: 1px solid #ecd7cc;
	border-radius: 8px;
	min-height: 200px;
	display: flex;
	padding: 1rem;
`;
export const TimestampWrapper = styled.div `
	color: gray;
`;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import * as Api from 'api';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Panel, Spinner, Empty, Icon } from '@src/components/elements';
import { Select, Switch } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import ReactGA from '@src/helpers/react-ga';
import { ApplicantsColumns } from './ApplicantsColumns';
import { ApplicantsTable } from './ApplicantsTable';
import { ApplicationDetailModal } from './ApplicationDetailModal';
import { ApplicationSendMessageModal } from './ApplicationSendMessageModal';
import { OfferApplicationsHeader, OfferSelect, LayoutSwitchWrapper } from './Applicants.style';
import { useAdminContextValue } from '../../admin-context';
const { Option, OptGroup } = Select;
function Applicants() {
    const [initialized, setInitialized] = React.useState(false);
    const [layout, setLayout] = React.useState('columns');
    const [applications, setApplications] = React.useState(null);
    const [applicationDetail, setApplicationDetail] = React.useState(null);
    const [sendMessageApplication, setSendMessageApplication] = React.useState(null);
    const [offer, setOffer] = React.useState(null);
    // @ts-ignore
    const company = useSelector(state => state.admin.company);
    const { offerId } = useParams();
    const { myOffers } = useAdminContextValue();
    const history = useHistory();
    const intl = useIntl();
    const init = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!company) {
            return;
        }
        try {
            // we don't need drafts - drafts should not be published
            // therefore they should not have any applications
            const myOffersMerged = [...myOffers.active, ...myOffers.notActive];
            if (myOffersMerged.length === 0) {
                return;
            }
            const defaultOffer = myOffersMerged.find(o => o.id === Number(offerId)) || myOffersMerged[0];
            const _a = yield Api.Admin.getOfferApplications(defaultOffer.id), { offer_applications } = _a, offerData = __rest(_a, ["offer_applications"]);
            setApplications(offer_applications);
            setOffer(offerData);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setInitialized(true);
        }
    }), [offerId, company, myOffers]);
    React.useEffect(() => {
        init();
    }, [init]);
    if (!initialized) {
        return (React.createElement(Panel, { className: "flex-center" },
            React.createElement(Spinner, null)));
    }
    if (!offer) {
        return (React.createElement(Panel, null,
            React.createElement(Empty, null)));
    }
    const offerCategoryMap = {
        active: intl.formatMessage({
            id: 'admin.offers.active',
        }),
        notActive: intl.formatMessage({
            id: 'admin.offers.archived',
        }),
        drafts: intl.formatMessage({
            id: 'admin.offers.drafts',
        }),
    };
    return (React.createElement(Panel, null,
        React.createElement(OfferApplicationsHeader, null,
            React.createElement("h2", { className: "m-b-m" },
                React.createElement(FormattedMessage, { id: "offer_application.offer_applicants" })),
            React.createElement(OfferSelect
            // @ts-ignore
            , { 
                // @ts-ignore
                onChange: value => history.pushLocalized(`/admin/uchadzaci/${value}`), defaultValue: offer.id }, Object.entries(myOffers)
                .filter(([category]) => category !== 'drafts')
                .map(([category, categoryValue]) => (React.createElement(React.Fragment, { key: `applicants-offer-select-option-group-${category}` }, categoryValue.length > 0 && (React.createElement(OptGroup, { label: offerCategoryMap[category] }, categoryValue.map(o => (React.createElement(Option, { value: o.id, key: `applicants-offer-select-option-${o.id}` },
                "(",
                o.offer_applications.length,
                ") ",
                o.title))))))))),
            React.createElement(LayoutSwitchWrapper, null,
                React.createElement("div", null,
                    React.createElement(FormattedMessage, { id: "global.layout" })),
                React.createElement(Switch, { checkedChildren: React.createElement(Icon, { type: "three-columns" }), unCheckedChildren: React.createElement(UnorderedListOutlined, null), onChange: () => {
                        const nextLayout = layout === 'columns' ? 'table' : 'columns';
                        setLayout(nextLayout);
                        ReactGA.event({
                            category: 'Interaction',
                            action: `Admin Offer Applications Layout changed to: "${nextLayout}"`,
                        });
                    }, defaultChecked: true, size: "small" }))),
        layout === 'columns' && (React.createElement(ApplicantsColumns, { applications: applications, setApplications: setApplications, setApplicationDetail: setApplicationDetail })),
        layout === 'table' && (React.createElement(ApplicantsTable, { applications: applications, setApplications: setApplications, setApplicationDetail: setApplicationDetail, setSendMessageApplication: setSendMessageApplication })),
        React.createElement(ApplicationDetailModal, { onClose: () => setApplicationDetail(null), application: applicationDetail, applications: applications, setApplications: setApplications }),
        React.createElement(ApplicationSendMessageModal, { setApplications: setApplications, applications: applications, application: sendMessageApplication, onClose: () => setSendMessageApplication(null) })));
}
export { Applicants };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import * as Api from '@src/api';
import { useIsomorphicLayoutEffect } from '@src/hooks/useIsomorphicLayoutEffect';
export const SSRFetchConfig = {
    // partners: {
    // 	key: 'unique-initial-state-partners' as const,
    // 	fetch: Api.Cms.Web.getPartners,
    // },
    // testimonials: {
    // 	key: 'unique-initial-state-testimonials' as const,
    // 	fetch: (locale: SupportedLanguage = SupportedLanguage.sk) => Api.Cms.Web.getTestimonials({ locale }),
    // },
    // companyLogos: {
    // 	key: 'unique-initial-state-company-logos' as const,
    // 	fetch: Api.Cms.Web.getCompanyLogos,
    // },
    // teamPeople: {
    // 	key: 'unique-initial-state-team-people' as const,
    // 	fetch: Api.Cms.Web.getTeam,
    // },
    // companyProfile: {
    // 	key: 'unique-initial-state-company-profile' as const,
    // 	fetch: (companyUniqueUrl: string) => Api.Company.getCompanyByUrl(companyUniqueUrl),
    // },
    // magazinePosts: {
    // 	key: 'unique-initial-state-magazine-posts' as const,
    // 	fetch: (currentPage: number) => Api.Cms.Magazine.getPosts(currentPage),
    // },
    // magazinePostsCount: {
    // 	key: 'unique-initial-state-magazine-posts-count' as const,
    // 	fetch: Api.Cms.Magazine.getPostsCount,
    // },
    // magazinePost: {
    // 	key: 'unique-initial-state-magazine-post' as const,
    // 	fetch: (slug: string) => Api.Cms.Magazine.getPost({ slug }),
    // },
    // companiesList: {
    // 	key: 'unique-initial-state-companies-List' as const,
    // 	fetch: (params?: { search?: string; page?: number }) =>
    // 		Api.Company.getCompaniesList(params?.search, params?.page),
    // },
    offersFilters: {
        key: 'unique-initial-state-offers-filters',
        fetch: (query) => Api.OffersFilters.getOffersFilters(query),
    },
    offers: {
        key: 'unique-initial-state-offers',
        fetch: (query) => Api.Offers.getOffers(query),
    },
};
export const SSRDataContext = React.createContext({});
export function useSSRFetch(globalStateKey, fetchArgs = undefined, dependencies = []) {
    const isServer = typeof window === 'undefined';
    const contexts = React.useContext(SSRDataContext);
    const [state, setState] = React.useState(contexts[globalStateKey]);
    const [error, setError] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    if (isServer && !state) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] SSR fetch did not match route in prefetch-routes for following key:', globalStateKey);
    }
    // offers filters tag removing
    useIsomorphicLayoutEffect(() => {
        // this fixes race condition
        let isActive = true;
        const fetch = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            setIsLoading(true);
            try {
                const fetchRequest = (_a = Object.values(SSRFetchConfig).find(obj => obj.key === globalStateKey)) === null || _a === void 0 ? void 0 : _a.fetch;
                const data = yield fetchRequest(fetchArgs);
                if (isActive) {
                    setState(data);
                }
            }
            catch (err) {
                setError(err);
            }
            finally {
                setIsLoading(false);
            }
        });
        fetch();
        return () => {
            isActive = false;
        };
    }, dependencies);
    if (state) {
        return { data: state, isLoading, error };
    }
    return { data: null, isLoading, error };
}

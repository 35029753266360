import React from 'react'
import Select from 'react-select'
import { useIntl } from 'react-intl'
import { Col, Row } from 'antd'

const renderSimpleSelect = ({ input, options, label, placeholder, disabled, clearable, meta: { touched, error } }) => {
	const intl = useIntl()
	return (
		<div className="form__row">
			<Row>
				<Col xs={24} id={input.name}>
					<label className="form__label" htmlFor={input.name}>
						{label}
					</label>
					<Select
						{...input}
						options={options}
						value={input.value}
						placeholder={placeholder}
						onChange={newOption => input.onChange(newOption.value)}
						onBlur={() => input.onBlur(input.value)}
						disabled={disabled}
						clearable={clearable}
						noResultsText={intl.formatMessage({
							id: 'forms.offer_notification_subscription_form.render_simple_select.no_results',
							defaultMessage: 'Žiadne výsledky',
						})}
						valueKey="value"
					/>
					{touched && error && <div className="form__input-error">{error}</div>}
				</Col>
			</Row>
		</div>
	)
}

export default renderSimpleSelect

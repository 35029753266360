const translateType = (duration_value, duration_type, intl) => {
	const specifyType = {
		"one" : {
			"day": intl.formatMessage({
				id: "offer_detail.details_model.one_day",
				defaultMessage: "deň",
			}),
			"week": intl.formatMessage({
				id: "offer_detail.details_model.one_week",
				defaultMessage: "týždeň",
			}),
			"month": intl.formatMessage({
				id: "offer_detail.details_model.one_month",
				defaultMessage: "mesiac",
			}),
			"year": intl.formatMessage({
				id: "offer_detail.details_model.one_year",
				defaultMessage: "rok",
			})
		},
		"twoToFive": {
			"day": intl.formatMessage({
				id: "offer_detail.details_model.two_days",
				defaultMessage: "dni",
			}),
			"week": intl.formatMessage({
				id: "offer_detail.details_model.two_weeks",
				defaultMessage: "týždne",
			}),
			"month": intl.formatMessage({
				id: "offer_detail.details_model.two_months",
				defaultMessage: "mesiace",
			}),
			"year": intl.formatMessage({
				id: "offer_detail.details_model.two_years",
				defaultMessage: "roky",
			})
		},
		"fiveAndMore": {
			"day": intl.formatMessage({
				id: "offer_detail.details_model.days",
				defaultMessage: "dní",
			}),
			"week": intl.formatMessage({
				id: "offer_detail.details_model.weeks",
				defaultMessage: "týždňov",
			}),
			"month": intl.formatMessage({
				id: "offer_detail.details_model.months",
				defaultMessage: "mesiacov",
			}),
			"year": intl.formatMessage({
				id: "offer_detail.details_model.years",
				defaultMessage: "rokov",
			})
		}
	}
	if(duration_value >= 5) {
		return specifyType.fiveAndMore[`${duration_type}`];
	} else if(duration_value >= 2) {
		return specifyType.twoToFive[`${duration_type}`];
	} else {
		return specifyType.one[`${duration_type}`];
	}
}

export default translateType;
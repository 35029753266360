import styled, { css } from 'styled-components';
export const Columns = styled.div `
	overflow-x: auto;
`;
export const Column = styled.div `
	background: #fff8f8;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	min-height: 500px;
	width: 186px;
	min-width: 186px;
	border: 1px solid transparent;
	margin: 0 0.5rem;
	transition: 0.3s;

	${({ isDragging }) => isDragging &&
    css `
			border: 1px solid lightgray;
		`}

	${({ isDraggingOver }) => isDraggingOver &&
    css `
			border: 1px solid #dd4f46;
		`}
`;
export const ColumnTitle = styled.div `
	text-align: center;
	min-width: 200px;
	width: 200px;
	font-size: 1rem;
`;

import { call, put } from 'redux-saga/effects'
import { history, store } from '@src/app/index'
import axios from 'axios'
import URI from 'urijs'
import { notification } from 'antd'
import ReactGA from 'helpers/react-ga'
import config from 'config'
import actions from '../actions'

export function* offerPostStart(values) {
	const uri = new URI(`${config.api}/0/offer`)
	const apiUri = uri.toString()

	const offerData = {
		...values.payload,
		is_paid: values.payload.is_paid === 'true',
	}

	const postData = data =>
		axios
			.post(apiUri, data, { withCredentials: true })
			.then(res => {
				notification.success({
					message: 'Upozornenie',
					description: 'Pridanie ponuky prebehlo úspešne',
				})
				return res
			})
			.catch(err => {
				throw err
			})

	try {
		yield call(postData, offerData)
		yield put(actions.offerPostSuccess(offerData.title))
	} catch (err) {
		yield put(actions.offerPostError(err))
	}
}

export function* offerPostError({ payload }) {
	const showError = () => {
		if (payload.status === 403) {
			notification.error({
				message: 'Chyba',
				description: 'Email užívateľa nie je overený',
			})
		} else if (payload.response.status === 400) {
			store.dispatch(actions.adminToggleNotEnoughCreditsDialog(true))
			ReactGA.event({
				category: 'Company',
				action: 'Offers limit reached',
				label: 'Offer Post',
			})
		} else {
			notification.error({
				message: 'Chyba',
				description: 'Niekde nastala chyba.',
			})
		}
	}
	yield call(showError)
}

export function* offerPostSuccess(action) {
	ReactGA.event({
		category: 'Company',
		action: 'Offer post',
		label: action.payload,
	})
	const redirect = () => history.pushLocalized('/admin')
	yield call(redirect)
}

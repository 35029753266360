var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import config from '@src/config';
import { OfferFilterType } from '@src/types';
import axios from './clients/axios';
export function getOffers(selectedFilterItems) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield axios.get(`${config.api}/0/offers`, {
            params: {
                typ: selectedFilterItems[OfferFilterType.type],
                loc: selectedFilterItems[OfferFilterType.location],
                job: selectedFilterItems[OfferFilterType.jobArea],
                page: selectedFilterItems.page,
            },
        });
        return response.data;
    });
}

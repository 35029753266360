const validate = (values, { intl }) => {
	const errors = {};

	if (!values.first_name) {
		errors.first_name = intl.formatMessage({
			id: "registration_student.sync_validation.first_name",
			defaultMessage: "Vyplňte meno"
		});
	} else if (/[0-9_~`!@#$%^&*(),.;<>?]/.test(values.first_name)) {
		errors.first_name = intl.formatMessage({
			id: "registration_student.sync_validation.restricted_symbols_first_name",
			defaultMessage: "Údaj obsahuje nepovolené znaky!"
		});
	}

	if (!values.last_name) {
		errors.last_name = intl.formatMessage({
			id: "registration_student.sync_validation.last_name",
			defaultMessage: "Vyplňte priezvisko"
		})
	} else if (/[0-9_~`!@#$%^&*(),.;<>?]/.test(values.last_name)) {
		errors.last_name = intl.formatMessage({
			id: "registration_student.sync_validation.restricted_symbols_last_name",
			defaultMessage: "Údaj obsahuje nepovolené znaky!"
		});
	}

	if (!values.email) {
		errors.email = intl.formatMessage({
			id: "registration_student.sync_validation.email",
			defaultMessage: "Vyplňte pole s emailovou adresou"
		});
	} else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
		errors.email = intl.formatMessage({
			id: "registration_student.sync_validation.invalid_email",
			defaultMessage: "Zadajte validný email"
		});
	}

	if (!values.password) {
		errors.password = intl.formatMessage({
			id: "registration_student.sync_validation.password",
			defaultMessage: "Vyplňte pole s heslom"
		});
	} else if (values.password.length < 8) {
		errors.password = intl.formatMessage({
			id: "registration_student.sync_validation.restricted_length_password",
			defaultMessage: "Heslo musí byť dlhé aspoň 8 znakov"
		});
	} else if (!/^.*(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/.test(values.password)) {
		errors.password = intl.formatMessage({
			id: "registration_student.sync_validation.restricted_symbols_password",
			defaultMessage: "Heslo musí obsahovať aspoň 1 malé, 1 veľké písmeno a 1 číslo"
		});
	}

	if (values.passwordRepeat !== values.password) {
		errors.passwordRepeat = intl.formatMessage({
			id: "registration_student.sync_validation.passwordRepeat",
			defaultMessage: "Heslá sa nezhodujú"
		});
	}

	if (!values.job_area || values.job_area.length === 0) {
		errors.job_area = intl.formatMessage({
			id: "registration_student.sync_validation.job_area",
			defaultMessage: "Prosím, vyberte aspoň 1 pracovné zameranie'"
		});
	}

	if (values.protection !== true) {
		errors.protection = intl.formatMessage({
			id: "registration_student.sync_validation.protection",
			defaultMessage: "Musíte súhlasiť so spracúvaním osobných údajov"
		});
	}

	return errors;
};

export default validate;

import { pathToRegexp, match, compile } from 'path-to-regexp';
import { routes } from '@src/router/Routes';
const defaultLanguage = 'sk';
const supportedLanguages = ['sk', 'en'];
export const getPathWithoutLocale = (path) => {
    let pathWithoutLocale = path;
    let currentLocale = defaultLanguage;
    supportedLanguages.forEach(lang => {
        const language = path.split('/').filter(p => Boolean(p))[0];
        if (language === lang) {
            pathWithoutLocale = path.replace(`/${lang}`, '');
            currentLocale = lang;
        }
    });
    return { currentLocale, pathWithoutLocale };
};
export function getLanguageFromPathname(pathname) {
    const splittedPath = pathname.substring(1).split('/');
    return supportedLanguages.includes(splittedPath[0]) ? splittedPath[0] : defaultLanguage;
}
export const findMatchingRoute = (routesArr, pathWithoutLocale) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const route of routesArr) {
        if (route.routes) {
            const matchingRoute = findMatchingRoute(route.routes, pathWithoutLocale);
            if (matchingRoute) {
                return matchingRoute;
            }
        }
        if (route.paths[defaultLanguage]) {
            // eslint-disable-next-line no-restricted-syntax
            for (const language of supportedLanguages) {
                const pathRegEx = pathToRegexp(route.paths[language]);
                if (pathRegEx.test(pathWithoutLocale)) {
                    return route;
                }
            }
        }
    }
    return null;
};
export const findMatchingRouteFromPathname = (pathname) => {
    const { pathWithoutLocale } = getPathWithoutLocale(pathname);
    return findMatchingRoute(routes, pathWithoutLocale);
};
export const getLocalizedPath = (path, targetLocale) => {
    const { pathWithoutLocale, currentLocale } = getPathWithoutLocale(path);
    const matchedRoute = findMatchingRoute(routes, pathWithoutLocale);
    if (matchedRoute) {
        const parsedRoute = match(matchedRoute.paths[currentLocale])(pathWithoutLocale);
        const localizedPath = compile(matchedRoute.paths[targetLocale])(Object.assign({}, parsedRoute.params));
        return targetLocale === defaultLanguage ? localizedPath : `/${targetLocale}${localizedPath}`;
    }
    return path;
};
// TODO - this might not work if there are duplicate routes under different locales, should be improved
export const getRouteId = (path) => {
    const { pathWithoutLocale } = getPathWithoutLocale(path);
    const matchedRoute = findMatchingRoute(routes, pathWithoutLocale);
    if (matchedRoute) {
        return matchedRoute.id;
    }
    return '';
};
export const isRouteActive = (current, target, exact = false) => {
    if (exact) {
        return getRouteId(current) === getRouteId(target);
    }
    return getRouteId(current).startsWith(getRouteId(target));
};

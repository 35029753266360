// @ts-nocheck
import React from 'react';
import * as Api from 'api';
import { useSSE } from 'use-sse';
import getCmsAsset from '@src/helpers/get-cms-asset';
import Tag from 'components/modules/global/tag';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Holder } from '@src/components/elements';
import { Row, Col, Typography } from 'antd';
export const Wrapper = styled.div `
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;
export const Hero = styled.div `
	padding-top: ${props => props.theme.size.headerHeight}px;
	width: 100%;
	height: 360px;
	position: relative;
	background: url('/media/images/stage-company.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	display: flex;
	align-items: center;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		z-index: 0;
		pointer-events: none;
		opacity: 0.9;
		background: url('/media/images/background-gradient.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		transform: rotate(180deg);
	}
`;
export const Headline = styled.div `
	position: relative;
	z-index: 1;
	text-align: center;
	padding: 0 2em 0;
	h1 {
		font-size: 2.4em;
		font-weight: bold;
		margin-bottom: 0.5em;
		color: ${props => props.theme.color.white};
	}
	h2 {
		font-size: 1.4em;
		font-weight: 200;
		line-height: 1.4;
		padding-bottom: 2em;
		color: ${props => props.theme.color.white};

		strong {
			font-weight: 700;
		}
	}
`;
const About = () => {
    const [people] = useSSE(() => {
        return Api.Cms.Web.getTeam();
    }, []);
    let founders = [];
    let team = [];
    if (people !== null) {
        founders = people.entries
            .filter(item => item.founder)
            .sort((a, b) => `${a.surname}${a.name}`.localeCompare(`${b.surname}${b.name}`));
        team = people.entries.filter(item => !item.founder).sort((a, b) => a.surname.localeCompare(b.surname));
    }
    const { Title } = Typography;
    return (React.createElement(Wrapper, null,
        React.createElement(Hero, null,
            React.createElement(Holder, null,
                React.createElement(Headline, null,
                    React.createElement("h1", null,
                        React.createElement(FormattedMessage, { id: "about.creating_bridge" })),
                    React.createElement(Row, { justify: "center" },
                        React.createElement(Col, { xs: { span: 24 }, md: { span: 18 } },
                            React.createElement("h2", null,
                                React.createElement(FormattedMessage, { id: "about.help_students_get_experience", values: {
                                        strong: chunks => React.createElement("strong", null, chunks),
                                    } }))))))),
        React.createElement(Holder, { marginVertical: true },
            React.createElement(Row, { justify: "center" },
                React.createElement(Col, null,
                    React.createElement(Title, { level: 2, type: "secondary" },
                        React.createElement(FormattedMessage, { id: "about.our_mission" })))),
            React.createElement(Row, { justify: "center" },
                React.createElement(Col, { xs: { span: 24 }, lg: { span: 18 } },
                    React.createElement("div", { className: "panel" },
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, { id: "about.young_people_collective", values: {
                                    strong: chunks => React.createElement("strong", null, chunks),
                                } })),
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, { id: "about.every_team_member", values: {
                                    strong: chunks => React.createElement("strong", null, chunks),
                                } })),
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, { id: "about.we_created_praxuj", values: {
                                    strong: chunks => React.createElement("strong", null, chunks),
                                } }))))),
            React.createElement(Row, { justify: "center", className: "m-t-l" },
                React.createElement(Col, null,
                    React.createElement(Title, { level: 2, type: "secondary" },
                        React.createElement(FormattedMessage, { id: "about.creators" })))),
            React.createElement(Row, { justify: "center" }, founders.map(item => {
                return (React.createElement(Col, { xs: { span: 24 }, md: { span: 8 }, key: item.name },
                    React.createElement("div", { className: "tile" },
                        React.createElement("div", { className: "tile__image tile__image--rounded" },
                            React.createElement("img", { src: getCmsAsset(item.image), width: "180", height: "180", alt: item.name })),
                        React.createElement("h3", { className: "tile__headline" },
                            item.name,
                            " ",
                            item.surname),
                        React.createElement("div", { className: "tile__tags" }, item.tags
                            .sort((a, b) => a.localeCompare(b))
                            .map(tag => {
                            return React.createElement(Tag, { key: tag }, tag);
                        })))));
            })),
            React.createElement(Row, { justify: "center", className: "m-t-l" },
                React.createElement(Col, null,
                    React.createElement(Title, { level: 2, type: "secondary" },
                        React.createElement(FormattedMessage, { id: "about.main_team" })))),
            React.createElement(Row, { justify: "center" }, team.map(item => {
                return (React.createElement(Col, { xs: { span: 24 }, md: { span: 8 }, key: item.name },
                    React.createElement("div", { className: "tile" },
                        React.createElement("div", { className: "tile__image tile__image--rounded" },
                            React.createElement("img", { src: getCmsAsset(item.image), width: "180", height: "180", alt: item.name })),
                        React.createElement("h3", { className: "tile__headline" },
                            item.name,
                            " ",
                            item.surname),
                        React.createElement("div", { className: "tile__tags" }, item.tags
                            .sort((a, b) => a.localeCompare(b))
                            .map(tag => {
                            return React.createElement(Tag, { key: tag }, tag);
                        })))));
            })))));
};
export default About;

const validate = (values, { intl }) => {
	const errors = {};

	if (!values.email) {
		errors.email = intl.formatMessage({
			id: "contact.validation.fill_email_field",
			defaultMessage: 'Vyplňte pole s emailovou adresou',
		});
	} else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
		errors.email = intl.formatMessage({
			id: "contact.validation.use_correct_email",
			defaultMessage: 'Zadajte validný email',
		});
	}

	if (!values.name) {
		errors.name = intl.formatMessage({
			id: "contact.validation.please_fill_name_and_surename",
			defaultMessage: 'Prosím, vyplňte meno a priezvisko',
		});
	} else if (/[0-9_~`!@#$%^&*(),.;<>?]/.test(values.area)) {
		errors.name = intl.formatMessage({
			id: "contact.validation.data_with_forbidden_chars",
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		});
	}

	if (!values.message) {
		errors.message = intl.formatMessage({
			id: "contact.validation.please_fill_message",
			defaultMessage: 'Prosím, vyplňte správu na odoslanie',
		});
	} else if (values.message.length > 1000) {
		errors.description = intl.formatMessage({
			id: "contact.validation.message_max_length",
			defaultMessage: 'Popis správa môže byť dlhá maximálne 1000 znakov',
		});
	}
	return errors;
};

export default validate;

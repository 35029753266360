import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Col, Row } from 'antd'
import { Holder, Title, Panel } from '@src/components/elements'
import ResetPasswordForm from './modules/reset-password'
import actions from '../../../actions'

const ResetPassword = props => (
	<Holder marginVertical>
		<Row justify="center">
			<Col>
				<Title level={1} type="secondary">
					<FormattedMessage
						id="reset_password.password_recovery"
						defaultMessage="Obnovenie zabudnutého hesla"
					/>
				</Title>
			</Col>
		</Row>
		<Row justify="center">
			<Col xs={24} md={18} lg={12}>
				<Panel>
					<ResetPasswordForm onSubmit={props.handleSubmit} resetPasswordError={props.resetPasswordError} />
				</Panel>
			</Col>
		</Row>
	</Holder>
)

const mapStateToProps = state => ({
	resetPasswordError: state.login.resetPasswordError,
})

const mapDispatchToProps = dispatch => ({
	handleSubmit: values => {
		dispatch(actions.resetPasswordStart(values))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)

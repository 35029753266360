import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { OAuthType } from '@src/types/auth';
import actions from 'actions';
import { Button, Holder } from '@src/components/elements';
import Separator from 'components/modules/global/separator';
import LoginForm from 'components/modules/login-form';
import { FacebookLoginButton } from '@src/components/elements/FacebookLoginButton';
import { GoogleLoginButton } from '@src/components/elements/GoogleLoginButton';
import { Row, Col } from 'antd';
function Login() {
    const intl = useIntl();
    const dispatch = useDispatch();
    // @ts-ignore
    const { loginError, loginErrorCount } = useSelector(state => state.login);
    React.useEffect(() => {
        if (typeof FB !== 'undefined') {
            FB.init({
                appId: '492836724588742',
                cookie: true,
                xfbml: true,
                version: 'v7.0',
            });
        }
    }, []);
    return (React.createElement(Holder, { marginVertical: true, "data-cy": "login" },
        React.createElement(Row, { justify: "center", gutter: [16, 16] },
            React.createElement(Col, { xs: { span: 24 }, md: { span: 12 }, xl: { span: 10 } },
                React.createElement("div", { className: "panel", style: { height: '100%' }, "data-cy": "1-panel" },
                    React.createElement("h2", { className: "headline headline--1" },
                        React.createElement(FormattedMessage, { id: "login.login", defaultMessage: "Prihl\u00E1senie" })),
                    React.createElement(LoginForm, { onSubmit: values => {
                            dispatch(actions.loginStart(values));
                        }, loginError: loginError, loginErrorCount: loginErrorCount }))),
            React.createElement(Col, { xs: { span: 24 }, md: { span: 12 }, xl: { span: 10 } },
                React.createElement("div", { className: "panel", style: { height: '100%' }, "data-cy": "2-panel" },
                    React.createElement("div", { className: "login__registration" },
                        React.createElement("div", { className: "login__registration-text" },
                            React.createElement("h2", { className: "headline headline--1" },
                                React.createElement(FormattedMessage, { id: "login.registration", defaultMessage: "Registr\u00E1cia" })),
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "login.dont_have_account_yet", defaultMessage: "Nem\u00E1te e\u0161te vytvoren\u00FD vlastn\u00FD \u00FA\u010Det?" }))),
                        React.createElement("div", { className: "flex-center" },
                            React.createElement(Button, { type: "primary", link: "/registracia", size: "middle", "data-cy": "company-register-button" },
                                React.createElement(FormattedMessage, { id: "login.i_am_empolyer", defaultMessage: "Som zamestn\u00E1vate\u013E" }))),
                        React.createElement(Separator, { text: intl.formatMessage({
                                id: 'login.or',
                                defaultMessage: 'Alebo',
                            }) }),
                        React.createElement("div", { className: "flex-col align-center" },
                            React.createElement("div", { className: "m-b-l" },
                                React.createElement(Button, { type: "primary", link: "/registracia-student", size: "middle", "data-cy": "student-register-button" },
                                    React.createElement(FormattedMessage, { id: "login.i_am_student", defaultMessage: "Som \u0161tudent/absolvent" }))),
                            React.createElement("div", { className: "flex-col" },
                                React.createElement(FacebookLoginButton, { type: OAuthType.registration }),
                                React.createElement(GoogleLoginButton, { type: OAuthType.registration })))))))));
}
export { Login };

import { call, put } from 'redux-saga/effects'
import { history } from '@src/app/index'
import axios from 'axios'
import URI from 'urijs'
import { notification } from 'antd'
import config from 'config'
import actions from '../actions'

export default function* resetPasswordStart(values) {
	const uri = new URI(`${config.api}/0/reset-password?token=${history.getQuery().token}`)
	const apiUri = uri.toString()

	const sendEmail = data =>
		axios
			.put(apiUri, data, { withCredentials: true })
			.then(() =>
				notification.success({
					message: 'Upozornenie',
					description: 'Zmena hesla prebehla úspešne',
					duration: 200,
				})
			)
			.catch(err => {
				throw err
			})

	try {
		const response = yield call(sendEmail, values.payload)
		yield put(actions.resetPasswordSuccess(response))
	} catch (err) {
		yield put(actions.resetPasswordError(err))
	}
}

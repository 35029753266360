import React from 'react'
import ReactDOM from 'react-dom'
import { createBroswerContext } from 'use-sse'
import App from './app/index'
import AppWrapper from './App'

const BroswerDataContext = createBroswerContext()

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate
renderMethod(
	<BroswerDataContext>
		<AppWrapper>
			<App />
		</AppWrapper>
	</BroswerDataContext>,
	document.getElementById('root')
)

if (module.hot) {
	module.hot.accept()
}

import React from 'react';
const AdminContext = React.createContext({
    myOffers: {},
    setMyOffers: () => null,
});
export const useAdminContextProvider = () => {
    const [myOffers, setMyOffers] = React.useState();
    return {
        AdminContextProvider: AdminContext.Provider,
        value: {
            myOffers,
            setMyOffers: o => {
                setMyOffers(o);
            },
        },
    };
};
export const useAdminContextValue = () => {
    const context = React.useContext(AdminContext);
    return context;
};

const validate = (values, { intl }) => {
	const errors = {};

	if (!values.email) {
		errors.email = intl.formatMessage({
			id: "admin.login_info_edit_sync_validations.please_fill_email",
			defaultMessage: 'Prosím, vyplňte email'
		})
	} else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
		errors.email = intl.formatMessage({
			id: "admin.login_info_edit_sync_validations.please_fill_valid_email",
			defaultMessage: 'Prosím, zadajte validný email'
		})
	}

	if (!values.currentPassword) {
		errors.currentPassword = intl.formatMessage({
			id: "admin.login_info_edit_sync_validations.please_fill_actual_password",
			defaultMessage: 'Prosím, vyplňte vaše aktuálne heslo'
		})
	}

	if (values.password) {
		if (values.password.length < 8) {
			errors.password = errors.currentPassword = intl.formatMessage({
				id: "admin.login_info_edit_sync_validations.eight_character_long_password",
				defaultMessage: 'Heslo musí byť dlhé aspoň 8 znakov'
			})
		} else if (!/^.*(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/.test(values.password)) {
			errors.password = intl.formatMessage({
				id: "admin.login_info_edit_sync_validations.password_must_contain",
				defaultMessage: 'Heslo musí obsahovať aspoň 1 malé, 1 veľké písmeno a 1 číslo'
			})
		}
	}

	if (values.passwordRepeat !== values.password) {
		errors.passwordRepeat = intl.formatMessage({
			id: "admin.login_info_edit_sync_validations.passwords_doesnt_match",
			defaultMessage: 'Heslá sa nezhodujú'
		})
	}

	return errors;
};

export default validate;

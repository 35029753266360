var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from './clients/axios';
export function getSubscription(token) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield axios.get(`/0/offer-notification-subscription${token ? `?onst=${token}` : ''}`);
        return response.data;
    });
}
export function createSubscription(subscriptionData) {
    return __awaiter(this, void 0, void 0, function* () {
        yield axios.post('/0/offer-notification-subscription', subscriptionData);
    });
}
export function updateSubscription(subscriptionData, token) {
    return __awaiter(this, void 0, void 0, function* () {
        yield axios.put(`/0/offer-notification-subscription?onst=${token}`, subscriptionData);
    });
}
export function isEmailTaken(email, token) {
    return __awaiter(this, void 0, void 0, function* () {
        const tokenQueryPart = token ? `&onst=${token}` : '';
        const response = yield axios.get(`/0/offer-notification-subscription/is-email-taken?email=${email}${tokenQueryPart}`);
        return response.data;
    });
}
export function deleteSubscription(token) {
    return __awaiter(this, void 0, void 0, function* () {
        yield axios.delete(`/0/offer-notification-subscription?onst=${token}`);
    });
}

import React from 'react'
import Multiselect from 'react-widgets/lib/Multiselect'
import { Col, Row } from 'antd'

const renderMultiselect = ({ input, label, data, meta: { touched, error } }) => (
	<div className="form__row">
		<Row>
			<Col xs={24} id={input.name}>
				<label className="form__label" htmlFor={input.name}>
					{label}
				</label>
				<Multiselect
					{...input}
					value={input.value || []}
					data={data}
					data-cy={input.name}
					data-cy="multiselect"
					onBlur={() => input.onBlur}
					valueField="id"
					textField="name"
				/>
				{touched && error && <span className="form__input-error">{error}</span>}
			</Col>
		</Row>
	</div>
)

export default renderMultiselect

import React from 'react';
import { Select as AntdSelect } from 'antd';
import styled from 'styled-components';
const StyledSelect = styled(AntdSelect) `
	.ant-select-selection-item-remove {
		display: flex;
		align-items: center;
	}
`;
function Select(props) {
    return React.createElement(StyledSelect, Object.assign({}, props));
}
export { Select };

import {
	call,
	put,
} from 'redux-saga/effects';
import axios from 'axios';
import URI from 'urijs';
import config from 'config';
import actions from '../actions';

export function* loadOfferApplicationStart(action) {
	const uri = new URI(`${config.api}/0/offer-application`);
	const apiUri = uri.toString();

	const getData = async (data) => {
		try {
			const response = await axios.get(apiUri, {
				params: {
					feedbackToken: data.feedbackToken
				},
				withCredentials: true
			});
			return response;
		} catch (err) {
			throw err;
		}
	}

	try {
		const data = yield call(getData, action.payload);
		yield put(actions.loadOfferApplicationSuccess(data));
	} catch(err) {
		yield put(actions.loadOfferApplicationError(err));
	}
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { call, put } from 'redux-saga/effects';
import { notification } from 'antd';
import axios from 'axios';
import URI from 'urijs';
import { history } from '@src/app/index';
import ReactGA from '@src/helpers/react-ga';
import config from '@src/config';
import { socket } from '@src/socket';
import { SocketEvents } from '@src/types';
import actions from '../actions';
export function* registrationStudentStart(values) {
    const uri = new URI(`${config.api}/0/account`);
    const apiUri = uri.toString();
    const postData = (data) => __awaiter(this, void 0, void 0, function* () {
        try {
            const studentResponse = yield axios.post(apiUri, Object.assign(Object.assign({}, data), { account_type_id: 2 }), {
                withCredentials: true,
                validateStatus: status => (status >= 200 && status < 300) || status === 429,
            });
            if (studentResponse.status === 429) {
                notification.error({
                    message: 'Ochrana proti útokom',
                    description: 'Snažíme sa ochrániť údaje užívateľov. Z toho dôvodu sme zablokovali vašu IP adresu na 30 minút.',
                });
                // eslint-disable-next-line @typescript-eslint/no-throw-literal
                throw studentResponse;
            }
            socket.emit(SocketEvents.ACCOUNT_REGISTERED, studentResponse.data.data);
            return studentResponse;
        }
        catch (err) {
            throw err;
        }
    });
    try {
        const response = yield call(postData, values.payload);
        yield put(actions.registrationStudentSuccess(response.data));
    }
    catch (err) {
        console.error(err);
        yield put(actions.registrationStudentError(err));
    }
}
export function* registrationStudentError() {
    const showError = () => {
        notification.error({
            message: 'Chyba',
            description: 'Niekde nastala chyba',
        });
    };
    yield call(showError);
}
export function* registrationStudentSuccess(action) {
    const GAEvent = () => {
        ReactGA.event({
            category: 'Student',
            action: 'Registration',
            label: action.payload.email,
        });
    };
    // @ts-ignore
    history.pushLocalized('/profil');
    yield call(GAEvent);
}

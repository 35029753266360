var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import * as Api from '@src/api';
import { Panel, Table, Button, Separator, Upload, Spinner } from '@src/components/elements';
import { UploadOutlined } from '@ant-design/icons';
import Timestamp from '@src/components/modules/global/timestamp';
import { Menu, Dropdown, Modal, notification } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
function ProfileResumes() {
    const [resumes, setResumes] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [modalWindowOpen, setModalWindowOpen] = React.useState(false);
    const [modalWindowEvent, setModalWindowEvent] = React.useState('');
    const intl = useIntl();
    const init = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            const resumePagination = yield Api.Profile.getMyResumes();
            setResumes(resumePagination);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setIsLoading(false);
        }
    }), [Api.Profile]);
    React.useEffect(() => {
        setIsLoading(true);
        init();
    }, []);
    const uploadResume = React.useCallback((e) => __awaiter(this, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            yield Api.Profile.uploadResume(e.file);
            yield init();
        }
        catch (err) {
            console.error(err);
        }
    }), [init, Api.Profile]);
    const archiveResume = React.useCallback((e) => __awaiter(this, void 0, void 0, function* () {
        try {
            yield Api.Profile.archiveResume(e.key);
            yield init();
        }
        catch (err) {
            console.error(err);
        }
    }), [init, Api.Profile]);
    const showModal = () => {
        setModalWindowOpen(true);
    };
    const handleCancel = () => {
        setModalWindowOpen(false);
    };
    const handleOk = () => {
        archiveResume(modalWindowEvent);
        handleCancel();
    };
    const handleArchived = e => {
        setModalWindowEvent(e);
        showModal();
    };
    const validateImage = React.useCallback((file) => {
        if (file.size / 1024 / 1024 >= 1) {
            notification.error({
                message: 'Upozornenie',
                description: 'Súbor je príliš veľký. Maximálna veľkosť súboru je 1 MB',
            });
            return false;
        }
        return true;
    }, []);
    if (!resumes) {
        return (React.createElement(Panel, { className: "p-l" },
            React.createElement(Spinner, null)));
    }
    const resumeColumns = [
        {
            title: intl.formatMessage({
                id: 'profile.modules.resumes.table.name',
                defaultMessage: 'Názov',
            }),
            dataIndex: 'resume_name',
            align: 'center',
            width: '45%',
        },
        {
            title: intl.formatMessage({
                id: 'profile.modules.resumes.table.date',
                defaultMessage: 'Dátum nahratia',
            }),
            dataIndex: 'created_at',
            align: 'center',
            width: '35%',
            render: val => React.createElement(Timestamp, { value: val }),
        },
        {
            title: '',
            width: '20%',
            align: 'center',
            render: (val, row) => (React.createElement(React.Fragment, null,
                React.createElement(Dropdown.Button, { onClick: () => window.open(row.resume_url), overlay: React.createElement(Menu, { onClick: e => handleArchived(e) },
                        React.createElement(Menu.Item, { key: val.id },
                            React.createElement(FormattedMessage, { id: "profile.modules.resumes.archived", defaultMessage: "Archivova\u0165" }))) },
                    React.createElement(FormattedMessage, { id: "profile.modules.resumes.download", defaultMessage: "Stiahn\u00FA\u0165" })),
                React.createElement(Modal, { title: intl.formatMessage({
                        id: 'profile.modules.resumes.modal.title',
                        defaultMessage: 'Archivácia',
                    }), visible: modalWindowOpen, onOk: () => handleOk(), onCancel: handleCancel, cancelText: intl.formatMessage({
                        id: 'profile.modules.resumes.modal.button',
                        defaultMessage: 'Zrušiť',
                    }) },
                    React.createElement("p", null,
                        React.createElement(FormattedMessage, { id: "profile.modules.resumes.modal.content", defaultMessage: "Naozaj chcete tento s\u00FAbor archivova\u0165?" }))))),
        },
    ];
    return (React.createElement(Panel, null,
        React.createElement(Table
        // @ts-ignore
        , { 
            // @ts-ignore
            dataSource: resumes.map(x => (Object.assign(Object.assign({}, x), { key: `resume-${x.id}` }))), columns: resumeColumns, bordered: true, pagination: false }),
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: "p-l p-b-m" },
                React.createElement(Separator, { type: "horizontal" })),
            React.createElement("div", { className: "flex-center" },
                React.createElement(Upload, { customRequest: uploadResume, showUploadList: false, beforeUpload: validateImage, disabled: isLoading },
                    React.createElement(Button, { loading: isLoading, className: "flex-row justify-around align-center" },
                        !isLoading && React.createElement(UploadOutlined, { className: "m-s" }),
                        React.createElement(FormattedMessage, { id: "profile.modules.resumes.upload", defaultMessage: "Nahra\u0165 \u017Eivotopis" })))))));
}
export { ProfileResumes };

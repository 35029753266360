import React from 'react'
import 'react-select/dist/react-select.css'
import { connect } from 'react-redux'
import { FormattedMessage, useIntl, injectIntl } from 'react-intl'
import { reduxForm, Field } from 'redux-form'
import _get from 'lodash/get'
// field components
import showError from 'components/modules/forms/showError'
import renderField from 'components/modules/forms/renderField'
import Button from 'components/modules/global/button'
import { Col, Row } from 'antd'
import validate from '../validation/sync-validation'
import asyncValidate from '../validation/async-validation'

let LoginInfoEditFormComponent = props => {
	const { handleSubmit } = props
	const intl = useIntl()

	return (
		<form className="form" onSubmit={handleSubmit}>
			<div className="form__row">
				<Row gutter={[16, 16]}>
					<Col xs={24} md={12}>
						<Field
							component={renderField}
							name="email"
							label={intl.formatMessage({
								id: 'admin.login_info_edit_form.email',
								defaultMessage: 'Email *',
							})}
							type="email"
						/>
					</Col>
					<Col xs={24} md={12}>
						<Field
							component={renderField}
							name="currentPassword"
							label={intl.formatMessage({
								id: 'admin.login_info_edit_form.current_password',
								defaultMessage: 'Aktuálne heslo *',
							})}
							type="password"
						/>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col xs={24} md={12}>
						<Field
							component={renderField}
							name="password"
							label={intl.formatMessage({
								id: 'admin.login_info_edit_form.new_password',
								defaultMessage: 'Nové heslo',
							})}
							type="password"
						/>
					</Col>
					<Col xs={24} md={12}>
						<Field
							component={renderField}
							name="passwordRepeat"
							label={intl.formatMessage({
								id: 'admin.login_info_edit_form.verify_new_password',
								defaultMessage: 'Overenie nového hesla',
							})}
							type="password"
						/>
					</Col>
				</Row>
			</div>
			<div className="form__submit">
				<Button
					type="submit"
					color="brand"
					text={intl.formatMessage({
						id: 'admin.login_info_edit_form.save_changes',
						defaultMessage: 'Uložiť zmeny',
					})}
					disabled={props.submitting}
				/>
			</div>
			<div className="bottom-line">
				<FormattedMessage
					id="admin.login_info_edit_form.strict_fields"
					defaultMessage="* Polia označené hviezdičkou sú povinné"
				/>
			</div>
		</form>
	)
}

LoginInfoEditFormComponent = reduxForm({
	form: 'loginInfo',
	destroyOnUnmount: true,
	enableReinitialize: true,
	validate,
	asyncValidate,
	asyncBlurFields: ['email'],
	onSubmitFail: showError,
})(LoginInfoEditFormComponent)

LoginInfoEditFormComponent = connect(state => ({
	initialValues: {
		email: _get(state, 'app.user.email'),
	},
}))(LoginInfoEditFormComponent)

// exporting const because of no-mutable-exports
const LoginInfoEditForm = LoginInfoEditFormComponent

export default injectIntl(LoginInfoEditForm)

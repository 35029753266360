import React from 'react';
import { Row, Col } from 'antd';
import { Panel } from '@src/components/elements';
import { CenteredHeading, MessageWrapper, StyledNoteAlertIcon } from './MissingContentView.style';
const MissingContentView = ({ children, heading, message, }) => {
    return (React.createElement(Panel, null,
        React.createElement(Row, { justify: "center", gutter: [16, 16] },
            React.createElement(Col, { xs: 24, md: 18, className: "text-center" },
                React.createElement(StyledNoteAlertIcon, null),
                heading && React.createElement(CenteredHeading, null, heading),
                message && React.createElement(MessageWrapper, null, message)),
            React.createElement(Col, { className: "text-center" }, children))));
};
export default MissingContentView;

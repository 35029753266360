import actionNames from 'actions';
import cloneState from 'reducers/helpers';

const defaultState = {
	isLoading: false,
	items: [],
	total: null,
	page: null,
	pageCount: null,
	itemsPerPage: null,
};

export default function MyOfferApplications(state = defaultState, action) {
	switch (action.type) {
		case actionNames.loadMyOfferApplicationsSuccess().type:
			return cloneState(state, {
				isLoading: false,
				items: action.payload.items,
				total: action.payload.rowCount,
				page: action.payload.page,
				pageCount: action.payload.pageCount,
				itemsPerPage: action.payload.pageSize,
			});

		case actionNames.loadMyOfferApplicationsStart().type:
			return cloneState(state, {
				isLoading: true,
			});

		case actionNames.loadMoreOfferApplicationsSuccess().type:
			return cloneState(state, {
				// isLoading: false,
				items: [...state.items, ...action.payload.items],
				total: action.payload.rowCount,
				page: state.page + 1,
				pageCount: action.payload.pageCount,
				itemsPerPage: action.payload.pageSize,
			});

		default:
			return state;
	}
}

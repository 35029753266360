// @ts-nocheck
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { Button, Holder, Head } from '@src/components/elements';
import { CompanyLogos } from '@src/components/modules/global/CompanyLogos';
import config from '@src/config';
import getCmsAsset from '@src/helpers/get-cms-asset';
import { useSSE } from 'use-sse';
import * as Api from 'api';
import actions from '@src/actions';
import { transparentize } from 'polished';
import { BoxQuickstart } from './BoxQuickstart';
const BOXES_TOP_MARGIN = '220px';
export const Wrapper = styled.div `
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;
export const Hero = styled.div `
	padding-top: ${props => props.theme.size.headerHeight}px;
	width: 100%;
	height: 720px;
	position: relative;
	background: url('/media/images/hero-homepage-desaturated.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	display: flex;
	align-items: center;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		z-index: 0;
		pointer-events: none;
		opacity: 0.9;
		background: url('/media/images/background-gradient.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		transform: rotate(180deg);
	}
`;
export const Headline = styled.div `
	position: relative;
	z-index: 1;
	text-align: center;
	padding: 0 2em ${BOXES_TOP_MARGIN};

	& > * {
		color: ${props => props.theme.color.white};
	}
	h1 {
		font-size: 2.4em;
		font-weight: bold;
		margin-bottom: 0.5em;
	}
	h2 {
		font-size: 1.4em;
		font-weight: 200;
		line-height: 1.4;
		padding-bottom: 2em;

		strong {
			font-weight: 700;
		}
	}
`;
export const Boxes = styled.div `
	width: 100%;
	position: relative;
	z-index: 1;
	display: flex;
	align-items: flex-start;
	margin-top: -${BOXES_TOP_MARGIN};
	flex-direction: column;

	${up('md')} {
		flex-direction: row;
	}
`;
export const Content = styled.div `
	width: 100%;
`;
export const CompanyLogosWrapper = styled.div `
	width: 100%;
	padding: 5em 0;

	h2 {
		font-size: 2em;
		text-align: center;
		font-weight: 700;
		margin: 0 0 2em;
		color: ${props => props.theme.color.brand4};
	}

	h3 {
		font-weight: 400;
		font-size: 1.5em;
		text-align: center;
		margin: 0;

		strong {
			color: ${props => props.theme.color.brand2};
		}
	}
`;
export const BottomPartners = styled.div `
	width: 100%;
	background: ${props => props.theme.color.white};
	padding: 5em 0;
	text-align: center;

	h2 {
		color: ${props => props.theme.color.brand4};
		font-size: 2em;
		font-weight: 700;
		margin: 0 0 0.5em;
	}
`;
export const CompanyCTA = styled.div `
	width: 100%;
	font-weight: 400;
	font-size: 0.9em;
	padding: 2em 2em 0;
	display: block;

	a,
	a:focus,
	a:link,
	a:visited {
		color: ${props => transparentize(0.3, props.theme.color.white)};
	}

	a:hover {
		text-decoration: underline;
	}

	strong {
		color: ${props => props.theme.color.white};
	}
`;
export const HeroButton = styled(Button) `
	${up('sm')} {
		transform: scale(1.2);
	}
`;
export const Partners = styled.div `
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	a {
		padding: 0 0 2em 0;
		width: 100%;
		${up('sm')} {
			width: 50%;
		}
		${up('md')} {
			width: 30%;
		}
		${up('lg')} {
			width: 25%;
		}
	}
`;
const Home = props => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const location = useLocation();
    React.useEffect(() => {
        if (location.pathname === '/offer_feedback') {
            dispatch(actions.offerFeedbackStart());
        }
    }, []);
    const [partners] = useSSE(() => {
        return Api.Cms.Web.getPartners();
    }, []);
    return (React.createElement(Wrapper, null,
        React.createElement(Head, { id: "home", customSocialTitle: true }),
        React.createElement(Hero, null,
            React.createElement(Holder, null,
                React.createElement(Headline, null,
                    React.createElement("h1", null,
                        React.createElement(FormattedMessage, { id: "home.hero.headline" })),
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "home.hero.subheadline1", values: {
                                strong: chunks => React.createElement("strong", null, chunks),
                            } }),
                        React.createElement("br", null),
                        React.createElement(FormattedMessage, { id: "home.hero.subheadline2" })),
                    React.createElement(HeroButton, { style: {
                            background: theme.color.success,
                            borderColor: theme.color.success,
                            color: theme.color.white,
                        }, link: "/ponuky" }, useBreakpoint(up('md')) ? (React.createElement(FormattedMessage, { id: "home.hero.cta_offers" })) : (React.createElement(FormattedMessage, { id: "home.hero.cta_offers_short" }))),
                    React.createElement(CompanyCTA, null,
                        React.createElement(Link, { to: "/firmy" },
                            React.createElement(FormattedMessage, { id: "home.hero.cta_company", values: {
                                    strong: chunks => React.createElement("strong", null, chunks),
                                } })))))),
        React.createElement(Content, null,
            React.createElement(Holder, null,
                React.createElement(Boxes, null,
                    React.createElement(BoxQuickstart, null))),
            React.createElement(CompanyLogosWrapper, null,
                React.createElement(Holder, null,
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "home.clients.headilne" })),
                    React.createElement(CompanyLogos, null),
                    React.createElement("h3", null,
                        React.createElement(FormattedMessage, { id: "companies.clients.subheadline", values: {
                                count: React.createElement("strong", null,
                                    config.marketing.companies,
                                    "+"),
                            } })))),
            React.createElement(BottomPartners, null,
                React.createElement(Holder, null,
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "home.partners.headilne" })),
                    (partners === null || partners === void 0 ? void 0 : partners.entries) && (React.createElement(Partners, null, partners.entries.map(item => {
                        return (React.createElement("a", { href: item.link, key: item.name, target: "_blank", rel: "noreferrer", title: item.name },
                            React.createElement("img", { src: getCmsAsset(item.image), width: "120", height: "120", alt: item.name })));
                    }))))))));
};
export { Home };

import { Big } from 'big.js';
import config from '@src/config';
const coefficientVAT = new Big(100).add(config.pricing.VAT).div(100).toNumber();
const getCreditPricing = (creditCount) => {
    if (new Big(creditCount).lte(0)) {
        throw new Error(`${creditCount} is not a valid credit amount`);
    }
    const DEFAULT_PRICE = new Big(config.pricing.levels[0].price);
    let price;
    config.pricing.levels.some(item => {
        if (new Big(creditCount).lte(item.limit)) {
            price = new Big(item.price);
            return true;
        }
        return false;
    });
    return {
        creditPrice: price.times(coefficientVAT).toNumber(),
        creditPriceWithoutVAT: price.toNumber(),
        totalPrice: price.times(creditCount).times(coefficientVAT).toNumber(),
        totalPriceWithoutVAT: price.times(creditCount).toNumber(),
        originalPrice: DEFAULT_PRICE.times(creditCount).times(coefficientVAT).toNumber(),
        originalPriceWithoutVAT: DEFAULT_PRICE.times(creditCount).toNumber(),
        discount: DEFAULT_PRICE.times(creditCount).minus(price.times(creditCount)).times(coefficientVAT).toNumber(),
        discountWithoutVAT: DEFAULT_PRICE.times(creditCount).minus(price.times(creditCount)).toNumber(),
        discountRelative: new Big(100).minus(price.div(DEFAULT_PRICE).times(100).round()).toNumber(),
    };
};
export { getCreditPricing };

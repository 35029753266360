import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { FormattedMessage } from 'react-intl';
export const StyledFeatures = styled.div `
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	${up('md')} {
		justify-content: initial;
	}
`;
export const Box = styled.div `
	width: 100%;
	padding: 0 2em 3em;
	display: flex;
	position: relative;
	flex-direction: column;

	${up('sm')} {
		width: 50%;
	}

	${up('md')} {
		width: 33%;
	}
`;
export const Picture = styled.div `
	width: 150px;
	height: 150px;
	overflow: hidden;
	align-self: center;
	& img {
		display: block;
		max-width: 100%;
	}
`;
export const Content = styled.dl `
	align-self: center;
	flex: 1;
	text-align: center;

	dt {
		color: ${props => props.theme.color.brand2};
		font-size: 1.2em;
		font-weight: 700;
		margin: 0 0 0.5em;
	}
`;
const features = [
    {
        name: React.createElement(FormattedMessage, { id: "companies.features.feature_1_headline" }),
        image: 'piggy',
        text: React.createElement(FormattedMessage, { id: "companies.features.feature_1_body" }),
    },
    {
        name: React.createElement(FormattedMessage, { id: "companies.features.feature_2_headline" }),
        image: 'posting',
        text: React.createElement(FormattedMessage, { id: "companies.features.feature_2_body" }),
    },
    {
        name: React.createElement(FormattedMessage, { id: "companies.features.feature_3_headline" }),
        image: 'app',
        text: React.createElement(FormattedMessage, { id: "companies.features.feature_3_body" }),
    },
    {
        name: React.createElement(FormattedMessage, { id: "companies.features.feature_4_headline" }),
        image: 'office',
        text: React.createElement(FormattedMessage, { id: "companies.features.feature_4_body" }),
    },
    {
        name: React.createElement(FormattedMessage, { id: "companies.features.feature_5_headline" }),
        image: 'network',
        text: React.createElement(FormattedMessage, { id: "companies.features.feature_5_body" }),
    },
    {
        name: React.createElement(FormattedMessage, { id: "companies.features.feature_6_headline" }),
        image: 'email',
        text: React.createElement(FormattedMessage, { id: "companies.features.feature_6_body" }),
    },
    {
        name: React.createElement(FormattedMessage, { id: "companies.features.feature_7_headline" }),
        image: 'data',
        text: React.createElement(FormattedMessage, { id: "companies.features.feature_7_body" }),
    },
    {
        name: React.createElement(FormattedMessage, { id: "companies.features.feature_8_headline" }),
        image: 'cloud',
        text: React.createElement(FormattedMessage, { id: "companies.features.feature_8_body" }),
    },
    {
        name: React.createElement(FormattedMessage, { id: "companies.features.feature_9_headline" }),
        image: 'pr',
        text: React.createElement(FormattedMessage, { id: "companies.features.feature_9_body" }),
    },
];
const Features = () => {
    return (React.createElement(StyledFeatures, null, features.map(item => {
        return (React.createElement(Box, { key: item.image },
            React.createElement(Picture, null,
                React.createElement("img", { src: `/media/images/icon-${item.image}.svg`, alt: "" })),
            React.createElement(Content, null,
                React.createElement("dt", null, item.name),
                React.createElement("dd", null, item.text))));
    })));
};
export { Features };

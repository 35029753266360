import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { useIntl, FormattedMessage, injectIntl } from 'react-intl'
// field components
import showError from 'components/modules/forms/showError'
import renderField from 'components/modules/forms/renderField'
import renderCheckbox from 'components/modules/forms/renderCheckbox'
import Button from 'components/modules/global/button'
import { Col, Row } from 'antd'
import validate from '../validation/sync-validation'

let PersonalInfoEditFormComponent = props => {
	const { handleSubmit } = props
	const intl = useIntl()
	return (
		<form className="form" onSubmit={handleSubmit}>
			<div className="form__row">
				<Row gutter={[16, 16]}>
					<Col xs={24} md={12}>
						<Field
							component={renderField}
							name="first_name"
							label={intl.formatMessage({
								id: 'admin.personal_info_edit.first_name',
								defaultMessage: 'Meno *',
							})}
							type="text"
						/>
						<Field
							component={renderField}
							name="last_name"
							label={intl.formatMessage({
								id: 'admin.personal_info_edit.last_name',
								defaultMessage: 'Priezvisko *',
							})}
							type="text"
						/>
						<Field
							component={renderField}
							name="telephone"
							label={intl.formatMessage({
								id: 'admin.personal_info_edit.telephone',
								defaultMessage: 'Telefónne číslo *',
							})}
							type="tel"
						/>
					</Col>
					<Col xs={24} md={12}>
						<Field
							component={renderField}
							name="title_before"
							label={intl.formatMessage({
								id: 'admin.personal_info_edit.title_before',
								defaultMessage: 'Titul (pred menom)',
							})}
							type="text"
						/>
						<Field
							component={renderField}
							name="title_after"
							label={intl.formatMessage({
								id: 'admin.personal_info_edit.title_after',
								defaultMessage: 'Titul (za menom)',
							})}
							type="text"
						/>
					</Col>
					<Col xs={24}>
						<Field
							component={renderCheckbox}
							name="newsletter"
							label={intl.formatMessage({
								id: 'admin.personal_info_edit.newsletter_consent',
								defaultMessage: 'Súhlasím s odberom noviniek',
							})}
							type="checkbox"
						/>
					</Col>
				</Row>
			</div>
			<div className="form__submit">
				<Button
					type="submit"
					color="brand"
					text={intl.formatMessage({
						id: 'admin.personal_info_edit.save_changes',
						defaultMessage: 'Uložiť zmeny',
					})}
					disabled={props.submitting}
				/>
			</div>
			<div className="bottom-line">
				<FormattedMessage
					id="admin.personal_info_edit.required_fields"
					defaultMessage="* Polia označené hviezdičkou sú povinné"
				/>
			</div>
		</form>
	)
}

PersonalInfoEditFormComponent = reduxForm({
	form: 'personalInfo',
	destroyOnUnmount: true,
	enableReinitialize: true,
	onSubmitFail: showError,
	validate,
})(PersonalInfoEditFormComponent)

PersonalInfoEditFormComponent = connect(state => ({
	initialValues: state.personalInfo.personalInfoInit,
}))(PersonalInfoEditFormComponent)

// exporting const because of no-mutable-exports
const PersonalInfoEditForm = PersonalInfoEditFormComponent

export default injectIntl(PersonalInfoEditForm)

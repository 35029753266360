import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import actions from 'actions'
import { Row, Col } from 'antd'
import { Holder, Panel, Title } from '@src/components/elements'
import RegistrationStudentForm from './modules/registration-student-form'

const RegistrationStudent = props => (
	<Holder marginVertical>
		<Row justify="center">
			<Col xs={{ span: 24 }} lg={{ span: 12 }}>
				<Title level={2} type="secondary">
					<FormattedMessage id="registration_student.registration" />
				</Title>
			</Col>
		</Row>
		<Row justify="center">
			<Col xs={{ span: 24 }} lg={{ span: 12 }}>
				<Panel>
					<RegistrationStudentForm onSubmit={props.handleSubmit} isLoading={props.isLoading} />
				</Panel>
			</Col>
		</Row>
	</Holder>
)

const mapStateToProps = state => ({
	isLoading: state.registration.isLoading,
})

const mapDispatchToProps = dispatch => ({
	handleSubmit: values => {
		dispatch(actions.registrationStudentStart(values))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationStudent)

// import Diacritics from '@src/helpers/diacritics'

const validate = (values, { intl }) => {
	const errors = {}

	if (!values.name) {
		errors.name = intl.formatMessage({
			id: 'registration.sync_validation.name',
			defaultMessage: 'Vyplňte názov zamestnávateľa',
		})
	}

	if (values.cin && values.cin.toString().match(/\d/g) && values.cin.toString().match(/\d/g).length !== 8) {
		errors.cin = intl.formatMessage({
			id: 'registration.sync_validation.wrong_cin',
			defaultMessage: 'IČO je v nesprávnom tvare (8 číslic)',
		})
	} else if (values.cin && /\D/.test(values.cin.toString().replace(/\s/, ''))) {
		errors.cin = intl.formatMessage({
			id: 'registration.sync_validation.restricted_symbols_cin',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (values.tin && values.tin.toString().match(/\d/g) && values.tin.toString().match(/\d/g).length !== 10) {
		errors.tin = intl.formatMessage({
			id: 'registration.sync_validation.wrong_tin',
			defaultMessage: 'DIČ je v nesprávnom tvare (10 číslic)',
		})
	} else if (values.tin && /\D/.test(values.tin.toString().replace(/\s/, ''))) {
		errors.tin = intl.formatMessage({
			id: 'registration.sync_validation.restricted_symbols_tin',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (!values.formatted_address) {
		errors.formatted_address = intl.formatMessage({
			id: 'registration.sync_validation.formatted_adress',
			defaultMessage: 'Vyplňte adresu spoločnosti',
		})
	} else if (/[_~`!@#$%^&*(),;<>?]/.test(values.formatted_address)) {
		errors.formatted_address = intl.formatMessage({
			id: 'registration.sync_validation.restricted_symbols_adress',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (!values.city) {
		errors.city = intl.formatMessage({
			id: 'registration.sync_validation.city',
			defaultMessage: 'Vyplňte mesto',
		})
	} else if (/[0-9_~`!@#$%^&*(),;<>?]/.test(values.city)) {
		errors.city = intl.formatMessage({
			id: 'registration.sync_validation.restricted_symbols_city',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (!values.postal_code) {
		errors.postal_code = intl.formatMessage({
			id: 'registration.sync_validation.postal_code',
			defaultMessage: 'Vyplňte PSČ',
		})
	} else if (values.postal_code.match(/\d/g) && values.postal_code.match(/\d/g).length !== 5) {
		errors.postal_code = intl.formatMessage({
			id: 'registration.sync_validation.restricted_form_postal_code',
			defaultMessage: 'PSČ musí byť v tvare piatich číslic',
		})
	} else if (/\D/.test(values.postal_code.replace(/\s/, ''))) {
		errors.postal_code = intl.formatMessage({
			id: 'registration.sync_validation.restricted_symbols_postal_code',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (!values.description || values.description.replace(/<[^>]*>?/g, '').replace(/\s/g, '').length === 0) {
		errors.description = intl.formatMessage({
			id: 'registration.sync_validation.description',
			defaultMessage: 'Vyplňte popis spoločnosti',
		})
	}

	if (!values.area) {
		errors.area = intl.formatMessage({
			id: 'registration.sync_validation.area',
			defaultMessage: 'Vyplňte zameranie podnikania',
		})
	} else if (/[0-9_~`!@#$%^&*().;<>?]/.test(values.area)) {
		errors.area = intl.formatMessage({
			id: 'registration.sync_validation.restricted_symbols_area',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (!values.employees) {
		errors.employees = intl.formatMessage({
			id: 'registration.sync_validation.employees',
			defaultMessage: 'Vyplňte približný počet zamestnancov',
		})
	} else if (/[A-Za-z_~`!@#$%^&*(),.;<>?]/.test(values.employees)) {
		errors.employees = intl.formatMessage({
			id: 'registration.sync_validation.restricted_symbols_employees',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}
	if (!values.company_url) {
		errors.company_url = intl.formatMessage({
			id: 'admin.company_info_edit_validation.fill_company_url',
			defaultMessage: 'Vyplňte Url firemného profilu',
		})
	} else if (!/^[A-Za-z-]*$/.test(values.company_url)) {
		errors.company_url = intl.formatMessage({
			id: 'registration.sync_validation.restricted_symbols_last_name',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (!values.first_name) {
		errors.first_name = intl.formatMessage({
			id: 'registration.sync_validation.first_name',
			defaultMessage: 'Vyplňte meno',
		})
	} else if (/[0-9_~`!@#$%^&*(),.;<>?]/.test(values.first_name)) {
		errors.first_name = intl.formatMessage({
			id: 'registration.sync_validation.restricted_symbols_first_name',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (!values.last_name) {
		errors.last_name = intl.formatMessage({
			id: 'registration.sync_validation.restricted_symbols_last_name',
			defaultMessage: 'Vyplňte priezvisko',
		})
	} else if (/[0-9_~`!@#$%^&*(),.;<>?]/.test(values.last_name)) {
		errors.last_name = intl.formatMessage({
			id: 'registration.sync_validation.restricted_symbols_last_name',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (values.title_before && values.title_before.length > 8) {
		errors.title_before = intl.formatMessage({
			id: 'registration.sync_validation.restricted_length_title_before',
			defaultMessage: 'Titul pred menom môže obsahovať maximálne 8 znakov!',
		})
	} else if (/[0-9_~`!@#$%^&*(),;<>?]/.test(values.title_before)) {
		errors.title_before = intl.formatMessage({
			id: 'registration.sync_validation.restricted_symbols_title_before',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (values.title_after && values.title_after.length > 8) {
		errors.title_after = intl.formatMessage({
			id: 'registration.sync_validation.restricted_length_title_after',
			defaultMessage: 'Titul za menom môže obsahovať maximálne 8 znakov!',
		})
	} else if (/[0-9_~`!@#$%^&*(),;<>?]/.test(values.title_after)) {
		errors.title_after = intl.formatMessage({
			id: 'registration.sync_validation.restricted_symbols_title_after',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (!values.telephone) {
		errors.telephone = intl.formatMessage({
			id: 'registration.sync_validation.telephone',
			defaultMessage: 'Vyplňte telefonický kontakt',
		})
	} else if (/\d/g.test(values.telephone) && values.telephone.match(/\d/g).length < 9) {
		errors.telephone = intl.formatMessage({
			id: 'registration.sync_validation.restricted_short_telephone',
			defaultMessage: 'Telefónne číslo je príliš krátke',
		})
	} else if (/\d/g.test(values.telephone) && values.telephone.match(/\d/g).length > 12) {
		errors.telephone = intl.formatMessage({
			id: 'registration.sync_validation.restricted_long_telephone',
			defaultMessage: 'Telefónne číslo je príliš dlhé',
		})
	} else if (/[A-Za-z_~`!@#$%^&*(),.;<>?]/.test(values.telephone)) {
		errors.telephone = intl.formatMessage({
			id: 'registration.sync_validation.restricted_symbols_telephone',
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		})
	}

	if (!values.email) {
		errors.email = intl.formatMessage({
			id: 'registration.sync_validation.email',
			defaultMessage: 'Vyplňte pole s emailovou adresou',
		})
	} else if (
		!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			values.email
		)
	) {
		errors.email = intl.formatMessage({
			id: 'registration.sync_validation.invalid_email',
			defaultMessage: 'Zadajte validný email',
		})
	}

	if (!values.password) {
		errors.password = intl.formatMessage({
			id: 'registration.sync_validation.password',
			defaultMessage: 'Vyplňte pole s heslom',
		})
	} else if (values.password.length < 8) {
		errors.password = intl.formatMessage({
			id: 'registration.sync_validation.restricted_length_password',
			defaultMessage: 'Heslo musí byť dlhé aspoň 8 znakov',
		})
	} else if (!/^.*(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/.test(values.password)) {
		errors.password = intl.formatMessage({
			id: 'registration.sync_validation.restricted_symbols_password',
			defaultMessage: 'Heslo musí obsahovať aspoň 1 malé, 1 veľké písmeno a 1 číslo',
		})
	}

	if (values.passwordRepeat !== values.password) {
		errors.passwordRepeat = intl.formatMessage({
			id: 'registration.sync_validation.passwordRepeat',
			defaultMessage: 'Heslá sa nezhodujú',
		})
	}

	if (values.protection !== true) {
		errors.protection = intl.formatMessage({
			id: 'registration.sync_validation.protection',
			defaultMessage: 'Musíte súhlasiť so spracúvaním osobných údajov',
		})
	}

	return errors
}

export default validate

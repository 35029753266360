import React from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { EuroOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { Icon, Panel, Separator, Button, Tag } from '@src/components/elements';
import SlugOffer from '@src/shared/slug-offer';
import getSalaryString from '@src/shared/get-salary-string';
const SeparatorStyled = styled(Separator) `
	align-self: center;
	width: 50%;
	min-width: 50%;
`;
const RemovedOfferImageStyled = styled.img `
	align-self: center;
	width: 200px;
	height: 200px;
`;
const DeletedOfferSuggestedOffer = styled.div `
	margin-top: 1.5rem;
	max-width: 300px;
	width: 300px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	${down('sm')} {
		margin-top: 2rem;
		width: 100%;
	}
`;
const DeletedOfferSuggestedOffers = styled.div `
	display: flex;
	justify-content: space-around;

	${down('sm')} {
		flex-direction: column;
		align-items: center;
	}
`;
export function DeletedOffer(props) {
    const { similarOffers } = props;
    const history = useHistory();
    const showSimilarOffers = similarOffers.length > 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(Panel, { className: "flex-col p-t-l p-b-xl" },
            React.createElement("h1", { className: "m-b-l font-size-xxxl text-center" },
                React.createElement(FormattedMessage, { id: "offer_detail.offer_was_removed" })),
            React.createElement(RemovedOfferImageStyled, { src: "/media/images/icon-clipboard-subtract.svg", alt: "Removed" }),
            showSimilarOffers && (React.createElement(React.Fragment, null,
                React.createElement("h3", { className: "m-t-l font-size-l text-center" },
                    React.createElement(FormattedMessage, { id: "offer_detail.we_picked_similar_offers" })),
                React.createElement(SeparatorStyled, null),
                React.createElement(DeletedOfferSuggestedOffers, null, similarOffers.map(o => {
                    const slug = new SlugOffer({ text: o.title });
                    return (React.createElement(DeletedOfferSuggestedOffer, null,
                        React.createElement("div", { className: "font-size-l m-b-m" }, o.title),
                        React.createElement(Tag, { className: "m-b-xs flex-row align-center", color: "magenta", icon: React.createElement(Icon, { style: { width: '0.75rem' }, className: "icon", type: "maps-pin" }) }, o.city),
                        Boolean(Number(o.is_paid)) && (React.createElement(Tag, { className: "flex-row align-center", color: "green", icon: React.createElement(EuroOutlined, null) }, getSalaryString({
                            rate: o.salary_rate,
                            amountFrom: o.salary_amount_from,
                            amountTo: o.salary_amount_to,
                            currency: o.salary_currency,
                        }))),
                        React.createElement(Button, { className: "m-t-m", link: `/ponuka/${slug}-${o.id}`, size: "middle" },
                            React.createElement(FormattedMessage, { id: "offer_detail.details.more_details" }),
                            React.createElement(ArrowRightOutlined, { className: "m-l-s" }))));
                })))),
            !showSimilarOffers && (React.createElement(React.Fragment, null,
                React.createElement("h3", { className: "m-t-l font-size-l text-center" },
                    React.createElement(FormattedMessage, { id: "offer_detail.position_no_longer_available" })),
                React.createElement(SeparatorStyled, null),
                React.createElement("div", { className: "flex-row justify-space-around" },
                    React.createElement(Button, { type: "primary", onClick: () => history.pushLocalized('/ponuky') },
                        React.createElement(FormattedMessage, { id: "offer_detail.see_other_offers" }))))))));
}

var ELocations;
(function (ELocations) {
    ELocations[ELocations["Bratislavsk\u00FD kraj"] = 0] = "Bratislavsk\u00FD kraj";
    ELocations[ELocations["Trnavsk\u00FD kraj"] = 1] = "Trnavsk\u00FD kraj";
    ELocations[ELocations["Tren\u010Diansky kraj"] = 2] = "Tren\u010Diansky kraj";
    ELocations[ELocations["Nitriansky kraj"] = 3] = "Nitriansky kraj";
    ELocations[ELocations["\u017Dilinsk\u00FD kraj"] = 4] = "\u017Dilinsk\u00FD kraj";
    ELocations[ELocations["Banskobystrick\u00FD kraj"] = 5] = "Banskobystrick\u00FD kraj";
    ELocations[ELocations["Pre\u0161ovsk\u00FD kraj"] = 6] = "Pre\u0161ovsk\u00FD kraj";
    ELocations[ELocations["Ko\u0161ick\u00FD kraj"] = 7] = "Ko\u0161ick\u00FD kraj";
    ELocations[ELocations["Pr\u00E1ca z domu"] = 8] = "Pr\u00E1ca z domu";
})(ELocations || (ELocations = {}));
const EAction = {
    Edit: 'Edit',
    Create: 'Create',
};
export var WorkingTimeType;
(function (WorkingTimeType) {
    WorkingTimeType["FIX"] = "fix";
    WorkingTimeType["FLEXIBLE"] = "flexible";
})(WorkingTimeType || (WorkingTimeType = {}));
const WorkingTimeTypeMap = {
    sk: {
        flexible: 'Flexibilná',
        fix: 'Fixná',
    },
    en: {
        flexible: 'Flexible',
        fix: 'Fix',
    },
    uk: {
        flexible: 'Flexible',
        fix: 'Fix',
    },
};
const offerTypeMap = {
    sk: {
        staz: 1,
        praca: 2,
        dobrovolnictvo: 3,
        brigada: 4,
        'work-and-travel': 5,
        zivnost: 6,
    },
    en: {
        internship: 1,
        permanent: 2,
        volunteering: 3,
        'temporary-job': 4,
        'work-and-travel': 5,
        contractor: 6,
    },
};
var EOfferType;
(function (EOfferType) {
    EOfferType[EOfferType["internship"] = 1] = "internship";
    EOfferType[EOfferType["graduate"] = 2] = "graduate";
    EOfferType[EOfferType["volunteering"] = 3] = "volunteering";
    EOfferType[EOfferType["temporary_job"] = 4] = "temporary_job";
    EOfferType[EOfferType["work_and_travel"] = 5] = "work_and_travel";
    EOfferType[EOfferType["contractor"] = 6] = "contractor";
})(EOfferType || (EOfferType = {}));
const EContractType = {
    fullTime: 1,
    partTime: 2,
};
const ESalaryRate = {
    hourly: 'hourly',
    monthly: 'monthly',
    once: 'once',
};
const ESalaryCurrency = {
    euro: '€',
};
export { ELocations, EAction, offerTypeMap, EOfferType, EContractType, ESalaryRate, ESalaryCurrency, WorkingTimeTypeMap, };

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
const { Title: AntTitle } = Typography;
const StyledTitle = styled(AntTitle) `
	text-align: ${({ align }) => align};
`;
function Title(_a) {
    var { align = 'center' } = _a, rest = __rest(_a, ["align"]);
    return React.createElement(StyledTitle, Object.assign({}, rest, { align: align }));
}
export { Title };

import React from 'react'

const CoinStacks = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
		<g strokeLinecap="round" strokeLinejoin="round" strokeWidth="0">
			<path d="M47.96167 113.60417a1.44667 1.44667 0 001.39416-1.4525V98.49583a1.45833 1.45833 0 00-1.5225-1.45833c-2.33333.105-4.66666.1575-7 .1575a108.5 108.5 0 01-36.16666-5.25 1.45833 1.45833 0 00-1.9775 1.38833v12.55917c0 4.3575 17.07416 7.8575 38.14416 7.8575q3.6575 0 7.12834-.14583z" />
			<path d="M40.83333 44.695a87.17333 87.17333 0 0037.36834-6.34083 1.4525 1.4525 0 00.77583-1.28917V23.33333a1.45833 1.45833 0 00-1.98917-1.35916 108.5 108.5 0 01-36.16666 5.25 108.5 108.5 0 01-36.16667-5.25 1.45833 1.45833 0 00-1.96583 1.35916v13.7375a1.4525 1.4525 0 00.77583 1.28334A87.17333 87.17333 0 0040.83333 44.695z" />
			<path d="M40.83333 21.36167a87.17333 87.17333 0 0037.36834-6.34084 1.4525 1.4525 0 00.77583-1.28916v-2.45584C78.9775 6.93 61.90333 3.395 40.83333 3.395S2.68917 6.93 2.68917 11.27583v2.46167a1.4525 1.4525 0 00.77583 1.28333 87.17333 87.17333 0 0037.36833 6.34084z" />
			<path d="M40.83333 91.36167c2.49084 0 4.865-.06417 7.13417-.16917a1.45833 1.45833 0 001.38833-1.45833V80.7975a13.5625 13.5625 0 01.84584-5.1625 1.44667 1.44667 0 00-.175-1.3825 1.47 1.47 0 00-1.26-.58333c-2.6425.14-5.29667.21583-7.93334.21583a108.5 108.5 0 01-36.16666-5.25A1.45833 1.45833 0 002.68917 70v13.76083a1.4525 1.4525 0 00.77583 1.28334 87.17333 87.17333 0 0037.36833 6.3175z" />
			<path d="M40.83333 68.02833a122.5 122.5 0 0037.33334-6.34083 1.45833 1.45833 0 00.78166-1.28917V46.66667a1.45833 1.45833 0 00-1.98916-1.35917 108.5 108.5 0 01-36.16667 5.25 108.5 108.5 0 01-36.16667-5.25 1.45833 1.45833 0 00-1.93666 1.35917V60.4275a1.4525 1.4525 0 00.77583 1.28333 87.17333 87.17333 0 0037.36833 6.3175z" />
			<path d="M63 91.4725a1.45833 1.45833 0 00-1.98917 1.35917v13.755a1.4525 1.4525 0 00.77584 1.28916 87.17333 87.17333 0 0037.38 6.34084 87.17333 87.17333 0 0037.36833-6.34084 1.45833 1.45833 0 00.77583-1.28916v-13.755a1.45833 1.45833 0 00-1.98916-1.35917 108.5 108.5 0 01-36.16667 5.25A108.5 108.5 0 0163 91.4725z" />
			<path d="M99.16667 120.05A108.5 108.5 0 0163 114.8a1.45833 1.45833 0 00-1.98917 1.35917v12.55916c0 4.3575 17.07417 7.88084 38.14417 7.88084s38.14417-3.5 38.14417-7.88084V116.165a1.45833 1.45833 0 00-1.98917-1.35917A108.5 108.5 0 0199.16667 120.05z" />
			<path d="M61.0225 80.7975v2.45583a1.4525 1.4525 0 00.77583 1.28917 87.17333 87.17333 0 0037.36834 6.34083A87.17333 87.17333 0 00136.535 84.5425a1.4525 1.4525 0 00.77583-1.28917V80.7975c0-4.34583-17.07416-7.88083-38.14416-7.88083s-38.14417 3.535-38.14417 7.88083z" />
		</g>
	</svg>
)

export default CoinStacks

import {
	call,
	put,
} from 'redux-saga/effects';
// import { history } from '@src/app/index';
import axios from 'axios';
import URI from 'urijs';
import config from 'config';
import actions from '../actions';

// eslint-disable-next-line import/prefer-default-export
export function* createCompanyApiKeyStart() {
	const uri = new URI(`${config.api}/0/company-api-key`);
	const apiUri = uri.toString();

	const createApiKey = async () => {
		try {
			const response = await axios.post(apiUri, {}, { withCredentials: true });

			return response;
		} catch (err) {
			throw new Error(err);
		}
	};

	try {
		const response = yield call(createApiKey);
		yield put(actions.createCompanyApiKeySuccess(response));
		yield put(actions.myCompanyInfoInitStart());
	} catch (err) {
		yield put(actions.createCompanyApiKeyError(err));
	}
}

import React from 'react';
import styled from 'styled-components';
import { useSSE } from 'use-sse';
import getCmsAsset from '@src/helpers/get-cms-asset';
import * as Api from 'api';
export const StyledCompanyLogos = styled.div `
	padding: 0 0 2em 0;
	display: flex;
	flex-wrap: wrap;
`;
export const Box = styled.div `
	width: 25%;
	display: flex;
	padding: 0 0 2em 0;
	position: relative;
	flex: 1 1 auto;
	box-flex: 1;

	img {
		margin: 0 auto;
		opacity: 0.2;
		display: block;
		max-width: 100%;
		object-fit: contain;
	}
`;
const CompanyLogos = () => {
    const [companyLogos] = useSSE(() => {
        return Api.Cms.Web.getCompanyLogos();
    }, []);
    return (React.createElement(StyledCompanyLogos, null, companyLogos &&
        companyLogos.map(item => {
            return (React.createElement(Box, { key: item.name },
                React.createElement("img", { src: getCmsAsset(item.image, 'original'), alt: item.name, title: item.name, width: "150", height: "90" })));
        })));
};
export { CompanyLogos };

import { fork, takeLatest, all } from 'redux-saga/effects'
import actions from '../actions'
import { mainInitStart, cookieConsentStart, cookieConsentError, authCheckStart } from './app'
import {
	loadOfferDetailStart,
	loadOfferDetailError,
	loadOfferSuggestionsCompanyStart,
	loadSimilarOffersStart,
} from './offer-detail'
import { getPlanStart, getPlanError } from './plan'
import { getPlansStart, getPlansError } from './plans'
import { loadMyResumesStart } from './account-resume'
import {
	registrationSubmitStart,
	registrationSubmitSuccess,
	registrationSubmitError,
	registrationLoadCompanyNamesStart,
	registrationLoadCompanyInfoStart,
} from './registration'
import { registrationStudentStart, registrationStudentSuccess, registrationStudentError } from './registration-student'

import resetPasswordEmailStart from './reset-password-email'
import resetPasswordStart from './reset-password'
import {
	personalInfoInitStart,
	personalInfoInitError,
	personalInfoEditStart,
	personalInfoEditError,
	personalInfoEditSuccess,
} from './personal-info'
import {
	offerEditInitStart,
	offerEditInitError,
	offerEditStart,
	offerEditError,
	offerEditSuccess,
	offerToggleStart,
	offerToggleError,
	offerToggleSuccess,
} from './offer-edit'
import { offerDeleteStart, offerDeleteError, offerDeleteSuccess } from './offer-delete'
import {
	loadMyOfferApplicationsStart,
	loadMyOfferApplicationsError,
	loadMoreOfferApplicationsStart,
	loadMoreOfferApplicationsError,
} from './my-offer-applications'
import { myCompanyInfoInitStart, myCompanyInfoInitError } from './my-company'
import { createCompanyApiKeyStart } from './company-api-key'
import { loginInfoEditStart, loginInfoEditError, loginInfoEditSuccess } from './login-info'
import { accountEditStart, accountEditError, accountEditSuccess } from './account-edit'
import { companyInfoEditStart, companyInfoEditError, companyInfoEditSuccess } from './company-info-edit'
import { loginStart, loginSuccess } from './login'
import { contactUsStart, contactUsSuccess } from './contact-us'
import { sendResumeStart, sendResumeSuccess } from './send-resume'
import { userVerificationStart, userVerificationError } from './user-verification'
import { offerPostStart, offerPostSuccess, offerPostError } from './offer'
import { logoutStart, logoutSuccess, logoutError } from './logout'
import {
	resendVerificationEmailStart,
	resendVerificationEmailError,
	resendVerificationEmailSuccess,
} from './resend-verification-email'
import { offerFeedbackStart, offerFeedbackSuccess, offerFeedbackError } from './offer-feedback'
import { studentFeedbackStart, studentFeedbackSuccess, studentFeedbackError } from './student-feedback'
import { loadOfferApplicationStart } from './offer-application'

export function* sagas() {
	yield all([
		// app
		fork(takeLatest, actions.mainInitStart().type, mainInitStart),
		fork(takeLatest, actions.authCheckStart().type, authCheckStart),
		fork(takeLatest, actions.cookieConsentStart().type, cookieConsentStart),
		fork(takeLatest, actions.cookieConsentError().type, cookieConsentError),

		// contact-us
		fork(takeLatest, actions.contactUsStart().type, contactUsStart),
		fork(takeLatest, actions.contactUsSuccess().type, contactUsSuccess),

		// offers
		fork(takeLatest, actions.loadOfferDetailStart().type, loadOfferDetailStart),
		fork(takeLatest, actions.loadOfferDetailError().type, loadOfferDetailError),
		fork(takeLatest, actions.loadOfferSuggestionsCompanyStart().type, loadOfferSuggestionsCompanyStart),
		fork(takeLatest, actions.loadSimilarOffersStart().type, loadSimilarOffersStart),

		// plan
		fork(takeLatest, actions.getPlanStart().type, getPlanStart),
		fork(takeLatest, actions.getPlanError().type, getPlanError),

		// public plans
		fork(takeLatest, actions.getPlansStart().type, getPlansStart),
		fork(takeLatest, actions.getPlansError().type, getPlansError),

		// registration
		fork(takeLatest, actions.registrationSubmitError().type, registrationSubmitError),
		fork(takeLatest, actions.registrationSubmitStart().type, registrationSubmitStart),
		fork(takeLatest, actions.registrationSubmitSuccess().type, registrationSubmitSuccess),
		fork(takeLatest, actions.registrationLoadCompanyNamesStart().type, registrationLoadCompanyNamesStart),
		fork(takeLatest, actions.registrationLoadCompanyInfoStart().type, registrationLoadCompanyInfoStart),

		// registration-student
		fork(takeLatest, actions.registrationStudentError().type, registrationStudentError),
		fork(takeLatest, actions.registrationStudentStart().type, registrationStudentStart),
		fork(takeLatest, actions.registrationStudentSuccess().type, registrationStudentSuccess),

		// reset-password-email
		fork(takeLatest, actions.resetPasswordEmailStart().type, resetPasswordEmailStart),

		// user-verification
		fork(takeLatest, actions.userVerificationStart().type, userVerificationStart),
		fork(takeLatest, actions.userVerificationError().type, userVerificationError),

		// reset-password
		fork(takeLatest, actions.resetPasswordStart().type, resetPasswordStart),

		// personal-info-edit
		fork(takeLatest, actions.personalInfoInitStart().type, personalInfoInitStart),
		fork(takeLatest, actions.personalInfoInitError().type, personalInfoInitError),
		fork(takeLatest, actions.personalInfoEditStart().type, personalInfoEditStart),
		fork(takeLatest, actions.personalInfoEditSuccess().type, personalInfoEditSuccess),
		fork(takeLatest, actions.personalInfoEditError().type, personalInfoEditError),

		// send-resume
		fork(takeLatest, actions.sendResumeStart().type, sendResumeStart),
		fork(takeLatest, actions.sendResumeSuccess().type, sendResumeSuccess),

		// offer-delete
		fork(takeLatest, actions.offerDeleteStart().type, offerDeleteStart),
		fork(takeLatest, actions.offerDeleteSuccess().type, offerDeleteSuccess),
		fork(takeLatest, actions.offerDeleteError().type, offerDeleteError),

		// init my saved resumes
		fork(takeLatest, actions.loadMyResumesStart().type, loadMyResumesStart),

		// offer-edit
		fork(takeLatest, actions.offerEditInitStart().type, offerEditInitStart),
		fork(takeLatest, actions.offerEditInitError().type, offerEditInitError),
		fork(takeLatest, actions.offerEditStart().type, offerEditStart),
		fork(takeLatest, actions.offerEditSuccess().type, offerEditSuccess),
		fork(takeLatest, actions.offerEditError().type, offerEditError),
		fork(takeLatest, actions.offerToggleStart().type, offerToggleStart),
		fork(takeLatest, actions.offerToggleSuccess().type, offerToggleSuccess),
		fork(takeLatest, actions.offerToggleError().type, offerToggleError),

		// login
		fork(takeLatest, actions.loginStart().type, loginStart),
		fork(takeLatest, actions.loginSuccess().type, loginSuccess),

		// login-info-edit
		fork(takeLatest, actions.loginInfoEditStart().type, loginInfoEditStart),
		fork(takeLatest, actions.loginInfoEditSuccess().type, loginInfoEditSuccess),
		fork(takeLatest, actions.loginInfoEditError().type, loginInfoEditError),

		// account-edit
		fork(takeLatest, actions.accountEditStart().type, accountEditStart),
		fork(takeLatest, actions.accountEditSuccess().type, accountEditSuccess),
		fork(takeLatest, actions.accountEditError().type, accountEditError),

		// company-info-edit
		fork(takeLatest, actions.companyInfoEditStart().type, companyInfoEditStart),
		fork(takeLatest, actions.companyInfoEditSuccess().type, companyInfoEditSuccess),
		fork(takeLatest, actions.companyInfoEditError().type, companyInfoEditError),

		// company api key
		fork(takeLatest, actions.createCompanyApiKeyStart().type, createCompanyApiKeyStart),

		// logout
		fork(takeLatest, actions.logoutError().type, logoutError),
		fork(takeLatest, actions.logoutStart().type, logoutStart),
		fork(takeLatest, actions.logoutSuccess().type, logoutSuccess),

		// offers
		fork(takeLatest, actions.offerPostError().type, offerPostError),
		fork(takeLatest, actions.offerPostStart().type, offerPostStart),
		fork(takeLatest, actions.offerPostSuccess().type, offerPostSuccess),

		// admin-init
		fork(takeLatest, actions.myCompanyInfoInitStart().type, myCompanyInfoInitStart),
		fork(takeLatest, actions.myCompanyInfoInitError().type, myCompanyInfoInitError),

		// profile
		fork(takeLatest, actions.loadMyOfferApplicationsStart().type, loadMyOfferApplicationsStart),
		fork(takeLatest, actions.loadMyOfferApplicationsError().type, loadMyOfferApplicationsError),
		fork(takeLatest, actions.loadMoreOfferApplicationsStart().type, loadMoreOfferApplicationsStart),
		fork(takeLatest, actions.loadMoreOfferApplicationsError().type, loadMoreOfferApplicationsError),

		// resend-verification-email
		fork(takeLatest, actions.resendVerificationEmailError().type, resendVerificationEmailError),
		fork(takeLatest, actions.resendVerificationEmailStart().type, resendVerificationEmailStart),
		fork(takeLatest, actions.resendVerificationEmailSuccess().type, resendVerificationEmailSuccess),

		// offer-feedback
		fork(takeLatest, actions.offerFeedbackError().type, offerFeedbackError),
		fork(takeLatest, actions.offerFeedbackStart().type, offerFeedbackStart),
		fork(takeLatest, actions.offerFeedbackSuccess().type, offerFeedbackSuccess),

		// student-feedback
		fork(takeLatest, actions.studentFeedbackStart().type, studentFeedbackStart),
		fork(takeLatest, actions.studentFeedbackSuccess().type, studentFeedbackSuccess),
		fork(takeLatest, actions.studentFeedbackError().type, studentFeedbackError),

		// load offer-application for feedback
		fork(takeLatest, actions.loadOfferApplicationStart().type, loadOfferApplicationStart),
	])
}

const validate = (values, { intl }) => {
	const errors = {};
	if (!values.first_name) {
		errors.first_name = intl.formatMessage({
			id: "admin.personal_info_edit_validation.please_fill_first_name",
			defaultMessage: 'Prosím, vyplňte krstné meno',
		});
	} else if (/[0-9_~`!@#$%^&*(),.;<>?]/.test(values.first_name)) {
		errors.first_name = intl.formatMessage({
			id: "admin.personal_info_edit_validation.forbidden_characters",
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		});
	}

	if (!values.last_name) {
		errors.last_name = intl.formatMessage({
			id: "admin.personal_info_edit_validation.please_fill_last_name",
			defaultMessage: 'Prosím, vyplňte priezvisko',
		});
	} else if (/[0-9_~`!@#$%^&*(),.;<>?]/.test(values.last_name)) {
		errors.last_name = intl.formatMessage({
			id: "admin.personal_info_edit_validation.forbidden_characters",
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		});
	}

	if (values.title_before && values.title_before.length > 8) {
		errors.title_before = intl.formatMessage({
			id: "admin.personal_info_edit_validation.title_before_can_have_max",
			defaultMessage: 'Titul pred menom môže obsahovať maximálne 8 znakov!',
		});
	} else if (/[0-9_~`!@#$%^&*(),;<>?]/.test(values.title_before)) {
		errors.title_before = intl.formatMessage({
			id: "admin.personal_info_edit_validation.forbidden_characters",
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		});
	}

	if (values.title_after && values.title_after.length > 8) {
		errors.title_after = intl.formatMessage({
			id: "admin.personal_info_edit_validation.title_after_can_have_max",
			defaultMessage: 'Titul za menom môže obsahovať maximálne 8 znakov!',
		});
	} else if (/[0-9_~`!@#$%^&*(),;<>?]/.test(values.title_after)) {
		errors.title_after = intl.formatMessage({
			id: "admin.personal_info_edit_validation.forbidden_characters",
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		});
	}

	if (!values.telephone) {
		errors.telephone = intl.formatMessage({
			id: "admin.personal_info_edit_validation.please_fill_phone_number",
			defaultMessage: 'Prosím, vyplňte tel. číslo',
		});
	} else if (/\d/g.test(values.telephone) && values.telephone.match(/\d/g).length < 9) {
		errors.telephone = intl.formatMessage({
			id: "admin.personal_info_edit_validation.phone_number_too_short",
			defaultMessage: 'Tel. číslo je príliš krátke',
		});
	} else if (/\d/g.test(values.telephone) && values.telephone.match(/\d/g).length > 12) {
		errors.telephone = intl.formatMessage({
			id: "admin.personal_info_edit_validation.phone_number_too_long",
			defaultMessage: 'Tel. číslo je príliš dlhé',
		});
	} else if (/[A-Za-z_~`!@#$%^&*(),.;<>?]/.test(values.telephone)) {
		errors.telephone = intl.formatMessage({
			id: "admin.personal_info_edit_validation.forbidden_characters",
			defaultMessage: 'Údaj obsahuje nepovolené znaky!',
		});
	}

	return errors;
};

export default validate;

const cdnLink = ({ transformation, file }) => {
	const baseUrl = 'https://res.cloudinary.com/praxuj/image/upload';
	const transformationResult = transformation ? `/${transformation}` : '';
	const environment = `/${process.env.ENVIRONMENT}`;
	// bump this if you need to invalidate cached assets
	const version = '/v1559170020';
	// replace ':' -> '/'
	// due to dummy images
	const fileResult = file ? `/${file.replace(':', '/')}` : '';

	return `${baseUrl}${transformationResult}${version}${environment}${fileResult}`;
};

export default cdnLink;

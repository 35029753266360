import React from 'react';
import { isEqual } from 'lodash';
import { useHistory, useLocation, useParams } from 'react-router';
import { OfferFilterType } from '@src/types';
import { useSSRFetch, SSRFetchConfig } from '@src/hooks/useSSRFetch';
import { offerTypeMap } from '@src/types/offer';
import { getLanguageFromPathname } from '@src/shared/localized-path';
import { getFilterParamsFromPathname } from '@src/helpers/get-filter-params-from-pathname';
import { getOfferTypeIdsFromFilterTypeParameter, replaceWrongTypeUrlParamsToNewUrl, } from './utils/offers-filter';
const OffersFilterContext = React.createContext({
    typeFiltersSelected: [],
    locationFiltersSelected: [],
    jobAreaFiltersSelected: [],
    setTypeFilersSelected: () => null,
    setLocationFilersSelected: () => null,
    setJobAreaFiltersSelected: () => null,
    offers: {},
    offersFiltersSSR: {},
    areOffersLoading: false,
    areFiltersLoading: false,
    changePage: () => null,
    setPage: () => null,
});
export const useOffersFilterContextProvider = () => {
    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    const actualLocale = getLanguageFromPathname(location.pathname);
    const { offerTypeIds, page: pageFromPathname, offerTypesParam, pageParam, } = getFilterParamsFromPathname(location.pathname, actualLocale);
    const [page, setPage] = React.useState(pageFromPathname);
    const [typeFiltersSelected, setTypeFilersSelected] = React.useState(offerTypeIds);
    const [locationFiltersSelected, setLocationFilersSelected] = React.useState([]);
    const [jobAreaFiltersSelected, setJobAreaFiltersSelected] = React.useState([]);
    const selectedTypeFilterItemsRef = React.useRef(typeFiltersSelected);
    const selectedLocationFilterItemsRef = React.useRef(locationFiltersSelected);
    const selectedJobAreaFiltersSelectedRef = React.useRef(jobAreaFiltersSelected);
    React.useEffect(() => {
        const urlParts = location.pathname.split('/');
        let offerTypeUrlPart;
        const getLastUrlPart = () => urlParts[urlParts.length - 1];
        // do not take page number into account
        if (parseInt(getLastUrlPart(), 10)) {
            urlParts.pop();
        }
        if (getLastUrlPart().includes(`type:`)) {
            offerTypeUrlPart = urlParts.pop();
        }
        const hasTypeFilterChanged = offerTypeUrlPart !== offerTypesParam;
        if (hasTypeFilterChanged) {
            return;
        }
        if (typeFiltersSelected.length) {
            const offerTypesString = typeFiltersSelected
                .map(id => Object.keys(offerTypeMap[actualLocale])[id - 1])
                .join(',');
            const filteredTypes = `type:${offerTypesString}`;
            urlParts.push(filteredTypes);
            urlParts.push(pageParam);
            history.pushLocalized(urlParts.join('/'));
        }
        else {
            history.pushLocalized(location.pathname.replace(`/${offerTypesParam}`, ''));
        }
    }, [typeFiltersSelected, pageParam]);
    const changePage = (newPage) => {
        const pageHasNotChanged = (!parseInt(params.page, 10) && newPage === 1) || parseInt(params.page, 10) === newPage;
        if (pageHasNotChanged) {
            return;
        }
        setPage(newPage);
        const offerTypeParams = params.offerType ? `/${params.offerType}` : '';
        if (newPage === 1) {
            return history.pushLocalized(`/ponuky${offerTypeParams}`);
        }
        return history.pushLocalized(`/ponuky${offerTypeParams}/${newPage}`);
    };
    React.useEffect(() => {
        const areTypesEqual = isEqual(selectedTypeFilterItemsRef.current, typeFiltersSelected);
        const areLocationsEqual = isEqual(locationFiltersSelected, selectedLocationFilterItemsRef.current);
        const areJobAreasEqual = isEqual(jobAreaFiltersSelected, selectedJobAreaFiltersSelectedRef.current);
        if (!areTypesEqual || !areLocationsEqual || !areJobAreasEqual) {
            changePage(1);
            selectedTypeFilterItemsRef.current = typeFiltersSelected;
            selectedLocationFilterItemsRef.current = locationFiltersSelected;
            selectedJobAreaFiltersSelectedRef.current = jobAreaFiltersSelected;
        }
    }, [typeFiltersSelected, locationFiltersSelected, jobAreaFiltersSelected]);
    const { data: offers, isLoading: areOffersLoading, error: offersError, } = useSSRFetch(SSRFetchConfig.offers.key, {
        [OfferFilterType.type]: typeFiltersSelected,
        [OfferFilterType.location]: locationFiltersSelected,
        [OfferFilterType.jobArea]: jobAreaFiltersSelected,
        page,
    }, [typeFiltersSelected, locationFiltersSelected, jobAreaFiltersSelected, page]);
    const { data: offersFiltersSSR, isLoading: areFiltersLoading, error: filterError, } = useSSRFetch(SSRFetchConfig.offersFilters.key, {
        [OfferFilterType.type]: typeFiltersSelected,
        [OfferFilterType.location]: locationFiltersSelected,
        [OfferFilterType.jobArea]: jobAreaFiltersSelected,
        page,
    }, [typeFiltersSelected, locationFiltersSelected, jobAreaFiltersSelected, page]);
    React.useEffect(() => {
        if (filterError || offersError) {
            // @ts-ignore
            history.pushLocalized('/error');
        }
    }, [filterError, offersError]);
    React.useEffect(() => {
        const { offerType } = params;
        const { typeParamsForCurrentLocale, isTypeParamWrong } = getOfferTypeIdsFromFilterTypeParameter(offerType, actualLocale);
        if (isTypeParamWrong) {
            const urlParts = location.pathname.split('/');
            const correctUrl = replaceWrongTypeUrlParamsToNewUrl(urlParts, typeParamsForCurrentLocale);
            if (correctUrl) {
                history.pushLocalized(correctUrl);
            }
        }
    }, [params]);
    return {
        OffersFilterContextProvider: OffersFilterContext.Provider,
        value: {
            typeFiltersSelected,
            locationFiltersSelected,
            jobAreaFiltersSelected,
            setTypeFilersSelected: id => setTypeFilersSelected(id),
            setLocationFilersSelected: loc => setLocationFilersSelected(loc),
            setJobAreaFiltersSelected: id => setJobAreaFiltersSelected(id),
            offers,
            offersFiltersSSR,
            areOffersLoading,
            areFiltersLoading,
            changePage: n => changePage(n),
            setPage: p => setPage(p),
        },
    };
};
export const useOffersFilterContextValue = () => {
    const context = React.useContext(OffersFilterContext);
    return context;
};

import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import actions from '@src/actions';
import FeedbackForm from './modules/feedback-form';

const FeedbackStudent = () => {
	const dispatch = useDispatch()
	const { feedbackToken } = useParams()

	React.useEffect(() => {
		dispatch(actions.loadOfferApplicationStart({ feedbackToken }));
	}, [])

	return (
		<FeedbackForm />
	)
};

export default FeedbackStudent;

import React from 'react'
import axios from 'axios'
import config from 'config'
import { FormattedMessage, useIntl } from 'react-intl'
import { OfferNotificationSubscriptionForm } from 'components/modules/forms/offer-notification-subscription-form'
import FormResponse from 'components/modules/global/form-response'
import Button from 'components/modules/global/button'
import LoadingWrapper from 'components/modules/global/loading-wrapper'
import { Col, Row } from 'antd'

function offerNotificationSubscription() {
	const [subscription, setSubscription] = React.useState({})
	const [showForm, setShowForm] = React.useState(false)
	const [isLoading, setIsLoading] = React.useState(true)
	const intl = useIntl()

	const initialize = React.useCallback(async () => {
		setIsLoading(true)
		try {
			const response = await axios.get(`${config.api}/0/offer-notification-subscription`, {
				withCredentials: true,
			})
			setSubscription(response.data)
		} catch (err) {
			setSubscription({})
		} finally {
			setIsLoading(false)
		}
	}, [])

	React.useEffect(() => {
		initialize()
	}, [initialize])

	React.useEffect(() => {
		setShowForm(Boolean(subscription.id))
	}, [subscription])

	return (
		<div className="panel">
			<LoadingWrapper isLoading={isLoading}>
				{!subscription.id && !showForm && (
					<FormResponse
						icon={{
							type: 'edit',
						}}
						text={
							<div>
								<div className="u-margin-bottom">
									<FormattedMessage
										id="profile.offer_notification_subscription.you_dont_subscribe_news"
										defaultMessage="Momentálne nové ponuky <strong>neodoberáte</strong>"
										values={{
											strong: chunks => <strong>{chunks}</strong>,
										}}
									/>
								</div>
								<div className="u-subtext u-margin-bottom">
									<FormattedMessage
										id="profile.offer_notification_subscription.we_will_send_news"
										defaultMessage="Na email Vám budeme posielať nové ponuky, vybrané podľa oblasti a zamerania."
									/>
								</div>
								<Button
									text={intl.formatMessage({
										id: 'profile.offer_notification_subscription.subscribe',
										defaultMessage: 'Vytvoriť odber',
									})}
									onClick={() => setShowForm(true)}
								/>
							</div>
						}
					/>
				)}
				{showForm && (
					<>
						<Row gutter={[16, 16]}>
							<Col xs={24}>
								<div className="u-subtext u-margin-bottom u-text-center">
									<FormattedMessage
										id="offers.offer_notification_subscription_form.choose_regions"
										values={{
											breakingLine: <br />,
										}}
									/>
								</div>
							</Col>
						</Row>
						<OfferNotificationSubscriptionForm
							subscription={subscription}
							origin="section"
							onSuccess={initialize}
							intl={intl}
						/>
					</>
				)}
			</LoadingWrapper>
		</div>
	)
}

export default offerNotificationSubscription

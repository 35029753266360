import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { Row, Col } from 'antd'
import { Holder, Panel, Title } from '@src/components/elements'
import actions from '../../../actions'
import RegistrationForm from './modules/registration-form'

const Registration = props => {
	const intl = useIntl()
	return (
		<Holder marginVertical>
			<Row justify="center">
				<Col xs={{ span: 24 }} lg={{ span: 12 }}>
					<Title level={2} type="secondary">
						<FormattedMessage id="registration.employer" />
					</Title>
				</Col>
			</Row>
			<Row justify="center">
				<Col xs={{ span: 24 }} xl={{ span: 18 }}>
					<Panel>
						<RegistrationForm
							onSubmit={props.handleSubmit}
							registrationFormSuccess={props.registrationFormSuccess}
							options={props.options}
							registrationLoadCompanyNames={props.registrationLoadCompanyNames}
							registrationLoadCompanyInfo={props.registrationLoadCompanyInfo}
							isLoading={props.isLoading}
							intl={intl}
						/>
					</Panel>
				</Col>
			</Row>
		</Holder>
	)
}

const mapStateToProps = state => ({
	options: state.registration.options,
	isLoading: state.registration.isLoading,
	registrationFormSuccess: state.registration.registrationFormSuccess,
})

const mapDispatchToProps = dispatch => ({
	registrationLoadCompanyNames: query => {
		dispatch(actions.registrationLoadCompanyNamesStart(query))
	},
	handleSubmit: values => {
		dispatch(actions.registrationSubmitStart(values))
	},
	registrationLoadCompanyInfo: ico => {
		dispatch(actions.registrationLoadCompanyInfoStart(ico))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Registration)

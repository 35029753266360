import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import getCmsAsset from '@src/helpers/get-cms-asset';
import excerptHtml from 'excerpt-html';
import { Link, Title } from '@src/components/elements';
import Timestamp from '@src/components/modules/global/timestamp';
import { FormattedMessage } from 'react-intl';
export const StyledExcerpt = styled.article `
	border: 1px solid #e8e0da;
	background: #fff;
	border-radius: 0.3rem;
	overflow: hidden;
	display: flex;
	margin-bottom: 1.875rem;
	flex-direction: column;
	width: 100%;
	${up('md')} {
		flex-direction: row;
	}
`;
export const StyledImageLink = styled(Link) `
	display: block;
	padding-top: 0;
	min-height: 250px;
	max-height: 400px;
	height: 75vw;
	width: auto;
	${up('md')} {
		width: 340px;
		height: auto;
		min-height: initial;
		max-height: initial;
	}
`;
export const Image = styled.span `
	height: 100%;
	width: 100%;
	display: block;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
	background-image: url(${props => getCmsAsset(props.url)});

	@media only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (min--moz-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 1.3/1),
		only screen and (min-resolution: 144dpi),
		only screen and (min-resolution: 1.5dppx) {
		background-image: url(${props => getCmsAsset(props.url, 'preview_2x')});
	}
`;
export const Text = styled.div `
	padding: 2.25rem;
	flex: 1;
	display: flex;
	flex-direction: column;
	min-height: 340px;
`;
export const PostTimestamp = styled.time `
	font-weight: bold;
	text-transform: uppercase;
	color: #6c6c6c;
`;
export const Headline = styled.h2 `
	font-weight: bold;
	text-transform: uppercase;
	color: ${props => props.theme.color.brand1};
	font-size: 2rem;
	margin: 0.4rem 0;
`;
export const Content = styled.div `
	font-size: 1.1rem;
`;
export const LinkWrapper = styled.div `
	display: flex;
	flex: 1;
	margin-top: 0.8rem;
	flex-direction: column-reverse;
	align-items: flex-start;
	align-self: flex-end;
	justify-content: space-between;
	width: 100%;
	${up('md')} {
		flex-direction: row;
		align-items: flex-end;
		align-self: flex-end;
		justify-content: space-between;
	}
`;
export const StyledLink = styled(Link) `
	display: block;
	font-size: 1.4rem;
	text-decoration: underline;
	text-align: right;
`;
export const Category = styled.span `
	display: block;
	font-size: 1em;
	color: ${props => props.theme.color.gray5};
	text-transform: uppercase;
	text-align: left;
	margin-top: 2em;
	margin-bottom: 0.3em;
	${up('md')} {
		margin-top: 0;
	}
`;
export const StyledHeadlineLink = styled(Link) `
	display: inline-block;
	text-decoration: uppercase;
	&:hover {
		text-decoration: none;
	}
`;
export const Scheduled = styled.span `
	color: ${props => props.theme.color.white};
	background: ${props => props.theme.color.warning};
	padding: 0 0.5em;
	display: block;
	border-radius: 3px;
	${up('md')} {
		display: inline-block;
		margin: 0 0 0 1em;
	}
`;
export const Concept = styled.span `
	color: ${props => props.theme.color.white};
	background: ${props => props.theme.color.success};
	display: block;
	padding: 0 0.5em;
	border-radius: 3px;
	${up('md')} {
		display: inline-block;
		margin: 0 0 0 1em;
	}
`;
const Excerpt = ({ post }) => {
    const excerpt = excerptHtml(post.content, { pruneLength: 520 });
    return (React.createElement(StyledExcerpt, null,
        React.createElement(StyledImageLink, { to: `/magazin/${post.slug}` },
            React.createElement(Image, { url: post.coverImage })),
        React.createElement(Text, null,
            React.createElement(PostTimestamp, null,
                React.createElement(Timestamp, { value: post.date, type: "humanized" }),
                post.scheduled && (React.createElement(Scheduled, null,
                    React.createElement(FormattedMessage, { id: "magazine.scheduled" }))),
                !post.published && (React.createElement(Concept, null,
                    React.createElement(FormattedMessage, { id: "magazine.concept" })))),
            React.createElement(StyledHeadlineLink, { to: `/magazin/${post.slug}` },
                React.createElement(Title, { level: 2, type: "secondary", align: "left" }, post.title)),
            React.createElement(Content, null, excerpt),
            React.createElement(LinkWrapper, null,
                React.createElement(Category, null, post.category),
                React.createElement(StyledLink, { to: `/magazin/${post.slug}` },
                    React.createElement(FormattedMessage, { id: "magazine.full_article" }))))));
};
export default Excerpt;

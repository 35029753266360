import React from 'react';
import { Typography, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Modal } from '@src/components/elements';
import { OfferNotificationSubscriptionForm } from '@src/components/modules/forms/offer-notification-subscription-form';
const { Text, Title } = Typography;
const OfferNotificationSubscriptionModal = ({ isOpen, setIsOpen }) => {
    const closeModal = () => {
        setIsOpen(false);
    };
    return (React.createElement(Modal, { title: React.createElement(FormattedMessage, { id: "offers.offer_notification_subscription_form.didnt_find_suitable_offer" }), visible: isOpen, footer: null, onCancel: closeModal, destroyOnClose: true, width: 720 },
        React.createElement(Row, { gutter: [16, 16], className: "m-b-l" },
            React.createElement(Col, null,
                React.createElement(Title, { level: 3 },
                    React.createElement(FormattedMessage, { id: "offers.offer_notification_subscription_form.tell_us_which_offers" }))),
            React.createElement(Col, null,
                React.createElement(Text, null,
                    React.createElement(FormattedMessage, { id: "offers.offer_notification_subscription_form.choose_regions", values: {
                            breakingLine: React.createElement("br", null),
                        } })))),
        React.createElement(OfferNotificationSubscriptionForm, { showEmailField: true, onSubmit: closeModal })));
};
export { OfferNotificationSubscriptionModal };

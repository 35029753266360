import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import URI from 'urijs'
import { notification } from 'antd'
import * as Cookies from 'js-cookie'
import config from 'config'
import actions from '../actions'

export function* resendVerificationEmailStart() {
	const uri = new URI(`${config.api}/0/resend-verification-email`)
	const apiUri = uri.toString()

	const resendEmail = () =>
		axios
			.get(apiUri, { withCredentials: true })
			.then(res => res)
			.catch(err => {
				throw err
			})

	try {
		yield call(resendEmail)
		yield put(actions.resendVerificationEmailSuccess())
	} catch (err) {
		yield put(actions.resendVerificationEmailError(err))
	}
}

export function* resendVerificationEmailSuccess() {
	const showNotification = () =>
		notification.success({
			message: 'Upozornenie',
			description: 'Verifikačný email bol znova odoslaný',
		})

	const in30Minutes = 1 / 48
	Cookies.set('verificationResent', 'true', {
		expires: in30Minutes,
	})

	yield call(showNotification)
}

export function* resendVerificationEmailError(err) {
	const showError = () => {
		notification.error({
			message: 'Chyba',
			description: 'Užívateľ nie je prihlásený',
		})
	}

	yield call(showError(err))
}

import React from 'react';
import ISO6391 from 'iso-639-1';
import styled from 'styled-components';
import { useConfig } from '@src/hooks/useConfig';
import { FormattedMessage } from 'react-intl';
const TableRow = styled.div `
	font-size: ${props => props.theme.fontSizes.sm};
`;
const LevelTD = styled.td `
	float: right;
`;
const TableWrapper = styled.div `
	display: flex;
	flex-direction: column;
	max-width: 300px;
`;
const LevelTitleTD = styled.td `
	float: right;
	color: ${props => props.theme.color.gray7};
	margin-bottom: 0.5rem;
`;
const LanguageTd = styled.td `
	color: ${props => props.theme.color.gray7};
	margin-bottom: 0.5rem;
`;
function checkLanguageTranslation(languageCode, config) {
    return config.languages.filter(lang => lang.code === languageCode)[0];
}
const Languages = ({ languages }) => {
    const config = useConfig();
    return languages === null || languages === void 0 ? void 0 : languages.map(lang => {
        const languageTranslation = checkLanguageTranslation(lang.language_code, config);
        return (React.createElement("tr", { key: lang.language_code },
            React.createElement("td", null,
                React.createElement(TableRow, null, (languageTranslation === null || languageTranslation === void 0 ? void 0 : languageTranslation.value) || ISO6391.getNativeName(lang.language_code))),
            React.createElement(LevelTD, null, lang.languageLevel || lang.level)));
    });
};
export const RequiredLanguages = ({ languages }) => {
    return (React.createElement(TableWrapper, null,
        React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement(LanguageTd, null,
                        React.createElement(FormattedMessage, { id: "offer_detail.details.language" }),
                        ' '),
                    React.createElement(LevelTitleTD, null,
                        React.createElement(FormattedMessage, { id: "profile.resume.language_level" })))),
            React.createElement("tbody", null,
                React.createElement(Languages, { languages: languages })))));
};

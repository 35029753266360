import React from 'react'
import { Link } from '@src/components/elements'
import Button from 'components/modules/global/button'
import { Col, Row } from 'antd'

function FormResponse(props) {
	const { text, link } = props

	return (
		<div className="form-response">
			<div className="form-response__content">
				<Row justify="center">
					<Col xs={20}>
						<div className="form-response__text">{text}</div>
					</Col>
				</Row>
				{link && (
					<div className="form-response__link">
						<Link className="link" to={link.route}>
							<Button text={link.text} />
						</Link>
					</div>
				)}
			</div>
		</div>
	)
}

export default FormResponse

import React from 'react';
import { Pagination as AntPagination } from 'antd';
import styled from 'styled-components';
export const StyledPagination = styled(AntPagination) `
	& {
		text-align: center;
	}
	.ant-pagination-item,
	.ant-pagination-next .ant-pagination-item-link,
	.ant-pagination-prev .ant-pagination-item-link {
		border-radius: 0.4rem;
	}
`;
const Pagination = props => {
    return React.createElement(StyledPagination, Object.assign({}, props));
};
export { Pagination };

import styled from 'styled-components';
import { up } from 'styled-breakpoints';
export const StyledFeatures = styled.div `
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	${up('md')} {
		justify-content: initial;
	}
`;
export const Box = styled.div `
	width: 100%;
	padding: 0 2em 3em;
	display: flex;
	position: relative;
	flex-direction: column;

	${up('sm')} {
		width: 50%;
	}

	${up('md')} {
		width: 33%;
	}
`;
export const Picture = styled.div `
	width: 150px;
	height: 150px;
	overflow: hidden;
	align-self: center;
	& img {
		display: block;
		max-width: 100%;
	}
`;
export const Content = styled.dl `
	align-self: center;
	flex: 1;
	text-align: center;

	dt {
		color: ${props => props.theme.color.brand2};
		font-size: 1.2em;
		font-weight: 700;
		margin: 0 0 0.5em;
	}
`;

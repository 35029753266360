// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { SliderCredits, Button } from '@src/components/elements';
import styled, { useTheme } from 'styled-components';
import { transparentize } from 'polished';
import { getCreditPricing } from '@src/shared/get-credit-pricing';
const ButtonFreeText = styled.div ``;
const BoxHead = styled.div `
	padding: 1em 2em;
	text-align: center;
	background: ${props => transparentize(0.8, props.theme.color.success)};
	border-radius: 4px;
	font-weight: 400;
	font-size: 1.3em;
	strong {
		font-weight: 700;
	}
`;
const CreditsIntroduction = styled.div `
	text-align: center;
	color: ${props => transparentize(0.2, props.theme.color.black)};
	font-size: 1.2em;
	padding: 2em 3em 2em;

	small {
		color: ${props => transparentize(0.5, props.theme.color.black)};
		font-size: 0.8em;
	}
`;
const AboveCredits = styled.div `
	text-align: center;
	color: ${props => transparentize(0.6, props.theme.color.black)};
	font-weight: 700;
	padding: 0 3em 0.5em;
`;
const OriginalPrice = styled.div `
	text-align: center;
	color: ${props => transparentize(0.1, props.theme.color.gray4)};
	font-size: 0.8em;
	padding: 0 2em 0;

	small {
		font-size: 0.9em;
	}
`;
const TotalPrice = styled.div `
	text-align: center;
	color: ${props => transparentize(0.1, props.theme.color.black)};
	font-size: 1.3em;
	padding: 0.5em 2em 1em;

	strong {
		color: ${props => props.theme.color.success};
	}

	small {
		color: ${props => transparentize(0.6, props.theme.color.black)};
		font-size: 0.9em;
	}
`;
const BoxChosen = styled.div `
	padding: 0 1em 0.1em;
	text-align: center;
	font-size: 3em;
	font-weight: 700;
`;
const SliderWrapper = styled.div `
	padding: 2em 4em;
	text-align: center;
`;
const Introduction = styled.div `
	text-align: center;
	color: ${props => props.theme.color.gray6};
	font-size: 1.2em;
	padding: 1em 2em 0;
`;
const PricingCalculation = styled.div `
	display: flex;
	justify-content: center;
	padding: 0;
`;
const PricingCalculationBox = styled.div `
	display: flex;
	width: 50%;
	justify-content: center;

	dl {
		margin: 0;
	}
	dt {
		color: ${props => transparentize(0.4, props.theme.color.black)};
		font-size: 0.9em;
		font-weight: 700;
	}
	dd {
		color: ${props => props.theme.color.black};
		font-size: 2em;
		margin: 0;

		small {
			display: block;
			color: ${props => props.theme.color.gray4};
			font-size: 0.5em;
		}
	}
`;
const PricingTotal = styled.div `
	border-top: 1px solid ${props => transparentize(0.85, props.theme.color.black)};
	margin: 2em 0 0;
	padding: 2em 0 0;
	width: 100%;
	justify-content: center;
`;
const AdminCredits = () => {
    const theme = useTheme();
    const [creditCount, setCreditCount] = useState(10);
    const [creditPricing, setCreditPricing] = useState(null);
    useEffect(() => {
        setCreditPricing(getCreditPricing(creditCount));
    }, [creditCount]);
    return (React.createElement("div", { className: "panel" },
        React.createElement(BoxHead, null,
            React.createElement(FormattedMessage, { id: "admin.credits.refill_headline", values: {
                    strong: chunks => React.createElement("strong", null, chunks),
                } })),
        React.createElement(CreditsIntroduction, null,
            React.createElement(FormattedMessage, { id: "admin.credits.credit_explanation_1" }),
            React.createElement("br", null),
            React.createElement("small", null,
                "(",
                React.createElement(FormattedMessage, { id: "admin.credits.credit_explanation_2" }),
                ")")),
        React.createElement(AboveCredits, null,
            React.createElement(FormattedMessage, { id: "admin.credits.want_order" })),
        React.createElement(BoxChosen, null,
            React.createElement(FormattedMessage, { values: { count: creditCount }, id: "general.credits.pluralized" })),
        React.createElement(SliderWrapper, null,
            React.createElement(SliderCredits, { value: creditCount, onChange: value => setCreditCount(value) })),
        creditPricing && (React.createElement(Introduction, null,
            React.createElement(PricingCalculation, null,
                React.createElement(PricingCalculationBox, null,
                    React.createElement("dl", null,
                        React.createElement("dt", null,
                            React.createElement(FormattedMessage, { id: "admin.credits.price_single" })),
                        React.createElement("dd", null,
                            React.createElement(FormattedNumber, { value: creditPricing.creditPriceWithoutVAT, style: "currency", currency: "EUR" }),
                            React.createElement("small", null,
                                React.createElement(FormattedNumber, { value: creditPricing.creditPrice, style: "currency", currency: "EUR" }),
                                ' ',
                                React.createElement(FormattedMessage, { id: "general.credits.with_vat" }))))),
                creditPricing.discountRelative > 0 && (React.createElement(PricingCalculationBox, null,
                    React.createElement("dl", null,
                        React.createElement("dt", null,
                            React.createElement(FormattedMessage, { id: "admin.credits.you_save" }),
                            " ~",
                            React.createElement(FormattedNumber, { value: creditPricing.discountRelative, style: "unit", unit: "percent" })),
                        React.createElement("dd", null,
                            React.createElement(FormattedNumber, { value: creditPricing.discountWithoutVAT, style: "currency", currency: "EUR" }),
                            ' ',
                            React.createElement("small", null,
                                React.createElement(FormattedNumber, { value: creditPricing.discount, style: "currency", currency: "EUR" }),
                                ' ',
                                React.createElement(FormattedMessage, { id: "general.credits.with_vat" }))))))),
            React.createElement(PricingTotal, null,
                creditPricing.discountRelative > 0 && (React.createElement(OriginalPrice, null,
                    React.createElement(FormattedMessage, { id: "admin.credits.price_without_discount" }),
                    ":",
                    ' ',
                    React.createElement("strong", null,
                        React.createElement(FormattedNumber, { value: creditPricing.originalPriceWithoutVAT, style: "currency", currency: "EUR" })),
                    ' ',
                    creditPricing.discountRelative > 0 && (React.createElement("small", null,
                        "(",
                        React.createElement(FormattedNumber, { value: creditPricing.originalPrice, style: "currency", currency: "EUR" }),
                        ' ',
                        React.createElement(FormattedMessage, { id: "general.credits.with_vat" }),
                        ")")))),
                React.createElement(TotalPrice, null,
                    React.createElement(FormattedMessage, { id: "admin.credits.price_total" }),
                    ' ',
                    creditPricing.discountRelative > 0 && (React.createElement(FormattedMessage, { id: "admin.credits.with_discount" })),
                    ":",
                    ' ',
                    React.createElement("strong", null,
                        React.createElement(FormattedNumber, { value: creditPricing.totalPriceWithoutVAT, style: "currency", currency: "EUR" })),
                    ' ',
                    React.createElement("small", null,
                        "(",
                        React.createElement(FormattedNumber, { value: creditPricing.totalPrice, style: "currency", currency: "EUR" }),
                        ' ',
                        React.createElement(FormattedMessage, { id: "general.credits.with_vat" }),
                        ")")),
                React.createElement(Button, { style: {
                        background: theme.color.success,
                        borderColor: theme.color.success,
                        color: theme.color.white,
                    }, link: `/admin/objednavka/${creditCount}` },
                    React.createElement(ButtonFreeText, null,
                        React.createElement(FormattedMessage, { id: "admin.credits.continue_with_order" }))))))));
};
export { AdminCredits };

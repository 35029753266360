import React from 'react'

const IconSocialInstagram = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
		<path
			d="M102.08333 0H37.91667A37.975 37.975 0 000 37.91667v64.16666A37.975 37.975 0 0037.91667 140h64.16666A37.975 37.975 0 00140 102.08333V37.91667A37.975 37.975 0 00102.08333 0zM70 102.08333A32.08333 32.08333 0 11102.08333 70 32.08333 32.08333 0 0170 102.08333zm37.91667-64.16666a8.75 8.75 0 118.75-8.75 8.75 8.75 0 01-8.75 8.75z"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="0"
		/>
		<radialGradient id="instagramGradient" r="150%" cx="30%" cy="107%">
			<stop stopColor="#fdf497" offset="0" />
			<stop stopColor="#fdf497" offset="0.05" />
			<stop stopColor="#fd5949" offset="0.45" />
			<stop stopColor="#d6249f" offset="0.6" />
			<stop stopColor="#285AEB" offset="0.9" />
		</radialGradient>
	</svg>
)

export default IconSocialInstagram

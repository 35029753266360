import React from 'react';
import { Col, Row, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import cdnLink from '@src/shared/cdn-link';
import { FormattedMessage } from 'react-intl';
function getBase64(img) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result));
        reader.readAsDataURL(img);
    });
}
const renderUpload = ({ input, label, accept, name, beforeUpload, aspect, meta: { touched, error } }) => {
    var _a;
    const isValueString = typeof input.value === 'string';
    const thumbUrl = isValueString
        ? cdnLink({
            file: `web/company/${input.value}`,
            transformation: '',
        })
        : (_a = input.value.xhr) === null || _a === void 0 ? void 0 : _a.responseUrl;
    return (React.createElement(Row, null,
        React.createElement(Col, { xs: 24, id: name },
            React.createElement("label", { className: "form__label form__label--file", htmlFor: name }, label),
            React.createElement(ImgCrop, { rotate: true, aspect: aspect },
                React.createElement(Upload, { className: "form_upload", action: file => getBase64(file), listType: "picture-card", fileList: input.value ? [Object.assign(Object.assign({}, input.value), { thumbUrl })] : [], onChange: ({ file }) => {
                        input.onChange(file);
                    }, beforeUpload: beforeUpload, accept: accept },
                    "+ ",
                    React.createElement(FormattedMessage, { id: "registration.registration_form.upload", defaultMessage: "Upload" }))),
            touched && error && React.createElement("div", { className: "form__input-rounded-error" }, error))));
};
export default renderUpload;

import actionNames from '../actions';

const defaultState = {
	resetPasswordEmailError: false,
};

export default function resetPasswordEmail(state = defaultState, action) {
	switch (action.type) {

	case actionNames.resetPasswordEmailError().type:
		return Object.assign({}, state, {
			resetPasswordEmailError: true,
		});

	default:
		return state;
	}
}

import React from 'react';
import { Tabs } from 'antd';

const defaultProps = {
	animated: false,
};

function Container(props) {
	const { children, ...otherProps } = props;
	return <Tabs {...defaultProps} {...otherProps}>{children}</Tabs>;
}

const TabsComponent = { Container, TabPane: Tabs.TabPane };

export default TabsComponent;

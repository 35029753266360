import React from 'react';
import { connect } from 'react-redux';
import actions from 'actions';
import SettingsForm from './modules/settings-form';
import { useIntl } from 'react-intl';


const PersonalInfoEdit = props => {
	const intl = useIntl();
	return (
		<div className="panel">
			<SettingsForm
				onSubmit={props.handleSubmit}
				intl={intl}
			/>
		</div>
	);
}


const mapDispatchToProps = dispatch => ({
	handleSubmit: (values) => {
		dispatch(actions.accountEditStart(values));
	},
});

export default connect(
	null,
	mapDispatchToProps,
)(PersonalInfoEdit);

import React from 'react';
import { Router } from 'react-router';
import * as Analytics from '@src/helpers/analytics-tracking';
import actions from '@src/actions';
import Routes from './Routes';
function RouterComponent(props) {
    const { store, history } = props;
    React.useEffect(() => {
        store.dispatch(actions.mainInitStart());
        Analytics.trackPageView();
    }, []);
    return (React.createElement(Router, { history: history },
        React.createElement(Routes, null)));
}
export { RouterComponent };
